import Vue from 'vue'

import dayjs from 'dayjs'

import {
	postType,
	subjectLoungeType,
	eventCategory,
	applicationStatus,
	reservationStatus,
	testStatus,
	marketerRole,
	factoryRole,
	paymentMethods,
	idPhotoStatus,
	contentsMarketStatus,
} from '@/helpers'

export const resolveEventCategory = category => {
	return eventCategory[category]?.name
}
Vue.filter('resolveEventCategory', resolveEventCategory)

export const resolveApplicationStatus = (
	category,
	status,
	isRetake,
	isCharged,
) => {
	if (!status) return ''

	let _status =
		applicationStatus[status]?.name || applicationStatus.DEFAULT.name

	if (_status === applicationStatus.APPLIED.name) {
		if (category !== eventCategory.LICENSE_TEST.value) {
			_status = isRetake
				? '재수강 신청완료'
				: isCharged
				? '결제대기'
				: '신청완료'
		}
	}

	if (isRetake) {
		if (category === eventCategory.LICENSE_TEST.value) {
			_status = '재시험 신청완료'
		}

		if (status === applicationStatus.DONE.value) {
			_status = '완료'
		}
	}

	return _status
}

export function transactionTimeFormat(datetime) {
	if (!datetime) return ''
	return dayjs(datetime).format('YYYY/MM/DD HH:mm')
}
Vue.filter('transactionTimeFormat', transactionTimeFormat)

export const resolveApplicationStatusVariant = (
	category,
	status,
	isRetake,
	isCharged,
) => {
	if (!status) return ''

	let statusVariant = 'secondary'

	if (status === applicationStatus.APPLIED.value) {
		if (
			!isRetake &&
			[
				eventCategory.EDUCATION_LICENSE.value,
				eventCategory.EDUCATION.value,
			].includes(category)
		) {
			statusVariant = isCharged ? 'warning' : 'primary'
		} else {
			statusVariant = 'primary'
		}
	} else if (status === applicationStatus.CANCELED.value) {
		statusVariant = 'error'
	} else if (status === applicationStatus.PAID.value) {
		statusVariant = 'info'
	} else if (status === applicationStatus.DONE.value) {
		statusVariant = 'secondary'
	}

	if (isRetake) {
		statusVariant = 'primary'
		if (status === applicationStatus.DONE.value) {
			statusVariant = 'secondary'
		}
	}

	return statusVariant
}

export const resolveReservationStatus = status => {
	return reservationStatus[status]?.name
}

export const resolveReservationStatusVariant = status => {
	let statusVariant = 'secondary'

	if (status === reservationStatus.APPLIED.value) {
		statusVariant = 'primary'
	} else if (status === reservationStatus.PAID.value) {
		statusVariant = 'info'
	} else if (status === reservationStatus.PAYWAITING.value) {
		statusVariant = 'warning'
	} else if (status === applicationStatus.CANCELED.value) {
		statusVariant = 'error'
	} else {
		statusVariant = 'secondary'
	}

	return statusVariant
}

export const resolveMarketerRole = role => {
	return marketerRole[role]?.name
}

export const resolveFactoryRole = role => {
	return factoryRole[role]?.name
}

export const resolveTestStatus = status => {
	return testStatus[status]?.name
}
Vue.filter('resolveTestStatus', resolveTestStatus)

export const resolveTestStatusVariant = status => {
	let statusVariant

	if (status === testStatus.PASS.value) {
		statusVariant = 'success'
	} else if (status === testStatus.FAIL.value) {
		statusVariant = 'error'
	} else {
		statusVariant = 'secondary'
	}

	return statusVariant
}

export const resolvePaymentMethod = paymentMethod => {
	return paymentMethods[paymentMethod?.toUpperCase()]?.name
}

export const resolveIdPhotoStatus = status => {
	return idPhotoStatus[status]?.name
}

export const resolveIdPhotoStatusVariant = status => {
	let statusVariant

	if (status === idPhotoStatus.CONFIRMED.value) {
		statusVariant = 'success'
	} else if (status === idPhotoStatus.REJECTED.value) {
		statusVariant = 'error'
	} else {
		statusVariant = 'secondary'
	}

	return statusVariant
}

export const resolveContentsMarketStatus = status => {
	return contentsMarketStatus[status]?.name
}

export const resolveContentsMarketStatusVariant = status => {
	let statusVariant = 'transparent'

	switch (status) {
		case contentsMarketStatus.APPROVED.value:
			statusVariant = 'success'
			break
		case contentsMarketStatus.DISAPPROVED.value:
			statusVariant = 'secondary'
			break
		case contentsMarketStatus.READY.value:
			statusVariant = 'info'
			break
		default:
			break
	}

	return statusVariant
}

export function resolvePostType(type) {
	return postType[type]?.name
}
Vue.filter('resolvePostType', resolvePostType)

export function resoloveSubjectLoungeType(type) {
	return subjectLoungeType[type]?.name
}
Vue.filter('resoloveSubjectLoungeType', resoloveSubjectLoungeType)
