<template>
	<v-app class="content-layout vertical-nav">
		<app-left
			:is-drawer-open.sync="isDrawerOpen"
			@is-sign-out="isSignout = $event"
			v-if="!isSigninPage"
		/>

		<v-main tag="article">
			<router-view />
		</v-main>
		<v-speed-dial
			fixed
			right
			bottom
			v-model="fab"
			style="z-index: 999"
			v-if="!isSigninPage"
		>
			<template v-slot:activator>
				<v-btn
					fab
					v-model="fab"
					color="primary"
					class="d-block d-lg-none"
					@click="isDrawerOpen = !isDrawerOpen"
				>
					<v-icon v-if="fab">mdi-close</v-icon>
					<v-icon v-else>mdi-menu</v-icon>
				</v-btn>
			</template>
		</v-speed-dial>
	</v-app>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

import i18n from '@/i18n'

import { useRouter } from '@core/utils'
import { getVuetify } from '@core/utils'
import { confirmSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

import AppLeft from './AppLeft'

export default {
	components: {
		AppLeft,
	},
	setup() {
		const { router } = useRouter()
		const $vuetify = getVuetify()
		const { route } = useRouter()
		const fab = ref(false)
		const isSignout = ref(false)
		const isDrawerOpen = ref($vuetify.breakpoint.lgAndUp ? true : false)

		const fabClickOutSide = () => {
			if (isDrawerOpen.value) {
				fab.value = !fab.value
			}
		}

		const isSigninPage = computed(() => {
			return route.value.name === 'signin'
		})

		const isSignin = () => {
			return route.value.name === 'signin'
		}

		const signout = async () => {
			const confirm = await confirmSwal({
				html: i18n.t('signOutForm.html'),
				confirmButtonText: i18n.t('signOutForm.confirmButtonText'),
				cancelButtonText: i18n.t('signOutForm.cancelButtonText'),
			})

			if (confirm.isConfirmed) {
				await AuthService.signout()
				if (router.currentRoute.path !== '/') {
					router.push('/')
				}
			}
		}

		watch(
			() => isSignout.value,
			currentValue => {
				if (currentValue) {
					signout()
				}
			},
		)

		return {
			fab,
			isDrawerOpen,
			isSignin,
			isSignout,
			isSigninPage,

			fabClickOutSide,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-progress-linear {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}
button {
	::v-deep .v-btn__content {
		color: #ffffff;
	}
}
</style>
