import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
	require('./error'),
	require('./user'),
	require('./signin'),
	require('./license'),
	require('./dashboard'),
	require('./event'),
	require('./setting'),
	require('./notice'),
	require('./marketItem'),
	require('./marketOrder'),
	require('./marketPost'),
	require('./marketLocation'),
	require('./marketCourse'),
	require('./marketBalance'),
	require('./marketSurvey'),
]

let mergedRoutes = []
routes.forEach(route => {
	mergedRoutes = mergedRoutes.concat(route.default)
})

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: mergedRoutes,
})

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

	if (requiresAuth && !store.getters['auth/isSignin']) {
		next(`signin/?redirectUri=${to.fullPath}`)
	} else {
		next()
	}
})

export default router
