<template>
	<v-card>
		<v-toolbar flat>
			<v-btn icon :to="{ name: 'user-list' }">
				<v-icon large>mdi-chevron-left</v-icon>
			</v-btn>
			<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
				<v-tab v-for="(item, index) in items" :key="'tab-' + index">
					{{ item }}
				</v-tab>
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item>
				<user-detail-info :user-id="userId" />
			</v-tab-item>
			<v-tab-item>
				<user-detail-list-of-event-participant :user-id="userId" />
			</v-tab-item>
			<v-tab-item>
				<user-detail-order-payment-list :user-id="userId" />
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import UserDetailInfo from './UserDetailInfo.vue'
import UserDetailListOfEventParticipant from './UserDetailListOfEventParticipant.vue'
import UserDetailOrderPaymentList from './UserDetailOrderPaymentList.vue'

export default {
	components: {
		UserDetailInfo,
		UserDetailListOfEventParticipant,
		UserDetailOrderPaymentList,
	},
	setup() {
		const tab = ref(0)
		const { route } = useRouter()
		const userId = route.value.params.userId
		const items = reactive(['상세보기', '이벤트 신청 내역', '주문/결제 내역'])

		return {
			tab,
			items,
			userId,
		}
	},
}
</script>
<style lang="scss" scoped></style>
