<template>
	<v-card elevation="0">
		<v-card-title>답변</v-card-title>
		<v-divider />
		<v-card-text>
			<template v-if="post.comments.length > 0">
				<div
					v-for="comment in post.comments"
					:key="'comment-' + comment.id"
					class="mt-3 d-flex justify-space-between"
				>
					<div class="d-flex">
						<v-avatar size="26">
							<v-img
								:src="
									comment.user.publicPictureUrl ||
									require('@/assets/images/avatars/default.svg')
								"
							/>
						</v-avatar>
						<div class="pl-2">
							<div class="mt-1">
								<strong class="mr-2 text-sm">{{ comment.user.uid }}</strong>
								<span class="text-sm" v-html="comment.title"></span>
							</div>
						</div>
					</div>
					<div>
						<span class="text-sm">
							{{ comment.createdAt | convertUTC2KST }}
						</span>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="mt-4">등록된 답변이 없습니다</div>
			</template>
		</v-card-text>
		<v-divider />
		<v-card-actions style="background-color: #efefef" class="py-3">
			<v-textarea
				v-model="comment"
				placeholder="답변 달기..."
				outlined
				hide-details=""
				rows="3"
				style="background-color: white"
			/>
			<v-btn
				color="primary"
				:ripple="false"
				class="px-0 font-weight-bold ml-2 upload-comment"
				@click="addComment"
				style="height: 82px !important"
			>
				등록
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { ref } from '@vue/composition-api'

import { warningSwal } from '@/plugins/swalMixin'

import MarketPostService from '@/services/MarketPostService'

export default {
	components: {},
	props: {
		post: {
			type: Object,
			required: true,
		},
	},
	setup(props, { emit }) {
		const comment = ref('')

		const addComment = async () => {
			if (comment.value === '') {
				warningSwal('답변을 작성해주세요')
				return
			}

			try {
				await MarketPostService.postMarketPostComment({
					id: props.post.id,
					comment: comment.value,
				})
				comment.value = ''
				emit('fetch-post', true)
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '댓글을 업로드하는데 문제가 발생했습니다.',
				)
			}
		}

		return {
			comment,

			addComment,
		}
	},
}
</script>
