<template>
	<div>
		<validation-provider
			v-slot="{ errors }"
			name="contentMarketType"
			rules="required"
		>
			<v-select
				item-text="name"
				item-value="value"
				:items="contentMarketTypeGroups"
				:error-messages="errors"
				v-model="form.type"
				label="콘텐츠 마켓 타입을 선택하세요"
				class="mb-1"
				dense
				outlined
			/>
		</validation-provider>

		<validation-provider v-slot="{ errors }" name="categories">
			<v-chip
				class="mb-2 mr-1"
				color="primary"
				text-color="white"
				@click:close="removeChip(index)"
				v-for="(category, index) in form.categories"
				close
				:key="index"
			>
				{{ category }}
			</v-chip>

			<v-text-field
				v-model="inputValue"
				@keypress.enter="addChip"
				:error-messages="errors"
				label="해시태그를 입력하고 엔터를 눌러 추가하세요"
				dense
				outlined
			/>
		</validation-provider>

		<validation-provider
			v-slot="{ errors }"
			name="subjectLoungeType"
			v-if="isLoungeContents"
		>
			<v-select
				item-text="name"
				item-value="value"
				:items="subjectLoungeTypeGroups"
				:error-messages="errors"
				v-model="form.lounge"
				label="라운지 위치를 선택하세요"
				class="mb-1"
				dense
				outlined
			/>
		</validation-provider>

		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>

		<validation-provider name="content" rules="required">
			<toast-ui-editor
				:content.sync="form.content"
				:market-post-subject-id="form.id"
				:subject-market-post-id="form.subjectMarketPostId"
				:type="'marketPostSubject'"
			/>
		</validation-provider>

		<validation-provider v-slot="{ errors }" name="dateRange" rules="required">
			<date-picker-range-dialog
				class="mt-4"
				:error-messages="errors"
				:label="'등록 시작 - 종료 날짜를 입력하세요'"
				:date-value.sync="dateRange"
			/>
		</validation-provider>

		<validation-provider name="visible">
			<v-checkbox
				class="mb-2"
				v-model="form.visible"
				label="공개 여부"
				hide-details
			/>
		</validation-provider>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { postType, subjectLoungeType } from '@/helpers'

import ToastUiEditor from '@/components/ToastUiEditor.vue'
import DatePickerRangeDialog from '@/components/common/DatePickerRangeDialog.vue'

export default {
	components: {
		ToastUiEditor,
		DatePickerRangeDialog,
	},
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const inputValue = ref('')
		const contentMarketTypeGroups = ref([
			postType.CONTEST2,
			postType.CONTEST2_DONA,
		])
		const subjectLoungeTypeGroups = ref([
			subjectLoungeType.MIR_YONGSAN,
			subjectLoungeType.MIR_UIJEONGBU,
			subjectLoungeType.MIR_JEONJU,
		])

		const dateRange = computed({
			get() {
				return [props.form.startDate, props.form.endDate]
			},
			set(value) {
				const temp = value.sort(function (a, b) {
					return new Date(a) - new Date(b)
				})

				props.form.startDate = temp[0]
				props.form.endDate = temp[1]
			},
		})

		const addChip = () => {
			if (props.form.categories == null) {
				props.form.categories = []
			}

			if (inputValue.value.length) {
				const found = props.form.categories.find(e => e === inputValue.value)
				if (!found) {
					props.form.categories.push(inputValue.value)
				}

				inputValue.value = ''
			}
		}

		const removeChip = index => {
			props.form.categories.splice(index, 1)
		}

		const isLoungeContents = computed(() => {
			return props.form.type === postType.CONTEST2.value
		})

		return {
			postType,
			dateRange,
			inputValue,
			subjectLoungeTypeGroups,
			contentMarketTypeGroups,

			isLoungeContents,

			addChip,
			removeChip,
		}
	},
}
</script>
