<template>
	<v-dialog v-model="isOpenUserIdPhotoDialog" persistent max-width="450">
		<v-card>
			<v-card-title>
				증명 사진 확인하기
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-user-id-photo-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<v-img :src="user.idPhotoUrl" v-if="user && user.idPhotoUrl" />
			</v-card-text>
			<v-card-actions class="d-flex">
				<v-btn @click="confirmIdPhoto" color="primary" style="width: 50%">
					승인
				</v-btn>
				<v-btn @click="rejectIdPhoto" color="error" style="width: 50%">
					거절
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'

export default {
	props: {
		isOpenUserIdPhotoDialog: {
			type: Boolean,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
	},
	components: {},
	setup(props) {
		const confirmIdPhoto = async () => {
			const confirm = await confirmSwal('승인 처리하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await UserService.confirmIdPhoto(props.user.id)
					successSwal('승인 처리 되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '승인 처리하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const rejectIdPhoto = async () => {
			const confirm = await confirmSwal('거절 처리하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await UserService.rejectIdPhoto(props.user.id)
					successSwal('거절 처리 되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '거절 처리하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			confirmIdPhoto,
			rejectIdPhoto,
		}
	},
}
</script>
<style lang="scss" scoped>
.title {
	border: 1px solid #dadde6;
	border-radius: 5px;
	padding: 10px;
	min-height: 40px;
	font-size: 16px;
}
.template {
	border: 1px solid #dadde6;
	border-radius: 5px;
	padding: 10px;
	min-height: 340px;
}
</style>
