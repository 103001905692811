var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"title","item-value":"id","items":_vm.licenses,"error-messages":errors,"readonly":"","label":"자격증을 입력하세요","required":""},model:{value:(_vm.form.licenseId),callback:function ($$v) {_vm.$set(_vm.form, "licenseId", $$v)},expression:"form.licenseId"}})]}}])}),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"타입을 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}])}),_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"시험 장소를 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})]}}])}),_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"시험 정원을 입력하세요","autocomplete":"off","suffix":"명","required":""},model:{value:(_vm.form.maxParticipants),callback:function ($$v) {_vm.$set(_vm.form, "maxParticipants", $$v)},expression:"form.maxParticipants"}})]}}])}),_c('validation-provider',{attrs:{"name":"testAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"시험 날짜를 입력하세요","required":""},model:{value:(_vm.form.testAt),callback:function ($$v) {_vm.$set(_vm.form, "testAt", $$v)},expression:"form.testAt"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }