<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{
					name: 'license-test-round',
					params: { licenseId: licenseId, testRoundId: testRoundId },
				}"
				class="text-decoration-none"
			>
				시험지 리스트
			</router-link>
			<span class="mx-1">&#62;</span>
			답안지 등록
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<div v-for="item in workbook.problemCount" :key="item">
				<v-col cols="12" md="12" sm="12">
					<multiple-choice-questions
						:workbook-id="workbook.id"
						:question-number="item"
						@add-answer-sheet="addAnswerSheet"
					/>
				</v-col>
			</div>
		</v-card-text>
		<v-card-actions class="d-flex justify-end">
			<v-btn @click="registerAnswerSheet" color="primary">답안지 등록</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import MultipleChoiceQuestions from './components/MultipleChoiceQuestions.vue'

export default {
	components: {
		MultipleChoiceQuestions,
	},
	setup() {
		const { route } = useRouter()
		const workbook = ref({})
		const answerSheet = ref([])
		const licenseId = ref(route.value.params.licenseId)
		const testRoundId = ref(route.value.params.testRoundId)
		const workbookId = ref(route.value.params.workbookId)

		const getWorkbook = async () => {
			try {
				workbook.value = await MarketLicenseService.getWorkbook(
					workbookId.value,
				)

				store.dispatch('license/setAnswerSheet', {
					workbookId: workbookId.value,
					answerSheet: workbook.value.answer,
				})
			} catch (e) {
				console.error(e)
			}
		}
		getWorkbook()

		const addAnswerSheet = answer => {
			answerSheet.value = store.getters['license/getAnswerSheet'](
				workbookId.value,
			)
			const index = answerSheet.value.findIndex(obj => obj.key === answer.key)
			if (index !== -1) {
				if (answer.value) {
					answerSheet.value[index].value = answer.value
				}
				if (answer.question) {
					answerSheet.value[index].question = answer.question
				}
				if (answer.choices) {
					answerSheet.value[index].choices = answer.choices
				}
			} else {
				answerSheet.value.push(answer)
			}

			store.dispatch('license/setAnswerSheet', {
				workbookId: workbookId.value,
				answerSheet: answerSheet.value,
			})
		}

		const registerAnswerSheet = async () => {
			const confirm = await confirmSwal('시험 답안지를 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const payload = {
						...workbook.value,
						answer: [...answerSheet.value],
					}
					MarketLicenseService.updateTestRoundWorkbook(payload)
					store.dispatch('license/deleteAnswerSheet', {
						workbookId: workbookId.value,
					})
					successSwal('답안지 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '답안지를 등록하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			workbook,
			licenseId,
			testRoundId,

			addAnswerSheet,
			registerAnswerSheet,
		}
	},
}
</script>
