<template>
	<v-card class="balance">
		<v-card-title>
			<v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
			출금 신청 내역
		</v-card-title>
		<div class="balance-list">
			<v-card-title class="d-flex justify-space-between align-end">
				<div>
					<div>
						<v-radio-group row v-model="dateCriteriaGroup" hide-details>
							<v-radio label="신청 날짜 기준" value="transactionAt" />
							<v-radio label="출금 날짜 기준" value="withdrawnAt" />
						</v-radio-group>
					</div>
					<div class="d-flex align-end">
						<date-picker-range-dialog
							class="mt-4"
							:label="'시작-종료 날짜를 입력하세요'"
							style="width: 235px"
							:date-value.sync="dateRange"
						/>
						<v-btn @click="inquiryDate" color="primary">조회</v-btn>
					</div>
				</div>
				<div class="d-flex">
					<v-select
						item-text="name"
						item-value="value"
						:items="withdrawnStatusGroups"
						label="출금 완료 여부"
						:value="selectedWithdrawnStatus"
						@change="changeWithdrawnStatus"
						style="max-width: 110px"
						hide-details
						class="ml-1"
						dense
						outlined
					/>
					<v-select
						item-text="name"
						item-value="value"
						:items="itemsPerPageGroups"
						label="페이지당 개수"
						@change="changeItemsPerPage"
						:value="10"
						style="max-width: 100px"
						hide-details
						class="ml-1"
						dense
						outlined
					/>
					<v-btn
						class="ml-2"
						@click="downloadExcel"
						color="accent"
						:loading="isExcelDownloading"
					>
						엑셀 다운로드
					</v-btn>
				</div>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="withdrawns"
				v-model="selectedWithdrawns"
				:items-per-page="itemsPerPage"
				no-data-text="조회된 내역이 없습니다."
				class="elevation-1 cursor-pointer"
				:loading="isLoading"
				loading-text="주문을 불러오는 중입니다..."
				show-select
				hide-default-footer
			>
				<template
					v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
				>
					<v-simple-checkbox
						:value="isSelected"
						@input="select"
						:ripple="false"
						:disabled="item.withdrawn"
					/>
				</template>
				<template v-slot:[`item.processing`]="{ item }">
					<v-chip :color="item.withdrawn ? 'success' : 'secondary'">
						{{ item.withdrawn ? '처리 완료' : '미처리' }}
					</v-chip>
				</template>
				<template v-slot:[`item.user`]="{ item }">
					{{ item.user.name }}
				</template>
				<template v-slot:[`item.phone`]="{ item }">
					{{ item.user.phoneNumber | excludeInternationalPhoneFormat }}
				</template>
				<template v-slot:[`item.refund`]="{ item }">
					예금주:
					<strong>{{ item.user.name }}</strong>
					<br />
					계좌정보:
					<strong>
						{{ resolveBankCode(item.user.refundBankCode) }}
						{{ item.user.refundBankAccount }}
					</strong>
				</template>
				<template v-slot:[`item.amount`]="{ item }">
					{{ Math.abs(item.amount) | commaFormat }} 원
				</template>
				<template v-slot:[`item.withdrawnAmount`]="{ item }">
					<span class="withdrawal-amount">
						{{ calculateWithdrawnAmount(item.amount) | commaFormat }} 원
					</span>
				</template>
				<template v-slot:[`item.transactionDate`]="{ item }">
					{{ transactionTimeFormat(convertUTC2KST(item.transactionDate)) }}
				</template>
				<template v-slot:[`item.withdrawnAt`]="{ item }">
					{{ transactionTimeFormat(convertUTC2KST(item.withdrawnAt)) || '' }}
				</template>
			</v-data-table>
			<div class="mx-auto mt-6">
				<v-pagination
					v-model="currentPage"
					:length="totalPages"
					@input="handlePageChange"
					total-visible="7"
				/>
			</div>
		</div>
		<v-card-actions class="d-flex justify-end pt-3">
			<v-btn
				color="primary"
				@click="onSave"
				:loading="isProcessing"
				:disabled="selectedWithdrawns.length == 0"
			>
				출금 완료 처리
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { onMounted, ref, reactive, computed } from '@vue/composition-api'

import dayjs from 'dayjs'

import { convertUTC2KST } from '@/filter'
import { withdrawnStatus } from '@/helpers'
import { transactionTimeFormat } from '@core/utils/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import UtilityService from '@/services/UtilityService'
import MarketBalanceWithdrawalService from '@/services/MarketBalanceWithdrawalService'

import DatePickerRangeDialog from '@/components/common/DatePickerRangeDialog.vue'

dayjs.locale('ko')

const ITEMS_PER_PAGE = 10
const AMOUNT_WEIGHT = 0.9
const TAX_LIMIT_AMOUNT = 1000
const TAX_RATE = 0.033

export default {
	components: {
		DatePickerRangeDialog,
	},
	setup() {
		const isLoading = ref(false)
		const isProcessing = ref(false)
		const isExcelDownloading = ref(false)
		const withdrawns = ref([])
		const dateCriteriaGroup = ref('transactionAt')
		const banks = ref([])
		const beginDate = ref('')
		const endDate = ref('')
		const headers = ref([
			{ text: '출금 처리 여부', value: 'processing' },
			{ text: '신청자', value: 'user' },
			{ text: '휴대폰 번호', value: 'phone' },
			{ text: '출금 신청 금액', value: 'amount' },
			{ text: '출금 계좌 정보', value: 'refund' },
			{
				text: '출금 예상액',
				value: 'withdrawnAmount',
				width: '200px',
				align: 'right',
			},
			{ text: '신청 날짜', value: 'transactionDate' },
			{ text: '출금 날짜', value: 'withdrawnAt' },
			{
				text: '적요',
				align: 'left',
				value: 'title',
			},
		])
		const currentPage = ref(0)
		const totalPages = ref(0)
		const itemsPerPage = ref(ITEMS_PER_PAGE)
		const selectedWithdrawns = ref([])
		const selectedWithdrawnStatus = ref('')
		const withdrawnStatusGroups = reactive([
			{
				name: '전체',
				value: '',
			},
			...Object.values(withdrawnStatus),
		])
		const itemsPerPageGroups = reactive([
			{
				name: '10',
				value: 10,
			},
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])

		const dateRange = computed({
			get() {
				return [beginDate.value, endDate.value]
			},
			set(value) {
				const temp = value.sort(function (a, b) {
					return new Date(a) - new Date(b)
				})

				beginDate.value = temp[0]
				endDate.value = temp[1]
			},
		})

		const listMarketBalancesWithdrawals = async () => {
			try {
				isLoading.value = true
				const payload = {
					limit: itemsPerPage.value,
					page: currentPage.value - 1,
				}

				if (beginDate.value) {
					payload.beginDate = dayjs(beginDate.value).format(
						'YYYY-MM-DD 00:00:01',
					)
				}
				if (endDate.value) {
					payload.endDate = dayjs(endDate.value).format('YYYY-MM-DD 23:59:59')
				}

				if (selectedWithdrawnStatus.value != '') {
					payload.withdrawn = selectedWithdrawnStatus.value
				}

				if (dateCriteriaGroup.value === 'withdrawnAt') {
					payload.dateCriteria = dateCriteriaGroup.value
				}

				const data =
					await MarketBalanceWithdrawalService.listMarketBalanceWithdrawalsDetail(
						payload,
					)

				withdrawns.value = data.data
				totalPages.value = parseInt(data.totalPages)
			} catch (e) {
				warningSwal('내역을 가져오는데 문제가 발생했습니다.')
			} finally {
				isLoading.value = false
			}
		}

		const downloadExcel = async () => {
			try {
				isExcelDownloading.value = true
				const payload = {
					beginDate: beginDate.value,
					endDate: endDate.value,
					limit: itemsPerPage.value,
					page: currentPage.value - 1,
				}

				if (selectedWithdrawnStatus.value != '') {
					payload.withdrawn = selectedWithdrawnStatus.value
				}

				if (dateCriteriaGroup.value === 'withdrawnAt') {
					payload.dateCriteria = dateCriteriaGroup.value
				}

				const response =
					await MarketBalanceWithdrawalService.downloadMarketBalanceWithdrawalExcel(
						payload,
					)
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute(
					'download',
					`출금신청내역_${dayjs().format('YYMDhhmm')}.xlsx`,
				)
				document.body.appendChild(link)
				link.click()
			} catch (e) {
				warningSwal('엑셀을 다운로드 받는데 문제가 발생했습니다.')
			} finally {
				isExcelDownloading.value = false
			}
		}

		const calculateWithdrawnAmount = amount => {
			const _amount = Math.abs(amount)
			const weightedAmount = _amount * AMOUNT_WEIGHT

			return TAX_LIMIT_AMOUNT > weightedAmount * TAX_RATE
				? Math.floor(weightedAmount)
				: Math.floor(weightedAmount - weightedAmount * TAX_RATE)
		}

		const getBanks = async () => {
			banks.value = await UtilityService.getBanks()
		}

		const resolveBankCode = bankCode => {
			return banks.value[bankCode]
		}

		const changeWithdrawnStatus = async status => {
			selectedWithdrawnStatus.value = status
			await listMarketBalancesWithdrawals()
		}

		const inquiryDate = async () => {
			if (!beginDate.value || !endDate.value) {
				warningSwal('시작-종료 날짜를 입력하세요')
				return
			}

			await listMarketBalancesWithdrawals()
		}

		const changeItemsPerPage = async limit => {
			itemsPerPage.value = limit
			await listMarketBalancesWithdrawals()
		}

		const onSave = async () => {
			if (selectedWithdrawns.value.length === 0) {
				warningSwal('출금 완료 처리할 건을 선택하세요')
			}

			const confirm = await confirmSwal(
				`${selectedWithdrawns.value.length}건의 출금 완료 처리를 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					isProcessing.value = true
					for (const withdrawn of selectedWithdrawns.value) {
						const payload = {
							userId: withdrawn.user.id,
							amount: Math.abs(withdrawn.amount),
							title: '고객 리워드 출금',
						}
						await MarketBalanceWithdrawalService.postMarketBalanceCompleteWithdrawal(
							withdrawn.id,
							payload,
						)
					}
					await listMarketBalancesWithdrawals()
					selectedWithdrawns.value = []
					successSwal('출금 완료 처리되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '출금 완료 처리하는데 문제가 발생했습니다.',
					)
				} finally {
					isProcessing.value = false
				}
			}
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await listMarketBalancesWithdrawals()
		}

		onMounted(() => {
			getBanks()
			listMarketBalancesWithdrawals()
		})

		return {
			withdrawns,
			headers,
			isLoading,
			isProcessing,
			dateCriteriaGroup,
			beginDate,
			endDate,
			currentPage,
			itemsPerPage,
			totalPages,
			selectedWithdrawns,
			selectedWithdrawnStatus,
			withdrawnStatusGroups,
			isExcelDownloading,
			itemsPerPageGroups,

			dateRange,
			convertUTC2KST,
			transactionTimeFormat,
			calculateWithdrawnAmount,
			resolveBankCode,
			handlePageChange,

			onSave,
			inquiryDate,
			downloadExcel,
			changeItemsPerPage,
			changeWithdrawnStatus,
		}
	},
}
</script>
<style lang="scss" scoped>
.balance-list {
	.withdrawal-amount {
		color: #ff4c51;
		font-size: 16px;
		font-weight: 900;
	}
}
</style>
