<template>
	<v-dialog
		v-model="isOpenEventSeatCoexDialog"
		fullscreen
		hide-overlay
		persistent
	>
		<v-card>
			<v-btn
				@click="$emit('update:is-open-event-seat-coex-dialog', false)"
				absolute
				right
				top
				icon
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<div
				class="text-center stage-box d-flex justify-center align-center font-weight-bold mb-7"
			>
				무대
			</div>

			<div class="grid-container seat">
				<div
					:class="['grid-items-' + i, isSeatOccupied(i) ? 'occupied' : '']"
					v-for="i in 168"
					:key="i"
				>
					<div>
						<span>{{ i }}</span>
						<span v-if="isSeatOccupied(i)" class="d-block">
							{{ isSeatOccupied(i)['name'] }}
						</span>
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import EventService from '@/services/EventService'

export default {
	components: {},
	props: {
		eventId: {
			require: true,
		},
		isOpenEventSeatCoexDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup(props) {
		const occupiedSeats = ref([])

		const getEventParticipants = async () => {
			try {
				const data = await EventService.listEventParticipants(props.eventId)

				const seated = data
					.filter(e => e.seatNumber !== null)
					.map(arr => ({
						seatNumber: arr['seatNumber'],
						name: arr['user']['name'],
					}))

				occupiedSeats.value = [...seated]
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => props.isOpenEventSeatCoexDialog,
			currentValue => {
				if (currentValue) {
					getEventParticipants()
				}
			},
		)

		const isSeatOccupied = seatNumber => {
			return occupiedSeats.value.find(e => e.seatNumber === seatNumber)
		}

		return {
			occupiedSeats,

			isSeatOccupied,
		}
	},
}
</script>
<style lang="scss" scoped>
.stage-box {
	border: 3px solid #333;
	min-height: 45px;
	font-weight: 900;
	font-size: 1.5rem;
	width: 78%;
	margin: 0 auto;
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.room-number {
	font-weight: 900;
	font-size: 2.5rem;
}

.occupied {
	justify-content: center;
	// background-color: #595959;
	padding-left: 0px !important;

	&.my-seat {
		background-color: #16b1ff;
	}
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(21, 1fr);
	grid-template-rows: repeat(21, 1fr);
	grid-row-gap: 30px;
	grid-column-gap: 1px;
	text-align: center;
	overflow-x: scroll;
	height: 810px;

	&.seat {
		[class^='grid-items'] {
			border: 2px solid #333;
			height: 75px;
			min-width: 75px;
			display: flex;
			align-items: center;
			padding-left: 10px;
			word-break: break-all;

			&:nth-child(3n) {
				margin-right: 6px;
			}

			&:nth-child(3n + 1) {
				margin-left: 6px;
			}

			span {
				font-weight: 900;
				font-size: 1rem;
			}
		}
	}
}
</style>
