<template>
	<div class="px-2">
		<v-select
			v-model="selectedResult"
			:items="resultGroups"
			:menu-props="{
				closeOnClick: true,
			}"
			dense
			chips
			filled
			outlined
			single-line
			hide-details
			return-object
			item-text="name"
			label="상태"
			color="blue-grey lighten-2"
			style="background-color: white"
		/>
		<v-btn
			rounded
			color="primary"
			:ripple="false"
			:disabled="!selectedResult"
			class="px-0 my-2 font-weight-bold w-full"
			@click="update()"
		>
			업데이트 하기 (준비중)
		</v-btn>
	</div>
</template>
<script>
import { ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketPostService from '@/services/MarketPostService'
import { contentsMarketStatus } from '@/helpers'

export default {
	components: {},
	props: {
		post: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const resultGroups = ref([
			{
				name: contentsMarketStatus.READY.name,
				value: contentsMarketStatus.READY.value,
			},
			{
				name: contentsMarketStatus.APPROVED.name,
				value: contentsMarketStatus.APPROVED.value,
			},
			{
				name: contentsMarketStatus.DISAPPROVED.name,
				value: contentsMarketStatus.DISAPPROVED.value,
			},
		])
		const selectedResult = ref(null)

		const update = async () => {
			const confirm = await confirmSwal('상태를 업데이트 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketPostService.update(props.post.id, {
						result: selectedResult.value,
					})
					selectedResult.value = null
					successSwal('상태가 업데이트 되었습니다')
				} catch (e) {
					warningSwal('상태를 업데이트하는데 문제가 발생했습니다')
				}
			}
		}

		return {
			resultGroups,
			selectedResult,

			update,
		}
	},
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
