var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
				name: 'license-detail',
				params: { licenseId: _vm.licenseId },
				query: { tab: 1 },
			}}},[_vm._v(" 자격시험 리스트 ")]),_c('span',{staticClass:"mx-1"},[_vm._v(">")]),_vm._v(" 시험지 보기 ")],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-card-text',[_c('v-simple-table',{attrs:{"fixed-header":"","height":"560px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("시험 타이틀")]),_c('th',{staticClass:"text-center"},[_vm._v("시험 내용")]),_c('th',{staticClass:"text-center"},[_vm._v("총 문제 수")]),_c('th',{staticClass:"text-center"},[_vm._v("최소 합격 점수")]),_c('th',{staticClass:"text-center"},[_vm._v("공개 여부")]),_c('th',{staticClass:"text-center"},[_vm._v("수정 / 답안지 등록")])])]),_c('tbody',[(_vm.workbooks.length > 0)?_vm._l((_vm.workbooks),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(item.title))])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.body))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.problemCount)+" 문제")]),_c('td',{staticClass:"text-center"},[(item.minPassScore)?_c('span',[_vm._v(" "+_vm._s(item.minPassScore)+" 점 ")]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.visible ? '공개' : '비공개')+" ")]),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openLicenseEditTestRoundWorkbookDialog(item)}}},[_vm._v(" 수정 ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary","to":{
										name: 'license-test-round-register-answer-sheet',
										params: {
											licenseId: _vm.licenseId,
											testRoundId: _vm.testRoundId,
											workbookId: item.id,
										},
									}}},[_vm._v(" 답안지 등록 ")])],1)])}):[_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v("등록된 시험지가 없습니다")])])]],2)]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isOpenLicenseRegisterTestRoundWorkbookDialog =
					!_vm.isOpenLicenseRegisterTestRoundWorkbookDialog}}},[_vm._v(" 시험지 등록 ")])],1),_c('license-register-test-round-workbook-dialog',{attrs:{"license-id":_vm.licenseId,"test-round-id":_vm.testRoundId,"is-open-license-register-test-round-workbook-dialog":_vm.isOpenLicenseRegisterTestRoundWorkbookDialog},on:{"is-fetch-workbooks":function($event){_vm.isFetchWorkBooks = $event},"update:isOpenLicenseRegisterTestRoundWorkbookDialog":function($event){_vm.isOpenLicenseRegisterTestRoundWorkbookDialog=$event},"update:is-open-license-register-test-round-workbook-dialog":function($event){_vm.isOpenLicenseRegisterTestRoundWorkbookDialog=$event}}}),_c('license-edit-test-round-workbook-dialog',{attrs:{"selected-workbook":_vm.selectedWorkbook,"is-open-license-edit-test-round-workbook-dialog":_vm.isOpenLicenseEditTestRoundWorkbookDialog},on:{"is-fetch-workbooks":function($event){_vm.isFetchWorkBooks = $event},"update:isOpenLicenseEditTestRoundWorkbookDialog":function($event){_vm.isOpenLicenseEditTestRoundWorkbookDialog=$event},"update:is-open-license-edit-test-round-workbook-dialog":function($event){_vm.isOpenLicenseEditTestRoundWorkbookDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }