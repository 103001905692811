<template>
	<v-card elevation="0">
		<v-card-title>강의 리스트</v-card-title>
		<v-data-table
			:headers="headers"
			:items="marketCourses"
			item-key="email"
			no-data-text="조회된 과정이 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text="과정를 불러오는 중입니다..."
			hide-default-footer
			@click:row="routeToDetail($event.id)"
		>
			<template v-slot:[`item.visible`]="{ item }">
				{{ item.visible ? '공개' : '비공개' }}
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<v-btn
					@click.stop="routeToCourseRegistraions(item.id)"
					color="primary"
					small
				>
					수강자 보기
				</v-btn>
			</template>
		</v-data-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn :to="{ name: 'market-course-register' }" color="primary">
				과정 등록
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import MarketCourseService from '@/services/MarketCourseService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const isLoading = ref(false)
		const marketCourses = ref([])
		const headers = ref([
			{
				text: '타이틀',
				align: 'left',
				value: 'title',
			},
			{ text: '설명', value: 'description' },
			{ text: '공개 여부', value: 'visible' },
			{ text: '수강자 보기', value: 'action' },
		])

		const listMarketCourses = async () => {
			try {
				isLoading.value = true
				marketCourses.value = await MarketCourseService.listMarketCourses()
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}

		const routeToCourseRegistraions = marketCourseId => {
			router.push({
				name: 'market-course-registration-list',
				params: { marketCourseId: marketCourseId },
			})
		}

		const routeToDetail = marketCourseId => {
			router.push({
				name: 'market-course-detail',
				params: { marketCourseId: marketCourseId },
			})
		}

		onMounted(() => {
			listMarketCourses()
		})

		return {
			isLoading,
			marketCourses,
			headers,

			routeToDetail,
			routeToCourseRegistraions,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
