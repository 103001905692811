<template>
	<v-card elevation="0">
		<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
			<v-tab v-for="(tab, index) in tabs" :key="'tab-' + index">
				{{ tab }}
			</v-tab>
			<v-spacer />
			<v-btn
				color="primary"
				@click="isCalendar = !isCalendar"
				v-if="tab === 0"
				style="position: relative; top: 5px; right: 5px"
			>
				{{ isCalendar ? '목록 보기' : '달력 보기' }}
			</v-btn>
		</v-tabs>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item>
				<market-location-reservation-calendar v-if="isCalendar" />
				<market-location-reservation-list v-else />
			</v-tab-item>
			<v-tab-item>
				<v-card elevation="0">
					<v-data-table
						:headers="headers"
						:items="marketLocations"
						item-key="email"
						no-data-text="조회된 라운지가 없습니다."
						class="elevation-1"
						:loading="isLoading"
						loading-text="라운지를 불러오는 중입니다..."
						hide-default-footer
					>
						<template v-slot:[`item.name`]="{ item }">
							<span
								@click="routeToDetail(item.id)"
								class="text-decoration-underline cursor-pointer"
							>
								{{ item.name }}
							</span>
						</template>
						<template v-slot:[`item.baseFareAnHourWon`]="{ item }">
							{{ item.baseFareAnHourWon | commaFormat }} 원
						</template>
						<template v-slot:[`item.maxNumberOfPeople`]="{ item }">
							{{ item.maxNumberOfPeople }} 명
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-btn
								small
								:to="{
									name: 'market-locations-detail-reservation',
									params: {
										marketLocationId: item.id,
									},
								}"
								color="primary"
							>
								예약 목록 보기
							</v-btn>
						</template>
					</v-data-table>
					<v-card-actions class="d-flex justify-end mt-5">
						<v-btn :to="{ name: 'market-locations-register' }" color="primary">
							라운지 등록
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import MarketLocationReservationList from './MarketLocationReservationList.vue'

import MarketLocationService from '@/services/MarketLocationService'
import MarketLocationReservationCalendar from './MarketLocationReservationCalendar.vue'

export default {
	components: {
		MarketLocationReservationList,
		MarketLocationReservationCalendar,
	},
	setup() {
		const { router } = useRouter()
		const isLoading = ref(false)
		const marketLocations = ref([])
		const tab = ref(null)
		const tabs = reactive(['전체 예약자', '라운지'])
		const headers = ref([
			{
				text: '아룸',
				align: 'left',
				value: 'name',
			},
			{ text: '소개', value: 'introduction' },
			{ text: '주소', value: 'address' },
			{ text: '전화번호', value: 'phoneNumber' },
			{ text: '시간당 기본 비용', value: 'baseFareAnHourWon' },
			{ text: '이용 가능 인원 ', value: 'maxNumberOfPeople' },
			{ text: '예약 목록 ', value: 'actions' },
		])
		const isCalendar = ref(false)

		const listMarketLocations = async () => {
			try {
				isLoading.value = true
				marketLocations.value =
					await MarketLocationService.listMarketLocations()
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}
		listMarketLocations()

		const routeToDetail = marketLocationId => {
			router.push({
				name: 'market-locations-detail',
				params: { marketLocationId: marketLocationId },
			})
		}

		return {
			isLoading,
			marketLocations,
			headers,
			tab,
			tabs,
			isCalendar,

			routeToDetail,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
