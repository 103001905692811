var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"타이틀을 입력하세요","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"설명을 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}])}),_c('validation-provider',{attrs:{"name":"vimeoVideoId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"비메오 ID를 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.vimeoVideoId),callback:function ($$v) {_vm.$set(_vm.form, "vimeoVideoId", $$v)},expression:"form.vimeoVideoId"}})]}}])}),_c('validation-provider',{attrs:{"name":"vimeoVideoLengthInSec","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"autocomplete":"off","label":"비메오 영상 길이를 초단위로 입력하세요","required":""},model:{value:(_vm.form.vimeoVideoLengthInSec),callback:function ($$v) {_vm.$set(_vm.form, "vimeoVideoLengthInSec", $$v)},expression:"form.vimeoVideoLengthInSec"}})]}}])}),_c('validation-provider',{attrs:{"name":"visible"}},[_c('v-checkbox',{staticClass:"mb-2",attrs:{"label":"공개 여부","hide-details":""},model:{value:(_vm.form.visible),callback:function ($$v) {_vm.$set(_vm.form, "visible", $$v)},expression:"form.visible"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }