const reservationStatus = {
	APPLIED: { name: '신청완료', value: 'APPLIED' },
	CANCELED: {
		name: '신청취소',
		value: 'CANCELED',
	},
	PAID: { name: '결제완료', value: 'PAID' },
	REFUNDED: { name: '환불완료', value: 'REFUNDED' },
	PAYWAITING: { name: '결제대기', value: 'PAYWAITING' },
}

export default reservationStatus
