<template>
	<v-row no-gutters>
		<v-col cols="12" class="d-flex">
			<v-col md="8" class="pa-0">
				<v-card>
					<v-card-title>
						<router-link
							:to="{ name: 'market-items-list' }"
							class="text-decoration-none"
						>
							상품 리스트
						</router-link>
						<span class="mx-1">&#62;</span>
						{{ form.title }}
					</v-card-title>
					<v-divider class="mb-5" />
					<v-card-text>
						<validation-observer ref="observer" v-slot="{ valid }">
							<market-item-form :form.sync="form" />
							<div class="d-flex justify-end pb-6">
								<v-btn
									color="accent"
									@click="
										isOpenLinkMarketItemEventDialog =
											!isOpenLinkMarketItemEventDialog
									"
									class="mr-1"
								>
									이벤트 연결하기
								</v-btn>
								<v-btn color="error" @click="deleteMarketItem" class="mr-1">
									삭제
								</v-btn>
								<v-btn
									color="primary"
									:disabled="!valid"
									@click="updateMarketItem"
								>
									수정
								</v-btn>
							</div>
						</validation-observer>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col md="4" class="pt-0 pr-0 pb-0">
				<v-card height="669">
					<v-card-title>연결된 이벤트 리스트</v-card-title>
					<v-divider />
					<template v-if="marketItemEvent.length > 0">
						<v-card
							v-for="(item, index) in marketItemEvent"
							:key="'market-item-event' + index"
							class="ma-3"
							outlined
							:to="{
								name: 'event-detail',
								params: { eventId: item.marketEventId },
							}"
						>
							<v-card-title>이벤트 ID {{ item.marketEventId }}</v-card-title>
							<v-card-subtitle>
								<v-icon small color="info">mdi-map-marker</v-icon>
								{{ item.createdAt | dateKOSimpleSiBunChoFormat }}
							</v-card-subtitle>
							<v-btn
								icon
								absolute
								top
								right
								@click.prevent="deleteMarketItemEvent(item)"
							>
								<v-icon color="error">mdi-trash-can-outline</v-icon>
							</v-btn>
						</v-card>
					</template>
					<template v-else>
						<div
							class="d-flex align-center justify-center"
							style="height: 100%"
						>
							연결된 이벤트 정보가 없습니다
						</div>
					</template>
				</v-card>
			</v-col>
		</v-col>
		<market-item-link-event-dialog
			:is-open-link-market-item-event-dialog.sync="
				isOpenLinkMarketItemEventDialog
			"
			@fetch-market-item-event="isFetchMarketItemEvent = $event"
			:market-item-id="marketItemId"
		/>
	</v-row>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { dateKOSimpleSiBunChoMsFormat, dateKSTToUTCFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketItemService from '@/services/MarketItemService'

import MarketItemForm from './components/MarketItemForm.vue'
import MarketItemLinkEventDialog from './MarketItemLinkEventDialog.vue'

export default {
	components: {
		MarketItemForm,
		MarketItemLinkEventDialog,
	},
	setup() {
		const form = ref({})
		const { route, router } = useRouter()
		const marketItemId = ref(route.value.params.marketItemId)
		const isOpenLinkMarketItemEventDialog = ref(false)
		const isFetchMarketItemEvent = ref(false)
		const marketItemEvent = ref([])

		const getMarketItemDetail = async () => {
			try {
				form.value = await MarketItemService.getMarketItemDetail(
					marketItemId.value,
				)

				form.value.startDate = dateKOSimpleSiBunChoMsFormat(
					form.value.startDate,
				)
				form.value.endDate = dateKOSimpleSiBunChoMsFormat(form.value.endDate)
			} catch (e) {
				console.error(e)
			}
		}

		const getMarketItemEvent = async () => {
			try {
				marketItemEvent.value = await MarketItemService.getMarketItemEvent(
					marketItemId.value,
				)
			} catch (e) {
				console.error(e)
			} finally {
				isFetchMarketItemEvent.value = false
			}
		}

		onMounted(() => {
			getMarketItemDetail()
			getMarketItemEvent()
		})

		watch(
			() => isFetchMarketItemEvent.value,
			currentValue => {
				if (currentValue) {
					getMarketItemEvent()
				}
			},
		)

		const updateMarketItem = async () => {
			const confirm = await confirmSwal('상품을 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }

					data.startDate = dateKSTToUTCFormat(data.startDate)
					data.endDate = dateKSTToUTCFormat(data.endDate)

					await MarketItemService.updateMarketItem(data)
					successSwal('상품 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '상품을 수정하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const deleteMarketItem = async () => {
			const confirm = await confirmSwal('상품을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketItemService.deleteMarketItem(form.value.id)
					successSwal('상품 삭제가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '상품을 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					router.push({ name: 'market-items-list' })
				}
			}
		}

		const deleteMarketItemEvent = async event => {
			const confirm = await confirmSwal(
				`${event.title} 이벤트를 해제하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketItemService.unLinkMarketItemEvent({
						marketItemId: marketItemId.value,
						marketEventId: event.id,
					})
					successSwal('이벤트가 해제되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '이벤트를 해제하는데 문제가 발생했습니다.',
					)
				} finally {
					getMarketItemEvent()
				}
			}
		}

		return {
			form,
			marketItemId,
			isFetchMarketItemEvent,
			marketItemEvent,
			isOpenLinkMarketItemEventDialog,

			updateMarketItem,
			deleteMarketItem,
			deleteMarketItemEvent,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
