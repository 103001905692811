var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"타이틀을 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('div',{staticClass:"mb-4"},[_c('toast-ui-editor',{attrs:{"content":_vm.form.body,"type":'marketItem',"market-item-id":_vm.form.id},on:{"update:content":function($event){return _vm.$set(_vm.form, "body", $event)}}})],1),_c('v-file-input',{attrs:{"placeholder":"첨부할 파일을 업로드하세요","label":"첨부 파일 업로드","prepend-icon":"mdi-paperclip","multiple":""},on:{"change":_vm.inputChanged},model:{value:(_vm.uploadedFile),callback:function ($$v) {_vm.uploadedFile=$$v},expression:"uploadedFile"}}),(_vm.form.files && _vm.form.files.length > 0)?_c('div',{staticClass:"mb-4 d-flex flex-wrap"},_vm._l((_vm.form.files),function(file,index){return _c('v-chip',{key:index,staticClass:"mr-1 mb-1",attrs:{"color":"primary","close":""},on:{"click:close":function($event){return _vm.remove(file, index)}}},[_vm._v(" "+_vm._s(file.name || file.fileName)+" ")])}),1):_vm._e(),_c('validation-provider',{attrs:{"name":"visible","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"autocomplete":"off","label":"설명을 입력하세요","required":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}])}),_c('validation-provider',{attrs:{"name":"visible","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"autocomplete":"off","label":"장소를 입력하세요","required":""},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})]}}])}),_c('validation-provider',{attrs:{"name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"상품 비용을 입력하세요","autocomplete":"off","suffix":"원","required":""},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}])}),_c('validation-provider',{attrs:{"name":"startDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"등록 시작 날짜를 입력하세요","required":""},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"등록 마감 날짜를 입력하세요","required":""},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"charged"}},[_c('v-checkbox',{attrs:{"label":"유료 여부","hide-details":""},model:{value:(_vm.form.charged),callback:function ($$v) {_vm.$set(_vm.form, "charged", $$v)},expression:"form.charged"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }