<template>
	<div>
		<editor
			:initial-value="content"
			:options="editorOptions"
			@blur="onEditorChange"
			height="400px"
			initial-edit-type="wysiwyg"
			preview-style="vertical"
			ref="toastuiEditor"
		/>
	</div>
</template>

<script>
import { ref, reactive, watch } from '@vue/composition-api'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/i18n/ko-kr'
import { Editor } from '@toast-ui/vue-editor'

import NoticeService from '@/services/NoticeService'
import MarketItemService from '@/services/MarketItemService'
import MarketPostService from '@/services/MarketPostService'

const NOTICE_TYPE = 'notice'
const MARKET_ITEM_TYPE = 'marketItem'
const MARKET_POST_SUBJECT = 'marketPostSubject'

export default {
	props: {
		content: {
			type: String,
		},
		type: {
			type: String,
		},
		marketPostSubjectId: {
			type: [String, Number],
		},
		marketItemId: {
			type: [String, Number],
		},
		noticeId: {
			type: [String, Number],
		},
	},
	components: {
		Editor,
	},
	setup(props, { emit }) {
		const toastuiEditor = ref(null)
		const editorOptions = reactive({
			minHeight: '200px',
			language: 'ko-KR',
			useCommandShortcut: true,
			usageStatistics: true,
			hideModeSwitch: false,
			toolbarItems: [
				['heading', 'bold', 'italic', 'strike'],
				['hr', 'quote'],
				['ul', 'ol', 'task', 'indent', 'outdent'],
				['table', 'image', 'link'],
				['code', 'codeblock'],
				['scrollSync'],
			],
			hooks: {
				addImageBlobHook: async (blob, callback) => {
					const formData = new FormData()
					formData.append('data', blob)

					try {
						if (props.type === NOTICE_TYPE) {
							await NoticeService.uploadNoticeFile(props.noticeId, formData)
							const data = await NoticeService.getNoticeDetail(props.noticeId)
							const url = data.files[data.files.length - 1].url
							callback(url, '')
						} else if (props.type === MARKET_ITEM_TYPE) {
							await MarketItemService.uploadMarketItemFile(
								props.marketItemId,
								formData,
							)
							const data = await MarketItemService.getMarketItemDetail(
								props.marketItemId,
							)
							const url = data.files[data.files.length - 1].url
							callback(url, '')
						} else if (props.type === MARKET_POST_SUBJECT) {
							const data = await MarketPostService.create({
								subjectMarketPostId: props.marketPostSubjectId,
								type: 'SUBJECT',
							})
							const fileData = await MarketPostService.uploadFile(
								data.id,
								formData,
							)
							callback(fileData.url, '')
						}
					} catch (e) {
						console.error(e)
					}
				},
			},
		})

		watch(
			() => props.content,
			currentValue => {
				if (currentValue) {
					toastuiEditor.value.invoke('setHTML', currentValue)
				}
			},
		)

		const onEditorChange = html => {
			const _html = toastuiEditor.value.invoke('getHTML', html)
			emit('update:content', _html)
		}

		return {
			toastuiEditor,
			editorOptions,

			onEditorChange,
		}
	},
}
</script>
<style lang="scss" scoped></style>
