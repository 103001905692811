<template>
	<v-dialog v-model="isOpenPostCodeDialog" persistent max-width="450">
		<v-card>
			<v-card-title>
				주소 찾기
				<v-spacer />
				<v-btn icon @click="$emit('update:is-open-post-code-dialog', false)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<div id="address-embed" style="width: 100%"></div>
		</v-card>
	</v-dialog>
</template>
<script>
import { onMounted, watch } from '@vue/composition-api'

export default {
	props: {
		isOpenPostCodeDialog: {
			required: true,
			type: Boolean,
		},
	},
	setup(props, { emit }) {
		let daum = null

		onMounted(() => {
			const script = document.createElement('script')
			script.setAttribute(
				'src',
				'//ssl.daumcdn.net/dmaps/map_js_init/postcode.v2.js?autoload=false',
			)
			script.async = true
			document.head.appendChild(script)
		})

		const openPostcode = () => {
			daum = window.daum

			daum.postcode.load(function () {
				new daum.Postcode({
					width: '100%',
					oncomplete: data => {
						emit('address', data)
					},
				}).embed(document.getElementById('address-embed'))
			})
		}

		watch(
			() => props.isOpenPostCodeDialog,
			currentValue => {
				if (currentValue) {
					openPostcode()
				}
			},
		)
	},
}
</script>
