<template>
	<v-dialog v-model="isOpenEventRegisterDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				이벤트 등록
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-event-register-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<event-form :form.sync="form" :event-category="eventCategory" />
					<div class="d-flex justify-end pb-6">
						<v-btn color="primary" :disabled="!valid" @click.stop="createEvent">
							등록
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

import { dateKSTToUTCFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'

import EventForm from './components/EventForm.vue'

export default {
	props: {
		isOpenEventRegisterDialog: {
			type: Boolean,
			required: true,
		},
		category: {
			type: String,
		},
		eventCategory: {
			type: Object,
		},
	},
	components: {
		EventForm,
	},
	setup(props, { emit }) {
		const form = ref({
			title: '',
			body: '',
			category: props.category,
			maxParticipants: 0,
			waitingAvailable: false,
			visible: false,
			eventAt: '',
			startRegistrationDate: '',
			endRegistrationDate: '',
			canRetake: false,
			startRetakeRegistrationDate: '',
			endRetakeRegistrationDate: '',
		})

		const createEvent = async () => {
			const confirm = await confirmSwal('이벤트를 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }
					data.eventAt = dateKSTToUTCFormat(data.eventAt)
					data.startRegistrationDate = dateKSTToUTCFormat(
						data.startRegistrationDate,
					)
					data.endRegistrationDate = dateKSTToUTCFormat(
						data.endRegistrationDate,
					)

					await EventService.createEvent(data)
					emit('is-fetch-events', true)
					successSwal('이벤트 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '이벤트를 등록하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-event-register-dialog', false)
				}
			}
		}

		return {
			form,

			createEvent,
		}
	},
}
</script>
