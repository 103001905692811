var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'market-locations-list' }}},[_vm._v(" 라운지 ")]),_c('span',{staticClass:"mx-1"},[_vm._v(">")]),_vm._v(" "+_vm._s(_vm.form.name)+" ")],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('market-location-form',{attrs:{"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}}),_c('div',{staticClass:"d-flex justify-space-between pb-6 text-wrap"},[_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","disabled":!valid},on:{"click":_vm.updateMarketLocation}},[_vm._v(" 수정 ")])],1),_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"info"},on:{"click":function($event){_vm.isOpenAddScheduleDialog = !_vm.isOpenAddScheduleDialog}}},[_vm._v(" 스케쥴 등록하기 ")]),_c('v-btn',{attrs:{"color":"accent","to":{
							name: 'market-locations-detail-reservation',
							params: {
								marketLocationId: _vm.marketLocationId,
							},
						}}},[_vm._v(" 예약 목록 보기 ")])],1)])]}}])})],1),_c('market-location-add-schedule-dialog',{attrs:{"market-location-id":_vm.marketLocationId,"is-open-add-schedule-dialog":_vm.isOpenAddScheduleDialog},on:{"update:isOpenAddScheduleDialog":function($event){_vm.isOpenAddScheduleDialog=$event},"update:is-open-add-schedule-dialog":function($event){_vm.isOpenAddScheduleDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }