const assignmentStatus = {
	READY: { name: '대기', value: 'READY' },
	PASS: { name: '합격', value: 'PASS' },
	FAIL: {
		name: '불합격',
		value: 'FAIL',
	},
}

export default assignmentStatus
