<template>
	<v-dialog v-model="isOpenAddScheduleDialog" persistent fullscreen>
		<v-card>
			<v-sheet height="64">
				<v-toolbar flat>
					<v-btn icon @click="closeDialog">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-spacer />
					<v-btn fab text small color="grey darken-2" @click="calendar.prev()">
						<v-icon small>mdi-chevron-left</v-icon>
					</v-btn>
					<v-btn outlined class="mx-4" color="grey darken-2" @click="setToday">
						오늘
					</v-btn>
					<v-btn fab text small color="grey darken-2" @click="calendar.next()">
						<v-icon small>mdi-chevron-right</v-icon>
					</v-btn>
					<v-spacer />
					<v-btn color="primary" @click="addSchedules">
						전체 {{ newEvents.length }}건 등록하기
					</v-btn>
				</v-toolbar>
			</v-sheet>
			<v-sheet>
				<v-calendar
					ref="calendar"
					v-model="focus"
					locale="ko"
					color="primary"
					:first-time="firstTime"
					:type="type"
					:event-overlap-mode="mode"
					:events="events"
					:event-color="getEventColor"
					:event-ripple="false"
					:first-interval="100"
					:interval-height="70"
					:event-height="35"
					@click:event="showEvent"
					@mousedown:event="startDrag"
					@mousedown:time="startTime"
					@mousemove:time="mouseMove"
					@mouseup:time="endDrag"
					@mouseleave.native="cancelDrag"
				>
					<template v-slot:event="{ event, timed }">
						<div
							v-if="timed"
							class="v-event-drag-top"
							@mousedown.stop="extendTop(event)"
						></div>
						<div class="content pa-1">
							<div class="d-flex justify-space-between">
								<strong>{{ event.name }}</strong>
								<v-btn
									small
									icon
									color="white"
									@click.stop="removeEvent(event)"
									v-if="event.canRemove"
								>
									<v-icon>mdi-close-circle-outline</v-icon>
								</v-btn>
							</div>
							<div>
								{{ event.start | timeFormat }}
								~
								{{ event.end | timeFormat }}
							</div>
							<div>
								인당 비용:
								{{ event.farePerPersonWon | commaFormat }} 원
							</div>
							<div>
								이용 가능 룸수:
								{{ event.maxNumberOfRooms | commaFormat }} 개
							</div>
						</div>
						<div
							v-if="timed"
							class="v-event-drag-bottom"
							@mousedown.stop="extendBottom(event)"
						></div>
					</template>
				</v-calendar>
			</v-sheet>
			<v-bottom-sheet hide-overlay v-model="selectedOpen">
				<v-card v-if="selectedEvent">
					<v-card-title class="d-flex justify-space-between">
						<div class="d-flex align-center">
							<v-chip :color="selectedEvent.color">
								<strong>
									{{ selectedEvent.name }}
								</strong>
							</v-chip>
							<div class="ml-4 mt-1">
								{{ selectedEvent.start | dateKOFormat }} ~
								{{ selectedEvent.end | dateKOFormat }}
							</div>
						</div>
						<div>
							<v-btn @click="selectedOpen = !selectedOpen" icon>
								<v-icon large>mdi-chevron-down</v-icon>
							</v-btn>
						</div>
					</v-card-title>

					<v-card-actions class="d-block">
						<validation-observer ref="observer" v-slot="{ valid }">
							<form @submit.prevent="addSchedule(selectedEvent)" ref="form">
								<validation-provider v-slot="{ errors }" name="amount">
									<v-text-field
										v-model="selectedEvent.fareAnHourWon"
										type="text"
										:error-messages="errors"
										label="라운지 시간당 기본비용을 입력하세요"
										autocomplete="off"
										suffix="원"
										required
									/>
								</validation-provider>
								<validation-provider
									v-slot="{ errors }"
									name="farePerPersonWon"
								>
									<v-text-field
										v-model="selectedEvent.farePerPersonWon"
										type="text"
										:error-messages="errors"
										label="라운지 인당 비용을 입력하세요"
										autocomplete="off"
										suffix="원"
										required
									/>
								</validation-provider>
								<validation-provider
									v-slot="{ errors }"
									name="maxNumberOfRooms"
								>
									<v-text-field
										v-model="selectedEvent.maxNumberOfRooms"
										type="number"
										:error-messages="errors"
										label="해당 스케쥴에 이용 가능한 룸 수를 입력하세요"
										required
									/>
								</validation-provider>
								<validation-provider
									v-slot="{ errors }"
									name="maxNumberOfPeople"
								>
									<v-text-field
										v-model="selectedEvent.numberOfPeople"
										type="number"
										:error-messages="errors"
										label="이용 가능 인원을 입력하세요"
										suffix="명"
										required
									/>
								</validation-provider>
								<div class="d-flex justify-end">
									<v-btn color="primary" type="submit" :disabled="!valid">
										등록하기
									</v-btn>
								</div>
							</form>
						</validation-observer>
					</v-card-actions>
				</v-card>
			</v-bottom-sheet>
		</v-card>
	</v-dialog>
</template>
<script>
import { ref, watch, nextTick } from '@vue/composition-api'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { convertUTC2KST } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLocationService from '@/services/MarketLocationService'

dayjs.extend(utc)

export default {
	components: {},
	props: {
		marketLocationId: {
			required: true,
		},
		isOpenAddScheduleDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		const title = ref('IT MARKETER 캘린더')
		const focus = ref('')
		const type = ref('week')
		const selectedEvent = ref({})
		const selectedElement = ref(null)
		const mode = ref('column')
		const selectedOpen = ref(false)
		const events = ref([])
		const newEvents = ref([])
		const colors = ref([
			'#2196F3',
			'#3F51B5',
			'#673AB7',
			'#00BCD4',
			'#4CAF50',
			'#FF9800',
		])
		const firstTime = ref('05:00')
		const names = ref([''])
		const dragTime = ref(null)
		const dragEvent = ref(null)
		const dragStart = ref(null)
		const lastEvent = ref(null)
		const createEvent = ref(null)
		const createStart = ref(null)
		const createEnd = ref(null)
		const extendOriginal = ref(null)
		const calendar = ref(null)
		const startDateTime = ref('')
		const endDateTime = ref('')
		const scheduleForm = ref({
			beginDate: '',
			endDate: '',
			type: 'PERPERSON',
			fareAnHourWon: 10000,
			farePerPersonWon: 10000,
			maxNumberOfRooms: 2,
			numberOfPeople: 8,
		})

		const getMarketLocationSchedules = async () => {
			try {
				const data = await MarketLocationService.getMarketLocationSchedules({
					marketLocationId: props.marketLocationId,
					beginDate: startDateTime.value,
					endDate: endDateTime.value,
				})

				const formatArr = data.map(arr => ({
					id: arr['id'],
					name: arr['title'],
					start: convertUTC2KST(arr['beginDate']).substring(0, 16),
					end: convertUTC2KST(arr['endDate']).substring(0, 16),
					fareAnHourWon: arr['fareAnHourWon'],
					farePerPersonWon: arr['farePerPersonWon'],
					maxNumberOfRooms: arr['maxNumberOfRooms'],
					status: arr['status'],
					isActive: arr['isActive'],
					color: '#757575',
					canRemove: false,
				}))

				events.value = formatArr
			} catch (e) {
				warningSwal('운영 스케쥴을 불러오는데 문제가 발생했습니다.')
			}
		}

		const viewDay = ({ date }) => {
			focus.value = date
			type.value = 'day'
		}

		const setToday = () => {
			focus.value = ''
		}

		const showEvent = ({ nativeEvent, event }) => {
			const open = () => {
				selectedEvent.value = event
				selectedElement.value = nativeEvent.targetaddSchedule
				requestAnimationFrame(() =>
					requestAnimationFrame(() => (selectedOpen.value = true)),
				)
			}

			if (selectedOpen.value) {
				selectedOpen.value = false
				requestAnimationFrame(() => requestAnimationFrame(() => open()))
			} else {
				open()
			}

			open()

			nativeEvent.stopPropagation()
		}

		const startDrag = ({ event, timed }) => {
			if (event && timed) {
				dragEvent.value = event
				dragTime.value = null
				extendOriginal.value = null
			}
		}

		const roundTime = (time, down = true) => {
			const roundDownTime = 30 * 60 * 1000 // 30 minutes

			return down
				? time - (time % roundDownTime)
				: time + (roundDownTime - (time % roundDownTime))
		}

		const toTime = tms => {
			return new Date(
				tms.year,
				tms.month - 1,
				tms.day,
				tms.hour,
				tms.minute,
			).getTime()
		}

		const rnd = (a, b) => {
			return Math.floor((b - a + 1) * Math.random()) + a
		}

		const rndElement = arr => {
			return arr[rnd(0, arr.length - 1)]
		}

		const startTime = tms => {
			const mouse = toTime(tms)

			const diffDay = newEvents.value.find(
				e => dayjs(e.start).diff(dayjs(mouse), 'days') === 0,
			)

			if (diffDay) {
				return
			}

			if (dragEvent.value && dragTime.value === null) {
				const start = dragEvent.value.start

				dragTime.value = mouse - start
			} else {
				const eventId = newEvents.value.length + 1
				createStart.value = roundTime(mouse)
				createEnd.value = roundTime(mouse)
				createEvent.value = {
					id: `new-${eventId}`,
					name: `운영 스케쥴 #${eventId}`,
					color: rndElement(colors.value),
					start: createStart.value,
					end: createEnd.value,
					timed: true,
					type: scheduleForm.value.type,
					fareAnHourWon: scheduleForm.value.fareAnHourWon,
					farePerPersonWon: scheduleForm.value.farePerPersonWon,
					numberOfPeople: scheduleForm.value.numberOfPeople,
					maxNumberOfRooms: scheduleForm.value.maxNumberOfRooms,
					canRemove: true,
				}

				newEvents.value.push(createEvent.value)
				events.value.push(createEvent.value)
			}
		}

		const extendTop = event => {
			createEvent.value = event
			createEnd.value = event.end
			extendOriginal.value = event.start
		}

		const extendBottom = event => {
			createEvent.value = event
			createStart.value = event.start
			extendOriginal.value = event.end
		}

		const mouseMove = tms => {
			const mouse = toTime(tms)

			if (dragEvent.value && dragTime.value !== null) {
				const start = dragEvent.value.start
				const end = dragEvent.value.end
				const duration = end - start
				const newStartTime = mouse - dragTime.value
				const newStart = roundTime(newStartTime)
				const newEnd = newStart + duration

				dragEvent.value.start = newStart
				dragEvent.value.end = newEnd
			} else if (createEvent.value && createStart.value !== null) {
				const mouseRounded = roundTime(mouse, false)
				const min = Math.min(mouseRounded, createStart.value)
				const max = Math.max(mouseRounded, createStart.value)

				createEvent.value.start = min
				createEvent.value.end = max
			} else if (createEvent.value && createEnd.value !== null) {
				const mouseRounded = roundTime(mouse, false)
				const min = Math.min(mouseRounded, createEnd.value)
				const max = Math.max(mouseRounded, createEnd.value)

				createEvent.value.start = min
				createEvent.value.end = max
			}
		}

		const endDrag = () => {
			selectedEvent.value = createEvent.value
			selectedOpen.value = true

			dragTime.value = null
			dragEvent.value = null
			createEvent.value = null
			createStart.value = null
			extendOriginal.value = null
		}

		const cancelDrag = () => {
			if (createEvent.value) {
				if (extendOriginal.value) {
					createEvent.value.end = extendOriginal.value
				} else {
					const i = newEvents.value.indexOf(createEvent.value)
					if (i !== -1) {
						newEvents.value.splice(i, 1)
					}
				}
			}

			createEvent.value = null
			createStart.value = null
			dragTime.value = null
			dragEvent.value = null
		}

		const getEventColor = event => {
			const rgb = parseInt(event?.color?.substring(1), 16)
			const r = (rgb >> 16) & 0xff
			const g = (rgb >> 8) & 0xff
			const b = (rgb >> 0) & 0xff

			return event === dragEvent.value
				? `rgba(${r}, ${g}, ${b}, 0.7)`
				: event === createEvent.value
				? `rgba(${r}, ${g}, ${b}, 0.7)`
				: event.color
		}

		const removeEvent = event => {
			const index = events.value.findIndex(e => e.id === event.id)
			events.value.splice(index, 1)
			selectedOpen.value = false
		}

		const closeDialog = () => {
			events.value = []
			emit('update:is-open-add-schedule-dialog', false)
		}

		const addSchedules = async () => {
			const confirm = await confirmSwal(
				`${newEvents.value.length}개의 스케쥴을 등록 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					newEvents.value.forEach(async element => {
						const _payload = { ...scheduleForm.value }
						_payload.beginDate = dayjs(element.start)
							.utc()
							.format()
							.substring(0, 19)
						_payload.endDate = dayjs(element.end)
							.utc()
							.format()
							.substring(0, 19)
						_payload.fareAnHourWon = element.fareAnHourWon
						_payload.farePerPersonWon = element.farePerPersonWon
						_payload.maxNumberOfRooms = element.maxNumberOfRooms

						await MarketLocationService.addMarketLocationSchedule({
							marketLocationId: props.marketLocationId,
							payload: _payload,
						})
					})

					successSwal('스케쥴이 등록되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '스케쥴을 등록하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const addSchedule = async selectedEvent => {
			const confirm = await confirmSwal(
				`${selectedEvent.name}을 등록 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					const _payload = { ...scheduleForm.value }
					_payload.beginDate = dayjs(selectedEvent.start)
						.utc()
						.format()
						.substring(0, 19)
					_payload.endDate = dayjs(selectedEvent.end)
						.utc()
						.format()
						.substring(0, 19)
					_payload.fareAnHourWon = selectedEvent.fareAnHourWon
					_payload.farePerPersonWon = selectedEvent.farePerPersonWon
					_payload.maxNumberOfRooms = selectedEvent.maxNumberOfRooms

					await MarketLocationService.addMarketLocationSchedule({
						marketLocationId: props.marketLocationId,
						payload: _payload,
					})

					newEvents.value = []
					successSwal('스케쥴이 등록되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400
								? e.response.data.message
								: '스케쥴을 등록하는데 문제가 발생했습니다.',
					})
				}
			}
		}

		watch(
			() => props.isOpenAddScheduleDialog,
			currentValue => {
				if (currentValue) {
					nextTick(() => {
						calendar.value.checkChange()
						getMarketLocationSchedules()
					})
				}
			},
		)

		return {
			title,
			focus,
			type,
			mode,
			selectedEvent,
			selectedElement,
			selectedOpen,
			events,
			newEvents,
			colors,
			names,
			firstTime,
			dragEvent,
			dragStart,
			lastEvent,
			createEvent,
			createStart,
			extendOriginal,
			calendar,
			scheduleForm,

			viewDay,
			setToday,
			showEvent,
			getEventColor,
			startDrag,
			startTime,
			extendTop,
			extendBottom,
			mouseMove,
			endDrag,
			cancelDrag,
			addSchedule,
			addSchedules,
			toTime,
			removeEvent,
			closeDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-event-draggable {
	padding-left: 6px;
}

.v-event-timed {
	user-select: none;
	-webkit-user-select: none;
}

.v-event-drag-bottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 4px;
	height: 4px;
	cursor: ns-resize;

	&::after {
		display: none;
		position: absolute;
		left: 50%;
		height: 4px;
		border-top: 1px solid white;
		border-bottom: 1px solid white;
		width: 16px;
		margin-left: -8px;
		opacity: 0.8;
		content: '';
	}

	&:hover::after {
		display: block;
	}
}

.v-event-drag-top {
	position: absolute;
	left: 0;
	right: 0;
	top: 4px;
	height: 4px;
	cursor: ns-resize;

	&::after {
		display: none;
		position: absolute;
		left: 50%;
		height: 4px;
		border-top: 1px solid white;
		border-bottom: 1px solid white;
		width: 16px;
		margin-left: -8px;
		opacity: 0.8;
		content: '';
	}

	&:hover::after {
		display: block;
	}
}
</style>
