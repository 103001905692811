import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const loading = {
	name: 'loading',
	namespaced: true,
	state: {
		isOverlay: false,
	},
	mutations: {
		setIsOverlay(state, isOverlay) {
			state.isOverlay = isOverlay
		},
	},
	actions: {
		setIsOverlay({ commit }, isOverlay) {
			commit('setIsOverlay', isOverlay)
		},
	},
}

export default loading
