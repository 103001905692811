<template>
	<v-dialog v-model="isOpenMarketBalanceDetailDialog" persistent>
		<v-card class="balance">
			<v-card-title class="d-flex justify-space-between">
				<div>
					<v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
					사용자 이체 내역
				</div>
				<div>
					<v-btn
						@click="$emit('update:is-open-market-balance-detail-dialog', false)"
						icon
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-card-title>
			<div class="balance-list">
				<v-card-title class="d-flex justify-space-between align-end">
					<div>
						<div class="d-flex align-end">
							<date-picker-range-dialog
								class="mt-4"
								:label="'거래 시작-종료 날짜를 입력하세요'"
								:width="272"
								:date-value.sync="dateRange"
							/>
							<v-btn @click="inquiryDate" color="primary">조회</v-btn>
						</div>
					</div>
					<div class="d-flex">
						<v-select
							item-text="name"
							item-value="value"
							:items="itemsPerPageGroups"
							label="페이지당 개수"
							@change="changeItemsPerPage"
							:value="10"
							style="max-width: 100px"
							hide-details
							dense
							outlined
						/>
					</div>
				</v-card-title>
				<v-data-table
					:headers="headers"
					:items="balances"
					:items-per-page="itemsPerPage"
					no-data-text="조회된 내역이 없습니다."
					class="elevation-1 cursor-pointer"
					:loading="isLoading"
					loading-text="주문을 불러오는 중입니다..."
					show-select
					hide-default-footer
				>
					<template
						v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
					>
						<v-simple-checkbox
							:value="isSelected"
							@input="select"
							:ripple="false"
							:disabled="item.withdrawn"
						/>
					</template>
					<template v-slot:[`item.user`]="{ item }">
						{{ item.user.name }}
					</template>
					<template v-slot:[`item.phone`]="{ item }">
						{{ item.user.phoneNumber | excludeInternationalPhoneFormat }}
					</template>
					<template v-slot:[`item.refund`]="{ item }">
						예금주:
						<strong>{{ item.user.name }}</strong>
						<br />
						계좌정보:
						<strong>
							{{ resolveBankCode(item.user.refundBankCode) }}
							{{ item.user.refundBankAccount }}
						</strong>
					</template>
					<template v-slot:[`item.balance`]="{ item }">
						{{ Math.abs(item.balance) | commaFormat }} 원
					</template>
					<template v-slot:[`item.transactionType`]="{ item }">
						<span class="type" :class="resolveBalanceTypeVariant(item)">
							{{ resolveBalanceType(item) }}
						</span>
					</template>
					<template v-slot:[`item.amount`]="{ item }">
						<span class="amount" :class="resolveBalanceTypeVariant(item)">
							<span v-if="item.amount < 0">-</span>
							{{ Math.abs(item.amount) | commaFormat }} 원
						</span>
					</template>
					<template v-slot:[`item.transactionDate`]="{ item }">
						{{ transactionTimeFormat(convertUTC2KST(item.transactionDate)) }}
					</template>
					<template v-slot:[`item.withdrawnAt`]="{ item }">
						{{ transactionTimeFormat(convertUTC2KST(item.withdrawnAt)) || '' }}
					</template>
					<template v-slot:[`item.title`]="{ item }">
						{{ resolveTitle(item) }}
					</template>
				</v-data-table>
				<div class="mx-auto mt-6">
					<v-pagination
						v-model="currentPage"
						:length="totalPages"
						@input="handlePageChange"
						total-visible="7"
					/>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { onMounted, ref, reactive, computed, watch } from '@vue/composition-api'

import dayjs from 'dayjs'

import { convertUTC2KST } from '@/filter'
import { warningSwal } from '@/plugins/swalMixin'
import { transactionTimeFormat } from '@core/utils/filter'

import UtilityService from '@/services/UtilityService'
import MarketBalanceService from '@/services/MarketBalanceService'

import DatePickerRangeDialog from '@/components/common/DatePickerRangeDialog.vue'

const ITEMS_PER_PAGE = 10

export default {
	components: {
		DatePickerRangeDialog,
	},
	props: {
		isOpenMarketBalanceDetailDialog: {
			type: Boolean,
			default: false,
		},
		userId: {
			type: [String, Number],
		},
	},
	setup(props) {
		const isLoading = ref(false)
		const balances = ref([])
		const banks = ref([])
		const beginDate = ref('')
		const endDate = ref('')
		const selectedTransactionType = ref([])
		const selectTransactionTypeEl = ref(null)
		const headers = ref([
			{ text: '이름', value: 'user' },
			{ text: '휴대폰 번호', value: 'phone' },
			{ text: '계좌 정보', value: 'refund' },
			{ text: '잔액', value: 'balance' },
			{ text: '거래 타입', value: 'transactionType' },
			{ text: '금액', value: 'amount' },
			{ text: '거래 날짜', value: 'transactionDate' },
			{
				text: '적요',
				align: 'left',
				value: 'title',
			},
		])
		const currentPage = ref(0)
		const totalPages = ref(0)
		const itemsPerPage = ref(ITEMS_PER_PAGE)
		const itemsPerPageGroups = reactive([
			{
				name: '10',
				value: 10,
			},
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])
		const transactionTypeGroups = reactive([
			{
				name: '입금',
				value: 'show_income',
			},
			{
				name: '출금',
				value: 'show_expense',
			},
		])

		const dateRange = computed({
			get() {
				return [beginDate.value, endDate.value]
			},
			set(value) {
				const temp = value.sort(function (a, b) {
					return new Date(a) - new Date(b)
				})

				beginDate.value = temp[0]
				endDate.value = temp[1]
			},
		})

		const listMarketBalances = async () => {
			try {
				isLoading.value = true
				const payload = {
					limit: itemsPerPage.value,
					page: currentPage.value - 1,
				}

				if (beginDate.value) {
					payload.beginDate = dayjs(beginDate.value).format(
						'YYYY-MM-DD 00:00:01',
					)
				}

				if (endDate.value) {
					payload.endDate = dayjs(endDate.value).format('YYYY-MM-DD 23:59:59')
				}

				if (selectedTransactionType.value.length > 0) {
					payload.transactionType = selectedTransactionType.value
				}

				if (props.userId) {
					payload.userId = props.userId
				}

				const data = await MarketBalanceService.listMarketBalancesDetail(
					payload,
				)

				balances.value = data.data
				totalPages.value = parseInt(data.totalPages)
			} catch (e) {
				warningSwal('내역을 가져오는데 문제가 발생했습니다.')
			} finally {
				isLoading.value = false
			}
		}

		const getBanks = async () => {
			banks.value = await UtilityService.getBanks()
		}

		const resolveBankCode = bankCode => {
			return banks.value[bankCode]
		}

		const resolveTitle = item => {
			let title = item.title
			if (item.marketBalancePoint) {
				title = item.marketBalancePoint.title
			}
			if (item.marketBalanceTransfer) {
				title = item.marketBalanceTransfer.title
			}
			if (item.marketBalanceWithdrawal) {
				title = item.marketBalanceWithdrawal.title
			}
			if (item.marketPayment) {
				title = item.marketPayment.title
			}

			return title
		}

		const resolveBalanceType = item => {
			let type = ''
			if (item.marketBalancePoint) {
				type = '리워드 입금'
			}
			if (item.marketBalanceTransfer) {
				type = '리워드 이체'
			}
			if (item.marketBalanceWithdrawal) {
				type = '리워드 출금'
			}
			if (item.marketPayment) {
				type = '리워드 결제'
			}

			return type
		}

		const resolveBalanceTypeVariant = item => {
			let variant = ''
			if (item.marketBalancePoint) {
				variant = 'income'
			}
			if (item.marketBalanceTransfer) {
				variant = 'expense'
			}
			if (item.marketBalanceWithdrawal) {
				variant = 'expense'
			}
			if (item.marketPayment) {
				variant = 'income'
			}

			return variant
		}

		const inquiryDate = async () => {
			if (!beginDate.value || !endDate.value) {
				warningSwal('시작-종료 날짜를 입력하세요')
				return
			}

			await listMarketBalances()
		}

		const inquiryTransactionType = async () => {
			if (selectTransactionTypeEl.value) {
				selectTransactionTypeEl.value.blur()
			}

			await listMarketBalances()
		}

		const changeItemsPerPage = async limit => {
			itemsPerPage.value = limit
			await listMarketBalances()
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await listMarketBalances()
		}

		watch(
			() => props.userId,
			async () => {
				await listMarketBalances()
			},
		)

		onMounted(() => {
			getBanks()
		})

		return {
			balances,
			headers,
			isLoading,
			beginDate,
			endDate,
			totalPages,
			currentPage,
			itemsPerPage,
			itemsPerPageGroups,
			transactionTypeGroups,
			selectTransactionTypeEl,
			selectedTransactionType,

			dateRange,
			resolveTitle,
			convertUTC2KST,
			resolveBankCode,
			handlePageChange,
			changeItemsPerPage,
			resolveBalanceType,
			resolveBalanceTypeVariant,
			transactionTimeFormat,
			inquiryTransactionType,

			inquiryDate,
		}
	},
}
</script>
<style lang="scss" scoped>
.balance-list {
	.type,
	.amount {
		font-weight: 600;

		&.expense {
			color: #ff4c51;
		}
	}
}
</style>
