<template>
	<div class="px-2">
		<v-autocomplete
			ref="selectSubjectEl"
			v-model="selectedSubject"
			:items="subjectGroups"
			:menu-props="{
				closeOnClick: true,
			}"
			dense
			chips
			filled
			outlined
			single-line
			hide-details
			return-object
			item-text="name"
			label="콘텐츠 주제"
			color="blue-grey lighten-2"
			style="background-color: white"
		>
			<template v-slot:selection="data">
				<v-chip
					v-bind="data.attrs"
					:input-value="data.selected"
					close
					@click="data.select"
					@click:close="removeChip()"
				>
					{{ data.item.name }}
				</v-chip>
			</template>

			<template v-slot:item="{ item, attrs, on }">
				<v-list-item
					v-on="on"
					v-bind="attrs"
					#default="{ active }"
					style="height: 3.5rem"
				>
					<v-list-item-action>
						<v-checkbox :ripple="false" :input-value="active" />
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>
							<v-row no-gutters align="center">
								<span
									class="text-base"
									v-html="item.name + item.description"
									v-if="item.description"
								></span>
								<span class="text-base" v-html="item.name" v-else></span>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<template v-slot:no-data>
				<div class="text-center">검색된 데이터가 없습니다</div>
			</template>
		</v-autocomplete>
		<div class="d-flex justify-space-around align-center my-2">
			<v-card v-if="post" style="flex: 1">
				<v-card-subtitle class="pb-0">
					<strong>변경 전</strong>
				</v-card-subtitle>
				<v-card-title class="pt-0">
					{{ post.subject.title }}
					&#40; id: {{ post.subject.id }} &#41;
				</v-card-title>
			</v-card>
			<v-icon x-large>mdi-arrow-right-bold</v-icon>
			<v-card v-if="post" style="flex: 1">
				<v-card-subtitle class="pb-0">
					<strong>변경 후</strong>
				</v-card-subtitle>
				<v-card-title class="pt-0">
					<span v-if="selectedSubject" class="mr-1">
						{{ selectedSubject.name }} &#40; id:
						{{ selectedSubject.value }} &#41;
					</span>
					<span class="mr-1" v-else>-</span>
				</v-card-title>
			</v-card>
		</div>
		<v-btn
			rounded
			color="primary"
			:ripple="false"
			:disabled="!selectedSubject"
			class="px-0 mb-2 font-weight-bold w-full"
			@click="update()"
		>
			업데이트 하기 (준비중)
		</v-btn>
	</div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'

import dayjs from 'dayjs'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketPostService from '@/services/MarketPostService'
import MarketPostSubjectService from '@/services/MarketPostSubjectService'

export default {
	components: {},
	props: {
		post: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const subjectGroups = ref([])
		const selectedSubject = ref(null)

		const update = async () => {
			const confirm = await confirmSwal(
				`<strong>${selectedSubject.value.name} (id: ${selectedSubject.value.value})</strong> 주제로 업데이트 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketPostService.update(props.post.id, {
						subjectId: selectedSubject.value.value,
					})
					selectedSubject.value = null
					successSwal('주제가 업데이트 되었습니다')
				} catch (e) {
					warningSwal('주제를 업데이트하는데 문제가 발생했습니다')
				}
			}
		}

		const getAll = async () => {
			const { data } = await MarketPostSubjectService.getAll({
				type: props.post.type,
				lounge: props.post.subject.lounge,
				orderby: 'endDate',
				order: 'desc',
				limit: 10000,
			})

			data.map(e => {
				const description =
					`(id: ${e.id})` +
					'<br>' +
					`<small>시작날짜: <strong>${dayjs(e.startDate).format(
						'YYYY년 MM월 D일',
					)}</strong></small>`

				subjectGroups.value.push({
					name: e.title,
					lounge: e.lounge,
					description: description,
					value: e.id,
				})
			})
		}

		const removeChip = () => {
			selectedSubject.value = null
		}

		onMounted(() => {
			getAll()
		})

		return {
			subjectGroups,
			selectedSubject,

			removeChip,
			update,
		}
	},
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
