<template>
	<v-dialog
		v-model="isOpenLinkMarketCourseLectureDialog"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				강의 연결하기
				<v-spacer />
				<v-btn
					icon
					@click="
						$emit('update:is-open-link-market-course-lecture-dialog', false)
					"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<market-lecture-form :form.sync="form" />

					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							@click.stop="linkMarketCourseLecture"
							:disabled="!valid"
						>
							연결하기
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLectureService from '@/services/MarketLectureService'

import MarketLectureForm from './components/MarketLectureForm.vue'

export default {
	components: {
		MarketLectureForm,
	},
	props: {
		isOpenLinkMarketCourseLectureDialog: {
			type: Boolean,
			required: true,
		},
		marketCourseId: {
			required: true,
		},
	},
	setup(props, { emit }) {
		const form = ref({
			title: '',
			marketCourseId: props.marketCourseId,
			description: '',
			vimeoVideoId: null,
			vimeoVideoLengthInSec: null,
			visible: false,
		})

		const linkMarketCourseLecture = async () => {
			const confirm = await confirmSwal('강의를 연결하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLectureService.createMarketLecture(form.value)
					await successSwal('해당 강의가 과정에 연결되었습니다')
					emit('fetch-market-course-lectures', true)
					emit('update:is-open-link-market-course-lecture-dialog', false)
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '해당 강의를 과정에 연결하는데 문제가 발생했습니다.',
					)
				}
			}
		}
		return {
			form,

			linkMarketCourseLecture,
		}
	},
}
</script>
