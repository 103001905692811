<template>
	<v-card elevation="0">
		<v-card-title>상품 리스트</v-card-title>
		<v-data-table
			:headers="headers"
			:items="marketItems"
			item-key="email"
			no-data-text="조회된 상품이 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text="상품을 불러오는 중입니다..."
			hide-default-footer
			@click:row="routeToDetail($event.id)"
		>
			<template v-slot:[`item.amount`]="{ item }">
				{{ item.amount | commaFormat }}
			</template>
			<template v-slot:[`item.startDate`]="{ item }">
				{{ item.startDate | dateKOSimpleSiBunChoFormat }}
			</template>
			<template v-slot:[`item.endDate`]="{ item }">
				{{ item.endDate | dateKOSimpleSiBunChoFormat }}
			</template>
		</v-data-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn :to="{ name: 'market-items-register' }" color="primary">
				상품 등록
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import MarketItemService from '@/services/MarketItemService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const isLoading = ref(false)
		const marketItems = ref([])
		const headers = ref([
			{
				text: '타이틀',
				align: 'left',
				value: 'title',
			},
			{ text: '설명', value: 'description' },
			{ text: '장소', value: 'location' },
			{ text: '금액', value: 'amount' },
			{ text: '등록 시작 날짜', value: 'startDate' },
			{ text: '등록 마감 날짜', value: 'endDate' },
		])

		const listMarketItems = async () => {
			try {
				isLoading.value = true
				marketItems.value = await MarketItemService.listMarketItems()
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}
		listMarketItems()

		const routeToDetail = marketItemId => {
			router.push({
				name: 'market-items-detail',
				params: { marketItemId: marketItemId },
			})
		}

		return {
			isLoading,
			marketItems,
			headers,

			routeToDetail,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
