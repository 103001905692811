export default [
	{
		path: '/market-survey-questionnaires',
		name: 'market-survey-questionnaires-list',
		component: () =>
			import(
				'@/pages/marketSurvey/marketSurveyQuestionnaires/MarketSurveyQuestionnairesList.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
	{
		path: '/market-survey-questionnaires/:marketQuestionnairesId',
		name: 'market-survey-questionnaires-detail',
		component: () =>
			import(
				'@/pages/marketSurvey/marketSurveyQuestionnaires/MarketSurveyQuestionnairesDetail.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
	{
		path: '/market-survey-questionnaires-register',
		name: 'market-survey-questionnaires-register',
		component: () =>
			import(
				'@/pages/marketSurvey/marketSurveyQuestionnaires/MarketSurveyQuestionnairesRegister.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
