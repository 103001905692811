<template>
	<v-card elevation="0" class="px-3">
		<v-card-title class="d-flex justify-space-between px-0">
			<div>강의 수강자 대시보드</div>
			<div class="d-flex">
				<v-btn color="primary" @click="downloadUsersExcelFile">
					엑셀 다운로드
				</v-btn>
			</div>
		</v-card-title>
		<market-course-registration-filter
			@inquiry="inquiry"
			@begin-date="beginDate = $event"
			@end-date="endDate = $event"
			@items-per-page="itemsPerPage = $event"
			@date-criteria="dateCriteria = $event"
			@keyword="keyword = $event"
			@license-ids="licenseIds = $event"
			@selected-search-category="selectedSearchCategory = $event"
		/>

		<v-data-table
			:headers="headers"
			:items="registrations"
			:items-per-page="itemsPerPage"
			item-key="email"
			no-data-text="조회된 수강자가 없습니다."
			class="elevation-1"
			:loading="isLoading"
			loading-text="수강자를 불러오는 중입니다..."
			hide-default-footer
		>
			<template v-slot:[`item.index`]="{ item }">
				{{ registrations.indexOf(item) + 1 }}
			</template>
			<template v-slot:[`item.name`]="{ item }">
				<span @click="openUserInfoDialog(item.user.id)" class="clickable">
					{{ item.user.name }}
				</span>
			</template>
			<template v-slot:[`item.user.phoneNumber`]="{ item }">
				{{ item.user.phoneNumber | excludeInternationalPhoneFormat }}
			</template>
			<template v-slot:[`item.user.createdAt`]="{ item }">
				{{ item.user.createdAt | convertUTC2KST }}
			</template>
			<template v-slot:[`item.user.ancestorUser`]="{ item }">
				<span
					v-if="item.user.ancestorUser"
					@click="openUserInfoDialog(item.user.ancestorUser.id)"
					class="clickable"
				>
					{{ item.user.ancestorUser.name }} &#47;
					{{ item.user.ancestorUser.uid }}
				</span>
			</template>
			<template v-slot:[`item.user.recommenderUser`]="{ item }">
				<span
					v-if="item.user.recommenderUser"
					@click="openUserInfoDialog(item.user.recommenderUser.id)"
					class="clickable"
				>
					{{ item.user.recommenderUser.name }} &#47;
					{{ item.user.recommenderUser.uid }}
				</span>
			</template>
			<template v-slot:[`item.courseRegistration.createdAt`]="{ item }">
				{{ item.courseRegistration.createdAt | convertUTC2KST }}
			</template>
			<template v-slot:[`item.applyCanceledAt`]="{ item }">
				{{ item.applyCanceledAt | convertUTC2KST }}
			</template>
			<template v-slot:[`item.status`]>
				<v-chip small color="primary">신청완료</v-chip>
			</template>
			<template v-slot:[`item.paymentAmount`]="{ item }">
				<span v-if="item.latestPayment">
					{{ item.latestPayment.amount | commaFormat }}원
				</span>
			</template>
			<template v-slot:[`item.paymentStatus`]="{ item }">
				<v-chip
					small
					:color="
						resolveReservationStatusVariant(
							item.latestPayment.marketOrder.status.toUpperCase(),
						)
					"
					v-if="item.latestPayment && item.latestPayment.marketOrder"
				>
					{{
						resolveReservationStatus(
							item.latestPayment.marketOrder.status.toUpperCase(),
						)
					}}
				</v-chip>
			</template>
			<template v-slot:[`item.paymentTotalAmount`]="{ item }">
				{{ item.totalPayment | commaFormat }} 원
			</template>
			<template v-slot:[`item.paymentAt`]="{ item }">
				<span v-if="item.latestPayment && item.latestPayment.marketOrder">
					{{ item.latestPayment.marketOrder.paidAt | convertUTC2KST }}
				</span>
			</template>
			<template v-slot:[`item.paymentMethod`]="{ item }">
				<span v-if="item.latestPayment && item.latestPayment.pgResponse">
					{{ resolvePgResponsePaymentMethod(item.latestPayment.pgResponse) }}
				</span>
			</template>
			<template v-slot:[`item.paymentCanceledAt`]="{ item }">
				<span v-if="item.latestPayment && item.latestPayment.marketOrder">
					{{ item.latestPayment.marketOrder.canceledAt | convertUTC2KST }}
				</span>
			</template>

			<template v-slot:[`item.completionCount`]="{ item }">
				<strong
					:class="item.courseRegistration.finished ? 'finished' : 'unfinished'"
				>
					{{ item.courseRegistration.finishedCount }}
				</strong>

				/
				{{ item.courseRegistration.totalCount }}
			</template>
			<template v-slot:[`item.testAt`]="{ item }">
				{{ item.testAt | convertUTC2KST }}
			</template>
			<template v-slot:[`item.resultScore`]="{ item }">
				<strong
					:class="item.latestWorkbook.pass ? 'pass' : 'failed'"
					v-if="item.latestWorkbook && item.latestWorkbook.pass"
				>
					{{ item.latestWorkbook.score }} 점
				</strong>
			</template>
			<template v-slot:[`item.answerSheet`]="{ item }">
				<v-btn
					small
					rounded
					color="secondary"
					@click.stop="openAnswerSheet(item.latestWorkbook)"
					v-if="item.latestWorkbook"
				>
					답안지 보기
				</v-btn>
			</template>
			<template v-slot:[`item.retryCount`]="{ item }">
				<span v-if="item.licenseUserTests.length > 1">
					{{ item.licenseUserTests.length - 1 }} 회
				</span>
			</template>
			<template v-slot:[`item.retryPaymentInfo`]="{ item }">
				{{
					resolveRetryPaymentInfo({
						latestPayment: item.latestPayment,
						payments: item.payments,
					})
				}}
			</template>
			<template v-slot:[`item.licenseTitle`]="{ item }">
				<span v-if="item.user.licenseIssues.length > 0">
					{{
						item.user.licenseIssues[item.user.licenseIssues.length - 1].license
							.title
					}}
				</span>
			</template>
			<template v-slot:[`item.licenseClassNumber`]="{ item }">
				<span v-if="item.user.licenseIssues.length > 0">
					{{
						item.user.licenseIssues[item.user.licenseIssues.length - 1]
							.classNumber
					}}기
				</span>
			</template>
			<template v-slot:[`item.licenseIssueCode`]="{ item }">
				<span v-if="item.user.licenseIssues.length > 0">
					{{
						item.user.licenseIssues[item.user.licenseIssues.length - 1]
							.issueCode
					}}
				</span>
			</template>
			<template v-slot:[`item.licenseIssuedAt`]="{ item }">
				<span v-if="item.user.licenseIssues.length > 0">
					{{
						item.user.licenseIssues[item.user.licenseIssues.length - 1].issuedAt
							| convertUTC2KST
					}}
				</span>
			</template>
			<template v-slot:[`item.user.idPhotoUrl`]="{ item }">
				{{ item.user.idPhotoUrl ? '유' : '무' }}
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
		<user-dialog
			:user-id="selectedUserId"
			:is-open-user-info-dialog.sync="isOpenUserInfoDialog"
		/>
		<license-test-participants-user-answer-sheet-dialog
			:workbook="selectedWorkbook"
			:is-open-user-test-dialog.sync="isOpenUserTestDialog"
		/>
	</v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import { paymentMethods, eventCategory } from '@/helpers'
import {
	resolveApplicationStatus,
	resolveApplicationStatusVariant,
	resolveReservationStatus,
	resolveReservationStatusVariant,
} from '@core/utils/filter'

import UserService from '@/services/UserService'
import MarketCourseService from '@/services/MarketCourseService'

import UserDialog from '@/components/common/UserDialog.vue'
import MarketCourseRegistrationFilter from './MarketCourseRegistrationFilter.vue'
import LicenseTestParticipantsUserAnswerSheetDialog from '@/pages/license/LicenseTestParticipantsUserAnswerSheetDialog.vue'

export default {
	components: {
		UserDialog,
		MarketCourseRegistrationFilter,
		LicenseTestParticipantsUserAnswerSheetDialog,
	},
	setup() {
		const keyword = ref('')
		const licenseIds = ref('')
		const beginDate = ref('')
		const endDate = ref('')
		const dateCriteria = ref('')
		const selectedUserId = ref(0)
		const selectedWorkbook = ref({})
		const selectedSearchCategory = ref('')
		const isOpenUserInfoDialog = ref(false)
		const isOpenUserTestDialog = ref(false)
		const isOpenMarketOrderPaymentDialog = ref(false)
		const isLoading = ref(false)
		const registrations = ref([])
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const headers = ref([
			{ text: 'no', value: 'index', width: '80px' },
			{ text: '이름', value: 'name', width: '100px' },
			{ text: '유저명', value: 'user.uid', width: '100px' },
			{ text: '생년월일', value: 'user.birthDate', width: '120px' },
			{ text: '휴대폰 번호', value: 'user.phoneNumber', width: '120px' },
			{ text: '회원가입일', value: 'user.createdAt', width: '170px' },
			{ text: '추천인', value: 'user.recommenderUser', width: '170px' },
			{
				text: '추천인 휴대폰 번호',
				value: 'user.recommenderUser.phone',
				width: '150px',
			},
			{ text: '상위리더', value: 'user.ancestorUser', width: '170px' },
			{
				text: '상위리더 휴대폰 번호',
				value: 'user.ancestorUser.phone',
				width: '150px',
			},
			{
				text: '강의 신청일',
				value: 'courseRegistration.createdAt',
				width: '220px',
			},
			{ text: '강의 신청 취소일', value: 'applyCanceledAt', width: '220px' },
			{ text: '강의 신청 상태', value: 'status', width: '120px' },
			{ text: '강의 결제일', value: 'paymentAt', width: '220px' },
			{ text: '강의 결제 환불일', value: 'paymentCanceledAt', width: '220px' },
			{ text: '강의 결제 상태', value: 'paymentStatus', width: '120px' },
			{ text: '강의 결제 금액', value: 'paymentAmount', width: '120px' },
			{ text: '강의 결제 수단', value: 'paymentMethod', width: '240px' },
			{ text: '강의 수강수', value: 'completionCount', width: '160px' },
			{ text: '시험 응시일', value: 'testAt', width: '220px' },
			{ text: '시험 응시 점수', value: 'resultScore', width: '120px' },
			{ text: '시험 응시 답안지', value: 'answerSheet', width: '140px' },
			{ text: '시험 재응시 횟수', value: 'retryCount', width: '130px' },
			{
				text: '시험 재응시 결제정보',
				value: 'retryPaymentInfo',
				width: '550px',
			},
			{ text: '총 결제 금액', value: 'paymentTotalAmount', width: '130px' },
			{ text: '자격증 명', value: 'licenseTitle', width: '140px' },
			{ text: '자격증 기수', value: 'licenseClassNumber', width: '140px' },
			{ text: '자격증 발급번호', value: 'licenseIssueCode', width: '140px' },
			{ text: '자격증 발급일', value: 'licenseIssuedAt', width: '220px' },
			{
				text: '자격증 사진 업로드 유무',
				value: 'user.idPhotoUrl',
				width: '100px',
			},
		])

		const listMarketCourseRegistrationsDashboard = async () => {
			const _registrations = []
			try {
				isLoading.value = true
				const result =
					await MarketCourseService.listMarketCourseRegistrationsDashboard(
						currentPage.value - 1,
						itemsPerPage.value,
						keyword.value,
						licenseIds.value,
						selectedSearchCategory.value,
						dateCriteria.value,
						beginDate.value,
						endDate.value,
					)

				totalPages.value = parseInt(result.totalPages)
				result.data.map(e => {
					// 최근 결제 정보
					const _latestPayment = e.payments.sort(
						(a, b) => new Date(b.createdAt) - new Date(a.createdAt),
					)
					e.latestPayment = _latestPayment[0]

					// 결제 금액 합계
					const _totalPayment = e.payments.reduce(
						(accumulator, currentValue) => accumulator + currentValue.amount,
						0,
					)
					e.totalPayment = _totalPayment

					// 최근 시험 응시 정보
					const _latesWorkbook = e.licenseUserTests.sort(
						(a, b) => new Date(b.id) - new Date(a.id),
					)
					e.latestWorkbook = _latesWorkbook[0]

					_registrations.push(e)
				})
				registrations.value = _registrations
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}

		const openAnswerSheet = payload => {
			selectedUserId.value = payload.user.id
			selectedWorkbook.value = {
				id: payload.licenseWorkbook.id,
				answerSheet: payload.answerSheet,
				problemCount: payload.licenseWorkbook.problemCount,
				userId: payload.user.id,
				score: payload.score,
				pass: payload.pass,
			}
			isOpenUserTestDialog.value = !isOpenUserTestDialog.value
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await listMarketCourseRegistrationsDashboard()
		}

		const downloadUsersExcelFile = async () => {
			const userIds = []
			registrations.value.map(async registration => {
				userIds.push(registration.user.id)
			})

			try {
				const response = await UserService.downloadUserExcelUserIds(
					userIds.join(),
				)
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', '강의수강자_리스트.xlsx')
				document.body.appendChild(link)
				link.click()
			} catch (e) {
				console.error(e)
			}
		}

		const openUserInfoDialog = userId => {
			selectedUserId.value = userId
			isOpenUserInfoDialog.value = !isOpenUserInfoDialog.value
		}

		const isEmptyObj = obj => {
			for (const prop in obj) {
				// https://stackoverflow.com/questions/39282873/object-hasownproperty-yields-the-eslint-no-prototype-builtins-error-how-to
				if (Object.prototype.hasOwnProperty.call(obj, prop)) return false
			}

			return true
		}

		const resolvePgResponsePaymentMethod = pgResponse => {
			try {
				const _pgResponse = JSON.parse(pgResponse)
				if (isEmptyObj(_pgResponse)) {
					return ''
				}

				let paymentMethod = ''
				if (_pgResponse.pay_method === paymentMethods.CARD.value) {
					paymentMethod += `${paymentMethods.CARD.name} ${_pgResponse.card_name} ${_pgResponse.card_number}`
				} else if (_pgResponse.pay_method === paymentMethods.VBANK.value) {
					paymentMethod += `${paymentMethods.CARD.name} ${_pgResponse.vbank_name} ${_pgResponse.vbank_num}`
				} else {
					paymentMethod += '정보없음'
				}

				return paymentMethod
			} catch (e) {
				console.error(e)
			}
		}

		const resolveRetryPaymentInfo = ({ latestPayment, payments }) => {
			try {
				const _payments = payments.filter(e => e.id !== latestPayment.id)
				let paymentInfo = ''
				for (const [_, payment] of Object.entries(_payments)) {
					if (paymentInfo !== '') {
						paymentInfo += ' &#47; '
					}
					if (isEmptyObj(JSON.parse(payment.marketOrder.pgResponse))) {
						const markeOrder = payment.marketOrder
						if (markeOrder.payMethod === paymentMethods.CARD.value) {
							paymentInfo += `${paymentMethods.CARD.name}`
						} else if (markeOrder.payMethod === paymentMethods.VBANK.value) {
							paymentInfo += `${paymentMethods.VBANK.name} ${markeOrder.vaccountName} ${markeOrder.vaccountNumber}`
						} else {
							paymentInfo += `정보없음`
						}
					} else {
						paymentInfo += resolvePgResponsePaymentMethod(
							payment.marketOrder.pgResponse,
						)
					}
				}

				return paymentInfo
			} catch (e) {
				console.error(e)
			}
		}

		const inquiry = async () => {
			await listMarketCourseRegistrationsDashboard()
		}

		onMounted(async () => {
			await listMarketCourseRegistrationsDashboard()
		})

		return {
			headers,
			keyword,
			beginDate,
			endDate,
			isLoading,
			totalPages,
			licenseIds,
			currentPage,
			itemsPerPage,
			dateCriteria,
			registrations,
			eventCategory,
			selectedUserId,
			selectedWorkbook,
			selectedSearchCategory,
			isOpenUserInfoDialog,
			isOpenUserTestDialog,

			isOpenMarketOrderPaymentDialog,

			resolveApplicationStatus,
			resolveApplicationStatusVariant,
			resolveReservationStatus,
			resolveReservationStatusVariant,

			inquiry,
			isEmptyObj,
			openAnswerSheet,
			handlePageChange,
			openUserInfoDialog,
			downloadUsersExcelFile,
			resolveRetryPaymentInfo,
			resolvePgResponsePaymentMethod,
		}
	},
}
</script>
<style lang="scss" scoped>
.finished,
.pass {
	color: #16b1ff;
}
.unfinished,
.failed {
	color: #ff4c51;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
