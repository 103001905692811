<template>
	<v-card>
		<v-card-title>
			<div>1:1 문의</div>
		</v-card-title>
		<v-divider />
		<v-data-table
			:headers="headers"
			:items="posts"
			:items-per-page="-1"
			item-key="email"
			no-data-text="조회된 1:1문의가 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text=" 1:1문의를 불러오는 중입니다..."
			hide-default-footer
			@click:row="openPostDetailDialog($event.id)"
		>
			<template v-slot:[`item.writerUserUid`]="{ item }">
				{{ item.writerUserUid }}
			</template>
			<template v-slot:[`item.hasCommented`]="{ item }">
				<v-chip small :color="item.comments.length > 0 ? 'success' : 'error'">
					{{ item.comments.length > 0 ? '답변 완료' : '답변 필요' }}
				</v-chip>
			</template>
			<template v-slot:[`item.createdAt`]="{ item }">
				{{ item.createdAt | dateKOFormat }}
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
		<market-post-private-inquiry-dialog
			:post-id="selectedPostId"
			:is-open-post-private-inquiry-dialog.sync="isOpenPostPrivateInquiryDialog"
			@fetch-market-posts="isFetchMarketPosts = $event"
		/>
	</v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import MarketPostService from '@/services/MarketPostService'

import MarketPostPrivateInquiryDialog from './MarketPostPrivateInquiryDialog.vue'

const ITEMS_PER_PAGE = 1000

export default {
	components: {
		MarketPostPrivateInquiryDialog,
	},
	setup() {
		const posts = ref([])
		const selectedPostId = ref(null)
		const query = ref('')
		const currentPage = ref(0)
		const totalPages = ref(0)
		const itemsPerPage = ref(ITEMS_PER_PAGE)
		const selectedOrderBy = ref('createdAt')
		const selectedOrder = ref('desc')
		const isOpenPostPrivateInquiryDialog = ref(false)
		const isLoading = ref(false)
		const isFetchMarketPosts = ref(false)
		const headers = ref([
			{
				text: 'id',
				align: 'left',
				value: 'id',
				width: '120px',
			},
			{
				text: '문의자 유저명',
				value: 'writerUserUid',
			},
			{
				text: '제목',
				value: 'title',
			},
			{
				text: '답변 여부',
				value: 'hasCommented',
			},
			{
				text: '문의 날짜',
				value: 'createdAt',
			},
		])

		const getMarketPosts = async () => {
			try {
				isLoading.value = true

				const _posts = await MarketPostService.getMarketPosts({
					limit: itemsPerPage.value,
					page: currentPage.value,
					query: query.value,
					orderBy: selectedOrderBy.value,
					order: selectedOrder.value,
					type: 'PRIVATE_INQUIRY',
				})

				for (const post of _posts.posts) {
					posts.value.push(post)
				}
			} catch (e) {
				console.log(e)
			} finally {
				isLoading.value = false
			}
		}

		const openPostDetailDialog = postId => {
			selectedPostId.value = postId
			isOpenPostPrivateInquiryDialog.value =
				!isOpenPostPrivateInquiryDialog.value
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await getMarketPosts()
		}

		onMounted(() => {
			getMarketPosts()
		})

		return {
			posts,
			headers,
			selectedPostId,
			currentPage,
			itemsPerPage,
			totalPages,
			isOpenPostPrivateInquiryDialog,
			isLoading,
			isFetchMarketPosts,

			openPostDetailDialog,
			handlePageChange,
		}
	},
}
</script>
