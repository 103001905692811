<template>
	<v-dialog v-model="isOpenPostDetailDialog" max-width="840" persistent>
		<v-card>
			<v-card-title class="d-flex justify-space-between">
				<div style="flex: 1">
					<v-btn @click="closeDialog" icon>
						<v-icon large>mdi-chevron-left</v-icon>
					</v-btn>
				</div>

				<div class="font-weight-bold text-center" style="flex: 1">
					콘텐츠
					<span v-if="post">{{ post.postingNumber }}</span>
				</div>

				<div style="flex: 1; display: flex; justify-content: end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" icon>
								<v-icon>mdi-dots-vertical</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item @click="changeMode = 'subject'">
								<v-list-item-title>주제 변경하기</v-list-item-title>
							</v-list-item>
							<v-list-item @click="changeMode = 'result'">
								<v-list-item-title>상태 변경하기</v-list-item-title>
							</v-list-item>
							<v-list-item @click="deleteMarketPost(post.id)">
								<v-list-item-title>삭제하기</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-card-title>

			<transition name="fade" v-if="post">
				<market-post-detail-subject-change
					:post="post"
					v-if="changeMode === 'subject'"
				/>
				<market-post-detail-result-change
					:post="post"
					v-if="changeMode === 'result'"
				/>
			</transition>

			<v-divider />

			<v-card-text class="px-0 pt-2" v-if="post">
				<div
					class="px-3"
					style="background-color: white; width: 100%"
					:class="isSmAndDown ? 'px-1 pb-3 ' : 'pa-2'"
				>
					<div
						class="d-flex align-center cursor-pointer"
						@click="loadUserPosts(post.writerUser.uid)"
					>
						<v-avatar
							:size="isSmAndDown ? 20 : 26"
							v-if="post && post.writerUser"
						>
							<v-img
								:src="
									post.writerUser.publicPictureUrl ||
									require('@/assets/images/avatars/default.svg')
								"
							/>
						</v-avatar>
						<div class="d-flex flex-column ml-1">
							<span
								class="d-flex flex-wrap"
								:class="isSmAndDown ? 'text-xxs' : 'text-sm'"
								v-if="post && post.writerUser"
							>
								<span
									class="font-weight-bold"
									v-if="
										post &&
										post.writerUserLicenseIssues &&
										post.writerUserLicenseIssues.classNumber
									"
								>
									{{ post.writerUserLicenseIssues.classNumber }}기
								</span>
								<span class="font-weight-bold">
									{{ post.writerUser.uid }}
								</span>

								<div
									class="d-flex justify-end"
									v-if="post && post.contentMarketUserStatistics"
								>
									&#40;
									<span class="text-sm">
										<span class="text-xs">판매</span>
										{{ post.contentMarketUserStatistics.countAmount }}
										<span class="text-xs">건</span>
									</span>
									<span class="mx-1">&#47;</span>
									<span class="text-sm">
										<span class="text-xs">최고가</span>
										{{
											post.contentMarketUserStatistics.maxAmount | commaFormat
										}}
										<span class="text-xs">원</span>
									</span>
									<span class="mx-1">&#47;</span>
									<span class="text-sm">
										<span class="text-xs">총</span>
										{{
											post.contentMarketUserStatistics.sumAmount | commaFormat
										}}
										<span class="text-xs">원</span>
									</span>
									&#41;
								</div>
							</span>
							<span class="text-xs" style="letter-spacing: -0.4px" v-if="post">
								{{ post.createdAt | dateKOFormat }}
							</span>
						</div>
					</div>

					<v-divider
						v-if="post && post.contentMarketUserStatistics"
						class="my-1"
					/>

					<div class="text-right text-sm">
						<span class="text-sm">
							<span class="text-xs">총</span>
							{{ post.files.length }}
							<span class="text-xs">개</span>
						</span>
						&#47;
						<span class="text-sm">
							<span class="text-xs">이미지</span>
							{{ getNumberOfImages(post.files) }}
							<span class="text-xs">개</span>
						</span>
						&#47;
						<span class="text-sm">
							<span class="text-xs">GIF</span>
							{{ getNumberOfGifs(post.files) }}
							<span class="text-xs">개</span>
						</span>
						&#47;
						<span class="text-sm">
							<span class="text-xs">영상</span>
							{{ getNumberOfVideos(post.files) }}
							<span class="text-xs">개</span>
						</span>
					</div>
				</div>

				<market-post-swiper
					:post="post"
					:is-detail-content="true"
					:is-replace-img-src-empty.sync="isReplaceImgSrcEmpty"
					@open-media-dialog="openMediaDialog($event)"
				/>

				<div class="wrapper-content">
					<div class="d-flex justify-space-between">
						<div class="font-weight-bold">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										small
										v-bind="attrs"
										v-on="on"
										@click.stop="downloadFiles(post.files)"
									>
										<v-icon>mdi-download</v-icon>
									</v-btn>
								</template>
								<span>전체 다운로드</span>
							</v-tooltip>

							<span>
								{{ post.type | resolvePostType }}
								{{ post.postingNumber }}
							</span>
							<span v-if="post.subject" class="mx-1">&#62;</span>
							<span v-if="post.subject">{{ post.subject.title }}</span>
						</div>
						<post-comments-menu
							:post-id="post.id"
							:post-comments-count="post.comments.length"
							:unread-post-comments-count="post.unreadPostCommentsCount"
							v-if="post.comments.length > 0"
						/>
					</div>
					<div class="content" v-if="post">
						<span v-html="post.content"></span>
					</div>
				</div>
				<div v-if="!isDetermined(post.result)">
					<v-divider class="py-4 px-16" />
					<div class="payment px-4">
						<v-text-field
							ref="paymentInput"
							v-model="payment"
							hide-details
							readonly
							outlined
							reverse
							label="금액"
							placeholder="금액을 보낼까요?"
							@keypress="allowOnlyNumber($event)"
							prefix="P"
							style="font-size: 21px"
						/>
						<div class="grid-container mt-2" style="gap: 1px; flex-wrap: wrap">
							<v-btn @click="addPayment(6000)" outlined depressed>6천원</v-btn>
							<v-btn @click="addPayment(1000)" outlined depressed>1천원</v-btn>
							<v-btn @click="addPayment(0)" outlined depressed>초기화</v-btn>

							<v-btn @click="addPayment(10000)" outlined depressed>1만원</v-btn>
							<v-btn @click="addPayment(3000)" outlined depressed>3천원</v-btn>
							<v-btn
								color="accent"
								:disabled="
									payment === 0 || payment === '' || payment === undefined
								"
								@click="putCart"
							>
								<strong>담기</strong>
							</v-btn>
						</div>
					</div>

					<div class="actions">
						<div class="d-flex mt-2">
							<v-textarea
								lable="메모"
								class="flex-grow-1"
								placeholder="남길 메모를 작성해주세요"
								v-model="comment"
								hide-details
								outlined
								rows="3"
							/>
							<v-btn
								color="primary"
								:ripple="false"
								class="px-0 font-weight-bold ml-2 upload-comment flex-grow-0"
								style="min-height: 82px"
								:disabled="!comment"
								@click="addComment"
							>
								등록
							</v-btn>
						</div>
						<div class="d-flex mt-2" style="gap: 4px; flex-wrap: wrap">
							<v-btn color="primary" @click="purchase">
								<strong>바로 구매</strong>
							</v-btn>
							<v-btn color="error" @click="repay" depressed>
								<strong>반환</strong>
							</v-btn>
							<v-btn color="secondary" @click="closeDialog">
								<strong>창닫기</strong>
							</v-btn>
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>
		<market-post-media-dialog
			:file-url="selectedFileUrl"
			:is-open-media-dialog.sync="isOpenMediaDialog"
		/>
	</v-dialog>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'

import dayjs from 'dayjs'

import store from '@/store'
import { commaRemove } from '@/filter'
import { getVuetify } from '@core/utils'
import { contentsMarketStatus } from '@/helpers'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'
import MarketPostService from '@/services/MarketPostService'
import MarketPostSubjectService from '@/services/MarketPostSubjectService'

import PostCommentsMenu from './components/PostCommentsMenu.vue'
import MarketPostSwiper from './components/MarketPostSwiper.vue'
import MarketPostMediaDialog from './MarketPostMediaDialog.vue'
import MarketPostDetailSubjectChange from './components/MarketPostDetailSubjectChange.vue'
import MarketPostDetailResultChange from './components/MarketPostDetailResultChange.vue'

const THOUSAND_UNIT = 1000
const TENTHOUSAND_UNIT = 10000
const HUNDREDTHOUSAND_UNIT = 100000

export default {
	components: {
		MarketPostSwiper,
		PostCommentsMenu,
		MarketPostMediaDialog,
		MarketPostDetailResultChange,
		MarketPostDetailSubjectChange,
	},
	props: {
		isOpenPostDetailDialog: {
			required: true,
			type: Boolean,
		},
		postId: {
			type: [String, Number],
		},
	},
	setup(props, { emit }) {
		const post = ref(null)
		const sheet = ref(false)
		const comment = ref('')
		const payment = ref(0)
		const changeMode = ref('')
		const subjectGroups = ref([])
		const selectedFileUrl = ref(null)
		const selectedResult = ref(null)
		const selectedSubject = ref(null)
		const isOpenMediaDialog = ref(false)
		const isReplaceImgSrcEmpty = ref(false)
		const isSubjectChangeMode = ref(false)
		const isResultChangeMode = ref(false)
		const $vuetify = getVuetify()
		const profile = computed(() => store.getters['user/getMe'])

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const isDetermined = result => {
			return ![
				contentsMarketStatus.READY.value,
				contentsMarketStatus.DISAPPROVED.value,
				null,
				'undefined',
			].includes(result)
		}

		const isImage = url => {
			return url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
		}

		const isValidAmount = () => {
			let _message = ''
			let _result = true
			const _payment = commaRemove(payment.value)

			if (_payment < TENTHOUSAND_UNIT) {
				if (_payment < THOUSAND_UNIT) {
					_message = '1천원 이상만 입력이 가능합니다'
					_result = false
				} else {
					if (_payment % THOUSAND_UNIT != 0) {
						_message = '천 단위로만 입력이 가능합니다'
						_result = false
					}
				}
			} else {
				if (_payment > HUNDREDTHOUSAND_UNIT) {
					_message = '10만 이하로만 입력이 가능합니다.'
					_result = false
				} else {
					if (_payment % TENTHOUSAND_UNIT != 0) {
						_message = '만 단위로만 입력이 가능합니다.'
						_result = false
					}
				}
			}

			return {
				result: _result,
				message: _message,
			}
		}

		const purchase = async () => {
			if (!isValidAmount().result) {
				warningSwal(isValidAmount().message)
				return
			}

			const saveConfirm = await confirmSwal('바로 구매하시겠습니까?')

			if (saveConfirm.isConfirmed) {
				try {
					await MarketPostService.approvalMarketPost({
						postId: post.value.id,
						payment: parseInt(commaRemove(payment.value)),
						message: '',
					})
					emit('fetch-market-posts', true)
					emit('update:is-open-post-detail-dialog', false)
					successSwal('구매 완료되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400 || e.response.status === 404
								? e.response.data.message
								: '바로 구매하는데 문제가 발생했습니다.',
					})
				} finally {
					payment.value = 0
				}
			}
		}

		const deletePostProcessTarget = async postId => {
			try {
				const cartItems = await store.getters['post/getPostProcessTargets']()
				const _cartItems = Object.keys(cartItems).map(k => {
					cartItems[k].firebaseKey = k
					return cartItems[k]
				})
				const found = _cartItems.findIndex(e => e.postId === postId)

				if (found !== -1) {
					store.dispatch('post/deletePostProcessTarget', {
						firebaseKey: Object.values(cartItems)[found].firebaseKey,
						postId: postId,
					})
				}
			} catch (e) {
				console.error(e)
			} finally {
				emit('fetch-post-process-targets', true)
			}
		}

		const repay = async () => {
			const saveConfirm = await confirmSwal('반환하시겠습니까?')

			if (saveConfirm.isConfirmed) {
				try {
					await MarketPostService.disapprovalMarketPost({
						postId: post.value.id,
						message: '',
					})
					await deletePostProcessTarget(post.value.id)
					successSwal('반환되었습니다')
					emit('fetch-market-posts', true)
					emit('fetch-post-process-targets', true)
					emit('update:is-open-post-detail-dialog', false)
					return
				} catch (e) {
					let message = '반환 하는데 문제가 발생했습니다.'
					if (e.response.status === 400 || e.response.status === 404) {
						message = e.response.data.message
					}
					warningSwal(message)
				} finally {
					payment.value = 0
				}
			}
		}

		const putCart = async () => {
			if (!isValidAmount().result) {
				warningSwal(isValidAmount().message)
				return
			}

			try {
				const cartItems = await store.getters['post/getPostProcessTargets']()
				if (cartItems) {
					const found = Object.values(cartItems).findIndex(
						e => e.postId === post.value.id,
					)
					if (found !== -1) {
						warningSwal('이미 장바구니에 담겨졌습니다')
						return
					}
				}

				const payload = {
					postId: post.value.id,
					postingNumber: post.value.postingNumber,
					writerUserPublicPictureUrl: post.value.writerUser.publicPictureUrl,
					writerUserUid: post.value.writerUser.uid,
					postFiles: post.value.files,
					payment: parseInt(commaRemove(payment.value)),
					message: '',
				}

				store.dispatch('post/setPostProcessTarget', payload)

				successSwal('장바구니에 담겨졌습니다')
				emit('update:is-open-post-detail-dialog', false)
				payment.value = 0
			} catch (e) {
				console.error(e)
			}
		}

		const getMarketPostDetail = async () => {
			try {
				post.value = await MarketPostService.getMarketPostDetail(
					props.postId,
					true,
				)

				post.value.unreadPostCommentsCount = post.value.comments.filter(
					e => !e.readAdmin && e.user.id !== profile.value.id,
				).length

				const user = await UserService.getUser(post.value.writerUser.id)
				const tempArray = user.licenseIssues.sort(function (a, b) {
					return new Date(a.createdAt) - new Date(b.createdAt)
				})
				const _licenseIssues = tempArray[0]
				post.value.writerUserLicenseIssues = _licenseIssues

				if (post.value.result === contentsMarketStatus.APPROVED.value) {
					payment.value = parseInt(post.value.resultJson?.payment)
				}
			} catch (e) {
				console.error(e)
			}
		}

		const openMediaDialog = fileUrl => {
			selectedFileUrl.value = fileUrl
			isOpenMediaDialog.value = !isOpenMediaDialog.value
		}

		const closeDialog = () => {
			emit('update:is-open-post-detail-dialog', false)

			isReplaceImgSrcEmpty.value = true
			isResultChangeMode.value = false
			isSubjectChangeMode.value = false
			payment.value = 0
		}

		const loadUserPosts = uid => {
			emit('load-user-posts', uid)
			emit('update:is-open-post-detail-dialog', false)
			payment.value = 0
		}

		const addPayment = _payment => {
			if (payment.value === '' || _payment === 0) {
				payment.value = 0
			}
			payment.value = parseInt(commaRemove(payment.value)) + _payment
		}

		const toDataURL = async url => {
			const blob = await fetch(url).then(res => res.blob())
			return URL.createObjectURL(blob)
		}

		const createDownloadLink = async file => {
			const link = document.createElement('a')
			link.href = await toDataURL(file.url)
			link.download = file.fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}

		const downloadFiles = postFiles => {
			postFiles.forEach(async e => {
				await createDownloadLink(e)
			})
		}

		const addComment = async () => {
			try {
				await MarketPostService.postMarketPostComment({
					id: post.value.id,
					comment: comment.value,
				})
				comment.value = ''

				successSwal('답변이 저장되었습니다')
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '댓글을 업로드하는데 문제가 발생했습니다',
				)
			}
		}

		const updateMarketPostSubject = async () => {
			const confirm = await confirmSwal(
				`<strong>${selectedSubject.value.name}</strong> 주제로 업데이트 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketPostService.update(post.value.id, {
						subjectId: selectedSubject.value.value,
					})
					selectedSubject.value = null
					successSwal('주제가 업데이트 되었습니다')
				} catch (e) {
					warningSwal('주제를 업데이트하는데 문제가 발생했습니다')
				}
			}
		}

		const deleteMarketPost = async id => {
			const confirm = await confirmSwal('콘텐츠를 삭제하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketPostService.deleteMarketPost(id)
					successSwal('포스트가 삭제되었습니다')
				} catch (e) {
					warningSwal('포스트를 삭제하는데 문제가 발생했습니다')
				}
			}
		}

		const updateMarketPostStatus = async () => {
			const confirm = await confirmSwal('상태를 변경하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketPostService.update(post.value.id, {
						result: selectedResult.value,
					})
					successSwal('포스트가 삭제되었습니다')
				} catch (e) {
					warningSwal('포스트를 삭제하는데 문제가 발생했습니다')
				}
			}
		}

		const listMarketPostSubjects = async () => {
			const { data } = await MarketPostSubjectService.getAll({
				type: post.value.type,
				lounge: post.value.subject.lounge,
				orderby: 'endDate',
				order: 'desc',
				limit: 10000,
			})

			data.map(e => {
				const _statistics =
					'<br>' +
					`<small>시작날짜: <strong>${dayjs(e.startDate).format(
						'YYYY년 MM월 D일',
					)}</strong></small>`

				subjectGroups.value.push({
					name: e.title,
					lounge: e.lounge,
					statistics: _statistics,
					value: e.id,
				})
			})
		}

		const removeChip = () => {
			selectedSubject.value = null
		}

		const getFileExtension = url => {
			return url.substring(url.lastIndexOf('.') + 1).toLowerCase()
		}

		const getNumberOfImages = files => {
			let count = 0
			for (const file of files) {
				const fileExtension = getFileExtension(file.url)
				if (['jpeg', 'jpg', 'png', 'webp'].includes(fileExtension)) {
					count++
				}
			}
			return count
		}

		const getNumberOfVideos = files => {
			let count = 0
			for (const file of files) {
				const fileExtension = getFileExtension(file.url)
				if (['mp4', 'mov', 'wmv', 'avi', 'webm'].includes(fileExtension)) {
					count++
				}
			}
			return count
		}

		const getNumberOfGifs = files => {
			let count = 0
			for (const file of files) {
				const fileExtension = getFileExtension(file.url)
				if (['gif'].includes(fileExtension)) {
					count++
				}
			}
			return count
		}

		watch(
			() => payment.value,
			currentValue => {
				if (currentValue) {
					payment.value = commaRemove(currentValue).toLocaleString()
				}
			},
		)

		watch(
			() => props.isOpenPostDetailDialog,
			currentValue => {
				if (currentValue) {
					getMarketPostDetail()
				} else {
					changeMode.value = ''
				}
			},
		)

		watch(
			() => isSubjectChangeMode.value,
			currentValue => {
				if (currentValue && subjectGroups.value.length === 0) {
					listMarketPostSubjects()
				}
			},
		)

		return {
			post,
			sheet,
			payment,
			comment,
			changeMode,
			isSmAndDown,
			subjectGroups,
			selectedFileUrl,
			selectedResult,
			selectedSubject,
			isOpenMediaDialog,
			isResultChangeMode,
			isSubjectChangeMode,
			isReplaceImgSrcEmpty,

			repay,
			putCart,
			isImage,
			purchase,
			removeChip,
			addPayment,
			addComment,
			closeDialog,
			isDetermined,
			loadUserPosts,
			downloadFiles,
			openMediaDialog,
			allowOnlyNumber,
			deleteMarketPost,
			getNumberOfImages,
			getNumberOfGifs,
			getNumberOfVideos,
			updateMarketPostStatus,
			updateMarketPostSubject,
		}
	},
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.wrapper-content {
	font-size: 14px;
	padding: 16px 16px 0;

	@media (max-width: 674px) {
		padding: 8px 8px 0;
	}
}

div.actions {
	padding-left: 12px;
	padding-right: 12px;

	.v-btn {
		flex-grow: 1;
		min-height: 42px;
	}
}

div.payment {
	.v-btn {
		flex-grow: 1;
		font-weight: bold;
		font-size: 18px;
		min-height: 52px;
	}
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-row-gap: 10px;
	grid-column-gap: 10px;
}
</style>
