<template>
	<v-card min-height="320">
		<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
			<v-tab v-for="(item, index) in items" :key="'tab-' + index">
				{{ item.name }}
			</v-tab>
		</v-tabs>
		<div>
			<v-tabs-items v-model="tab" touchless>
				<v-tab-item v-for="(item, index) in items" :key="'tab-item-' + index">
					<event-table-list
						:category="item.value"
						:event-category="eventCategory"
					/>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { eventCategory } from '@/helpers'

import EventTableList from './EventTableList.vue'

export default {
	components: {
		EventTableList,
	},
	props: {},
	setup() {
		const tab = ref(null)
		const items = ref(
			Object.values(eventCategory).filter(
				e => e.value !== eventCategory.LICENSE_TEST.value,
			),
		)

		items.value = items.value.filter((value, _) => {
			return value.value !== eventCategory.EDUCATION_LICENSE.value
		})

		return {
			tab,
			items,
			eventCategory,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}

::v-deep {
	.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
		> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
		.v-slide-group__prev {
		display: none;
	}
}
</style>
