import dayjs from 'dayjs'
import axios from '@/api/axios'

const marketCouseService = {
	listMarketCourses(page, limit) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (page) {
				params['page'] = page
			}
			if (limit) {
				params['limit'] = limit
			}

			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-courses',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketCourse(marketCourseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-courses/${marketCourseId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketCourseDetail(marketCourseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-courses/${marketCourseId}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createMarketCourse(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-courses',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateMarketCourse(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-courses/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketCourse(marketCourseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-courses/${marketCourseId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketCourseLectures(marketCourseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-courses/${marketCourseId}/market-lectures`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketCourseRegistrations(marketCourseId, page, limit) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (page) {
				params['page'] = page
			}
			if (limit) {
				params['limit'] = limit
			}

			try {
				const result = await axios({
					method: 'GET',
					url: `/market-courses/${marketCourseId}/market-registrations/detail`,
					params,
				})
				resolve(result)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketCourseRegistrationsDashboard(
		page,
		limit,
		keyword,
		licenseIds,
		searchCategory,
		dateCriteria,
		beginDate,
		endDate,
	) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (page) {
				params['page'] = page
			}
			if (limit) {
				params['limit'] = limit
			}
			if (licenseIds) {
				params['license_ids'] = licenseIds
			}
			if (searchCategory) {
				params[searchCategory] = keyword
			}
			if (dateCriteria) {
				params['date_criteria'] = dateCriteria
			}
			if (beginDate) {
				const _beginDate = dayjs(beginDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['begin_date'] = _beginDate
			}
			if (endDate) {
				const _endDate = dayjs(endDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['end_date'] = _endDate
			}

			try {
				const response = await axios({
					method: 'GET',
					url: '/market-course-registrations/dashboard',
					params,
				})

				resolve({
					data: response.data,
					totalCount: response.headers['x-total-count'],
					totalPages: response.headers['x-total-pages-count'],
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketCouseService
