const applicationStatus = {
	APPLIED: { name: '신청완료', value: 'APPLIED' },
	CANCELED: {
		name: '신청취소',
		value: 'CANCELED',
	},
	PAID: { name: '결제완료', value: 'PAID' },
	FORCE_CHECK_IN: { name: '강제 출석체크', value: 'FORCE_CHECK_IN' },
	DONE: { name: '완료', value: 'DONE' },
}

export default applicationStatus
