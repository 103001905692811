<template>
	<div>
		<v-card>
			<v-card-text class="d-flex">
				<div>
					<v-avatar rounded size="120" class="me-6">
						<v-img
							:src="
								profile.publicPictureUrl
									? profile.publicPictureUrl
									: require('@/assets/images/avatars/default.svg')
							"
						/>
					</v-avatar>
					<v-btn
						color="primary"
						class="me-3 mt-5"
						@click="$refs.refInputEl.click()"
					>
						<v-icon class="d-sm-block" :class="{ 'mr-2': isSmAndUp }">
							mdi-cloud-upload-outline
						</v-icon>
						<span class="d-none d-sm-block">프로필 업로드</span>
					</v-btn>

					<input
						ref="refInputEl"
						type="file"
						accept=".jpeg,.png,.jpg,GIF"
						:hidden="true"
					/>
					<p class="text-sm mt-5">
						JPG, GIF or PNG 만 가능합니다.(최대 사이즈 800K)
					</p>
				</div>
			</v-card-text>
			<v-card-text>
				<v-form class="multi-col-validation mt-6">
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="profile.email"
								label="이메일"
								autocomplete="off"
								readonly
							/>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field
								v-model="profile.uid"
								label="닉네임"
								autocomplete="off"
							/>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field
								v-model="profile.name"
								label="실명"
								autocomplete="off"
							/>
						</v-col>
						<v-col cols="12" md="6" class="d-flex">
							<v-expansion-panels>
								<v-expansion-panel>
									<v-expansion-panel-header>
										비밀번호 변경
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<validation-observer ref="observer" v-slot="{ valid }">
											<form @submit.prevent="changePassword" ref="form">
												<validation-provider
													v-slot="{ errors }"
													name="currentPassword"
													rules="required|password"
												>
													<v-text-field
														v-model="changePasswordForm.oldPassword"
														:type="isPasswordVisible ? 'text' : 'password'"
														:error-messages="errors"
														label="현재 비밀번호를 입력하세요"
														:append-icon="
															isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
														"
														@click:append="
															isPasswordVisible = !isPasswordVisible
														"
														autocomplete="off"
														required
													/>
												</validation-provider>
												<validation-provider
													v-slot="{ errors }"
													name="password"
													rules="required|password"
												>
													<v-text-field
														v-model="changePasswordForm.newPassword"
														:type="isPasswordVisible ? 'text' : 'password'"
														:error-messages="errors"
														label="새로운 비밀번호를 입력하세요"
														:append-icon="
															isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
														"
														@click:append="
															isPasswordVisible = !isPasswordVisible
														"
														autocomplete="off"
														required
													/>
												</validation-provider>
												<validation-provider
													v-slot="{ errors }"
													name="repassword"
													rules="required|repassword:@password"
												>
													<v-text-field
														v-model="changePasswordForm.repassword"
														:type="isPasswordVisible ? 'text' : 'password'"
														:error-messages="errors"
														label="새로운 비밀번호를 재입력하세요"
														:append-icon="
															isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
														"
														@click:append="
															isPasswordVisible = !isPasswordVisible
														"
														autocomplete="off"
														required
													/>
												</validation-provider>
												<v-btn
													class="button full-width"
													type="submit"
													width="100%"
													color="primary"
													:disabled="!valid"
													:loading="changePasswordLoading"
												>
													변경하기
												</v-btn>
											</form>
										</validation-observer>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
						<v-col cols="12" md="6">
							<v-row no-gutters>
								<v-col cols="12" class="mb-1">
									<v-select
										v-model="countryCode"
										:items="countryCodes"
										item-text="name"
										item-value="value"
									/>
								</v-col>
								<v-col cols="12" class="pr-1">
									<v-text-field
										v-model="profile.phoneNumber"
										autocomplete="off"
										label="휴대폰 번호"
									/>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="6" class="d-flex">
							<template v-if="originProfile.value.recommenderReferralCode">
								<v-col cols="9" class="pa-0">
									<v-text-field
										v-model="profile.recommenderReferralCode"
										autocomplete="off"
										label="추천인 유저명"
									/>
								</v-col>
								<v-col cols="3" class="pt-0 pr-0 pb-0">
									<v-btn
										@click="inquiryReferralCode"
										:disabled="profile.recommenderReferralCode ? false : true"
										:loading="inquiryLoading"
										color="primary"
										class="inquiry-btn full-width"
									>
										조회
									</v-btn>
								</v-col>
							</template>
							<template v-else>
								<p>추천인명이 존재하지 않습니다</p>
							</template>
						</v-col>
					</v-row>
					<v-divider class="mt-6 mb-2" />
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<v-btn color="primary" class="me-3 mt-4" @click="save">
								저장하기
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { ref, computed, reactive } from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import {
	confirmSwal,
	errorSwal,
	successSwal,
	warningSwal,
} from '@/plugins/swalMixin'
import { getVuetify } from '@core/utils'

import UserService from '@/services/UserService'
import AuthService from '@/services/AuthService'

export default {
	components: {},
	setup() {
		const $vuetify = getVuetify()
		const isValid = ref(false)
		const isPhoneNumberValid = ref(false)
		const isReferralCodeValid = ref(false)
		const changePasswordLoading = ref(false)
		const inquiryLoading = ref(false)
		const profile = computed(() => store.getters['user/getMe'])
		const originProfile = JSON.parse(JSON.stringify(profile))
		const isPasswordVisible = ref(false)
		const countryCode = ref('82')
		const countryCodes = reactive([
			{
				name: '대한민국+82',
				value: '82',
			},
		])
		const changePasswordForm = reactive({
			email: profile.value.email,
			repassword: '',
			oldPassword: '',
			newPassword: '',
		})

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		const isReferralCodeChanged = computed(() => {
			return (
				profile.value.recommenderReferralCode !==
					originProfile.value.recommenderReferralCode &&
				profile.value.recommenderReferralCode !== null &&
				profile.value.recommenderReferralCode !== ''
			)
		})

		const isPhoneNumberChanged = computed(() => {
			return (
				profile.value.phoneNumber !== originProfile.value.phoneNumber &&
				profile.value.phoneNumber !== null &&
				profile.value.phoneNumber !== ''
			)
		})

		const changePassword = async () => {
			try {
				await AuthService.changePassword(changePasswordForm)
				successSwal('비밀번호가 변경되었습니다')
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '비밀번호를 변경하는데 문제가 발생했습니다.',
				)
			}
		}

		const save = async () => {
			try {
				if (isReferralCodeChanged.value && !isReferralCodeValid.value) {
					warningSwal('추천인 정보를 조회해주세요')
					return
				}

				if (isPhoneNumberChanged.value && !isPhoneNumberValid.value) {
					warningSwal('변경된 휴대폰 번호를 인증해주세요')
					return
				}

				await UserService.putMe(profile.value)
				const confirm = await confirmSwal('내 정보가 저장되었습니다')
				if (confirm.isConfirmed) {
					UserService.getMe()
				}
			} catch (e) {
				errorSwal({
					title: '실패',
					html: '내 정보를 저장하는데 문제가 발생했습니다',
				})
			}
		}

		const inquiryReferralCode = async () => {
			try {
				inquiryLoading.value = true
				const data = await AuthService.inquiryReferralCode(
					profile.value.recommenderReferralCode,
				)
				isReferralCodeValid.value = true
				successSwal({
					title: i18n.t('signupForm.referrerInquiryMsg.success.title'),
					html: i18n.t('signupForm.referrerInquiryMsg.success.html', {
						name: data.name || data.uid,
					}),

					confirmButtonText: i18n.t(
						'signupForm.referrerInquiryMsg.success.confirmButtonText',
					),
				})
			} catch (e) {
				isReferralCodeValid.value = false
				errorSwal({
					title: i18n.t('signupForm.referrerInquiryMsg.fail.title'),
					html: i18n.t('signupForm.referrerInquiryMsg.fail.html'),
					confirmButtonText: i18n.t(
						'signupForm.referrerInquiryMsg.fail.confirmButtonText',
					),
				})
			} finally {
				inquiryLoading.value = false
			}
		}

		return {
			isValid,
			profile,
			isSmAndUp,
			countryCode,
			countryCodes,
			originProfile,
			inquiryLoading,
			isPasswordVisible,
			changePasswordForm,
			isReferralCodeValid,
			changePasswordLoading,

			inquiryReferralCode,
			changePassword,
			save,
		}
	},
}
</script>
<style lang="scss" scoped>
.full-width {
	width: 100% !important;
	max-width: 100% !important;
}
</style>
