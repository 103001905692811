import axios from '@/api/axios'

const marketOrderService = {
	listMarketOrders({ page, limit }) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (page >= 0) {
				params['page'] = page
			}
			if (limit) {
				params['limit'] = limit
			}

			try {
				const result = await axios({
					method: 'GET',
					url: '/market-orders',
					params,
				})
				resolve(result)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketOrder(marketOrderId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-orders/${marketOrderId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketOrderDetail(marketOrderId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-orders/${marketOrderId}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getUserMarketOrderDetail(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-orders/detail/?user_id=${userId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketOrder(orderId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-orders/${orderId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketOrderService
