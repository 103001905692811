import { auth } from '@/firebase'
import { signInWithCustomToken, signOut } from 'firebase/auth'

import store from '@/store'
import axios from '@/api/axios'
import { marketerRole } from '@/helpers'
import { errorSwal } from '@/plugins/swalMixin'
import axiosWithoutHeader from '@/api/axiosWithoutHeader'

import UserService from '@/services/UserService'

import router from '@/router'

const authService = {
	checkEmail(email) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/check-email-availability',
					data: {
						email: email,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	checkUsername(userName) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/check-username-availability',
					data: {
						userName: userName,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	findEmailByPhoneNumber(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/find-email-by-phone-number',
					data: {
						name: payload.name,
						phoneNumber: payload.phoneNumber,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	findEmailByVerificationCode(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/find-email-by-verification-code',
					data: {
						name: payload.name,
						phoneNumber: payload.phoneNumber,
						verificationCode: payload.verificationCode,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	resetPassword(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/reset-password',
					data: {
						email: payload.email,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	changePassword(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/change-password',
					data: {
						email: payload.email,
						oldPassword: payload.oldPassword,
						newPassword: payload.newPassword,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	emailSignin(form) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-in',
					data: {
						id: form.id,
						password: form.password,
						deviceName: navigator.userAgent,
					},
				})
				this.setSignin(data)
				this.signinFirebase(data.firebaseAccessToken)
				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signout() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-out',
				})
				this.setSignout()
				signOut(auth)
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	inquiryReferralCode(referralCode) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `users/referral-code/${referralCode}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signinFirebase(firebaseAccessToken) {
		return new Promise(async (resolve, reject) => {
			try {
				const { user } = await signInWithCustomToken(auth, firebaseAccessToken)

				sessionStorage.setItem('firebaseUser', JSON.stringify(user))
				store.dispatch('auth/firebaseUser', user)
				resolve(user)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getToken() {
		return {
			token: sessionStorage.getItem('token'),
		}
	},
	getFirebaseToken() {
		return {
			firebaseAccessToken: sessionStorage.getItem('firebaseAccessToken'),
		}
	},
	getFirebaseUser() {
		return {
			firebaseUser: JSON.parse(sessionStorage.getItem('firebaseUser')),
		}
	},
	storeSignin() {
		store.dispatch('auth/signin', this.getToken())
	},
	storeFirebaseUser() {
		const data = this.getFirebaseUser()
		store.dispatch('auth/firebaseUser', data.firebaseUser)
	},
	setSignin(data) {
		sessionStorage.setItem('token', data.token)
		sessionStorage.setItem('refreshToken', data.refreshToken)
		sessionStorage.setItem('firebaseAccessToken', data.firebaseAccessToken)

		store.dispatch('auth/signin', data)
	},
	setSignout() {
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('refreshToken')
		sessionStorage.removeItem('firebaseUser')
		sessionStorage.removeItem('firebaseAccessToken')

		store.dispatch('auth/signout')
	},
	async init() {
		this.storeSignin()
		this.storeFirebaseUser()
		if (store.getters['auth/isSignin'] === false) return

		const data = await UserService.getMe()

		if (data.marketerRole !== marketerRole.ADMIN.value) {
			errorSwal({
				title: '로그인 실패',
				html: '어드민 권한이 없습니다',
			})

			this.setSignout()
			router.push({ name: 'signin' })
		}
	},
}

export default authService
