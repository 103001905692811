import Vue from 'vue'

import '@/firebase'

import App from './App'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import AuthService from './services/AuthService'

import './plugins'
import './filter'
import './validator'
import './directives'
import i18n from './i18n'

Vue.config.productionTip = false

async function init() {
	try {
		await AuthService.init()
	} catch (e) {
		AuthService.setSignout()
		throw e
	} finally {
		new Vue({
			router,
			store,
			vuetify,
			i18n,
			render: h => h(App),
		}).$mount('#app')
	}
}
init()
