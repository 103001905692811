<template>
	<v-app>
		<v-container fluid>
			<component :is="layout">
				<router-view />
			</component>
		</v-container>
	</v-app>
</template>
<script>
import { computed } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import LayoutContent from '@/layouts/LayoutContent.vue'
import LayoutBlank from '@/layouts/LayoutBlank.vue'

export default {
	components: {
		LayoutContent,
		LayoutBlank,
	},
	setup() {
		const { route } = useRouter()
		const layout = computed(() => route.value.meta.layout || 'layout-blank')

		return {
			layout,
		}
	},
}
</script>
