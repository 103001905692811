<template>
	<v-navigation-drawer
		:value="isDrawerOpen"
		app
		floating
		width="260"
		class="app-navigation-menu pr-2"
		:right="$vuetify.rtl"
		@input="val => $emit('update:is-drawer-open', val)"
	>
		<v-list
			expand
			shaped
			class="vertical-nav-menu-items d-flex flex-column pr-0 mt-12"
		>
			<div class="d-flex flex-column align-start">
				<p style="color: white !important" class="pl-4 mb-1">
					관리자
					<strong>{{ profile.name }}</strong>
					님
				</p>
				<span
					@click="$emit('is-sign-out', true)"
					class="white--text mb-8 pl-4 text-xs cursor-pointer"
				>
					로그아웃
				</span>
			</div>
			<div v-for="(item, index) in menuGroupItems" :key="'group-' + index">
				<div v-if="item.children">
					<nav-menu-group :title="item.text" :icon="item.icon">
						<div
							v-for="(child, index) in item.children"
							:key="'group-item-' + index"
						>
							<nav-menu-link
								class="child-link"
								:title="child.text"
								:to="child.route"
							/>
						</div>
					</nav-menu-group>
				</div>
				<div v-else>
					<nav-menu-link
						:title="item.text"
						:to="item.route"
						:icon="item.icon"
						:role="item.role"
					/>
				</div>
			</div>
		</v-list>
		<div
			class="d-flex align-center justify-center nav-menu-logo"
			style="min-height: 30px"
		>
			<div class="d-flex align-center text-decoration-none">
				<v-img
					:src="require('@/assets/logo/logo__white.png')"
					max-height="68px"
					alt="logo"
					contain
					eager
					class="app-logo me-3"
				/>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import { reactive, ref, computed } from '@vue/composition-api'

import store from '@/store'

import NavMenuLink from '../common/NavMenuLink.vue'
import NavMenuGroup from '../common/NavMenuGroup.vue'

export default {
	components: {
		NavMenuLink,
		NavMenuGroup,
	},
	props: {
		isDrawerOpen: {
			type: Boolean,
			default: null,
		},
	},
	setup() {
		const profile = computed(() => store.getters['user/getMe'])
		const selectedItem = ref(0)
		const menuGroupItems = reactive([
			{
				text: '대시보드',
				icon: 'mdi-view-dashboard',
				route: { name: 'dashboard' },
			},
			{
				text: '사용자 관리',
				icon: 'mdi-account-group-outline',
				children: [
					{
						text: '사용자 리스트',
						route: { name: 'user-list' },
					},
					{
						text: '증명사진 리스트',
						route: { name: 'user-id-photo-list' },
					},
				],
			},
			{
				text: '콘텐츠 마켓',
				icon: 'mdi-folder-multiple-image',
				children: [
					{
						text: '콘텐츠 보기',
						route: { name: 'market-post-list' },
					},
					{
						text: '콘텐츠 마켓 주제 설정',
						route: { name: 'market-post-subject-list' },
					},
					{
						text: '구매 완료 리스트',
						route: { name: 'market-post-payment-list' },
					},
				],
			},
			{
				text: '강의',
				icon: 'mdi-cast-education',
				children: [
					{
						text: '강의 리스트',
						route: { name: 'market-course-list' },
					},
					{
						text: '강의 수강자 대시보드',
						route: { name: 'course-registrations' },
					},
				],
			},
			{
				text: '자격증',
				icon: 'mdi-school',
				children: [
					{
						text: '자격증',
						route: { name: 'licenses' },
					},
					{
						text: '자격증 New',
						route: { name: 'course-registrations' },
					},
				],
			},
			{
				text: '이벤트',
				icon: 'mdi-application-edit',
				route: { name: 'events' },
			},
			{
				text: '라운지',
				icon: 'mdi-map-marker-check-outline',
				route: { name: 'market-locations-list' },
			},
			{
				text: '출금 신청 내역',
				icon: 'mdi-ticket',
				route: { name: 'market-balance-withdrawal-list' },
			},
			{
				text: '사용자 이체 내역',
				icon: 'mdi-receipt-outline',
				route: { name: 'market-balance-list' },
			},
			{
				text: '상품',
				icon: 'mdi-archive',
				children: [
					{
						text: '상품',
						route: { name: 'market-items-list' },
					},
					{
						text: '주문',
						route: { name: 'market-orders-list' },
					},
				],
			},
			{
				text: '1:1 문의',
				icon: 'mdi-face-agent',
				route: { name: 'market-post-private-inquiry' },
			},
			{
				text: '공지사항',
				icon: 'mdi-bulletin-board',
				route: { name: 'notices' },
			},
			{
				text: '설문지',
				icon: 'mdi-note-check',
				children: [
					{
						text: '질문지',
						route: { name: 'market-survey-questionnaires-list' },
					},
				],
			},
			{
				text: '설정',
				icon: 'mdi-account-cog-outline',
				route: { name: 'setting' },
			},
		])
		return {
			profile,
			selectedItem,
			menuGroupItems,
		}
	},
}
</script>
<style lang="scss" scoped>
.theme--light.v-navigation-drawer {
	background-color: #0f2c5c;
	&::-webkit-scrollbar-thumb {
		background-color: #ffffff;
	}
}

.nav-menu-logo {
	padding-bottom: 20px;
}
</style>
