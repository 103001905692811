import Swal from 'sweetalert2'

const swalMixin = Swal.mixin({
	focusConfirm: false,
	showClass: {
		popup: '',
	},
	hideClass: {
		popup: '',
	},
})

const confirmSwal = async params => {
	const {
		html = '',
		confirmButtonText = '확인',
		cancelButtonText = '아니오',
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		html: html,
		showCloseButton: false,
		showCancelButton: true,
		confirmButtonText: confirmButtonText,
		confirmButtonColor: '#060539',
		cancelButtonText: cancelButtonText,
		cancelButtonColor: '#8a8d93',
	})
}

const successSwal = async params => {
	const {
		title = '',
		html = '',
		confirmButtonText = '확인',
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		title: title,
		html: html,
		icon: 'success',
		confirmButtonText: confirmButtonText,
	})
}

const warningSwal = async params => {
	const { html = '', confirmButtonText = '확인' } =
		typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		html: html,
		icon: 'warning',
		confirmButtonText: confirmButtonText,
	})
}

const errorSwal = async params => {
	const {
		title = '',
		html = '',
		confirmButtonText = '확인',
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		title: title,
		html: html,
		icon: 'error',
		confirmButtonText: confirmButtonText,
	})
}

export { confirmSwal, successSwal, warningSwal, errorSwal }
