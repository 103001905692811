import axios from '@/api/axios'

const marketLocationService = {
	listMarketLocations() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-locations?limit=10000',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLocation(marketLocationId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-locations/${marketLocationId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLocationDetail(marketLocationId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-locations/${marketLocationId}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createMarketLocation(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-locations',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateMarketLocation(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-locations/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketLocation(marketLocationId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-locations/${marketLocationId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLocationSchedules({ marketLocationId, beginDate, endDate }) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (beginDate && endDate) {
				params['begin_date'] = beginDate
				params['end_date'] = endDate
			}

			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-locations/${marketLocationId}/schedules?limit=10000`,
					params,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketLocationReservations({ beginDate, endDate }) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (beginDate && endDate) {
				params['begin_date'] = beginDate
				params['end_date'] = endDate
			}

			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-location-reservations/detail?limit=10000',
					params,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLocationReservations({ marketLocationId, beginDate, endDate }) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (beginDate && endDate) {
				params['begin_date'] = beginDate
				params['end_date'] = endDate
			}

			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-location-reservations/detail?market_location_id=${marketLocationId}&limit=10000`,
					params,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	addMarketLocationSchedule({ marketLocationId, payload }) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-locations/${marketLocationId}/schedules`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketLocationService
