<template>
	<v-card>
		<v-card-title
			ref="filterHeight"
			class="py-2 fixed"
			:class="{ 'px-2': isMdAndDown }"
		>
			<div class="d-flex justify-space-between" style="width: 100%">
				<div class="d-flex align-center">
					<v-checkbox
						v-model="allChecked"
						@click="checkedAll($event.target.checked)"
					/>

					<div>
						<div :class="isSmAndDown ? 'text-base' : 'text-lg'">
							콘텐츠 보기
							<span :class="isSmAndDown ? 'text-sm' : 'text-base'">
								(총 {{ totalCount }}개)
							</span>
						</div>
					</div>
					<div></div>
				</div>
				<div class="d-flex align-center">
					<v-select
						item-text="name"
						item-value="value"
						:items="keywordTypeGroups"
						:value="selectedKeywordType"
						:disabled="isLoading"
						@change="changeKeywordType"
						:menu-props="{
							closeOnClick: true,
							closeOnContentClick: true,
						}"
						label="검색 타입"
						style="max-width: 138px"
						hide-details
						dense
						outlined
					/>
					<v-text-field
						v-model="query"
						placeholder="유저명"
						dense
						outlined
						rounded
						ref="searchField"
						hide-details=""
						prepend-inner-icon="mdi-magnify"
						:disabled="isLoading"
						class="d-flex align-center my-2 mx-1"
						:style="{
							maxWidth: isSmAndDown ? '180px' : '260px',
						}"
						@keypress.enter.prevent="search('input', $event)"
					>
						<template v-slot:append>
							<v-btn
								rounded
								:ripple="false"
								text
								class="px-0 font-weight-bold mt-0"
								style="
									color: #0d6efd;
									position: relative;
									left: 30px;
									bottom: 4px;
								"
								@click="search('button', $refs.searchField)"
							>
								검색
							</v-btn>
						</template>
					</v-text-field>
				</div>
			</div>

			<div class="d-flex justify-end" style="width: 100%; flex-wrap: wrap">
				<div class="d-flex align-center" style="gap: 5px">
					<div class="d-flex flex-column">
						<v-btn
							class="mb-1"
							color="primary"
							@click="changeStatusDisapproved"
							:loading="isChanging"
							:disabled="isChanging"
							v-if="selectedPosts.length > 0"
						>
							{{ selectedPosts.length }}건 일괄 반환
						</v-btn>
						<v-btn
							@click="changeStatusDisapprovedAll"
							color="error"
							:loading="isChangingDisapprovedAll"
							:disabled="isChangingDisapprovedAll"
							v-if="selectedPosts.length > 0"
							small
						>
							전체 일괄 반환
						</v-btn>
					</div>
					<v-checkbox
						v-model="hasSubjectId"
						label="주제 미지정 콘텐츠 조회"
						class="mt-0"
						hide-details
					/>
					<v-select
						item-text="name"
						item-value="value"
						:items="typeGroups"
						:value="selectedType"
						:disabled="isLoading"
						@change="changeType"
						:menu-props="{
							closeOnClick: true,
							closeOnContentClick: true,
						}"
						label="콘텐츠 타입"
						style="max-width: 138px"
						hide-details
						dense
						outlined
					/>
					<v-select
						item-text="name"
						item-value="value"
						:items="subjectLoungeTypeGroups"
						:value="selectedSubjectLoungeType"
						:disabled="isLoading"
						@change="changeSubjectLoungeType"
						:menu-props="{
							closeOnClick: true,
							closeOnContentClick: true,
						}"
						label="라운지 위치"
						style="max-width: 138px"
						hide-details
						dense
						outlined
						v-if="selectedType === postType.CONTEST2.value"
					/>

					<v-autocomplete
						ref="selectSubjectEl"
						v-model="selectedSubjects"
						:items="subjectGroups"
						:disabled="isLoading"
						:menu-props="{
							closeOnClick: true,
						}"
						filled
						chips
						color="blue-grey lighten-2"
						label="콘텐츠 주제"
						item-text="name"
						multiple
						dense
						outlined
						single-line
						hide-details
						style="background-color: white"
						v-if="canSelectSubject(selectedType)"
					>
						<template v-slot:prepend-item>
							<div class="d-flex flex-column px-2">
								<v-btn
									small
									@click="inquirySubject"
									color="primary"
									style="width: 100%"
									:disabled="
										!selectedSubjects ||
										(selectedSubjects && selectedSubjects.length === 0)
									"
								>
									조회
								</v-btn>
							</div>
						</template>

						<template v-slot:selection="data">
							<v-chip
								v-bind="data.attrs"
								:input-value="data.selected"
								close
								@click="data.select"
								@click:close="removeChip(data.item)"
							>
								{{ data.item.name }}
							</v-chip>
						</template>

						<template v-slot:item="{ item, attrs, on }">
							<v-list-item
								v-on="on"
								v-bind="attrs"
								#default="{ active }"
								style="height: 3.5rem"
							>
								<v-list-item-action>
									<v-checkbox :ripple="false" :input-value="active" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>
										<v-row no-gutters align="center">
											<span
												class="text-base"
												v-html="item.name + item.statistics"
												v-if="item.statistics"
											></span>
											<span class="text-base" v-html="item.name" v-else></span>
										</v-row>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
						<template v-slot:no-data>
							<div class="text-center">검색된 데이터가 없습니다</div>
						</template>
					</v-autocomplete>

					<v-select
						item-text="name"
						item-value="value"
						:items="approvalStatusGroups"
						label="승인여부"
						:value="selectedApprovalStatus"
						:disabled="isLoading"
						@change="changeApprovalStatus"
						style="max-width: 106px"
						hide-details
						dense
						outlined
					/>
					<v-select
						item-text="name"
						item-value="value"
						:value="selectedDateRange"
						:items="dateRangeGroups"
						:disabled="isLoading"
						label="기간 조회"
						@change="changedDateRange"
						style="max-width: 110px"
						hide-details
						dense
						outlined
					/>
					<v-select
						ref="selectSubjectEl"
						:items="sortGroups"
						v-model="selectedSortCriteria"
						:disabled="isLoading"
						:menu-props="{
							closeOnClick: true,
						}"
						label="정렬 기준"
						hide-details
						multiple
						chips
						dense
						outlined
					>
						<template v-slot:prepend-item>
							<div class="px-2">
								<v-btn
									small
									@click="inquirySubject"
									color="primary"
									style="width: 100%"
									:loading="isLoading"
									:disabled="
										!selectedSortCriteria ||
										(selectedSortCriteria && selectedSortCriteria.length === 0)
									"
								>
									조회
								</v-btn>
							</div>
						</template>
						<template v-slot:selection="{ item }">
							<v-chip>
								<span class="text-base" v-html="item.name"></span>
							</v-chip>
						</template>
						<template v-slot:item="{ item, attrs, on }">
							<v-list-item
								v-on="on"
								v-bind="attrs"
								#default="{ active }"
								:disabled="isListClickable(item)"
							>
								<v-list-item-action>
									<v-checkbox :ripple="false" :input-value="active" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>
										<v-row no-gutters align="center">
											<span class="text-base" v-html="item.name"></span>
										</v-row>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-select>

					<v-btn
						@click="inquiry"
						color="primary"
						:disabled="isLoading"
						:loading="isLoading"
					>
						조회
					</v-btn>

					<v-badge
						color="error"
						overlap
						bordered
						:content="
							Object.keys($store.state.post.postProcessTargets).length ||
							postProcessTargets.length
						"
						:value="
							Object.keys($store.state.post.postProcessTargets).length > 0 ||
							postProcessTargets.length
						"
					>
						<v-btn
							fab
							color="primary"
							class="v-btn--example"
							@click="
								isOpenPostProcessTargetListDialog =
									!isOpenPostProcessTargetListDialog
							"
							small
						>
							<v-icon :small="isSmAndDown">mdi-cart-outline</v-icon>
						</v-btn>
					</v-badge>
				</div>
				<div class="d-flex align-center" v-if="isOpenDateRange">
					<date-picker-dialog
						:label="'시작날짜'"
						:date-value.sync="beginDate"
					/>
					<date-picker-dialog :label="'끝날짜'" :date-value.sync="endDate" />
					<v-btn @click="inquiryDate" color="primary">조회</v-btn>
				</div>
			</div>

			<div v-if="writerUid">
				<span class="text-base">전체 콘텐츠 불러오기</span>
				<v-btn icon class="px-0" @click="reset">
					<v-icon>mdi-autorenew</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<v-divider />
		<div ref="wrapperScrollComponent" style="margin-bottom: 40px">
			<div
				class="grid-container scrollable"
				ref="scrollComponent"
				:class="selectedDateRange === 0 ? 'expanded' : ''"
				v-if="posts.length > 0"
			>
				<v-card
					class="grid-item cursor-pointer"
					v-for="(post, index) in posts"
					:key="'post-' + post.id"
					elevation="1"
				>
					<div
						class="px-3"
						style="background-color: white; width: 100%"
						:class="isSmAndDown ? 'px-1 py-0 text-xs pt-1' : 'pa-2'"
					>
						<div class="d-flex align-center justify-space-between">
							<div
								class="d-flex align-center"
								style="overflow: hidden; text-overflow: ellipsis"
								:style="{ 'width: 70%;': isSmAndDown }"
							>
								<v-checkbox
									hide-details
									v-model="selectedPosts"
									:value="post.id"
									class="mt-0"
									v-if="post.result === contentsMarketStatus.READY.value"
								/>
								<v-avatar
									:size="isSmAndDown ? 14 : 26"
									@click="search('clickProfile', post.writerUser.uid)"
								>
									<v-img
										:src="
											post.writerUser.publicPictureUrl ||
											require('@/assets/images/avatars/default.svg')
										"
									/>
								</v-avatar>
								<div
									class="d-flex flex-column"
									@click="search('clickProfile', post.writerUser.uid)"
								>
									<span
										class="d-flex flex-wrap ml-1"
										:class="isSmAndDown ? 'text-xxs' : 'text-sm'"
									>
										<span class="font-weight-bold">
											{{ writerUserLicenseIssues[index] }}
										</span>
										<span
											class="font-weight-bold"
											v-if="writerUserLicenseIssues[index]"
										>
											기
										</span>

										<span class="font-weight-bold">
											{{ post.writerUser.uid }}
										</span>

										<span>
											<div
												class="d-flex justify-end"
												v-if="post.contentMarketUserStatistics"
											>
												&#40;
												<span class="text-sm">
													<span class="text-xs">판매</span>
													{{ post.contentMarketUserStatistics.countAmount }}
													<span class="text-xs">건</span>
												</span>
												<span class="mx-1">&#47;</span>
												<span class="text-sm">
													<span class="text-xs">최고가</span>
													{{
														post.contentMarketUserStatistics.maxAmount
															| commaFormat
													}}
													<span class="text-xs">원</span>
												</span>
												<span class="mx-1">&#47;</span>
												<span class="text-sm">
													<span class="text-xs">총</span>
													{{
														post.contentMarketUserStatistics.sumAmount
															| commaFormat
													}}
													<span class="text-xs">원</span>
												</span>
												&#41;
											</div>
											<div class="d-flex justify-end" v-else>
												&#40;
												<span class="text-sm">이력없음</span>
												&#41;
											</div>
										</span>
									</span>
									<span
										class="ml-1"
										:class="isSmAndDown ? 'text-xxs' : 'text-xs'"
										style="letter-spacing: -0.4px"
									>
										<span>
											{{ post.createdAt | dateKOSimpleDotFormat }}
										</span>
									</span>
								</div>
							</div>
							<div>
								<v-btn
									small
									text
									@click="openPostDetailDialog(post.id)"
									:icon="isSmAndDown"
									class="px-0"
								>
									<template v-if="isSmAndDown">
										<v-icon small>mdi-arrow-expand</v-icon>
									</template>
									<template v-else>자세히 보기</template>
								</v-btn>
							</div>
						</div>
						<v-divider class="my-1" />

						<div class="text-right text-sm">
							<span class="text-sm">
								<span class="text-xs">총</span>
								{{ post.files.length }}
								<span class="text-xs">개</span>
							</span>
							&#47;
							<span class="text-sm">
								<span class="text-xs">이미지</span>
								{{ getNumberOfImages(post.files) }}
								<span class="text-xs">개</span>
							</span>
							&#47;
							<span class="text-sm">
								<span class="text-xs">GIF</span>
								{{ getNumberOfGifs(post.files) }}
								<span class="text-xs">개</span>
							</span>
							&#47;
							<span class="text-sm">
								<span class="text-xs">영상</span>
								{{ getNumberOfVideos(post.files) }}
								<span class="text-xs">개</span>
							</span>
						</div>
					</div>
					<market-post-swiper :post="post" />
					<div class="wrapper-content" @click="openPostDetailDialog(post.id)">
						<div class="d-flex justify-space-between">
							<div class="font-weight-bold">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											icon
											small
											v-bind="attrs"
											v-on="on"
											@click.stop="downloadFiles(post.files)"
										>
											<v-icon>mdi-download</v-icon>
										</v-btn>
									</template>
									<span>전체 다운로드</span>
								</v-tooltip>
								<span>
									{{
										post.subject && post.subject.lounge
											? resoloveSubjectLoungeType(post.subject.lounge)
											: resolvePostType(post.type)
									}}
									{{ post.postingNumber }}
								</span>
								<span v-if="post.subject" class="mx-1">&#62;</span>
								<span v-if="post.subject">{{ post.subject.title }}</span>
							</div>

							<div v-if="post.comments.length > 0">
								<post-comments-menu
									:post-id="post.id"
									:post-comments-count="post.comments.length"
									:unread-post-comments-count="post.unreadPostCommentsCount"
								/>
							</div>
						</div>
						<div class="content">
							<span v-html="post.content"></span>
						</div>
					</div>
					<div
						class="wrapper-actions px-4 pb-4"
						:key="componentKey"
						v-if="post.result === contentsMarketStatus.READY.value"
					>
						<v-text-field
							ref="paymentInput"
							v-model="payments[index]"
							@keypress="allowOnlyNumber($event)"
							prefix="P"
							placeholder="지급할 금액을 입력하세요"
							readonly
							hide-details
							outlined
							reverse
							dense
						>
							<template v-slot:append-outer>
								<v-btn
									@click="purchase(index, post.id, payments[index])"
									color="primary"
									:disabled="
										payments[index] === 0 ||
										payments[index] === '' ||
										payments[index] === undefined
									"
									small
								>
									<strong>바로 구매</strong>
								</v-btn>
							</template>
						</v-text-field>
						<div
							class="grid-button-container mt-2"
							style="gap: 1px; flex-wrap: wrap"
						>
							<v-btn @click="addPayment(index, 6000)" small outlined depressed>
								6천원
							</v-btn>
							<v-btn @click="addPayment(index, 1000)" small outlined depressed>
								1천원
							</v-btn>
							<v-btn @click="addPayment(index, 0)" small outlined depressed>
								초기화
							</v-btn>
							<v-btn @click="addPayment(index, 10000)" small outlined depressed>
								1만원
							</v-btn>
							<v-btn @click="addPayment(index, 3000)" small outlined depressed>
								3천원
							</v-btn>
							<v-btn
								@click="putCart(index, post)"
								color="accent"
								small
								:disabled="
									payments[index] === 0 ||
									payments[index] === '' ||
									payments[index] === undefined
								"
							>
								<strong>담기</strong>
							</v-btn>
						</div>
					</div>
				</v-card>
			</div>
			<div v-else class="text-center">조회된 데이터가 없습니다.</div>
			<div class="fixed" style="bottom: 0">
				<v-pagination
					:value="currentPage"
					:disabled="isLoading"
					:length="totalPages"
					@input="handlePageChange"
					total-visible="10"
				/>
			</div>
		</div>
		<market-post-detail-dialog
			:post-id="selectedPostId"
			@load-user-posts="loadUserPost($event)"
			:is-open-post-detail-dialog.sync="isOpenPostDetailDialog"
			@fetch-market-posts="isFetchMarketPosts = $event"
		/>
		<market-post-process-target-list-dialog
			@fetch-market-posts="isFetchMarketPosts = $event"
			@fetch-post-process-targets="isFetchPostProcessTargets = $event"
			:is-open-post-process-target-list-dialog.sync="
				isOpenPostProcessTargetListDialog
			"
		/>
		<v-snackbar v-model="snackbar" :timeout="timeout">
			{{ snackbarMessage }}

			<template v-slot:action="{ attrs }">
				<v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
					닫기
				</v-btn>
			</template>
		</v-snackbar>
		<div
			class="d-flex align-center flex-wrap"
			style="
				position: fixed;
				right: 10px;
				bottom: 10px;
				gap: 8px;
				z-index: 9999;
			"
		>
			<v-select
				item-text="name"
				item-value="value"
				label="금액을 선택하세요"
				:items="bundlePurchaseAmountList"
				v-model="bundlePurchaseAmount"
				hide-details
				solo
				v-if="selectedPosts.length > 0"
			/>
			<v-btn
				@click="purchasedAll"
				color="primary"
				v-if="selectedPosts.length > 0"
			>
				<strong class="mr-1">{{ selectedPosts.length }}건</strong>
				구매하기
			</v-btn>
		</div>
	</v-card>
</template>

<script>
import { reactive, ref, onMounted, watch, computed } from '@vue/composition-api'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import {
	postType,
	subjectLoungeType,
	unassignedPostIds,
	contentsMarketStatus,
} from '@/helpers'
import store from '@/store'
import {
	resolvePostType,
	resoloveSubjectLoungeType,
} from '@/@core/utils/filter'
import { useRouter, getVuetify } from '@core/utils'
import { dayDiff, commaRemove, substractYear, commaFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'
import MarketPostService from '@/services/MarketPostService'
import MarketPostSubjectService from '@/services/MarketPostSubjectService'

import DatePickerDialog from '@/components/common/DatePickerDialog.vue'
import MarketPostSwiper from './components/MarketPostSwiper.vue'
import PostCommentsMenu from './components/PostCommentsMenu.vue'
import MarketPostDetailDialog from './MarketPostDetailDialog'
import MarketPostProcessTargetListDialog from './MarketPostProcessTargetListDialog'

dayjs.extend(isBetween)

const ITEMS_PER_PAGE = 12
const THOUSAND_UNIT = 1000
const TENTHOUSAND_UNIT = 10000
const HUNDREDTHOUSAND_UNIT = 100000

export default {
	components: {
		MarketPostSwiper,
		PostCommentsMenu,
		DatePickerDialog,
		MarketPostDetailDialog,
		MarketPostProcessTargetListDialog,
	},
	setup() {
		const { route } = useRouter()
		const $vuetify = getVuetify()
		const profile = computed(() => store.getters['user/getMe'])
		const posts = ref([])
		const componentKey = ref(0)
		const filterHeight = ref(0)
		const wrapperScrollComponent = ref(null)
		const scrollComponent = ref(null)
		const selectSubjectEl = ref(null)
		const selectedPostId = ref(null)
		const selectedDateRange = ref(1)
		const selectedSortCriteria = ref('')
		const query = ref('')
		const subjectKeyword = ref('')
		const currentPage = ref(0)
		const totalPages = ref(0)
		const itemsPerPage = ref(ITEMS_PER_PAGE)
		const selectedOrderBy = ref('createdAt')
		const selectedOrder = ref('desc')
		const isOpenPostDetailDialog = ref(false)
		const isLoading = ref(false)
		const isChanging = ref(false)
		const isChangingDisapprovedAll = ref(false)
		const totalCount = ref(0)
		const snackbar = ref(false)
		const snackbarMessage = ref('')
		const timeout = ref(1500)
		const writerUid = ref(null)
		const allChecked = ref(false)
		const payments = ref([])
		const messages = ref([])
		const isFetchMarketPosts = ref(false)
		const isFetchPostProcessTargets = ref(false)
		const hasSubjectId = ref(false)
		const bundlePurchaseAmount = ref(10000)
		const bundlePurchaseAmountList = reactive([
			{
				name: '1만원',
				value: 10000,
			},
			{
				name: '6천원',
				value: 6000,
			},
			{
				name: '3천원',
				value: 3000,
			},
			{
				name: '1천원',
				value: 1000,
			},
		])
		const beginDate = ref(
			dayjs(dayjs().format('YYYY-MM-DD  23:59:59'))
				.subtract(1, 'month')
				.format('YYYY-MM-DD 00:00:01'),
		)
		const endDate = ref(dayjs().format('YYYY-MM-DD  23:59:59'))
		const isOpenPostProcessTargetListDialog = ref(false)
		const postProcessTargets = ref([])
		const typeGroups = ref([
			postType.CONTEST2,
			postType.CONTEST2_DONA,
			{
				name: '전체',
				value: '',
			},
			postType.CONTEST2_UNASSIGNED,
		])
		const keywordTypeGroups = ref([
			{
				name: '유저명',
				value: 'writerUid',
			},
			{
				name: '주제명',
				value: 'subjectTitle',
			},
		])
		const subjectLoungeTypeGroups = ref([
			{
				name: '전체',
				value: '',
			},
			subjectLoungeType.MIR_YONGSAN,
			subjectLoungeType.MIR_UIJEONGBU,
			subjectLoungeType.MIR_JEONJU,
		])
		const subjectGroups = ref([])
		const filteredsubjectGroups = ref([])
		const selectedSubjects = ref(null)
		const selectedType = ref(null)
		const selectedKeywordType = ref('writerUid')
		const selectedSubjectLoungeType = ref(null)
		const breakpoints = ref({
			1200: {
				arrowsOutside: false,
			},
			900: {
				arrowsOutside: false,
			},
			600: {
				arrowsOutside: false,
			},
		})
		const headers = ref([
			{
				text: '아이디',
				align: 'left',
				value: 'id',
				width: '120px',
			},
			{ text: '파일명', value: 'fileName' },
			{ text: '미리보기', value: 'previewFile' },
		])
		const selectedApprovalStatus = ref(
			route.value.query.status || contentsMarketStatus.READY.value,
		)
		const approvalStatusGroups = reactive([
			{
				name: '전체',
				value: 'ALL',
			},
			...Object.values(contentsMarketStatus),
		])
		const writerUserLicenseIssues = ref([])
		const selectedPosts = ref([])
		const isOpenDateRange = ref(false)
		const dateRangeGroups = reactive([
			{
				name: '전체',
				value: '',
			},
			{
				name: '1주일',
				value: 0.25,
			},
			{
				name: '1개월',
				value: 1,
			},
			{
				name: '3개월',
				value: 3,
			},
			{
				name: '직접입력',
				value: 0,
			},
		])
		const sortGroups = reactive([
			{
				name: '기수 오름차순',
				type: 'writerUserLicenseClassNumber',
				value: 'asc-writerUserLicenseClassNumber',
			},
			{
				name: '기수 내림차순',
				type: 'writerUserLicenseClassNumber',
				value: 'desc-writerUserLicenseClassNumber',
			},
			{
				name: '유저명 오름차순',
				type: 'writerUserUid',
				value: 'asc-writerUserUid',
			},
			{
				name: '유저명 내림차순',
				type: 'writerUserUid',
				value: 'desc-writerUserUid',
			},
		])
		const hasInquired = ref(false)

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const isMdAndDown = computed(() => {
			return $vuetify.breakpoint.mdAndDown
		})

		const scrollToTop = () => {
			window.scrollTo(0, 0)
		}

		const getUserClassNumber = async writerUser => {
			let _classNumber = ''
			if (writerUser.marketerRole !== 'CAMERASTAFF') {
				const user = await UserService.getUser(writerUser.id)

				const [data] = user.licenseIssues.sort(function (a, b) {
					return new Date(a.createdAt) - new Date(b.createdAt)
				})

				if (data && data.classNumber) {
					_classNumber = data.classNumber
				}
			}
			writerUserLicenseIssues.value.push(_classNumber)
		}

		const getMarketPosts = async () => {
			try {
				selectedPosts.value = []
				hasInquired.value = true
				isLoading.value = true

				beginDate.value = dayjs(beginDate.value).format('YYYY-MM-DD 00:00:01')
				endDate.value = dayjs(endDate.value).format('YYYY-MM-DD 23:59:59')

				const _posts = await MarketPostService.getMarketPosts({
					limit: itemsPerPage.value,
					page: currentPage.value - 1,
					...(selectedKeywordType.value === 'writerUid' && {
						writerUid: query.value,
					}),
					category: 0,
					order: selectedOrder.value,
					orderby: selectedOrderBy.value,
					result: selectedApprovalStatus.value,
					beginDate:
						selectedDateRange.value ||
						(typeof selectedDateRange.value === 'number' &&
							selectedDateRange.value >= 0)
							? beginDate.value
							: substractYear(beginDate.value, 10),
					endDate: endDate.value,
					type:
						selectedType.value === postType.CONTEST2_UNASSIGNED.value
							? postType.CONTEST2.value
							: selectedType.value,
					subjectLounge: selectedSubjectLoungeType.value,
					subjectIds: selectedSubjects.value,
					contentMarketStatistics: true,
					sortCriteria: selectedSortCriteria.value,
					hasSubjectId: !hasSubjectId.value,
					published: true,
				})

				const tempPosts = []
				for (const post of _posts.posts) {
					const _post = {
						...post,
						unreadPostCommentsCount: post.comments.filter(
							e => !e.readAdmin && e.user.id !== profile.value.id,
						).length,
					}

					tempPosts.push(_post)
				}
				posts.value = tempPosts

				totalCount.value = _posts.totalCount
				totalPages.value = parseInt(_posts.totalPages)

				isLoading.value = false

				writerUserLicenseIssues.value = []
				for (const post of posts.value) {
					await getUserClassNumber(post.writerUser)
				}
			} catch (e) {
				console.log(e)
			}
		}

		const init = () => {
			currentPage.value = 0
			writerUid.value = null
			posts.value = []
		}

		const reset = async () => {
			init()
			await getMarketPosts()
			isFetchMarketPosts.value = false
		}

		const openPostDetailDialog = postId => {
			selectedPostId.value = postId
			isOpenPostDetailDialog.value = !isOpenPostDetailDialog.value
		}

		const checkedAll = () => {
			selectedPosts.value = []

			if (allChecked.value) {
				for (const [_, post] of Object.entries(posts.value)) {
					if (post.result === contentsMarketStatus.READY.value) {
						selectedPosts.value.push(post.id)
					}
				}
			}
		}

		const listMarketPostSubjects = async () => {
			subjectGroups.value = []
			let { data } = await MarketPostSubjectService.getAll({
				type:
					selectedType.value === postType.CONTEST2_UNASSIGNED.value
						? postType.CONTEST2.value
						: selectedType.value,
				lounge: selectedSubjectLoungeType.value,
				orderby: 'endDate',
				order: 'desc',
				limit: 10000,
			})

			if (selectedType.value === postType.CONTEST2_UNASSIGNED.value) {
				data = data.filter(e => [...unassignedPostIds].includes(e.id))
			}

			data.map(e => {
				let leftDay = ''
				if (dayDiff(e.endDate) > 0) {
					leftDay = `D-${dayDiff(e.endDate)}`
				} else if (dayDiff(e.endDate) == 0) {
					leftDay = '오늘마감'
				} else {
					leftDay = `D+${Math.abs(dayDiff(e.endDate))}`
				}

				const _name = `[${leftDay}] ${e.title}`
				const _statistics =
					`(<strong style="${
						e.statistics.ready > 0 ? 'color: red;' : ''
					}">심사전 ${e.statistics.ready}건</strong> / 구매완료 ${
						e.statistics.approved
					}건 / 반환 ${e.statistics.disapproved}건)` +
					'<br>' +
					`<small>시작날짜: <strong>${dayjs(e.startDate).format(
						'YYYY년 MM월 D일',
					)}</strong></small>`

				subjectGroups.value.push({
					name: _name,
					lounge: e.lounge,
					statistics: _statistics,
					value: e.id,
				})
			})

			filteredsubjectGroups.value = [...subjectGroups.value]
		}

		const listMarketPostSubjectsWithTitle = async () => {
			const { data } = await MarketPostSubjectService.getAll({
				title: query.value,
				orderby: 'endDate',
				order: 'desc',
				limit: 10000,
			})
			return data
		}

		const search = async (type, $event) => {
			if (type === 'button') {
				query.value = $event.value
			} else if (type === 'clickProfile') {
				query.value = $event
			} else {
				query.value = $event.target.value
			}

			currentPage.value = 0
			posts.value = []

			scrollToTop()

			if (selectedKeywordType.value === 'subjectTitle') {
				const data = await listMarketPostSubjectsWithTitle()
				if (data.length === 0) {
					currentPage.value = 0
					posts.value = []
					selectedSubjects.value = null
					return
				}
				selectedSubjects.value = data.map(e => e.id)
			}

			await getMarketPosts()
		}

		const loadUserPost = uid => {
			init()
			snackbarMessage.value = `${uid}  콘텐츠를 불러왔습니다`
			snackbar.value = true
			writerUid.value = uid
			getMarketPosts()
		}

		const changeApprovalStatus = status => {
			selectedApprovalStatus.value = status
		}

		const canSelectSubject = type => {
			return [
				postType.CONTEST2.value,
				postType.CONTEST2_DONA.value,
				postType.CONTEST2_UNASSIGNED.value,
			].includes(type)
		}

		const changeType = async type => {
			selectedSubjects.value = null
			selectedType.value = type

			if (selectedType.value !== postType.CONTEST2.value) {
				selectedSubjectLoungeType.value = ''
			}

			if (canSelectSubject(type)) {
				listMarketPostSubjects()
			}
		}

		const changeKeywordType = async type => {
			selectedSubjects.value = null
			selectedKeywordType.value = type
		}

		const changeSubjectLoungeType = async subjectLoungeType => {
			selectedSubjectLoungeType.value = subjectLoungeType

			await listMarketPostSubjects()

			if (subjectLoungeType) {
				subjectGroups.value = subjectGroups.value.filter(
					e => e.lounge === subjectLoungeType,
				)
			}
		}

		const inquiry = async () => {
			init()
			scrollToTop()

			await getMarketPosts()
		}

		const inquirySubject = async () => {
			try {
				isLoading.value = true
				if (selectSubjectEl.value) {
					selectSubjectEl.value.blur()
				}

				init()
				scrollToTop()
				await getMarketPosts()
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}

		const changedDateRange = async dateRange => {
			selectedDateRange.value = dateRange

			if (dateRange === 0) {
				beginDate.value = ''
				endDate.value = ''

				isOpenDateRange.value = !isOpenDateRange.value
				return
			}
			isOpenDateRange.value = false

			init()
			listMarketPostSubjects()

			if (dateRange) {
				let type = ''
				const currentDatetime = dayjs().format('YYYY-MM-DD  23:59:59')
				if (dateRange > 0.25) {
					type = 'month'
				} else {
					type = 'week'
					dateRange = 1
				}

				beginDate.value = dayjs(currentDatetime)
					.subtract(dateRange, type)
					.format('YYYY-MM-DD 00:00:01')
				endDate.value = currentDatetime
			}
		}

		const inquiryDate = async () => {
			currentPage.value = 0
			writerUid.value = null
			posts.value = []

			listMarketPostSubjects()
			scrollToTop()
			await getMarketPosts()
		}

		const changeStatusDisapproved = async () => {
			const confirm = await confirmSwal(
				`${selectedPosts.value.length}건 일괄 반환하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					isChanging.value = true
					for (const postId of selectedPosts.value) {
						await MarketPostService.disapprovalMarketPost({
							postId: postId,
							message: '',
						})
					}
					init()
					scrollToTop()
					await getMarketPosts()
					successSwal('반환되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '반환하는데 문제가 발생했습니다.',
					)
				} finally {
					allChecked.value = false
					isChanging.value = false
				}
			}
		}

		const changeStatusDisapprovedAll = async () => {
			const confirm = await confirmSwal('전체 일괄 반환하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const tempArray = []
					isChangingDisapprovedAll.value = true
					for (const post of posts.value) {
						if (post.result === contentsMarketStatus.READY.value) {
							tempArray.push(post.id)
						}
					}

					await MarketPostService.disapprovalAllMarketPost(tempArray)

					init()
					scrollToTop()
					await getMarketPosts()
					successSwal('전체 일괄 반환되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400
								? e.response.data.message
								: '반환하는데 문제가 발생했습니다.',
					})
				} finally {
					allChecked.value = false
					isChangingDisapprovedAll.value = false
				}
			}
		}

		const purchasedAll = async () => {
			const confirm = await confirmSwal(
				`${selectedPosts.value.length}건 일괄 ${commaFormat(
					bundlePurchaseAmount.value,
				)}원으로 구매하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					for (const postId of selectedPosts.value) {
						await MarketPostService.approvalMarketPost({
							postId: postId,
							payment: bundlePurchaseAmount.value,
							message: '',
						})

						const foundIndex = posts.value.findIndex(e => e.id == postId)
						posts.value.splice(foundIndex, 1)
					}
					selectedPosts.value = []
					scrollToTop()
					successSwal('일괄 구매되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400
								? e.response.data.message
								: '구매하는데 문제가 발생했습니다.',
					})
				} finally {
					allChecked.value = false
					isChangingDisapprovedAll.value = false
				}
			}
		}

		const countContentType = computed(() => {})

		const handlePageChange = value => {
			currentPage.value = value

			scrollToTop()
			getMarketPosts()
		}

		const isValidAmount = amount => {
			let _message = ''
			let _result = true

			if (amount === 0 || amount === undefined) {
				return {
					result: false,
					message: '금액을 입력하세요',
				}
			}

			const _amount = commaRemove(amount)
			if (_amount < TENTHOUSAND_UNIT) {
				if (_amount < THOUSAND_UNIT) {
					_message = '1천원 이상만 입력이 가능합니다'
					_result = false
				} else {
					if (_amount % THOUSAND_UNIT != 0) {
						_message = '천 단위로만 입력이 가능합니다'
						_result = false
					}
				}
			} else {
				if (_amount > HUNDREDTHOUSAND_UNIT) {
					_message = '10만 이하로만 입력이 가능합니다.'
					_result = false
				} else {
					if (_amount % TENTHOUSAND_UNIT != 0) {
						_message = '만 단위로만 입력이 가능합니다.'
						_result = false
					}
				}
			}

			return {
				result: _result,
				message: _message,
			}
		}

		const forceRerender = () => {
			componentKey.value += 1
		}

		const purchase = async (index, postId, amount) => {
			const _isValidAmount = isValidAmount(amount)

			if (!_isValidAmount.result) {
				warningSwal(_isValidAmount.message)
				return
			}

			const saveConfirm = await confirmSwal('바로 구매하시겠습니까?')

			if (saveConfirm.isConfirmed) {
				try {
					await MarketPostService.approvalMarketPost({
						postId: postId,
						payment: parseInt(commaRemove(payments.value[index])),
						message: '',
					})

					payments.value[index] = 0
					forceRerender()

					successSwal('구매 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400 || e.response.status === 404
							? e.response.data.message
							: '바로 구매하는데 문제가 발생했습니다.',
					)
				} finally {
					payments.value[index] = 0
				}
			}
		}

		const toDataURL = async url => {
			const blob = await fetch(url).then(res => res.blob())
			return URL.createObjectURL(blob)
		}

		const createDownloadLink = async file => {
			const link = document.createElement('a')
			link.href = await toDataURL(file.url)
			link.download = file.fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}

		const downloadFiles = postFiles => {
			postFiles.forEach(async e => {
				await createDownloadLink(e)
			})
		}

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const addPayment = (index, amount) => {
			if (
				payments.value[index] === undefined ||
				payments.value[index] === '' ||
				amount === 0
			) {
				payments.value[index] = 0
			}

			payments.value[index] =
				parseInt(commaRemove(payments.value[index])) + amount
			forceRerender()
		}

		const putCart = async (index, post) => {
			if (!isValidAmount(parseInt(commaRemove(payments.value[index]))).result) {
				warningSwal(
					isValidAmount(parseInt(commaRemove(payments.value[index]))).message,
				)
				return
			}

			try {
				const cartItems = await store.getters['post/getPostProcessTargets']()
				if (cartItems) {
					const found = Object.values(cartItems).findIndex(
						e => e.postId === post.id,
					)
					if (found !== -1) {
						snackbarMessage.value = '이미 장바구니에 담겨졌습니다'
						snackbar.value = true
						return
					}
				}

				const payload = {
					postId: post.id,
					postingNumber: post.postingNumber,
					writerUserPublicPictureUrl: post.writerUser.publicPictureUrl,
					writerUserUid: post.writerUser.uid,
					postFiles: post.files,
					payment: parseInt(commaRemove(payments.value[index])),
					message: '',
				}

				store.dispatch('post/setPostProcessTarget', payload)

				snackbarMessage.value = '장바구니에 담겨졌습니다'
				snackbar.value = true

				payments.value[index] = 0
				forceRerender()
			} catch (e) {
				console.error(e)
			}
		}

		const getPostProcessTargets = async () => {
			try {
				const tempObj = await store.getters['post/getPostProcessTargets']()
				if (tempObj) {
					postProcessTargets.value = Object.keys(tempObj).map(k => {
						tempObj[k].firebaseKey = k
						return tempObj[k]
					})
				}
			} catch (e) {
				console.error(e)
			} finally {
				isFetchPostProcessTargets.value = false
			}
		}

		const isListClickable = item => {
			let _isListClickable = false
			;[...selectedSortCriteria.value].forEach(e => {
				if (e.includes(item.type)) {
					if (item.value == e) {
						_isListClickable = false
					} else {
						_isListClickable = true
					}
				}
			})
			return _isListClickable
		}

		const observeHeight = () => {
			const resizeObserver = new ResizeObserver(function () {
				wrapperScrollComponent.value.style.marginTop =
					filterHeight.value.offsetHeight + 'px'
			})

			resizeObserver.observe(filterHeight.value)
		}

		const removeChip = item => {
			const index = selectedSubjects.value.findIndex(e => e == item.value)
			if (index >= 0) selectedSubjects.value.splice(index, 1)
		}

		const getFileExtension = url => {
			return url.substring(url.lastIndexOf('.') + 1).toLowerCase()
		}

		const getNumberOfImages = files => {
			let count = 0
			for (const file of files) {
				const fileExtension = getFileExtension(file.url)
				if (['jpeg', 'jpg', 'png', 'webp'].includes(fileExtension)) {
					count++
				}
			}
			return count
		}

		const getNumberOfVideos = files => {
			let count = 0
			for (const file of files) {
				const fileExtension = getFileExtension(file.url)
				if (['mp4', 'mov', 'wmv', 'avi', 'webm'].includes(fileExtension)) {
					count++
				}
			}
			return count
		}

		const getNumberOfGifs = files => {
			let count = 0
			for (const file of files) {
				const fileExtension = getFileExtension(file.url)
				if (['gif'].includes(fileExtension)) {
					count++
				}
			}
			return count
		}

		watch(
			() => isFetchMarketPosts.value,
			async currentValue => {
				if (currentValue) {
					reset()
					getPostProcessTargets()
				}
			},
		)

		watch(
			() => isFetchPostProcessTargets.value,
			async currentValue => {
				if (currentValue) {
					getPostProcessTargets()
				}
			},
		)

		watch(
			() => subjectKeyword.value,
			currentValue => {
				if (currentValue.length > 0) {
					filteredsubjectGroups.value = subjectGroups.value.filter(e =>
						e.name.includes(currentValue),
					)
				} else {
					filteredsubjectGroups.value = [...subjectGroups.value]
				}
			},
		)

		onMounted(async () => {
			observeHeight()
			getPostProcessTargets()
		})

		return {
			query,
			posts,
			timeout,
			headers,
			payments,
			messages,
			totalPages,
			currentPage,
			itemsPerPage,
			countContentType,
			handlePageChange,
			filterHeight,
			componentKey,
			selectedPostId,
			selectedDateRange,
			selectedSubjectLoungeType,
			selectedSortCriteria,
			isOpenPostDetailDialog,
			writerUserLicenseIssues,
			breakpoints,
			isLoading,
			hasSubjectId,
			totalCount,
			allChecked,
			writerUid,
			snackbar,
			snackbarMessage,
			sortGroups,
			subjectKeyword,
			contentsMarketStatus,
			selectedApprovalStatus,
			approvalStatusGroups,
			isFetchMarketPosts,
			isFetchPostProcessTargets,
			scrollComponent,
			endDate,
			postType,
			beginDate,
			isChanging,
			typeGroups,
			isSmAndDown,
			isMdAndDown,
			subjectLoungeTypeGroups,
			keywordTypeGroups,
			selectedType,
			selectedKeywordType,
			subjectGroups,
			filteredsubjectGroups,
			selectedPosts,
			isOpenDateRange,
			dateRangeGroups,
			selectSubjectEl,
			selectedSubjects,
			postProcessTargets,
			wrapperScrollComponent,
			isChangingDisapprovedAll,
			isOpenPostProcessTargetListDialog,
			bundlePurchaseAmount,
			bundlePurchaseAmountList,

			reset,
			search,
			removeChip,
			putCart,
			inquiry,
			purchase,
			checkedAll,
			addPayment,
			changeType,
			purchasedAll,
			getNumberOfImages,
			getNumberOfGifs,
			getNumberOfVideos,
			changeKeywordType,
			getUserClassNumber,
			inquiryDate,
			loadUserPost,
			downloadFiles,
			inquirySubject,
			resolvePostType,
			isListClickable,
			canSelectSubject,
			resoloveSubjectLoungeType,
			allowOnlyNumber,
			changedDateRange,
			changeSubjectLoungeType,
			openPostDetailDialog,
			changeApprovalStatus,
			changeStatusDisapproved,
			changeStatusDisapprovedAll,
		}
	},
}
</script>
<style lang="scss" scoped>
.fixed {
	right: 12px;
	position: fixed;
	overflow-y: hidden;
	overflow-x: auto;
	background-color: white;
	z-index: 99;
	width: calc(100% - 284px);
	border: 1.5px solid #cccccc;

	@media (max-width: 1263px) {
		width: calc(100% - 23px);
	}
}
.grid-button-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-row-gap: 10px;
	grid-column-gap: 10px;
}

.grid-container {
	display: grid;
	gap: 12px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;
	overflow-y: scroll;

	@media (max-width: 960px) {
		gap: 8px;
	}

	@media (max-width: 768px) {
		gap: 6px;
	}

	@media (max-width: 595px) {
		gap: 3px;
	}

	.grid-item {
		background-color: white;
		.status-chip {
			position: absolute;
			right: 5px;
			top: 5px;
		}

		@media (max-width: 960px) {
			min-height: 380px;
		}

		@media (max-width: 768px) {
			min-height: 275px;
		}

		@media (max-width: 595px) {
			min-height: 215px;
		}

		@media (max-width: 414px) {
			min-height: 145px;
		}

		// ipad landscape
		@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
			min-height: 275px;
		}

		// ipad portrait
		@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
			min-height: 295px;
		}

		.v-skeleton-loader {
			height: 355px !important;

			@media (max-width: 674px) {
				height: 36vw !important;
			}
		}

		::v-deep .v-skeleton-loader__list-item-avatar {
			.v-skeleton-loader__avatar {
				@media (max-width: 571px) {
					height: 15px;
					width: 15px;
				}
			}
		}
	}
}

.wrapper-content {
	font-size: 14px;
	padding: 8px 12px;

	@media (max-width: 674px) {
		padding: 6px 8px 2px;
	}

	.content {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
</style>
