const postType = {
	CONTEST: { name: '알레시', value: 'CONTEST' },
	CONTEST2: { name: '라운지 콘텐츠', value: 'CONTEST2' },
	CONTEST2_DONA: { name: '도나 콘텐츠', value: 'CONTEST2_DONA' },
	CONTEST2_UNASSIGNED: { name: '미지정 콘텐츠', value: 'CONTEST2_UNASSIGNED' },
	LANDING_PAGE: {
		name: '소개페이지',
		value: 'LANDING_PAGE',
	},
}

export default postType
