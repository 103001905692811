const executive = [
	{
		name: '박혜주',
		uid: '자스민',
	},
	{
		name: '이승민',
		uid: '트리니티',
	},
	{
		name: '최인선',
		uid: '자유인',
	},
	{
		name: '구교천',
		uid: '메타킹',
	},
	{
		name: '윤병록',
		uid: '락킹',
	},
	{
		name: '박양숙',
		uid: '원더우먼',
	},
	{
		name: '백현숙',
		uid: '백전노장',
	},
	{
		name: '김정순',
		uid: '알리나',
	},
	{
		name: '김한나',
		uid: '카이로스',
	},
]

export default executive
