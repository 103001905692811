<template>
	<v-dialog v-model="isOpenUserTestDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				답안지 보기
				<v-spacer />
				<v-btn icon @click="$emit('update:is-open-user-test-dialog', false)">
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<v-simple-table fixed-header>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">점수</th>
								<th class="text-left">합격여부</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="font-weight-bold">
									<span v-if="userTest && userTest.score">
										{{ userTest.score }}
									</span>
								</td>
								<td class="font-weight-bold">
									{{ testResult }}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<div v-for="item in problemCount" :key="item">
					<v-col cols="12" md="12" sm="12">
						<multiple-choice-questions-view
							:is-confirm="true"
							:workbook-id="workbookId"
							:question-number="item"
							:answer-sheet="answerSheet"
							:test-answer="testAnswer"
						/>
					</v-col>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { testStatus } from '@/helpers'
import { ref, watch, computed } from '@vue/composition-api'

import MarketLicenseService from '@/services/MarketLicenseService'

import MultipleChoiceQuestionsView from './components/MultipleChoiceQuestionsView.vue'

export default {
	props: {
		isOpenUserTestDialog: {
			type: Boolean,
			required: true,
		},
		testAnswer: {
			type: Array,
		},
		userId: {
			type: Number,
		},
		workbookId: {
			type: Number,
		},
		problemCount: {
			type: Number,
		},
	},
	components: {
		MultipleChoiceQuestionsView,
	},
	setup(props) {
		const userTest = ref({})
		const answerSheet = ref([])

		const getWorkbookUserTest = async () => {
			const data = await MarketLicenseService.getWorkbookUserTest({
				id: props.workbookId,
				userId: props.userId,
			})
			userTest.value = data[0]
			answerSheet.value = userTest.value?.answerSheet
		}

		const testResult = computed(() => {
			return userTest.value.pass ? testStatus.PASS.name : testStatus.FAIL.name
		})

		watch(
			() => props.isOpenUserTestDialog,
			currentValue => {
				if (currentValue) {
					getWorkbookUserTest()
				}
			},
			{
				immediate: true,
			},
		)

		return {
			userTest,
			testResult,
			answerSheet,

			icons: {
				mdiClose,
			},
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
