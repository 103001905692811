<template>
	<v-card>
		<v-card-title :class="{ 'pa-2': !isMdAndUp }">
			<date-picker-dialog :label="'시작날짜'" :date-value.sync="startDate" />
			<date-picker-dialog :label="'마감날짜'" :date-value.sync="endDate" />
			<v-btn small @click="inquiry" color="primary">조회</v-btn>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="reservations"
			:items-per-page="-1"
			no-data-text="조회된 예약 목록이 없습니다."
			class="elevation-1"
			:loading="isLoading"
			:mobile-breakpoint="0"
			loading-text="예약 목록을 불러오는 중입니다..."
			hide-default-header
			hide-default-footer
			style="min-height: 700px"
		>
			<template v-slot:header="{ props: { headers } }">
				<thead>
					<tr>
						<th
							v-for="header in headers"
							:key="header.text"
							:rowspan="header.children ? 1 : 2"
							:colspan="header.children ? header.children.length : 1"
							class="text-center"
						>
							{{ header.text }}
						</th>
					</tr>
					<tr v-if="isSmAndUp">
						<th
							v-for="subheader in getSubHeader(headers)"
							:key="subheader.text"
							:style="{ width: subheader.width + 'px' }"
						>
							{{ subheader.text }}
						</th>
					</tr>
				</thead>
			</template>
			<template v-slot:body="{ items }">
				<tbody>
					<tr v-for="item in items" :key="item.text">
						<td class="date">
							<strong>
								{{ item.date }}
								({{ renderDayOfWeek(item.date) }})
							</strong>
						</td>
						<td colspan="8" class="pl-0">
							<v-data-table
								:items="item.reservation"
								:mobile-breakpoint="0"
								item-key="name"
								hide-default-footer
								fixed-header
								v-if="isSmAndUp"
								disable-pagination
							>
								<template v-slot:body="{ items }">
									<tbody>
										<tr v-for="item in items" :key="'reservation-' + item.id">
											<td style="width: 25px">{{ item.id }}</td>
											<td style="width: 200px">
												<router-link
													:to="{
														name: 'market-locations-detail',
														params: {
															marketLocationId: item.marketLocation.id,
														},
													}"
												>
													{{ item.marketLocation.name }}
												</router-link>
											</td>
											<td style="width: 220px">
												{{ item.beginDate | timeFormat }} -
												{{ item.endDate | timeFormat }}
											</td>
											<td style="width: 220px">
												{{ item.title }} (
												<span
													@click="openUserInfoDialog(item.user.id)"
													class="clickable"
												>
													{{ item.user.name }}
												</span>
												)
											</td>
											<td style="width: 220px">
												{{
													item.user.phoneNumber
														| excludeInternationalPhoneFormat
												}}
											</td>
											<td style="width: 220px">
												{{ item.fare | commaFormat }} 원
											</td>
											<td style="width: 220px">
												{{ item.numberOfPeople | commaFormat }} 명
											</td>
											<td style="width: 150px">
												<v-chip
													small
													:color="resolveReservationStatusVariant(item.status)"
												>
													{{ resolveReservationStatus(item.status) }}
												</v-chip>
											</td>
										</tr>
									</tbody>
								</template>
							</v-data-table>
							<div v-else>
								<div
									v-for="(item, i) in item.reservation"
									:key="'m-reservation' + i"
								>
									<v-card elevation="0" class="card-reservation" tile>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title class="text-sm">
													<v-icon small color="info" class="mr-1">
														mdi-map-marker
													</v-icon>
													<router-link
														:to="{
															name: 'market-locations-detail',
															params: {
																marketLocationId: item.marketLocation.id,
															},
														}"
														class="mr-1"
													>
														{{ item.marketLocation.name }}
													</router-link>

													<v-chip
														small
														:color="
															resolveReservationStatusVariant(item.status)
														"
													>
														{{ resolveReservationStatus(item.status) }}
													</v-chip>
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title class="text-sm">
													<v-icon small class="mr-1">
														mdi-calendar-clock-outline
													</v-icon>
													{{ item.beginDate | timeFormat }} -
													{{ item.endDate | timeFormat }}
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title class="text-sm">
													<v-icon small class="mr-1">
														mdi-account-multiple
													</v-icon>
													{{ item.title }} (
													<span
														@click="openUserInfoDialog(item.user.id)"
														class="clickable"
													>
														{{ item.user.name }}
													</span>
													외
													{{ (item.numberOfPeople - 1) | commaFormat }}
													명)
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title class="text-sm">
													<v-icon small class="mr-1">mdi-cellphone</v-icon>
													{{
														item.user.phoneNumber
															| excludeInternationalPhoneFormat
													}}
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title class="text-sm">
													<v-icon small class="mr-1">mdi-credit-card</v-icon>
													{{ item.fare | commaFormat }} 원
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-card>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>
		<user-dialog
			:user-id="selectedUserId"
			:is-open-user-info-dialog.sync="isOpenUserInfoDialog"
		/>
	</v-card>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'

import dayjs from 'dayjs'

import { getVuetify } from '@core/utils'

import {
	resolveReservationStatus,
	resolveReservationStatusVariant,
} from '@core/utils/filter'
import { executive } from '@/helpers'
import { useRouter } from '@core/utils'
import { convertUTC2KST } from '@/filter'
import { warningSwal } from '@/plugins/swalMixin'

import MarketLocationService from '@/services/MarketLocationService'

import UserDialog from '@/components/common/UserDialog.vue'
import DatePickerDialog from '@/components/common/DatePickerDialog.vue'

export default {
	components: {
		UserDialog,
		DatePickerDialog,
	},
	setup() {
		const $vuetify = getVuetify()
		const { route } = useRouter()
		const selectedUserId = ref(0)
		const isOpenUserInfoDialog = ref(false)
		const marketLocationId = ref(route.value.params.marketLocationId)
		const headers = ref([
			{ text: '예약 날짜', value: 'date', width: '150px' },
			{
				text: '예약 정보',
				value: 'reservationInfo',
				align: 'center',
				children: [
					{ text: 'id', value: 'index', align: 'left', width: 25 },
					{ text: '장소', value: 'name', align: 'left', width: 220 },
					{ text: '예약 시간', value: 'date', align: 'left', width: 220 },
					{
						text: '예약명 (예약자명)',
						value: 'title',
						align: 'left',
						width: 220,
					},
					{
						text: '휴대폰번호',
						value: 'userPhone',
						align: 'left',
						width: 220,
					},
					{ text: '결제금액', value: 'fare', align: 'left', width: 220 },
					{
						text: '예약인원',
						value: 'numberOfPeople',
						align: 'left',
						width: 220,
					},
					{ text: '예약상태', value: 'status', align: 'left', width: 220 },
				],
			},
		])
		const reservations = ref([])
		const isLoading = ref(false)
		const startDate = ref(dayjs().subtract(1, 'day').format('YYYY-MM-DD'))
		const endDate = ref(dayjs().add(1, 'month').format('YYYY-MM-DD'))

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		const isExecutive = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			return index !== -1 ? true : false
		}

		const renderTopLeader = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			return index !== -1 ? ancestorUser.uid : ancestorUser.name
		}

		const listMarketLocationReservations = async () => {
			try {
				isLoading.value = true
				const data = await MarketLocationService.listMarketLocationReservations(
					{
						beginDate: `${startDate.value} 00:00:00`,
						endDate: `${endDate.value} 23:59:59`,
					},
				)

				const groups = data.reduce((groups, reservation) => {
					const date = dayjs(convertUTC2KST(reservation.beginDate)).format(
						'YYYY-MM-DD',
					)

					if (!groups[date]) {
						groups[date] = []
					}
					groups[date].push(reservation)
					return groups
				}, {})

				const groupArrays = Object.keys(groups).map(date => {
					const tempArray = groups[date].sort(function (a, b) {
						return new Date(a.beginDate) - new Date(b.beginDate)
					})

					return {
						date,
						reservation: tempArray,
					}
				})

				const sortedByDateArray = groupArrays.sort(function (a, b) {
					return new Date(a.date) - new Date(b.date)
				})

				reservations.value = sortedByDateArray
			} catch (e) {
				warningSwal('예약 목록을 불러오는데 문제가 발생했습니다.')
			} finally {
				isLoading.value = false
			}
		}

		const inquiry = () => {
			listMarketLocationReservations()
		}

		const getSubHeader = headers => {
			let result = []
			headers
				.filter(i => i.children)
				.forEach(v => {
					result = result.concat(v.children)
				})
			return result
		}

		const openUserInfoDialog = userId => {
			selectedUserId.value = userId
			isOpenUserInfoDialog.value = !isOpenUserInfoDialog.value
		}

		const renderDayOfWeek = date => {
			return dayjs(date).format('ddd')
		}

		onMounted(() => {
			listMarketLocationReservations()
		})

		return {
			headers,
			isLoading,
			marketLocationId,
			selectedUserId,
			reservations,
			isOpenUserInfoDialog,
			startDate,
			endDate,
			isMdAndUp,
			isSmAndUp,

			resolveReservationStatus,
			resolveReservationStatusVariant,

			renderDayOfWeek,
			getSubHeader,
			inquiry,
			openUserInfoDialog,
			isExecutive,
			renderTopLeader,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 660px;

	thead {
		tr {
			th {
				width: 200px;
				text-align: center !important;

				@media (max-width: 600px) {
					max-width: 100px;
				}
			}
		}

		tr:first-child {
			th:first-child {
				border-bottom: thin solid rgba(51, 51, 51, 0.14);
				border-left: thin solid rgba(51, 51, 51, 0.14);
				border-right: thin solid rgba(51, 51, 51, 0.14);
			}
		}
	}

	tbody {
		tr {
			td {
				height: 70px !important;
			}

			td.date {
				text-align: center !important;
			}

			td:nth-child(0) {
				display: flex-start;
			}

			td:nth-child(1) {
				border-left: thin solid rgba(51, 51, 51, 0.14);
			}
		}
	}
}

.card-reservation {
	border-left: thin solid rgba(51, 51, 51, 0.14);
	border-bottom: thin solid rgba(51, 51, 51, 0.14);
	padding-top: 10px;
	padding-bottom: 10px;

	.v-list-item {
		min-height: 25px;
	}
	.v-list-item__content {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 600;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
