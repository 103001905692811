export default [
	{
		path: '/market-courses',
		name: 'market-course-list',
		component: () => import('@/pages/marketCourse/MarketCourseList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/course-registrations',
		name: 'course-registrations',
		component: () => import('@/pages/marketCourse/MarketCourseRegistration'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-courses/:marketCourseId',
		name: 'market-course-detail',
		props: true,
		component: () => import('@/pages/marketCourse/MarketCourseDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-course-register',
		name: 'market-course-register',
		component: () => import('@/pages/marketCourse/MarketCourseRegister'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-course/:marketCourseId/registrions',
		name: 'market-course-registration-list',
		props: true,
		component: () =>
			import('@/pages/marketCourse/MarketCourseRegistrationList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
