<template>
	<div>
		<v-sheet height="64">
			<v-toolbar flat>
				<div class="d-flex mx-auto">
					<v-btn outlined small color="grey darken-2" @click="prev">
						<v-icon small>mdi-chevron-left</v-icon>
					</v-btn>
					<v-toolbar-title v-if="calendar" class="mx-5">
						<span @click="type = 'month'" class="cursor-pointer">
							{{ $refs.calendar.title }}
						</span>
					</v-toolbar-title>
					<v-btn outlined small color="grey darken-2" @click="next">
						<v-icon small>mdi-chevron-right</v-icon>
					</v-btn>
				</div>
			</v-toolbar>
		</v-sheet>
		<v-sheet height="700">
			<v-calendar
				ref="calendar"
				v-model="focus"
				color="primary"
				locale="ko"
				:events="events"
				:event-color="getEventColor"
				:type="type"
				:event-more-text="isSmAndDown ? '+{0}' : '{0}개 더보기'"
				@click:event="showEvent"
				@click:more="viewDay"
				@click:date="viewDay"
			/>
			<v-menu
				v-model="selectedOpen"
				:close-on-content-click="false"
				:activator="selectedElement"
				offset-x
			>
				<v-card color="grey lighten-4" min-width="350px" flat>
					<v-toolbar :color="selectedEvent.color" dark>
						<v-toolbar-title>
							{{ selectedEvent.name }}
							<v-chip
								small
								:color="resolveReservationStatusVariant(selectedEvent.status)"
							>
								{{ resolveReservationStatus(selectedEvent.status) }}
							</v-chip>
						</v-toolbar-title>
					</v-toolbar>
					<v-card-text :class="isSmAndDown ? 'px-3 pt-3' : 'pt-6'">
						<v-list-item two-line class="mb-2" :class="{ 'pl-0': isSmAndDown }">
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									예약자 명
								</v-list-item-subtitle>
								<v-list-item-title class="font-weight-bold">
									{{ selectedEvent.userName }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item class="mb-2" :class="{ 'pl-0': isSmAndDown }">
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									연락처
								</v-list-item-subtitle>
								<v-list-item-title class="font-weight-bold">
									{{ selectedEvent.phoneNumber }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line class="mb-2" :class="{ 'pl-0': isSmAndDown }">
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									예약 시간
								</v-list-item-subtitle>
								<v-list-item-title class="font-weight-bold text-wrap">
									{{ selectedEvent.start | timeFormat }} -
									{{ selectedEvent.end | timeFormat }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line class="mb-2" :class="{ 'pl-0': isSmAndDown }">
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									예약 인원
								</v-list-item-subtitle>
								<v-list-item-title class="font-weight-bold">
									{{ selectedEvent.numberOfPeople || 0 }} 명
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :class="{ 'pl-0': isSmAndDown }">
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									결제 금액
								</v-list-item-subtitle>
								<v-list-item-title class="font-weight-bold">
									{{ selectedEvent.fare | commaFormat }} 원
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-card-text>
					<v-card-actions class="d-flex justify-end">
						<v-btn color="primary" @click="selectedOpen = false">닫기</v-btn>
					</v-card-actions>
				</v-card>
			</v-menu>
		</v-sheet>
	</div>
</template>
<script>
import { onMounted, ref, computed } from '@vue/composition-api'

import dayjs from 'dayjs'

import { getVuetify } from '@core/utils'

import {
	resolveReservationStatus,
	resolveReservationStatusVariant,
} from '@core/utils/filter'
import { convertUTC2KST } from '@/filter'
import { warningSwal } from '@/plugins/swalMixin'

import MarketLocationService from '@/services/MarketLocationService'

export default {
	components: {},
	props: {
		marketLocationId: {
			type: [String, Number],
			required: true,
		},
	},
	setup(props) {
		const $vuetify = getVuetify()
		const title = ref('IT MARKETER 캘린더')
		const focus = ref('')
		const type = ref('month')
		const typeToLabel = ref({
			month: '월',
			week: '주',
			day: '일',
			'4day': '4일',
		})
		const selectedEvent = ref({})
		const selectedElement = ref(null)
		const selectedOpen = ref(false)
		const events = ref([])
		const colors = ref([
			'#2196F3',
			'#3F51B5',
			'#673AB7',
			'#00BCD4',
			'#4CAF50',
			'#FF9800',
			'#757575',
		])
		const lastDayInMonth = dayjs().daysInMonth()
		const names = ref([''])
		const dragEvent = ref(null)
		const dragStart = ref(null)
		const createEvent = ref(null)
		const createStart = ref(null)
		const extendOriginal = ref(null)
		const calendar = ref(null)
		const startDateTime = ref(dayjs().format('YYYY-MM-01 00:00:00'))
		const endDateTime = ref(
			dayjs().format(`YYYY-MM-${lastDayInMonth} 00:00:00`),
		)
		const reservations = ref([])

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const rnd = (a, b) => {
			return Math.floor((b - a + 1) * Math.random()) + a
		}

		const getMarketLocationReservations = async () => {
			try {
				const data = await MarketLocationService.getMarketLocationReservations({
					marketLocationId: props.marketLocationId,
					beginDate: startDateTime.value,
					endDate: endDateTime.value,
				})

				const formatArr = data.map(arr => ({
					id: arr['id'],
					name: arr['title'],
					start: convertUTC2KST(arr['beginDate']).substring(0, 16),
					end: convertUTC2KST(arr['endDate']).substring(0, 16),
					numberOfPeople: arr['numberOfPeople'],
					status: arr['status'],
					fare: arr['fare'],
					isActive: arr['isActive'],
					color: colors.value[rnd(0, colors.value.length - 1)],
					userName: arr['user']['name'],
					phoneNumber: arr['user']['phoneNumber'],
				}))

				events.value = formatArr
			} catch (e) {
				warningSwal('예약 목록을 불러오는데 문제가 발생했습니다.')
			}
		}

		const viewDay = ({ date }) => {
			focus.value = date
			type.value = 'day'
		}

		const setToday = () => {
			focus.value = ''
		}

		const showEvent = ({ nativeEvent, event }) => {
			const open = () => {
				selectedEvent.value = event
				selectedElement.value = nativeEvent.target
				requestAnimationFrame(() =>
					requestAnimationFrame(() => (selectedOpen.value = true)),
				)
			}

			if (selectedOpen.value) {
				selectedOpen.value = false
				requestAnimationFrame(() => requestAnimationFrame(() => open()))
			} else {
				open()
			}

			nativeEvent.stopPropagation()
		}

		const getEventColor = event => {
			const rgb = parseInt(event?.color?.substring(1), 16)
			const r = (rgb >> 16) & 0xff
			const g = (rgb >> 8) & 0xff
			const b = (rgb >> 0) & 0xff

			return event === dragEvent.value
				? `rgba(${r}, ${g}, ${b}, 0.7)`
				: event === createEvent.value
				? `rgba(${r}, ${g}, ${b}, 0.7)`
				: event.color
		}

		const prev = async () => {
			try {
				calendar.value.prev()

				startDateTime.value = dayjs(startDateTime.value)
					.subtract(1, 'month')
					.format('YYYY-MM-DD 00:00:00')

				const _lastDayInMonth = dayjs(startDateTime.value).daysInMonth()
				endDateTime.value = dayjs(startDateTime.value).format(
					`YYYY-MM-${_lastDayInMonth} 00:00:00`,
				)

				await getMarketLocationReservations()
			} catch (e) {
				console.error(e)
			}
		}

		const next = async () => {
			try {
				calendar.value.next()

				startDateTime.value = dayjs(startDateTime.value)
					.add(1, 'month')
					.format('YYYY-MM-DD 00:00:00')

				const _lastDayInMonth = dayjs(startDateTime.value).daysInMonth()
				endDateTime.value = dayjs(startDateTime.value).format(
					`YYYY-MM-${_lastDayInMonth} 00:00:00`,
				)

				await getMarketLocationReservations()
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(() => {
			getMarketLocationReservations()
			calendar.value.checkChange()
		})

		return {
			title,
			focus,
			type,
			isSmAndDown,
			events,
			colors,
			names,
			calendar,
			dragEvent,
			dragStart,
			createEvent,
			createStart,
			typeToLabel,
			reservations,
			selectedOpen,
			selectedEvent,
			selectedElement,
			extendOriginal,

			resolveReservationStatus,
			resolveReservationStatusVariant,

			prev,
			next,
			viewDay,
			setToday,
			showEvent,
			getEventColor,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.v-calendar-weekly__day.v-past {
		background: #f2f2f2 !important;
		opacity: 0.9;
	}
}

@media screen and (max-width: 768px) {
	div[role='menu'] {
		overflow-x: scroll;
		width: 100%;
	}
}
</style>
