<template>
	<v-card elevation="0">
		<v-card-title v-if="marketLocation">
			<router-link
				:to="{
					name: 'market-locations-detail',
					params: {
						marketLocationId: marketLocationId,
					},
				}"
				class="text-decoration-none"
			>
				{{ marketLocation.name }}
			</router-link>
			<span class="mx-1">&#62;</span>
			예약된 스케쥴
		</v-card-title>
		<v-divider />
		<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
			<v-tab v-for="(tab, index) in tabs" :key="'tab-' + index">
				{{ tab }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item>
				<market-location-detail-reservation-list
					:market-location-id="marketLocationId"
				/>
			</v-tab-item>
			<v-tab-item>
				<market-location-detail-reservation-calendar
					:market-location-id="marketLocationId"
				/>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>
<script>
import { useRouter } from '@core/utils'
import { ref, reactive, onMounted } from '@vue/composition-api'

import MarketLocationService from '@/services/MarketLocationService'

import MarketLocationDetailReservationCalendar from './MarketLocationDetailReservationCalendar.vue'
import MarketLocationDetailReservationList from './MarketLocationDetailReservationList.vue'

export default {
	components: {
		MarketLocationDetailReservationCalendar,
		MarketLocationDetailReservationList,
	},
	setup() {
		const { route } = useRouter()
		const tab = ref(null)
		const tabs = reactive(['예약자 목록', '달력으로 보기'])
		const marketLocation = ref(null)
		const marketLocationId = ref(route.value.params.marketLocationId)

		const getMarketLocation = async () => {
			try {
				marketLocation.value = await MarketLocationService.getMarketLocation(
					marketLocationId.value,
				)
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(async () => {
			getMarketLocation()
		})

		return {
			tab,
			tabs,
			marketLocation,
			marketLocationId,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.v-expansion-panel {
		&::before {
			box-shadow: none !important;
		}
	}
}
.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
	box-shadow: none !important;
}
.v-tab {
	margin-left: 0 !important;
}
</style>
