import axios from '@/api/axios'

const userService = {
	getNotice(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getNoticeDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/${id}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listNotices() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/for-marketer?order=desc&orderby=createdAt`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLatestNotice() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/for-marketer?order=desc&orderby=createdAt&limit=1`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLatestNotices() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/for-marketer?order=desc&orderby=createdAt&limit=5`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createNotice(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/notices',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateNotice(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/notices/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteNotice(noticeId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/notices/${noticeId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteNoticeFile({ noticeId, fileId }) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/notices/${noticeId}/files/${fileId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	uploadNoticeFile(noticeId, formData) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/notices/${noticeId}/files`,
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default userService
