<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{ name: 'market-post-subject-list', query: { ...query } }"
				class="text-decoration-none"
			>
				주제 리스트
			</router-link>
			<span class="mx-1">&#62;</span>
			<span class="text--primary">주제 등록</span>
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<validation-observer ref="observer" v-slot="{ invalid }">
				<market-post-subject-form :form.sync="form" />
				<div class="d-flex justify-end pb-6">
					<v-btn
						color="primary"
						:disabled="invalid"
						@click.stop="updateOne"
						class="mr-1"
						v-if="form.id"
					>
						등록
					</v-btn>

					<v-btn color="error" @click.stop="deleteOne" v-if="form.id">
						삭제
					</v-btn>
				</div>
			</validation-observer>
		</v-card-text>
	</v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import dayjs from 'dayjs'

import { postType } from '@/helpers'
import { useRouter } from '@core/utils'
import { convertUTC2KST } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketPostSubjectService from '@/services/MarketPostSubjectService'

import MarketPostSubjectForm from './components/MarketPostSubjectForm.vue'

export default {
	components: {
		MarketPostSubjectForm,
	},
	setup() {
		const { route, router } = useRouter()
		const query = ref(route.value.query)
		const subjectId = ref(route.value.params.subjectId)
		const form = ref({
			id: null,
			type: postType.CONTEST2.value,
			categories: [],
			lounge: '',
			title: '',
			content: '',
			visible: false,
			startDate: dayjs().format('YYYY-MM-DD'),
			endDate: dayjs().format('YYYY-MM-DD'),
		})

		const getOne = async () => {
			let data = null
			if (!subjectId.value) {
				const payload = { ...form.value }
				delete payload.lounge
				delete payload.categories

				data = await MarketPostSubjectService.create(payload)
				router.push({
					name: 'market-post-subject-detail',
					params: { subjectId: data.id },
				})
			} else {
				data = await MarketPostSubjectService.get(subjectId.value)
			}

			form.value = {
				...form.value,
				...data,
			}

			form.value.startDate = convertUTC2KST(form.value.startDate).substring(
				0,
				10,
			)
			form.value.endDate = convertUTC2KST(form.value.endDate).substring(0, 10)
		}

		const updateOne = async () => {
			const confirm = await confirmSwal('주제를 등록하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const payload = { ...form.value }

					// 출력되지 않는 문자 제거
					// https://darchuk.net/2017/10/13/removing-non-printable-characters-in-javascript/
					payload.title.replaceAll(
						// eslint-disable-next-line no-control-regex
						/[\u0000-\u0008,\u000A-\u001F,\u007F-\u00A0]+/g,
						'',
					)

					payload.startDate =
						dayjs(`${payload.startDate} 00:00:00`)
							.utc()
							.format()
							.toString()
							.replace('Z', '') + '.000+00:00'
					payload.endDate =
						dayjs(`${payload.endDate} 23:59:59`)
							.utc()
							.format()
							.toString()
							.replace('Z', '') + '.000+00:00'

					payload.categories = payload.categories?.join()

					await MarketPostSubjectService.update(payload.id, payload)
					successSwal('주제 등록이 완료되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400
								? e.response.data.message
								: '주제를 등록하는데 문제가 발생했습니다.',
					})
				}
			}
		}

		const deleteOne = async () => {
			const confirm = await confirmSwal('주제를 삭제하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketPostSubjectService.delete(form.value.id)
					await successSwal('주제가 삭제되었습니다')
					router.push({ name: 'market-post-subject-list' })
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '주제를 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		onMounted(() => {
			getOne()
		})

		return {
			form,
			query,

			updateOne,
			deleteOne,
		}
	},
}
</script>
