<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{ name: 'market-course-list' }"
				class="text-decoration-none"
			>
				강의 리스트
			</router-link>
			<span class="mx-1">&#62;</span>
			강의 등록
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<validation-observer ref="observer" v-slot="{ valid }">
				<market-course-form :form="form" />
				<div class="d-flex justify-end pb-6">
					<v-btn
						color="primary"
						:disabled="!valid"
						@click.stop="createMarketCourse"
					>
						등록
					</v-btn>
				</div>
			</validation-observer>
		</v-card-text>
	</v-card>
</template>

<script>
import { reactive } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketCourseService from '@/services/MarketCourseService'

import MarketCourseForm from './components/MarketCourseForm.vue'

export default {
	components: {
		MarketCourseForm,
	},
	setup() {
		const { router } = useRouter()
		const form = reactive({
			title: '',
			body: '',
			description: '',
			location: '',
			amount: 0,
			files: [],
			startRegistrationDate: '',
			endRegistrationDate: '',
		})

		const createMarketCourse = async () => {
			const confirm = await confirmSwal('과정을 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketCourseService.createMarketCourse(form)
					await successSwal('과정 등록이 완료되었습니다')
					router.push({ name: 'market-course-list' })
				} catch (e) {
					await warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '과정을 등록하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			form,

			createMarketCourse,
		}
	},
}
</script>
