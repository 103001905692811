import axios from '@/api/axios'

const marketLicenseService = {
	listLicenses() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLicense(licenseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-licenses/${licenseId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createLicense(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-licenses',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateLicense(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-licenses/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteLicense(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-licenses/${payload}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listLicenseTestRounds(licenseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-licenses/${licenseId}/test-rounds`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createTestRound(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-licenses/${payload.licenseId}/test-rounds`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateTestRound(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-license-test-rounds/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteTestRound(testRoundId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-license-test-rounds/${testRoundId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listTestRoundsDetail() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses/test-rounds/detail',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getTestRoundUserTakeDetail() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-license-test-round-user-take/detail',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listTestRoundWorkbooks(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-test-rounds/${id}/workbooks`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateTestRoundWorkbooks(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-license-test-rounds/${payload.id}/workbooks`,
					data: payload.answerSheet,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createTestRoundWorkbook(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-license-test-rounds/${payload.licenseTestRoundId}/workbooks`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateTestRoundWorkbook(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-license-workbooks/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteTestRoundWorkbook(workbookId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-license-workbooks/${workbookId}}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getWorkbook(workbookId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-workbooks/${workbookId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteWorkbook(workbookId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-license-workbooks/${workbookId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getTestRoundUserTakeMe() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses/test-rounds/me',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createWorkbookUserTest(id, _answerSheet) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-license-workbooks/${id}/user-tests`,
					data: {
						answerSheet: _answerSheet,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getWorkbookUserTest(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-workbooks/${payload.id}/user-tests/users/${payload.userId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketLicenseService
