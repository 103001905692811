import Vue from 'vue'

const lazyLoad = {
	inserted: el => {
		const options = {
			threshold: 0.1,
		}

		function loadImage() {
			if (el.nodeName === 'IMG') {
				el.src = el.dataset.src
			} else if (el.nodeName === 'DIV') {
				el.style.backgroundImage = `url(${el.dataset.src})`
			}
		}

		function createObserver() {
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						// 감지대상이 교차영역에 진입 할 경우
						loadImage() // 교차영역 들어올경우 이미지 로딩
						observer.unobserve(el) // 이미지 로딩 이후론 관찰할 필요 x
					}
				})
			}, options)

			observer.observe(el)
		}

		// 지원하지 않는 브라우저는 바로 이미지 로딩을 시켜주도록 호환성 체크
		window['IntersectionObserver'] ? createObserver() : loadImage()
	},
}

Vue.directive('lazyload', lazyLoad)
