import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const auth = {
	name: 'auth',
	namespaced: true,
	state: {
		token: null,
		refreshToken: null,
		firebaseUser: null,
	},
	getters: {
		isSignin: state => !!state.token,
		getFirebaseUser(state) {
			return state.firebaseUser
		},
	},
	mutations: {
		signin(state, data) {
			state.token = data.token
			state.refreshToken = data.refreshToken
		},
		signout(state) {
			for (const k in state) {
				state[k] = null
			}
		},
		firebaseUser(state, data) {
			state.firebaseUser = data
		},
	},
	actions: {
		signin({ commit }, data) {
			commit('signin', data)
		},
		signout({ commit }) {
			commit('signout')
		},
		firebaseUser({ commit }, data) {
			commit('firebaseUser', data)
		},
	},
}

export default auth
