<template>
	<v-dialog
		v-model="isOpenLicenseEditTestRoundWorkbookDialog"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				시험지 수정
				<v-spacer />
				<v-btn
					icon
					@click="
						$emit(
							'update:is-open-license-edit-test-round-workbook-dialog',
							false,
						)
					"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<license-test-round-workbook-form :form.sync="form" />
					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							class="mr-1"
							:disabled="!valid"
							@click.stop="updateTestRoundWorkbook"
						>
							수정
						</v-btn>
						<v-btn color="error" @click.stop="deleteTestRoundWorkbook">
							삭제
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseTestRoundWorkbookForm from './components/LicenseTestRoundWorkbookForm.vue'

export default {
	props: {
		isOpenLicenseEditTestRoundWorkbookDialog: {
			type: Boolean,
			required: true,
		},
		selectedTestRound: {
			type: Object,
		},
		selectedWorkbook: {
			type: Object,
		},
	},
	components: {
		LicenseTestRoundWorkbookForm,
	},
	setup(props, { emit }) {
		const form = ref({
			id: '',
			licenseId: null,
			licenseTestRoundId: null,
			title: '',
			body: '',
			problemCount: null,
			minPassScore: null,
			answer: null,
			visible: true,
		})
		watch(
			() => props.isOpenLicenseEditTestRoundWorkbookDialog,
			currentValue => {
				if (currentValue) {
					form.value = { ...props.selectedWorkbook }
				}
			},
		)

		const updateTestRoundWorkbook = async () => {
			const confirm = await confirmSwal('시험지를 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLicenseService.updateTestRoundWorkbook(form.value)
					emit('is-fetch-workbooks', true)
					successSwal('시험지 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '시험지를 수정하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-license-edit-test-round-workbook-dialog', false)
				}
			}
		}

		const deleteTestRoundWorkbook = async () => {
			const confirm = await confirmSwal('시험지를 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }
					await MarketLicenseService.deleteWorkbook(data.id)
					emit('is-fetch-workbooks', true)
					successSwal('시험지 삭제가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '시험지를 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-license-edit-test-round-workbook-dialog', false)
				}
			}
		}

		return {
			form,

			updateTestRoundWorkbook,
			deleteTestRoundWorkbook,
		}
	},
}
</script>
