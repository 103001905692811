<template>
	<v-card elevation="0">
		<v-card-title class="d-flex justify-space-between">
			주문 리스트

			<v-select
				item-text="name"
				item-value="value"
				:items="itemsPerPageGroups"
				label="페이지당 개수"
				@change="changeItemsPerPage"
				:value="10"
				style="max-width: 100px"
				hide-details
				class="mr-1"
				dense
				outlined
			/>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="marketOrders"
			no-data-text="조회된 주문이 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text="주문을 불러오는 중입니다..."
			hide-default-footer
			@click:row="routeToDetail($event.id)"
		>
			<template v-slot:[`item.paid`]="{ item }">
				<v-chip :color="item.paid ? 'primary' : 'secondary'" small>
					{{ item.paid ? '결제 완료' : '결제 대기' }}
				</v-chip>
			</template>
			<template v-slot:[`item.amount`]="{ item }">
				{{ item.amount | commaFormat }}
			</template>
			<template v-slot:[`item.orderedAt`]="{ item }">
				{{ item.orderedAt | dateKOSimpleSiBunChoFormat }}
			</template>
			<template v-slot:[`item.canceled`]="{ item }">
				<v-chip color="error" small v-if="item.canceled">취소 완료</v-chip>
				<span v-else>-</span>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn color="secondary" @click.stop="deleteOrder(item)" small>
					주문 삭제
				</v-btn>
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
	</v-card>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketOrderService from '@/services/MarketOrderService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const isLoading = ref(false)
		const marketOrders = ref([])
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const headers = ref([
			{ text: '결제 여부', value: 'paid' },
			{ text: '취소 여부', value: 'canceled' },
			{
				text: '주문명',
				align: 'left',
				value: 'title',
			},
			{ text: '금액', value: 'amount' },
			{ text: '주문 날짜', value: 'orderedAt' },
			{ text: '', value: 'actions' },
		])
		const itemsPerPageGroups = reactive([
			{
				name: '10',
				value: 10,
			},
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])

		const listMarketOrders = async () => {
			try {
				const result = await MarketOrderService.listMarketOrders({
					page: currentPage.value - 1,
					limit: itemsPerPage.value,
				})

				totalPages.value = parseInt(result.headers['x-total-pages-count'])
				marketOrders.value = result.data
			} catch (e) {
				console.error(e)
			}
		}
		listMarketOrders()

		const routeToDetail = marketOrderId => {
			router.push({
				name: 'market-orders-detail',
				params: { marketOrderId: marketOrderId },
			})
		}

		const deleteOrder = async order => {
			const confirm = await confirmSwal(
				` ${order.title} 주문 내역을<br/> 삭제하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketOrderService.deleteMarketOrder(order.id)
					await successSwal('주문 내역이 삭제되었습니다')
					await listMarketOrders()
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '주문 내역을 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const changeItemsPerPage = async limit => {
			itemsPerPage.value = limit
			await listMarketOrders()
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await listMarketOrders()
		}

		return {
			isLoading,
			marketOrders,
			headers,
			currentPage,
			totalPages,
			itemsPerPageGroups,

			deleteOrder,
			routeToDetail,
			changeItemsPerPage,
			handlePageChange,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
