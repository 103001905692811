<template>
	<swiper
		ref="swiperRef"
		class="swiper"
		v-if="isSliderReady"
		:options="swiperOption"
	>
		<swiper-slide
			v-for="(file, index) in virtualData.slides"
			:key="file.id"
			:virtual-index="file.id"
			:style="{ left: virtualData.offset + 'px' }"
		>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						x-small
						absolute
						v-bind="attrs"
						v-on="on"
						color="primary"
						@click.stop="downloadFile(file)"
						style="left: 5px; top: 5px; z-index: 99"
						v-if="!isCart"
					>
						<v-icon small>mdi-download</v-icon>
					</v-btn>
				</template>
				<span>개별 다운로드{{ index }}</span>
			</v-tooltip>

			<v-chip
				small
				:color="resolveContentsMarketStatusVariant(post.result)"
				class="status-chip"
			>
				<span
					:class="{
						'd-none':
							post.result === contentsMarketStatus.APPROVED.value &&
							isSmAndDown,
					}"
				>
					{{ resolveContentsMarketStatus(post.result) }}
					<v-btn x-small fab v-if="isDetailContent" class="d-none">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</span>
				<span
					v-if="post.result === contentsMarketStatus.APPROVED.value"
					class="ml-1"
				>
					<strong v-if="post && post.resultJson">
						{{ post.resultJson.payment | commaFormat }}
					</strong>
					<strong v-else>-</strong>
					원
				</span>
			</v-chip>

			<v-chip small absolute right style="top: 40px" v-if="!isImage(file.url)">
				<v-icon>mdi-movie-play-outline</v-icon>
			</v-chip>
			<div
				v-if="isImage(file.url)"
				class="text-center cursor-pointer content-wrapper"
			>
				<img
					v-lazyload
					:style="
						isCart
							? 'width: 100%; height: 160px; object-fit: contain;'
							: 'height: 300px'
					"
					:class="isDetailContent ? 'detail-content' : 'content'"
					:data-src="`${renderUrl(file.url)}`"
					@error="$event.target.src = file.url"
					@click="$emit('open-media-dialog', file.url)"
					loading="lazy"
				/>
			</div>
			<div class="content-wrapper" v-else>
				<video
					:style="
						isCart
							? 'width: 100%; height: 160px; object-fit: contain;'
							: 'width: 100%; height: 300px'
					"
					controls
					controlsList="nodownload"
				>
					<source
						:src="file.url"
						:class="isDetailContent ? 'detail-content' : 'content'"
					/>
				</video>
			</div>
			<v-btn small text class="arrow-button-first" @click="moveToFirst()">
				처음
			</v-btn>
			<v-btn small text class="arrow-button-last" @click="moveToLast()">
				끝
			</v-btn>
		</swiper-slide>
		<div class="swiper-pagination" slot="pagination"></div>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</swiper>
</template>
<script>
import { ref, watch, computed, onMounted, nextTick } from '@vue/composition-api'

import {
	resolveContentsMarketStatus,
	resolveContentsMarketStatusVariant,
} from '@core/utils/filter'
import { getVuetify } from '@core/utils'
import { contentsMarketStatus } from '@/helpers'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		post: {
			type: Object,
		},
		isDetailContent: {
			type: Boolean,
			default: false,
		},
		isCart: {
			type: Boolean,
			default: false,
		},
		isReplaceImgSrcEmpty: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const $vuetify = getVuetify()
		const swiperRef = ref(null)
		const virtualData = ref({
			slides: [],
		})
		const isSliderReady = ref(false)
		const swiperOption = ref({
			slidesPerView: 1,
			spaceBetween: 10,
			loop: false,
			lazy: {
				loadPrevNext: true,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'fraction',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		})

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const toDataURL = async url => {
			const blob = await fetch(url).then(res => res.blob())
			return URL.createObjectURL(blob)
		}

		const createDownloadLink = async file => {
			const link = document.createElement('a')
			link.href = await toDataURL(file.url)
			link.download = file.fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}

		const downloadFile = async postFile => {
			await createDownloadLink(postFile)
		}

		const isImage = url => {
			return url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
		}

		const contentDisplay = style => {
			const elements = document.querySelectorAll(
				'div.content-wrapper .detail-content',
			)
			elements.forEach(element => {
				element.src = ''
				element.style.display = style
				element.parentElement.style.display = style

				if (style === 'none') {
					element.removeAttribute('src')
					element.removeAttribute('data-src')
				}
			})
		}

		const renderUrl = fileUrl => {
			if (fileUrl.indexOf('https://factory-data-history.') > -1) {
				return (
					fileUrl.replace(
						'https://factory-data-history.',
						'https://factory-data-history-600.',
					) +
					'?t=' +
					Date.now()
				)
			}
		}

		const moveToFirst = () => {
			swiperRef.value.$swiper.slideTo(0)
		}
		const moveToLast = () => {
			const index = props.post.files.length - 1
			swiperRef.value.$swiper.slideTo(index)
		}

		watch(
			() => props.post,
			currentvalue => {
				if (currentvalue) {
					isSliderReady.value = true

					contentDisplay('block')

					swiperOption.value.virtual = {
						slides: props.post.files,
						renderExternal: data => {
							virtualData.value = { ...data }
						},
					}

					if (swiperRef.value) {
						swiperRef.value.initSwiper()

						swiperRef.value.$swiper.slideTo(0)
					}
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => props.isReplaceImgSrcEmpty,
			currentvalue => {
				if (currentvalue) {
					contentDisplay('none')
					emit('update:is-replace-img-src-empty', false)
				}
			},
		)

		onMounted(() => {
			nextTick(() => {
				swiperRef.value.initSwiper()
			})
		})

		return {
			swiperRef,
			virtualData,
			isSmAndDown,
			swiperOption,
			isSliderReady,
			contentsMarketStatus,

			isImage,
			renderUrl,
			moveToFirst,
			moveToLast,
			resolveContentsMarketStatus,
			resolveContentsMarketStatusVariant,

			downloadFile,
		}
	},
}
</script>
<style lang="scss" scoped>
.status-chip {
	position: absolute;
	right: 5px;
	top: 5px;
}

.content-wrapper {
	img {
		margin: 0 auto;
	}
}

.arrow-button-first,
.arrow-button-last {
	position: absolute;
	bottom: 0px;
}
.arrow-button-first {
	left: 0px;
}
.arrow-button-last {
	right: 0px;
}

.swiper-button-prev,
.swiper-button-next {
	color: #bfbfbf;
}

.swiper-pagination {
	position: absolute;
	left: 50%;
	bottom: 12px;
	transform: translate(-50%);
	margin: 0;
	display: inline-block;
	width: 25%;
	min-width: 100px;
	max-width: 100%;
	background-color: rgb(217, 217, 217, 0.5);
	text-align: center;
	padding: 6px 4px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: black;
	font-weight: 500;
}
</style>
