<template>
	<div>
		<v-card>
			<v-simple-table fixed-header height="560px">
				<template v-slot:default>
					<thead>
						<tr>
							<td class="text-center">제목</td>
							<td class="text-center">등록일</td>
						</tr>
					</thead>
					<tbody>
						<template v-if="notices.length > 0">
							<tr
								v-for="(item, index) in notices"
								:key="index"
								class="cursor-pointer"
								@click="openDialog(item)"
								:style="{ backgroundColor: !item.title ? '#e6f7ff' : '' }"
							>
								<td>
									{{ item.title }}
									<strong v-if="!item.title || !item.content">
										현재 작성 중인 공지사항입니다
									</strong>
								</td>
								<td>{{ item.createdAt | dateFormat }}</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<v-card-actions class="d-flex justify-end mt-5">
				<v-btn @click="registerNotice" color="primary">공지사항 등록</v-btn>
			</v-card-actions>
			<notice-register-dialog
				:notice-id="selectedNoticeId"
				:is-open-notice-register-dialog.sync="isOpenNoticeRegisterDialog"
				@is-fetch-notices="isFetchNotices = $event"
			/>
			<notice-edit-dialog
				:selected-notice-id="selectedNoticeId"
				:is-open-notice-edit-dialog.sync="isOpenNoticeEditDialog"
				@is-fetch-notices="isFetchNotices = $event"
			/>
		</v-card>
	</div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

import NoticeService from '@/services/NoticeService'

import { warningSwal } from '@/plugins/swalMixin'

import NoticeRegisterDialog from './NoticeRegisterDialog.vue'
import NoticeEditDialog from './NoticeEditDialog.vue'

export default {
	components: {
		NoticeRegisterDialog,
		NoticeEditDialog,
	},
	setup() {
		const notices = ref([])
		const selectedNoticeId = ref(null)
		const isFetchNotices = ref({})
		const isOpenNoticeRegisterDialog = ref(false)
		const isOpenNoticeEditDialog = ref(false)

		const listNotices = async () => {
			try {
				notices.value = await NoticeService.listNotices()
			} catch (e) {
				console.error(e)
			}
		}
		listNotices()

		const openDialog = item => {
			selectedNoticeId.value = item.id
			isOpenNoticeEditDialog.value = !isOpenNoticeEditDialog.value
		}

		const registerNotice = async () => {
			if (!notices.value[0].title || !notices.value[0].content) {
				const confirm = await warningSwal('현재 작성중인 글이 있습니다.')
				if (confirm.isConfirmed) {
					selectedNoticeId.value = notices.value[0].id
				}
			}

			isOpenNoticeRegisterDialog.value = !isOpenNoticeRegisterDialog.value
		}

		watch(
			() => isFetchNotices.value,
			currentValue => {
				if (currentValue) {
					listNotices()
					isFetchNotices.value = false
				}
			},
		)

		return {
			notices,
			selectedNoticeId,
			isFetchNotices,
			isOpenNoticeRegisterDialog,
			isOpenNoticeEditDialog,

			registerNotice,
			openDialog,
		}
	},
}
</script>
