export default [
	{
		path: '/notices',
		name: 'notices',
		component: () => import('@/pages/notice/NoticeList.vue'),
		meta: {
			layout: 'LayoutContent',
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
