<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{ name: 'market-locations-list' }"
				class="text-decoration-none"
			>
				라운지
			</router-link>
			<span class="mx-1">&#62;</span>
			라운지 등록
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<validation-observer ref="observer" v-slot="{ valid }">
				<market-location-form :form="form" />
				<div class="d-flex justify-end pb-6">
					<v-btn
						color="primary"
						:disabled="!valid"
						@click.stop="createMarketLocation"
					>
						등록
					</v-btn>
				</div>
			</validation-observer>
		</v-card-text>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLocationService from '@/services/MarketLocationService'

import MarketLocationForm from './components/MarketLocationForm.vue'

export default {
	components: {
		MarketLocationForm,
	},
	setup() {
		const { router } = useRouter()
		const form = ref({
			name: '',
			address: '',
			introduction: '',
			phoneNumber: '',
			baseFareAnHourWon: 0,
			maxNumberOfPeople: 0,
		})

		const createMarketLocation = async () => {
			const confirm = await confirmSwal('라운지를 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLocationService.createMarketLocation(form.value)
					await successSwal('라운지 등록이 완료되었습니다')
					router.push({ name: 'market-locations-list' })
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '라운지를 등록하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			form,
			createMarketLocation,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
