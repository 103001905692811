<template>
	<v-container fill-height fluid>
		<v-row align="center" justify="center">
			<v-col>
				<div class="Signup mx-auto">
					<div class="Signin mx-auto">
						<v-card
							elevation="4"
							class="d-flex flex-column mx-auto pa-5"
							max-width="348"
						>
							<v-card-title class="px-0 pt-0">
								<h1 class="heading">{{ $t('loginForm.title') }}</h1>
							</v-card-title>
							<div>
								<validation-observer ref="observer">
									<form @submit.prevent="emailSignin">
										<validation-provider v-slot="{ errors }" name="id">
											<v-text-field
												v-model="signinForm.id"
												:error-messages="errors"
												:label="$t('loginForm.id')"
												autocomplete="off"
											/>
										</validation-provider>
										<validation-provider v-slot="{ errors }" name="password">
											<v-text-field
												v-model="signinForm.password"
												type="password"
												:error-messages="errors"
												:label="$t('loginForm.password')"
												@keyup="checkCapsLock"
												autocomplete="off"
											>
												<template v-slot:append>
													<div
														v-if="isCapsLockOn"
														class="d-flex flex-column justify-center"
														style="position: relative; bottom: 4px"
													>
														<v-icon>mdi-arrow-up-bold-box-outline</v-icon>
														<strong style="font-size: 8px">CapsLock</strong>
													</div>
												</template>
											</v-text-field>
										</validation-provider>
										<v-btn
											class="button email-login full-width mb-3"
											type="submit"
											width="100%"
											height="50"
											color="primary"
											:disabled="!isSubmitValid"
											:loading="signinForm.loading"
										>
											{{ $t('loginForm.submit') }}
										</v-btn>
									</form>
								</validation-observer>
							</div>
						</v-card>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import { useRouter } from '@core/utils'
import { errorSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

export default {
	setup(_, { emit }) {
		const { router } = useRouter()
		const isCapsLockOn = ref(false)
		const signinForm = reactive({
			id: '',
			password: '',
			loading: false,
		})

		const isSubmitValid = computed(() => {
			return signinForm.id.length > 0 && signinForm.password.length > 0
		})

		const emailSignin = async () => {
			signinForm.loading = true
			try {
				signinForm.id = signinForm.id
					.trim()
					.replace(/\t/g, '')
					.replace(/\r\n/g, '')

				await AuthService.emailSignin(signinForm)
				await AuthService.init()
				router.push({ name: 'licenses' })
			} catch (error) {
				errorSwal({
					title: i18n.t('loginForm.submitMsg.fail.title'),
					html: i18n.t('loginForm.submitMsg.fail.html'),
				})
			} finally {
				signinForm.loading = false
				emit('update:is-open-signin-dialog', false)
			}
		}

		const checkCapsLock = $event => {
			$event.getModifierState('CapsLock')
				? (isCapsLockOn.value = true)
				: (isCapsLockOn.value = false)
		}

		store.dispatch('app/setIsLogoWhite', false)

		return {
			signinForm,
			isCapsLockOn,

			isSubmitValid,

			emailSignin,
			checkCapsLock,
		}
	},
}
</script>

<style lang="scss" scoped>
.container {
	height: 100vh;
	background-color: white;
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.Signin {
	form {
		justify-content: center;
		text-align: center;

		.v-input {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.v-card {
		padding: 34px;
		border: 1px solid #e4e5ed;
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		.signup-form {
			width: 100%;
		}
	}

	h1.heading {
		font-size: 27px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
	}

	button {
		text-transform: none !important;
		letter-spacing: normal !important;
		font-size: 18px !important;
		font-weight: 900 !important;

		&.email-login {
			height: 44px !important;
			width: 400px;
		}
	}
}

.vertical-line {
	position: relative;
	top: 3px;
	height: 14px;
	width: 1px;
	margin: 0 10px;
	background-color: #dadada;
}
</style>
