<template>
	<div>
		<v-col cols="12" md="12" sm="12" class="d-flex align-center">
			<div class="d-flex align-center">
				<div class="d-flex align-center" style="min-width: 70px">
					<h2 class="mr-1">{{ questionNumber }}</h2>
					번 문제
				</div>
				<h1 class="ml-4">{{ getAnswerQuestion }}</h1>
			</div>
			<v-spacer />
			<v-btn
				color="primary"
				@click="
					isOpenLicenseTestRoundWorkbookQuestionBankDialog =
						!isOpenLicenseTestRoundWorkbookQuestionBankDialog
				"
			>
				문제 선택
			</v-btn>
		</v-col>
		<v-col cols="12" md="12" sm="12">
			<div class="d-flex flex-column">
				<label
					v-for="index in totalQuestionCount"
					:key="'question-' + index"
					class="wrapper items-center align-center d-flex"
				>
					<input
						type="radio"
						:name="'question' + questionNumber"
						:value="index"
						:checked="getCheckedNumber(index)"
						:disabled="isConfirm"
						@click="
							addAnswerSheet({
								questionNumber: questionNumber,
								answer: index,
							})
						"
					/>
					<span class="checkmark">{{ index }}</span>
					<span class="ml-12 mb-6">
						{{ getChoices(index) }}
					</span>
				</label>
			</div>
		</v-col>
		<v-divider />
		<license-test-round-workbook-question-bank-dialog
			:is-open-license-test-round-workbook-question-bank-dialog.sync="
				isOpenLicenseTestRoundWorkbookQuestionBankDialog
			"
			:question-number="questionNumber"
			@selected-question="selectedQuestion = $event"
		/>
	</div>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

import LicenseTestRoundWorkbookQuestionBankDialog from '../LicenseTestRoundWorkbookQuestionBankDialog.vue'

const TOTAL_QUESTION_COUNT = 4

export default {
	props: {
		isConfirm: {
			type: Boolean,
			default: false,
		},
		questionNumber: {
			type: Number,
			required: true,
		},
		workbookId: {
			type: Number,
			required: true,
		},
	},
	components: {
		LicenseTestRoundWorkbookQuestionBankDialog,
	},
	setup(props, { emit }) {
		const selectedQuestion = ref({})
		const totalQuestionCount = ref(TOTAL_QUESTION_COUNT)
		const isOpenLicenseTestRoundWorkbookQuestionBankDialog = ref(false)
		const answerSheet = ref(
			store.getters['license/getAnswerSheet'](props.workbookId),
		)

		const addAnswerSheet = ({ questionNumber, answer }) => {
			const data = {
				key: questionNumber - 1,
				value: answer,
			}
			emit('add-answer-sheet', data)
		}

		const getAnswerSheetIndex = () => {
			return answerSheet.value.findIndex(
				obj => obj.key === props.questionNumber - 1,
			)
		}

		const getCheckedNumber = _index => {
			if (answerSheet.value.length > 0) {
				const index = getAnswerSheetIndex()
				if (index !== -1) {
					return answerSheet.value[index].value === _index
				}
			} else {
				return false
			}
		}

		const getAnswerQuestion = computed(() => {
			if (answerSheet.value.length > 0) {
				const index = getAnswerSheetIndex()
				if (index !== -1) {
					return answerSheet.value[index].question
				}
			} else {
				return selectedQuestion.value.question
			}
		})

		const getChoices = index => {
			if (answerSheet.value.length > 0) {
				const answerSheetIndex = getAnswerSheetIndex()
				if (answerSheetIndex !== -1) {
					return answerSheet.value[answerSheetIndex].choices[index - 1]
				}
			} else {
				if (selectedQuestion.value.choices) {
					return selectedQuestion.value.choices[index - 1]
				} else {
					return ''
				}
			}
		}

		watch(
			() => selectedQuestion.value,
			currentValue => {
				if (currentValue) {
					const { questionNumber, question, choices } = currentValue
					const data = {
						key: questionNumber - 1,
						question: question,
						choices: choices,
					}
					emit('add-answer-sheet', data)
				}
			},
		)

		return {
			selectedQuestion,
			totalQuestionCount,
			getCheckedNumber,
			getAnswerQuestion,
			getChoices,
			isOpenLicenseTestRoundWorkbookQuestionBankDialog,

			addAnswerSheet,
		}
	},
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: block;
	position: relative;
	cursor: pointer;
	text-align: center;
	font-size: 22px;
	min-height: 55px;

	@media screen and (max-width: 768px) {
		width: 20%;
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* Hide the browser's default radio button */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		/* When the radio button is checked, add a blue background */
		&:checked ~ .checkmark {
			color: white;
			background-color: #333333;
			transition: 0.4s;
		}
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0 auto;
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background-color: #ffffff;
		border: 1px solid #ccc;
	}
}
</style>
