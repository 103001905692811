<template>
	<v-dialog v-model="isOpenOrderPaymentDetailDialog" persistent max-width="600">
		<v-card>
			<v-card elevation="0">
				<v-card-title>
					<span class="text-lg font-weight-bold">고객 정보</span>
					<v-spacer />
					<v-btn
						icon
						@click="$emit('update:is-open-order-payment-detail-dialog', false)"
					>
						<v-icon size="22">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider class="mb-5" />
				<v-card-text>
					<v-simple-table fixed-header>
						<template v-slot:default>
							<tbody>
								<tr>
									<td>이름</td>
									<td>{{ pgResponse.buyer_name }}</td>
									<td>전화번호</td>
									<td>{{ pgResponse.buyer_tel }}</td>
								</tr>
								<tr>
									<td>이메일</td>
									<td>{{ pgResponse.buyer_email }}</td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
			<v-card elevation="0">
				<v-card-title>
					<span class="text-lg font-weight-bold">결제 정보</span>
				</v-card-title>
				<v-divider class="mb-5" />
				<v-card-text>
					<v-simple-table fixed-header>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">
										<div>
											{{ pgResponse.name }}
										</div>
										<div>
											{{ pgResponse.merchant_uid }}
										</div>
									</th>
									<th class="text-right">
										<v-btn
											small
											:href="pgResponse.receipt_url"
											target="_blank"
											color="secondary"
										>
											승인 영수증
										</v-btn>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>결제상태</td>
									<td>{{ pgResponse.success }}</td>
								</tr>
								<tr>
									<td>아임포트 거래번호</td>
									<td>{{ pgResponse.imp_uid }}</td>
								</tr>
								<tr>
									<td>결제수단</td>
									<td>
										{{ resolvePaymentMethod(pgResponse.pay_method) }} |
										{{ pgResponse.vbank_name }}

										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													small
													icon
													color="primary"
													dark
													v-bind="attrs"
													v-on="on"
												>
													<v-icon>mdi-information</v-icon>
												</v-btn>
											</template>
											<ul class="pl-2">
												<li>
													{{ pgResponse.vbank_holder }}
												</li>
												<li>{{ pgResponse.vbank_name }}</li>
												<li>{{ pgResponse.vbank_num }}</li>
											</ul>
										</v-tooltip>
									</td>
								</tr>
								<tr>
									<td>총 결제금액</td>
									<td>
										{{ pgResponse.paid_amount | commaFormat }}
										{{ pgResponse.currency }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-card>
	</v-dialog>
</template>

<script>
import { resolvePaymentMethod } from '@core/utils/filter'

export default {
	props: {
		isOpenOrderPaymentDetailDialog: {
			type: Boolean,
			required: true,
		},
		pgResponse: {
			type: Object,
		},
	},
	components: {},
	setup() {
		return {
			resolvePaymentMethod,
		}
	},
}
</script>
