<template>
	<v-card style="height: 100%" class="px-3 mt-3">
		<v-row>
			<v-col cols="12" md="8" sm="12">
				<v-card elevation="4">
					<user-form :user="user" />
					<v-card-actions class="d-flex justify-end">
						<v-btn color="primary" @click="save">저장하기</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" md="4" sm="12">
				<v-expansion-panels multiple v-model="panel" class="px">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<span class="text-lg font-weight-bold">자격증 발급 정보</span>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table :mobile-breakpoint="0" fixed-header dense>
								<template v-slot:default>
									<thead>
										<tr style="background: #e6e6e6">
											<td class="text-center">자격증 명</td>
											<td class="text-center">기수</td>
											<td class="text-center">발급번호</td>
											<td class="text-center">발급일</td>
										</tr>
									</thead>
									<tbody>
										<template
											v-if="user.licenseIssues && user.licenseIssues.length > 0"
										>
											<tr
												v-for="(item, index) in user.licenseIssues"
												:key="index"
											>
												<td class="text-center">{{ item.license.title }}</td>
												<td class="text-center">{{ item.classNumber }}</td>
												<td class="text-center">{{ item.issueCode }}</td>
												<td class="text-center">
													{{ item.issuedAt | dateFormat }}
												</td>
											</tr>
										</template>
										<template v-else>
											<tr>
												<td colspan="4" class="text-center">
													자격증 발급 정보가 없습니다
												</td>
											</tr>
										</template>
									</tbody>
								</template>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header>
							<span class="text-lg font-weight-bold">나의 추천인 정보</span>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table fixed-header dense style="max-height: 320px">
								<template v-slot:default>
									<thead>
										<tr style="background: #e6e6e6">
											<td class="text-center">이메일</td>
											<td class="text-center">실명</td>
											<td class="text-center">유저명</td>
											<td class="text-center">추천일</td>
										</tr>
									</thead>
									<tbody>
										<template
											v-if="
												user.recommendMeUsers &&
												user.recommendMeUsers.length > 0
											"
										>
											<tr
												v-for="(item, index) in user.recommendMeUsers"
												:key="index"
											>
												<td class="text-center">{{ item.email }}</td>
												<td class="text-center">{{ item.name }}</td>
												<td class="text-center">{{ item.uid }}</td>
												<td class="text-center">
													{{ item.recommenderUpdatedAt | dateFormat }}
												</td>
											</tr>
										</template>
										<template v-else>
											<tr>
												<td colspan="4" class="text-center">
													나의 추천인 정보가 없습니다
												</td>
											</tr>
										</template>
									</tbody>
								</template>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<user-point-transfer :user="user" />
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import { marketerRole, factoryRole } from '@/helpers'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'

import UserForm from './components/UserForm.vue'
import UserPointTransfer from './UserPointTransfer.vue'

export default {
	components: {
		UserForm,
		UserPointTransfer,
	},
	props: {
		userId: {
			type: [String, Number],
			required: true,
		},
	},
	setup(props) {
		const user = ref({})
		const originProfile = ref(null)
		const panel = ref([0, 1])

		const getUser = async () => {
			const {
				id,
				publicPictureUrl,
				email,
				emailVerified,
				emailVerifiedAt,
				uid,
				name,
				birthDate,
				address,
				addressDetail,
				phoneNumber,
				phoneNumberVerified,
				phoneNumberVerifiedAt,
				recommenderUser,
				licenseIssues,
				recommendMeUsers,
				marketerRole,
				refundBankCode,
				refundBankAccount,
				registrationNumberFront,
				registrationNumberRear,
				adminMemo,
			} = await UserService.getUser(props.userId)

			user.value = {
				id,
				publicPictureUrl,
				email,
				emailVerified,
				emailVerifiedAt,
				uid,
				name,
				birthDate,
				address,
				addressDetail,
				phoneNumber,
				phoneNumberVerified,
				phoneNumberVerifiedAt,
				recommenderUser,
				licenseIssues,
				recommendMeUsers,
				marketerRole,
				refundBankCode,
				refundBankAccount,
				registrationNumberFront,
				registrationNumberRear,
				adminMemo,
			}

			originProfile.value = JSON.parse(JSON.stringify(user.value))
		}

		const save = async () => {
			const changedValueKey = []
			const payload = {
				id: user.value.id,
			}

			delete user.value.recommenderUser
			delete user.value.licenseIssues
			delete user.value.recommendMeUsers

			for (const key in user.value) {
				if (originProfile.value[key] !== user.value[key]) {
					payload[key] = user.value[key]
					changedValueKey.push(true)
				}
			}

			if (changedValueKey.indexOf(true) === -1) {
				warningSwal('변경된 정보가 없습니다')
				return
			}

			try {
				await UserService.updateUser(payload)
				const confirm = await successSwal('정보가 업데이트 되었습니다')
				if (confirm.isConfirmed) {
					await getUser()
				}
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '이벤트를 등록하는정보를 업데이트하는데 문제가 발생했습니다.',
				)
			}
		}

		onMounted(() => {
			getUser()
		})

		return {
			user,
			panel,
			marketerRole,
			factoryRole,

			save,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
