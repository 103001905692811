import dayjs from 'dayjs'
import axios from '@/api/axios'

const marketBalanceService = {
	listMarketBalances() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-balances',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketBalancesDetail({
		limit,
		page,
		beginDate,
		endDate,
		transactionType,
		userId = null,
	}) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (limit) {
				params['limit'] = limit
			}
			if (page >= 0) {
				params['page'] = page
			}

			if (beginDate && endDate) {
				params['begin_date'] = dayjs(beginDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['end_date'] = dayjs(endDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
			}

			if (transactionType?.length > 0) {
				transactionType.forEach(element => {
					params[element] = 1
				})
			}

			if (userId) {
				params['user_id'] = userId
			}

			try {
				const response = await axios({
					method: 'GET',
					url: '/market-balances/detail',
					params,
				})
				resolve({
					data: response.data,
					totalPages: response.headers['x-total-pages-count'],
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketBalancesWithdrawals() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-balances/detail?show_balance_withdrawal=1',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	payPoint(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-balances/actions/point',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketBalanceService
