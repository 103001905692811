<template>
	<v-card elevation="0">
		<v-card-title class="d-flex justify-space-between">
			<div>
				<router-link
					:to="{ name: 'market-course-list' }"
					class="text-decoration-none"
				>
					강의 리스트
				</router-link>
				<span class="mx-1">&#62;</span>
				{{ course.title }}
			</div>
			<div class="d-flex">
				<v-select
					item-text="name"
					item-value="value"
					:items="itemsPerPageGroups"
					label="페이지당 개수"
					@change="changeItemsPerPage"
					:value="10"
					style="max-width: 100px"
					hide-details
					class="mr-1"
					dense
					outlined
				/>
				<v-btn color="primary" @click="downloadUsersExcelFile">
					엑셀 다운로드
				</v-btn>
			</div>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="registrations"
			:items-per-page="itemsPerPage"
			item-key="email"
			no-data-text="조회된 수강자가 없습니다."
			class="elevation-1"
			:loading="isLoading"
			loading-text="수강자를 불러오는 중입니다..."
			hide-default-footer
		>
			<template v-slot:[`item.index`]="{ index }">
				{{ index + 1 }}
			</template>
			<template v-slot:[`item.name`]="{ item }">
				{{ item.user.name }}
			</template>
			<template v-slot:[`item.phone`]="{ item }">
				{{ item.user.phoneNumber | excludeInternationalPhoneFormat }}
			</template>
			<template v-slot:[`item.recommenderUser`]="{ item }">
				<span v-if="recommenderUserIds[item.user.recommenderUserId]">
					{{
						recommenderUserIds[item.user.recommenderUserId].recommenderUser.name
					}}
					({{
						recommenderUserIds[item.user.recommenderUserId].recommenderUser.uid
					}})
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:[`item.completionRate`]="{ item }">
				{{ Math.floor(item.completionRate) }} %
			</template>
			<template v-slot:[`item.finished`]="{ item }">
				<v-chip small :color="item.finished ? 'success' : 'secondary'">
					{{ item.finished ? '완료' : '미완료' }}
				</v-chip>
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
	</v-card>
</template>

<script>
import { ref, reactive, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import UserService from '@/services/UserService'
import MarketCourseService from '@/services/MarketCourseService'

export default {
	components: {},
	setup() {
		const { route } = useRouter()
		const marketCourseId = route.value.params.marketCourseId
		const recommenderUserIds = ref({
			32: {
				recommenderUserId: 20,
			},
		})
		const isLoading = ref(false)
		const registrations = ref([])
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const course = ref({})
		const headers = ref([
			{ text: 'no', value: 'index' },
			{ text: '수강자 이름', value: 'name' },
			{ text: '수강자 연락처', value: 'phone' },
			{ text: '수강자 추천인', value: 'recommenderUser' },
			{ text: '수강 완료율', value: 'completionRate' },
			{ text: '수강 완료 여부', value: 'finished' },
		])
		const itemsPerPageGroups = reactive([
			{
				name: '10',
				value: 10,
			},
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])

		const listMarketCourseRegistrations = async () => {
			try {
				isLoading.value = true
				const result = await MarketCourseService.listMarketCourseRegistrations(
					marketCourseId,
					currentPage.value - 1,
					itemsPerPage.value,
				)

				totalPages.value = parseInt(result.headers['x-total-pages-count'])
				registrations.value = result.data
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}

		const getMarketCourse = async () => {
			try {
				course.value = await MarketCourseService.getMarketCourse(marketCourseId)
			} catch (e) {
				console.error(e)
			}
		}

		const getUserInfos = async () => {
			registrations.value.map(async registration => {
				if (registration.user.recommenderUserId) {
					const user = await UserService._getUser(
						registration.user.recommenderUserId,
					)
					recommenderUserIds.value[registration.user.id] = {
						recommenderUser: user,
					}
				}
			})
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await listMarketCourseRegistrations()
		}

		const changeItemsPerPage = async limit => {
			itemsPerPage.value = limit
			await listMarketCourseRegistrations()
		}

		const downloadUsersExcelFile = async () => {
			const userIds = []
			registrations.value.map(async registration => {
				userIds.push(registration.user.id)
			})

			try {
				const response = await UserService.downloadUserExcelUserIds(
					userIds.join(),
				)
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', '강의수강자_리스트.xlsx')
				document.body.appendChild(link)
				link.click()
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(async () => {
			await getMarketCourse()
			await listMarketCourseRegistrations()
			await getUserInfos()
		})

		return {
			isLoading,
			registrations,
			currentPage,
			itemsPerPage,
			totalPages,
			headers,
			course,
			itemsPerPageGroups,
			recommenderUserIds,

			handlePageChange,
			changeItemsPerPage,
			downloadUsersExcelFile,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	// max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
