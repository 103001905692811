import dayjs from 'dayjs'
import axios from '@/api/axios'

const marketSurveyService = {
	listMarketQuestionnaires() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-questionnaires',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketQuestionnaire(marketQuestionnairesId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-questionnaires/${marketQuestionnairesId}`,
				})

				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createMarketQuestionnaires(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				if (payload.beginDate) {
					payload.beginDate = dayjs(payload.beginDate)
						.utc()
						.format()
						.toString()
						.replace('Z', '')
				}
				if (payload.endDate) {
					payload.endDate = dayjs(payload.endDate)
						.utc()
						.format()
						.toString()
						.replace('Z', '')
				}

				const { data } = await axios({
					method: 'POST',
					url: '/market-questionnaires',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateMarketQuestionnaires(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				if (payload.beginDate) {
					const _beginDate = dayjs(payload.beginDate)
						.utc()
						.format()
						.toString()
						.replace('Z', '')
					payload.beginDate = _beginDate + '.000+00:00'
				}
				if (payload.endDate) {
					const _endDate = dayjs(payload.endDate)
						.utc()
						.format()
						.toString()
						.replace('Z', '')
					payload.endDate = _endDate + '.000+00:00'
				}

				const { data } = await axios({
					method: 'PUT',
					url: `/market-questionnaires/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketQuestionnaires(subjectId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-questionnaires/${subjectId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketSurveyAnswerSheets() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-answer-sheets',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketSurveyService
