import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import i18n from '@/i18n'

import AuthService from '@/services/AuthService'

Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule])
})

const isEmailUnique = async value => {
	const data = await AuthService.checkEmail(value)
	return {
		valid: !data.exist,
	}
}

extend('isEmailUnique', {
	validate: isEmailUnique,
	message: () => i18n.t('validator.rules.isEmailUnique'),
})

const isUsernameUnique = async value => {
	const data = await AuthService.checkUsername(value)
	return {
		valid: !data.exist,
	}
}

extend('isUsernameUnique', {
	validate: isUsernameUnique,
	message: () => i18n.t('validator.rules.isUsernameUnique'),
})

extend('phone', {
	validate(value) {
		const regex = /^01\d{8,9}$/
		return regex.test(value)
	},
	message: () => i18n.t('validator.rules.phone'),
})

extend('password', {
	validate(value) {
		const regex = /^(?=.*[a-zA-Z])((?=.*[0-9])|(?=.*[!@#$%^*+=-])).{8,}$/
		return regex.test(value)
	},
	message: () => i18n.t('validator.rules.password'),
})

extend('repassword', {
	validate(value, { password }) {
		return value === password
	},
	params: ['password'],
	message: () => i18n.t('validator.rules.repassword'),
})
