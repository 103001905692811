import { localize } from 'vee-validate'
import ko from 'vee-validate/dist/locale/ko.json'
import i18n from '@/i18n'

localize(
	'ko',
	Object.assign(ko, {
		names: {
			id: i18n.t('validator.fields.id.name'),
			email: i18n.t('validator.fields.email.name'),
			name: i18n.t('validator.fields.name.name'),
			username: i18n.t('validator.fields.username.name'),
			phone: i18n.t('validator.fields.phone.name'),
			currentPassword: i18n.t('validator.fields.currentPassword.name'),
			password: i18n.t('validator.fields.password.name'),
			repassword: i18n.t('validator.fields.repassword.name'),
			verificationCode: i18n.t('validator.fields.verificationCode.name'),
			body: '내용',
			content: '내용',
			description: '설명',
			title: '타이틀',
			type: '타입',
			location: '장소',
			address: '장소',
			introduction: '장소',
			maxParticipants: '정원',
			maxNumberOfPeople: '정원',
			eventAt: '이벤트 날짜',
			testAt: '시험 날짜',
			assignmentStatus: '과제 합격 여부',
			score: '점수',
			baseFareAnHourWon: '이용 가능 인원',
			phoneNumber: '전화번호',
			visible: '공개 여부',
			vimeoVideoId: '비메오 영상 ID',
			vimeoVideoLengthInSec: '비메오 영상 길이(초단위)',
			dateRange: '시작-종료 날짜',
		},
		messages: {
			required: fieldName =>
				i18n.t('validator.messages.required', {
					fieldName: fieldName,
				}),
			min: (name, obj) =>
				i18n.t('validator.messages.min', {
					length: obj.length,
				}),
			max: (name, obj) =>
				i18n.t('validator.messages.max', {
					length: obj.length,
				}),
			numeric: () => i18n.t('validator.messages.numeric'),
		},
		fields: {
			email: {
				required: () => i18n.t('validator.fields.email.required'),
				email: () => i18n.t('validator.fields.email.required'),
			},
			name: {
				required: () => i18n.t('validator.fields.name.required'),
				name: () => i18n.t('validator.fields.name.required'),
			},
			username: {
				required: () => i18n.t('validator.fields.username.required'),
				name: () => i18n.t('validator.fields.username.required'),
			},
			phone: {
				required: () => i18n.t('validator.fields.phone.required'),
				phone: () => i18n.t('validator.fields.phone.required'),
			},
			password: {
				required: () => i18n.t('validator.fields.password.required'),
				min: (name, obj) =>
					i18n.t('validator.fields.password.min', {
						length: obj.length,
					}),
				max: (name, obj) =>
					i18n.t('validator.fields.password.max', {
						length: obj.length,
					}),
			},
			repassword: {
				required: () => i18n.t('validator.fields.repassword.required'),
			},
			verificationCode: {
				required: () => i18n.t('validator.fields.verificationCode.required'),
			},
		},
	}),
)
