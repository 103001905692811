<template>
	<div>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<div class="mb-4">
			<toast-ui-editor
				:content.sync="form.body"
				:type="'marketItem'"
				:market-item-id="form.id"
			/>
		</div>
		<v-file-input
			v-model="uploadedFile"
			placeholder="첨부할 파일을 업로드하세요"
			label="첨부 파일 업로드"
			prepend-icon="mdi-paperclip"
			multiple
			@change="inputChanged"
		/>
		<div
			v-if="form.files && form.files.length > 0"
			class="mb-4 d-flex flex-wrap"
		>
			<v-chip
				v-for="(file, index) in form.files"
				:key="index"
				class="mr-1 mb-1"
				color="primary"
				close
				@click:close="remove(file, index)"
			>
				{{ file.name || file.fileName }}
			</v-chip>
		</div>
		<validation-provider v-slot="{ errors }" name="visible" rules="required">
			<v-text-field
				v-model="form.description"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="설명을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="visible" rules="required">
			<v-text-field
				v-model="form.location"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="장소를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="amount">
			<v-text-field
				v-model="form.amount"
				type="text"
				:error-messages="errors"
				label="상품 비용을 입력하세요"
				autocomplete="off"
				suffix="원"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="startDate">
			<v-text-field
				v-model="form.startDate"
				type="datetime-local"
				:error-messages="errors"
				label="등록 시작 날짜를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="endDate">
			<v-text-field
				v-model="form.endDate"
				type="datetime-local"
				:error-messages="errors"
				label="등록 마감 날짜를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider name="charged">
			<v-checkbox v-model="form.charged" label="유료 여부" hide-details />
		</validation-provider>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import ToastUiEditor from '@/components/ToastUiEditor.vue'
import MarketItemService from '@/services/MarketItemService'

export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	components: {
		ToastUiEditor,
	},
	setup(props) {
		const uploadedFile = ref([])

		const inputChanged = async () => {
			const data = [...uploadedFile.value]
			props.form.files = [...data, ...props.form.files]
			if (props.form.id > 0) {
				for (const file of data) {
					const _form = new FormData()
					_form.append('data', file)

					try {
						await MarketItemService.uploadMarketItemFile(props.form.id, _form)
					} catch (e) {
						console.error(e)
					}
				}

				successSwal('파일이 업로드 되었습니다')
			}
		}

		const remove = async (file, index) => {
			const confirm = await confirmSwal('해당 파일을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketItemService.deleteMarketItemFile({
						marketItemId: props.form.id,
						fileId: file.id,
					})
					props.form.files.splice(index, 1)
					successSwal('파일이 삭제되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '파일을 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			uploadedFile,

			inputChanged,
			remove,
		}
	},
}
</script>
