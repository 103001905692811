<template>
	<v-card style="background-color: aliceblue">
		<v-card-title
			class="d-flex justify-space-between"
			style="background-color: white"
		>
			<div>
				<v-icon class="mr-2">mdi-note-check</v-icon>
				<router-link
					:to="{ name: 'market-survey-questionnaires-list' }"
					class="text-decoration-none"
				>
					설문지
				</router-link>
				<span class="mx-1">&#62;</span>
				질문지 등록
			</div>
			<div class="d-flex" style="gap: 2px">
				<v-btn @click="save" color="accent">임시저장</v-btn>
				<v-btn
					@click="register"
					color="primary"
					:disabled="form.questionnaire && form.questionnaire.length === 0"
				>
					등록하기
				</v-btn>
			</div>
		</v-card-title>
		<v-card-text class="mt-6">
			<v-card
				elevation="1"
				class="mx-auto mb-4"
				max-width="720"
				style="border-top: 6px solid #0d6efd"
			>
				<v-card-title>
					<v-text-field
						v-model="form.title"
						style="font-size: 21px"
						hide-details
					/>
					<v-checkbox
						class="mb-2"
						v-model="form.visible"
						label="공개 여부"
						hide-details
					/>
				</v-card-title>

				<v-card-subtitle>
					<v-select
						item-text="name"
						item-value="value"
						:items="targetOptions"
						v-model="form.target"
						label="질문지 대상을 선택해주세요"
						required
					/>
					<date-picker-range-dialog
						class="mt-8"
						:label="'설문 시작-종료 날짜를 입력하세요'"
						:width="'100%'"
						:date-value.sync="dateRange"
					/>
				</v-card-subtitle>
			</v-card>
			<template v-if="form.questionnaire && form.questionnaire.length > 0">
				<draggable
					v-model="form.questionnaire"
					@start="drag = true"
					@end="drag = false"
				>
					<v-card
						elevation="1"
						class="mx-auto mb-4"
						max-width="720"
						v-for="(cardForm, index) in form.questionnaire"
						:key="'card-form-' + index"
						style="cursor: move"
					>
						<question-card-form
							:form="cardForm"
							:form-index="index"
							@copy-card-form="copyCardForm($event)"
							@delete-card-form="deleteCardForm($event)"
						/>
					</v-card>
				</draggable>
			</template>
			<template v-else>
				<p class="text-lg text-center">
					질문지가 없습니다.
					<strong>오른쪽 하단</strong>
					추가 버튼으로 등록하세요
				</p>
			</template>
		</v-card-text>
		<v-card-actions />
		<v-btn color="primary" fixed dark fab bottom right @click="addForm">
			<v-icon>mdi-plus</v-icon>
		</v-btn>

		<v-snackbar v-model="snackbar" :timeout="timeout">
			질문지를 임시저장하였습니다

			<template v-slot:action="{ attrs }">
				<v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
					닫기
				</v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
import {
	ref,
	reactive,
	watch,
	onMounted,
	nextTick,
	computed,
} from '@vue/composition-api'

import draggable from 'vuedraggable'

import { questionType } from '@/helpers'
import { useRouter } from '@core/utils'
import { confirmSwal, successSwal } from '@/plugins/swalMixin'

import MarketSurveyService from '@/services/MarketSurveyService'

import QuestionCardForm from './components/QuestionCardForm.vue'
import DatePickerRangeDialog from '@/components/common/DatePickerRangeDialog.vue'

export default {
	components: {
		draggable,
		QuestionCardForm,
		DatePickerRangeDialog,
	},
	setup() {
		const drag = ref(false)
		const timeout = ref(1500)
		const snackbar = ref(false)
		const { router } = useRouter()
		const targetOptions = reactive([
			{
				name: '성인용',
				value: 'adult',
			},
			{
				name: '학생용',
				value: 'student',
			},
		])
		const form = ref({
			title: '제목 없는 질문지',
			beginDate: '',
			endDate: '',
			visible: false,
			questionnaire: [
				{
					type: questionType.MULTIPLE_QUESTIONS_CHECKBOX.value,
					title: '',
					selections: [
						{
							title: '옵션1',
						},
					],
				},
			],
		})

		const dateRange = computed({
			get() {
				return [form.value.beginDate, form.value.endDate]
			},
			set(value) {
				const temp = value.sort(function (a, b) {
					return new Date(a) - new Date(b)
				})

				form.value.beginDate = temp[0]
				form.value.endDate = temp[1]
			},
		})

		const scrollToBottom = () => {
			window.scrollTo({
				top: document.body.scrollHeight,
				behavior: 'smooth',
			})
		}

		const addForm = () => {
			form.value.questionnaire.push({
				type: questionType.MULTIPLE_QUESTIONS_CHECKBOX.value,
				title: '',
				selections: [
					{
						title: '',
					},
				],
			})
			nextTick(() => {
				scrollToBottom()
			})
		}

		const copyCardForm = index => {
			const _form = JSON.parse(JSON.stringify(form.value.questionnaire[index]))
			form.value.questionnaire.splice(index + 1, 0, _form)
		}

		const deleteCardForm = index => {
			form.value.questionnaire.splice(index, 1)
		}

		const save = () => {
			try {
				localStorage.setItem('questionnairesForm', JSON.stringify(form.value))
				snackbar.value = true
			} catch (e) {
				console.error(e)
			}
		}

		const scrollIntoViewElement = elementId => {
			if (elementId) {
				document.getElementById(elementId)?.scrollIntoView()
			} else {
				window.scrollTo(0, 0)
			}
		}

		const isQuestionAnswersFulfiled = () => {
			if (!form.value.title || (!form.value.beginDate && !form.value.endDate)) {
				scrollIntoViewElement()
				return false
			}

			const _form = [...form.value.questionnaire]
			if (_form.length === 0) {
				scrollIntoViewElement('card-form-0')
				return false
			}

			for (let i = 0; i < _form.length; i++) {
				if (
					_form[i].type === questionType.MULTIPLE_QUESTIONS_SUBJECTIVE.value &&
					!_form[i].numberOfAnswers
				) {
					scrollIntoViewElement(`card-form-${i}`)
					return false
				}

				if (!_form[i].title.trim()) {
					scrollIntoViewElement(`card-form-${i}`)
					return false
				} else {
					if (_form[i].selections) {
						for (let j = 0; j < _form[i].selections.length; j++) {
							if (!_form[i].selections[j].title.trim()) {
								scrollIntoViewElement(`card-form-${i}`)
								return false
							}

							if (_form[i].selections[j].selections) {
								for (
									let k = 0;
									k < _form[i].selections[j].selections.length;
									k++
								) {
									if (!_form[i].selections[j].selections[k].title.trim()) {
										scrollIntoViewElement(`card-form-${i}`)
										return false
									}
								}
							}
						}
					}
				}
			}
			return true
		}

		const register = async () => {
			if (!isQuestionAnswersFulfiled()) {
				return
			}

			const registerConfirm = await confirmSwal('질문지를 등록하시겠습니까?')
			if (registerConfirm.isConfirmed) {
				try {
					const payload = { ...form.value }
					payload.questionnaire = Object.assign({}, payload.questionnaire)
					payload.beginDate = `${payload.beginDate} 00:00:01`
					payload.endDate = `${payload.endDate} 23:59:59`

					await MarketSurveyService.createMarketQuestionnaires(payload)

					localStorage.removeItem('questionnairesForm')

					const confirm = await successSwal('질문지가 생성되었습니다')
					if (confirm.isConfirmed) {
						router.push({ name: 'market-survey-questionnaires-list' })
					}
				} catch (e) {
					console.log(e)
				}
			}
		}

		const getQuestionnairesForm = () => {
			try {
				const questionnairesForm = localStorage.getItem('questionnairesForm')
				if (questionnairesForm) {
					form.value = JSON.parse(questionnairesForm)
				}
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => form.value,
			() => {
				save()
			},
			{
				deep: true,
			},
		)

		onMounted(() => {
			getQuestionnairesForm()
		})

		return {
			form,
			drag,
			timeout,
			snackbar,
			dateRange,
			targetOptions,

			save,
			register,
			addForm,
			copyCardForm,
			deleteCardForm,
		}
	},
}
</script>
