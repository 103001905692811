import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import user from './user'
import loading from './loading'
import post from './post'
import license from './license'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		user,
		post,
		loading,
		license,
		app,
	},
	plugins: [],
})
