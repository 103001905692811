var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_vm._v(" 주문 리스트 "),_c('v-select',{staticClass:"mr-1",staticStyle:{"max-width":"100px"},attrs:{"item-text":"name","item-value":"value","items":_vm.itemsPerPageGroups,"label":"페이지당 개수","value":10,"hide-details":"","dense":"","outlined":""},on:{"change":_vm.changeItemsPerPage}})],1),_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.marketOrders,"no-data-text":"조회된 주문이 없습니다.","loading":_vm.isLoading,"loading-text":"주문을 불러오는 중입니다...","hide-default-footer":""},on:{"click:row":function($event){return _vm.routeToDetail($event.id)}},scopedSlots:_vm._u([{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.paid ? 'primary' : 'secondary',"small":""}},[_vm._v(" "+_vm._s(item.paid ? '결제 완료' : '결제 대기')+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.amount))+" ")]}},{key:"item.orderedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateKOSimpleSiBunChoFormat")(item.orderedAt))+" ")]}},{key:"item.canceled",fn:function(ref){
var item = ref.item;
return [(item.canceled)?_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v("취소 완료")]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteOrder(item)}}},[_vm._v(" 주문 삭제 ")])]}}],null,true)}),_c('div',{staticClass:"mx-auto mt-6"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }