<template>
	<v-card>
		<v-simple-table fixed-header height="560px">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-center" style="width: 10%">구분</th>
						<th class="text-center">시험일정</th>
						<th class="text-center">시험장소</th>
						<th class="text-center">모집인원</th>
						<th class="text-center">마감일</th>
						<th class="text-center">시험지 보기</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="testRounds.length > 0">
						<tr
							v-for="(item, index) in testRounds"
							:key="index"
							class="cursor-pointer"
							@click="openTestRoundDialog(item)"
						>
							<td class="text-center">
								<strong>{{ item.type }}</strong>
							</td>
							<td class="text-center">{{ item.testAt | dateFormat }}</td>
							<td class="text-center">{{ item.location }}</td>
							<td class="text-center">{{ item.maxParticipants }} 명</td>
							<td class="text-center">{{ item.testAt | dateFormat }}</td>
							<td class="text-center">
								<v-btn
									color="primary"
									small
									:to="{
										name: 'license-test-round',
										params: { licenseId: item.licenseId, testRoundId: item.id },
									}"
								>
									시험지 보기
								</v-btn>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr>
							<td colspan="6">현재 동록한 자격 시험이 없습니다</td>
						</tr>
					</template>
				</tbody>
			</template>
		</v-simple-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn
				@click="
					isOpenLicenseRegisterTestRoundDialog =
						!isOpenLicenseRegisterTestRoundDialog
				"
				color="primary"
			>
				자격시험 등록
			</v-btn>
		</v-card-actions>
		<license-register-test-round-dialog
			:license-id="licenseId"
			:is-open-license-register-test-round-dialog.sync="
				isOpenLicenseRegisterTestRoundDialog
			"
			@is-fetch-test-rounds="isFetchTestRounds = $event"
		/>
		<license-edit-test-round-dialog
			:selected-test-round="selectedTestRound"
			:is-open-license-edit-test-round-dialog.sync="
				isOpenLicenseEditTestRoundDialog
			"
			@is-fetch-test-rounds="isFetchTestRounds = $event"
		/>
	</v-card>
</template>

<script>
import { mdiClipboardCheckMultipleOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseRegisterTestRoundDialog from './LicenseRegisterTestRoundDialog.vue'
import LicenseEditTestRoundDialog from './LicenseEditTestRoundDialog.vue'

export default {
	props: {
		licenseId: {
			required: true,
		},
	},
	components: {
		LicenseEditTestRoundDialog,
		LicenseRegisterTestRoundDialog,
	},
	setup(props) {
		const testRounds = ref([])
		const isEdit = ref(false)
		const isFetchTestRounds = ref(false)
		const selectedTestRound = ref({})
		const isOpenLicenseRegisterTestRoundDialog = ref(false)
		const isOpenLicenseEditTestRoundDialog = ref(false)
		const isOpenLicenseEditTestRoundWorkbookDialog = ref(false)
		const isOpenLicenseTestRoundWorkbookDialog = ref(false)

		const listTestRounds = async () => {
			const data = await MarketLicenseService.listLicenseTestRounds(
				props.licenseId,
			)
			testRounds.value = data
		}
		listTestRounds()

		const openTestRoundDialog = item => {
			selectedTestRound.value = item
			isOpenLicenseEditTestRoundDialog.value = true
		}

		watch(
			() => isFetchTestRounds.value,
			currentValue => {
				if (currentValue) {
					listTestRounds()
					isFetchTestRounds.value = false
				}
			},
		)

		return {
			testRounds,
			isEdit,
			isFetchTestRounds,
			selectedTestRound,
			isOpenLicenseEditTestRoundDialog,
			isOpenLicenseEditTestRoundWorkbookDialog,
			isOpenLicenseRegisterTestRoundDialog,
			isOpenLicenseTestRoundWorkbookDialog,

			openTestRoundDialog,

			icons: {
				mdiClipboardCheckMultipleOutline,
			},
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
