<template>
	<v-dialog v-model="isOpenUserTestDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				답안지 보기
				<v-spacer />
				<v-btn icon @click="$emit('update:is-open-user-test-dialog', false)">
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<v-simple-table fixed-header>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">점수</th>
								<th class="text-left">합격여부</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="font-weight-bold">
									<span>
										{{ workbook.score }}
									</span>
								</td>
								<td class="font-weight-bold">
									{{ testResult }}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<div v-for="item in workbook.problemCount" :key="item">
					<v-col cols="12" md="12" sm="12">
						<multiple-choice-questions-view
							:is-confirm="true"
							:workbook-id="workbook.id"
							:question-number="item"
							:answer-sheet="workbook.answerSheet"
							:test-answer="testAnswer"
							v-if="testAnswer"
						/>
					</v-col>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { testStatus } from '@/helpers'
import { ref, computed, watch } from '@vue/composition-api'

import MarketLicenseService from '@/services/MarketLicenseService'

import MultipleChoiceQuestionsView from './components/MultipleChoiceQuestionsView.vue'

export default {
	props: {
		isOpenUserTestDialog: {
			type: Boolean,
			required: true,
		},
		workbook: {
			type: Object,
		},
	},
	components: {
		MultipleChoiceQuestionsView,
	},
	setup(props) {
		const testAnswer = ref(null)

		const testResult = computed(() => {
			return props.workbook.pass ? testStatus.PASS.name : testStatus.FAIL.name
		})

		const getWorkbook = async () => {
			const data = await MarketLicenseService.getWorkbook(props.workbook.id)
			testAnswer.value = data.answer
		}

		watch(
			() => props.isOpenUserTestDialog,
			currentValue => {
				if (currentValue) {
					getWorkbook()
				}
			},
			{
				immediate: true,
			},
		)

		return {
			testResult,
			testAnswer,

			icons: {
				mdiClose,
			},
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
