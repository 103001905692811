var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2"},[_c('v-autocomplete',{ref:"selectSubjectEl",staticStyle:{"background-color":"white"},attrs:{"items":_vm.subjectGroups,"menu-props":{
			closeOnClick: true,
		},"dense":"","chips":"","filled":"","outlined":"","single-line":"","hide-details":"","return-object":"","item-text":"name","label":"콘텐츠 주제","color":"blue-grey lighten-2"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeChip()}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(ref){
		var item = ref.item;
		var attrs = ref.attrs;
		var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"3.5rem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
		var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(item.description)?_c('span',{staticClass:"text-base",domProps:{"innerHTML":_vm._s(item.name + item.description)}}):_c('span',{staticClass:"text-base",domProps:{"innerHTML":_vm._s(item.name)}})])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("검색된 데이터가 없습니다")])]},proxy:true}]),model:{value:(_vm.selectedSubject),callback:function ($$v) {_vm.selectedSubject=$$v},expression:"selectedSubject"}}),_c('div',{staticClass:"d-flex justify-space-around align-center my-2"},[(_vm.post)?_c('v-card',{staticStyle:{"flex":"1"}},[_c('v-card-subtitle',{staticClass:"pb-0"},[_c('strong',[_vm._v("변경 전")])]),_c('v-card-title',{staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm.post.subject.title)+" ( id: "+_vm._s(_vm.post.subject.id)+" ) ")])],1):_vm._e(),_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-arrow-right-bold")]),(_vm.post)?_c('v-card',{staticStyle:{"flex":"1"}},[_c('v-card-subtitle',{staticClass:"pb-0"},[_c('strong',[_vm._v("변경 후")])]),_c('v-card-title',{staticClass:"pt-0"},[(_vm.selectedSubject)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.selectedSubject.name)+" ( id: "+_vm._s(_vm.selectedSubject.value)+" ) ")]):_c('span',{staticClass:"mr-1"},[_vm._v("-")])])],1):_vm._e()],1),_c('v-btn',{staticClass:"px-0 mb-2 font-weight-bold w-full",attrs:{"rounded":"","color":"primary","ripple":false,"disabled":!_vm.selectedSubject},on:{"click":function($event){return _vm.update()}}},[_vm._v(" 업데이트 하기 (준비중) ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }