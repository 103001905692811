<template>
	<div style="height: 100%">
		<app-article />
	</div>
</template>
<script>
import AppArticle from '@/components/layouts/AppArticle.vue'

export default {
	components: {
		AppArticle,
	},
	setup() {},
}
</script>
