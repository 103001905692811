<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{ name: 'market-locations-list' }"
				class="text-decoration-none"
			>
				라운지
			</router-link>
			<span class="mx-1">&#62;</span>
			{{ form.name }}
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<validation-observer ref="observer" v-slot="{ valid }">
				<market-location-form :form.sync="form" />
				<div class="d-flex justify-space-between pb-6 text-wrap">
					<div>
						<v-btn
							class="mr-1"
							color="primary"
							:disabled="!valid"
							@click="updateMarketLocation"
						>
							수정
						</v-btn>
					</div>
					<div>
						<v-btn
							class="mr-1"
							color="info"
							@click="isOpenAddScheduleDialog = !isOpenAddScheduleDialog"
						>
							스케쥴 등록하기
						</v-btn>
						<v-btn
							color="accent"
							:to="{
								name: 'market-locations-detail-reservation',
								params: {
									marketLocationId: marketLocationId,
								},
							}"
						>
							예약 목록 보기
						</v-btn>
					</div>
				</div>
			</validation-observer>
		</v-card-text>
		<market-location-add-schedule-dialog
			:market-location-id="marketLocationId"
			:is-open-add-schedule-dialog.sync="isOpenAddScheduleDialog"
		/>
	</v-card>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLocationService from '@/services/MarketLocationService'

import MarketLocationForm from './components/MarketLocationForm.vue'
import MarketLocationAddScheduleDialog from './MarketLocationAddScheduleDialog.vue'

export default {
	components: {
		MarketLocationForm,
		MarketLocationAddScheduleDialog,
	},
	setup() {
		const { route, router } = useRouter()
		const marketLocationId = ref(route.value.params.marketLocationId)
		const form = ref({})
		const isFetchMarketLocationSchedule = ref(false)
		const isOpenAddScheduleDialog = ref(false)
		const marketLocationSchedule = ref([])

		const getMarketLocation = async () => {
			try {
				form.value = await MarketLocationService.getMarketLocation(
					marketLocationId.value,
				)
			} catch (e) {
				console.error(e)
			}
		}

		const getMarketLocationSchedules = async () => {
			try {
				marketLocationSchedule.value =
					await MarketLocationService.getMarketLocationSchedules(
						marketLocationId.value,
					)
			} catch (e) {
				console.error(e)
			} finally {
				isFetchMarketLocationSchedule.value = false
			}
		}

		onMounted(() => {
			getMarketLocation()
			getMarketLocationSchedules()
		})

		watch(
			() => isFetchMarketLocationSchedule.value,
			currentValue => {
				if (currentValue) {
					getMarketLocation()
				}
			},
		)

		const updateMarketLocation = async () => {
			const confirm = await confirmSwal('라운지 정보를 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLocationService.updateMarketLocation(form.value)
					successSwal('라운지 정보가 수정되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400
								? e.response.data.message
								: '라운지 정보를 수정하는데 문제가 발생했습니다.',
					})
				}
			}
		}

		const deleteMarketLocation = async () => {
			const confirm = await confirmSwal('라운지 정보를 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLocationService.deleteMarketLocation(form.value.id)
					await successSwal('라운지 정보가 삭제되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '라운지 정보를 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					router.push({ name: 'market-locations-list' })
				}
			}
		}

		const deleteMarketLocationSchedule = async event => {
			const confirm = await confirmSwal(
				`${event.title} 스케쥴 정보를 해제하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketLocationService.unLinkMarketItemEvent({
						// marketItemId: marketItemId.value,
						marketEventId: event.id,
					})
					successSwal('스케쥴 정보가 해제되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '스케쥴 정보를 해제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			form,
			marketLocationId,
			isFetchMarketLocationSchedule,
			marketLocationSchedule,
			isOpenAddScheduleDialog,

			updateMarketLocation,
			deleteMarketLocation,
			deleteMarketLocationSchedule,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-tab {
	margin-left: 0 !important;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
