<template>
	<div>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="body" rules="required">
			<v-text-field
				v-model="form.body"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="내용을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider name="visible" rules="required">
			<v-checkbox
				v-model="form.visible"
				label="공개 여부"
				autocomplete="off"
				hide-details
			/>
		</validation-provider>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	setup() {},
}
</script>
