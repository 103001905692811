<template>
	<div>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				autocomplete="off"
				hide-details=""
				required
			/>
			<validation-provider name="visible">
				<v-checkbox
					class="mb-2"
					v-model="form.visible"
					label="공개 여부"
					hide-details
				/>
			</validation-provider>
		</validation-provider>
		<v-file-input
			v-model="uploadedFile"
			placeholder="첨부할 파일을 업로드하세요"
			label="첨부 파일 업로드"
			prepend-icon="mdi-paperclip"
			multiple
			@change="inputChanged"
		/>
		<div v-if="form.files && form.files.length > 0" class="mb-4">
			<v-chip
				v-for="(file, index) in form.files"
				:key="index"
				class="mr-1"
				color="primary"
				close
				@click:close="remove(file, index)"
			>
				{{ file.name || file.fileName }}
			</v-chip>
		</div>
		<toast-ui-editor
			:content.sync="form.content"
			:type="'notice'"
			:notice-id="form.id"
		/>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import NoticeService from '@/services/NoticeService'

import ToastUiEditor from '@/components/ToastUiEditor.vue'

export default {
	props: {
		form: {
			required: true,
		},
	},
	components: {
		ToastUiEditor,
	},
	setup(props) {
		const uploadedFile = ref([])

		const inputChanged = async () => {
			const data = [...uploadedFile.value]
			props.form.files = [...data, ...props.form.files]
			if (props.form.id > 0) {
				for (const file of data) {
					const _form = new FormData()
					_form.append('data', file)

					try {
						await NoticeService.uploadNoticeFile(props.form.id, _form)
					} catch (e) {
						console.error(e)
					}
				}

				successSwal('파일이 업로드 되었습니다')
			}
		}

		const remove = async (file, index) => {
			const confirm = await confirmSwal('해당 파일을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await NoticeService.deleteNoticeFile({
						noticeId: props.form.id,
						fileId: file.id,
					})
					props.form.files.splice(index, 1)
					successSwal('파일이 삭제되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '파일을 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			uploadedFile,

			inputChanged,
			remove,
		}
	},
}
</script>
