<template>
	<v-dialog v-model="isOpenUserInfoDialog" persistent max-width="800">
		<v-card>
			<v-card-title style="position: absolute; right: 0">
				<v-spacer />
				<v-btn icon @click="$emit('update:is-open-user-info-dialog', false)">
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<user-form :user="user" v-if="user" />
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import UserService from '@/services/UserService'

import UserForm from '@/pages/user/components/UserForm.vue'

export default {
	props: {
		isOpenUserInfoDialog: {
			type: Boolean,
			default: false,
			required: true,
		},
		userId: {
			required: true,
		},
	},
	components: {
		UserForm,
	},
	setup(props) {
		const user = ref({})
		const getUser = async () => {
			const {
				id,
				publicPictureUrl,
				email,
				emailVerified,
				emailVerifiedAt,
				uid,
				name,
				birthDate,
				address,
				addressDetail,
				phoneNumber,
				phoneNumberVerified,
				phoneNumberVerifiedAt,
				recommenderReferralCode,
				recommenderUser,
				marketerRole,
				adminMemo,
			} = await UserService.getUser(props.userId)

			user.value = {
				id,
				publicPictureUrl,
				email,
				emailVerified,
				emailVerifiedAt,
				uid,
				name,
				birthDate,
				address,
				addressDetail,
				phoneNumber,
				phoneNumberVerified,
				phoneNumberVerifiedAt,
				recommenderReferralCode,
				recommenderUser,
				marketerRole,
				adminMemo,
			}
		}

		watch(
			() => props.isOpenUserInfoDialog,
			async currentValue => {
				if (currentValue) {
					await getUser()
				} else {
					user.value = {}
				}
			},
			{
				immediate: true,
			},
		)

		return {
			user,
		}
	},
}
</script>
