import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

import MarketPostService from '@/services/MarketPostService'

Vue.use(Vuex)

import { computed } from '@vue/composition-api'

const post = {
	name: 'post',
	namespaced: true,
	state: {
		contentPosts: [],
		postProcessTargets: {},
	},
	getters: {
		getPostProcessTarget: state => postId => {
			return state.postProcessTargets[postId] || {}
		},
		getPostProcessTargets: () => async () => {
			const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])
			try {
				const data = await MarketPostService.getMarketContentCart(
					firebaseUser.value.uid,
				)
				return data
			} catch (e) {
				console.error('getPostProcessTargets', e)
			}
		},
		getContentPosts: state => () => {
			return state.contentPosts || []
		},
	},
	mutations: {
		setPostProcessTarget(state, payload) {
			state.postProcessTargets[payload.postId] = payload
		},
		deletePostProcessTarget(state, payload) {
			delete state.postProcessTargets[payload.postId]
		},
		setContentPosts(state, payload) {
			state.contentPosts = payload
		},
		deleteContentPosts(state) {
			delete state.contentPosts
		},
	},
	actions: {
		async setPostProcessTarget({ commit }, payload) {
			const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])
			try {
				await MarketPostService.pushMarketContentCart(
					firebaseUser.value.uid,
					payload,
				)
				commit('setPostProcessTarget', payload)
			} catch (e) {
				console.error('setPostProcessTarget', e)
			}
		},
		async deletePostProcessTarget({ commit }, payload) {
			const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])
			try {
				await MarketPostService.deleteMarketContentCart(
					firebaseUser.value.uid,
					payload.firebaseKey,
				)
				commit('deletePostProcessTarget', payload)
			} catch (e) {
				console.error('deletePostProcessTarget', e)
			}
		},
		setContentPosts({ commit }, payload) {
			commit('setContentPosts', payload)
		},
		deleteContentPosts({ commit }) {
			commit('deleteContentPosts')
		},
	},
}

export default post
