<template>
	<v-dialog v-model="isOpenNoticeRegisterDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				공지사항 등록
				<v-spacer />
				<v-btn icon @click="closeDialog">
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<notice-form :form.sync="form" />
					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							:disabled="!valid"
							@click.stop="createNotice"
						>
							등록
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { reactive } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import NoticeService from '@/services/NoticeService'

import NoticeForm from './components/NoticeForm.vue'

export default {
	props: {
		noticeId: {
			type: [String, Number],
		},
		isOpenNoticeRegisterDialog: {
			type: Boolean,
			required: true,
		},
	},
	components: {
		NoticeForm,
	},
	setup(_, { emit }) {
		const form = reactive({
			title: '',
			content: '',
			files: [],
			forMarketer: true,
			forFactory: false,
			visible: false,
		})

		const createNotice = async () => {
			const confirm = await confirmSwal('공지사항을 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = await NoticeService.createNotice(form)

					if (form.files) {
						for (const file of form.files) {
							const _form = new FormData()
							_form.append('data', file)
							try {
								await NoticeService.uploadNoticeFile(data.id, _form)
							} catch (e) {
								console.error(e)
							}
						}
					}

					emit('is-fetch-notices', true)
					successSwal('공지사항 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '공지사항을 등록하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-notice-register-dialog', false)
				}
			}
		}

		const closeDialog = () => {
			emit('is-fetch-notices', true)
			emit('update:is-open-notice-register-dialog', false)
		}

		return {
			form,

			createNotice,
			closeDialog,
		}
	},
}
</script>
