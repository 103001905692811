var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"contentMarketType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-1",attrs:{"item-text":"name","item-value":"value","items":_vm.contentMarketTypeGroups,"error-messages":errors,"label":"콘텐츠 마켓 타입을 선택하세요","dense":"","outlined":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}])}),_c('validation-provider',{attrs:{"name":"categories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.form.categories),function(category,index){return _c('v-chip',{key:index,staticClass:"mb-2 mr-1",attrs:{"color":"primary","text-color":"white","close":""},on:{"click:close":function($event){return _vm.removeChip(index)}}},[_vm._v(" "+_vm._s(category)+" ")])}),_c('v-text-field',{attrs:{"error-messages":errors,"label":"해시태그를 입력하고 엔터를 눌러 추가하세요","dense":"","outlined":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addChip.apply(null, arguments)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})]}}])}),(_vm.isLoungeContents)?_c('validation-provider',{attrs:{"name":"subjectLoungeType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-1",attrs:{"item-text":"name","item-value":"value","items":_vm.subjectLoungeTypeGroups,"error-messages":errors,"label":"라운지 위치를 선택하세요","dense":"","outlined":""},model:{value:(_vm.form.lounge),callback:function ($$v) {_vm.$set(_vm.form, "lounge", $$v)},expression:"form.lounge"}})]}}],null,false,3437226694)}):_vm._e(),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"타이틀을 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('validation-provider',{attrs:{"name":"content","rules":"required"}},[_c('toast-ui-editor',{attrs:{"content":_vm.form.content,"market-post-subject-id":_vm.form.id,"subject-market-post-id":_vm.form.subjectMarketPostId,"type":'marketPostSubject'},on:{"update:content":function($event){return _vm.$set(_vm.form, "content", $event)}}})],1),_c('validation-provider',{attrs:{"name":"dateRange","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-range-dialog',{staticClass:"mt-4",attrs:{"error-messages":errors,"label":'등록 시작 - 종료 날짜를 입력하세요',"date-value":_vm.dateRange},on:{"update:dateValue":function($event){_vm.dateRange=$event},"update:date-value":function($event){_vm.dateRange=$event}}})]}}])}),_c('validation-provider',{attrs:{"name":"visible"}},[_c('v-checkbox',{staticClass:"mb-2",attrs:{"label":"공개 여부","hide-details":""},model:{value:(_vm.form.visible),callback:function ($$v) {_vm.$set(_vm.form, "visible", $$v)},expression:"form.visible"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }