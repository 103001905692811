<template>
	<div class="date-picker-fields">
		<v-dialog
			ref="dateDialog"
			v-model="dateModal"
			:return-value.sync="date"
			persistent
			width="290px"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					:value="dateRange"
					:label="label"
					prepend-icon="mdi-calendar"
					:error-messages="errorMessages"
					name="dateRange"
					readonly
					v-on="on"
					hide-details
					class="pt-0 shrink"
					required
					:style="{ width: `${width}px` }"
				/>
			</template>
			<v-date-picker v-model="date" range scrollable locale="ko-kr">
				<v-spacer />
				<v-btn color="primary" @click="dateModal = !dateModal">취소</v-btn>
				<v-btn color="primary" @click="set">확인</v-btn>
			</v-date-picker>
		</v-dialog>
	</div>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'

export default {
	props: {
		label: {
			type: String,
			required: true,
		},
		width: {
			type: [String, Number],
			default: 290,
		},
		ShowIcon: {
			type: Boolean,
			default: true,
		},
		errorMessages: {
			type: Array,
		},
		dateValue: {
			type: Array,
		},
	},
	setup(props, { emit }) {
		const date = ref(null)
		const dateModal = ref(false)
		const dateDialog = ref(null)

		const set = () => {
			emit('update:date-value', date.value)
			dateDialog.value.save(date.value)
		}

		const dateRange = computed(() => {
			return date.value[0] !== '' && date.value[1] !== ''
				? date.value.join(' ~ ')
				: null
		})

		watch(
			() => props.dateValue,
			currentValue => {
				date.value = currentValue
			},
			{
				immediate: true,
			},
		)

		return {
			date,
			dateModal,
			dateDialog,

			dateRange,

			set,
		}
	},
}
</script>
