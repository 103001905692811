<template>
	<v-dialog
		v-model="isOpenLicenseRegisterTestRoundWorkbookDialog"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				시험지 등록
				<v-spacer />
				<v-btn
					icon
					@click="
						$emit(
							'update:is-open-license-register-test-round-workbook-dialog',
							false,
						)
					"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<license-test-round-workbook-form :form.sync="form" />
					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							:disabled="!valid"
							@click.stop="createTestRoundWorkbook"
						>
							등록
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { mdiClose } from '@mdi/js'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseTestRoundWorkbookForm from './components/LicenseTestRoundWorkbookForm.vue'

export default {
	props: {
		isOpenLicenseRegisterTestRoundWorkbookDialog: {
			type: Boolean,
			required: true,
		},
		licenseId: {
			required: true,
		},
		testRoundId: {
			required: true,
		},
	},
	components: {
		LicenseTestRoundWorkbookForm,
	},
	setup(props, { emit }) {
		const form = ref({
			licenseId: props.licenseId,
			licenseTestRoundId: props.testRoundId,
			title: '',
			body: '',
			problemCount: 0,
			minPassScore: null,
			visible: true,
		})

		watch(
			() => props.selectedTestRound,
			currentValue => {
				if (currentValue) {
					form.value.licenseId = currentValue.licenseId
					form.value.licenseTestRoundId = currentValue.id
				}
			},
		)

		const createTestRoundWorkbook = async () => {
			const confirm = await confirmSwal('시험지를 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLicenseService.createTestRoundWorkbook(form.value)
					emit('is-fetch-workbooks', true)
					successSwal('시험지 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '시험지를 등록하는데 문제가 발생했습니다.',
					)
				} finally {
					emit(
						'update:is-open-license-register-test-round-workbook-dialog',
						false,
					)
				}
			}
		}

		return {
			form,
			createTestRoundWorkbook,

			icons: {
				mdiClose,
			},
		}
	},
}
</script>
