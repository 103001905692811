<template>
	<v-card style="height: 100%" elevation="0" class="px-3">
		<v-data-table
			:headers="headers"
			:items="orders"
			:items-per-page="itemsPerPage"
			item-key="email"
			no-data-text="조회된 주문내역이 없습니다."
			class="elevation-1"
			:loading="isLoading"
			loading-text="주문내역을 불러오는 중입니다..."
			hide-default-footer
		>
			<template v-slot:[`item.amount`]="{ item }">
				{{ item.amount | commaFormat }} 원
			</template>
			<template v-slot:[`item.orderedAt`]="{ item }">
				{{ item.orderedAt | dateKOFormat }}
			</template>
			<template v-slot:[`item.canceledAt`]="{ item }">
				{{ item.canceledAt | dateKOFormat }}
			</template>
			<template v-slot:[`item.paidAt`]="{ item }">
				<span v-if="item.paid">
					{{ item.paidAt | dateKOFormat }}
				</span>
				<span v-else>결제 안함</span>
			</template>
			<template v-slot:[`item.userName`]="{ item }">
				{{ item.user.name }}
			</template>
			<template v-slot:[`item.paidInfo`]="{ item }">
				<v-btn
					x-small
					rounded
					color="primary"
					dark
					@click="openOrderPaymentDetailDialog(item.formattedPgResponse)"
				>
					<v-icon small>mdi-information</v-icon>
					<span class="text-xs">결제정보</span>
				</v-btn>
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
		<order-payment-detail-dialog
			:pg-response="selectedPgResonse"
			:is-open-order-payment-detail-dialog.sync="isOpenOrderPaymentDetailDialog"
		/>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { resolvePaymentMethod } from '@core/utils/filter'
import { warningSwal } from '@/plugins/swalMixin'

import MarketOrderService from '@/services/MarketOrderService'

import OrderPaymentDetailDialog from './OrderPaymentDetailDialog.vue'

export default {
	components: {
		OrderPaymentDetailDialog,
	},
	props: {
		userId: {
			type: [String, Number],
			required: true,
		},
	},
	setup(props) {
		const orders = ref([])
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const isLoading = ref(false)
		const menu = ref([])
		const selectedPgResonse = ref({})
		const isOpenOrderPaymentDetailDialog = ref(false)
		const headers = ref([
			{
				text: 'id',
				align: 'left',
				value: 'id',
				width: '80px',
			},
			{ text: '주문자', value: 'userName' },
			{ text: '주문명', value: 'title' },
			{ text: '주문금액', value: 'amount' },
			{ text: '주문일', value: 'orderedAt' },
			{ text: '주문 취소일', value: 'canceledAt' },
			{ text: '주문 결제일', value: 'paidAt' },
			{ text: '결제 정보', value: 'paidInfo' },
		])

		const formatPgResponse = pgResponse => {
			return pgResponse ? JSON.parse(pgResponse) : null
		}

		const getUserOrders = async () => {
			try {
				isLoading.value = true
				orders.value = await MarketOrderService.getUserMarketOrderDetail(
					props.userId,
				)

				for (const [index, order] of Object.entries(orders.value)) {
					orders.value[index].formattedPgResponse = formatPgResponse(
						order.pgResponse,
					)
				}
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}
		getUserOrders()

		const openOrderPaymentDetailDialog = pgResponse => {
			if (!pgResponse) {
				warningSwal('결제 상세정보가 존재하지 않습니다')
				return
			}
			selectedPgResonse.value = pgResponse
			isOpenOrderPaymentDetailDialog.value =
				!isOpenOrderPaymentDetailDialog.value
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await getUserOrders()
		}

		return {
			headers,
			orders,
			currentPage,
			itemsPerPage,
			totalPages,
			isLoading,
			menu,
			isOpenOrderPaymentDetailDialog,
			selectedPgResonse,

			resolvePaymentMethod,

			handlePageChange,
			openOrderPaymentDetailDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
