export default [
	{
		path: '/market-locations',
		name: 'market-locations-list',
		component: () => import('@/pages/marketLocation/MarketLocationList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-locations/:marketLocationId',
		name: 'market-locations-detail',
		props: true,
		component: () => import('@/pages/marketLocation/MarketLocationDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-locations-register',
		name: 'market-locations-register',
		component: () => import('@/pages/marketLocation/MarketLocationRegister'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-locations/:marketLocationId/reservations',
		name: 'market-locations-detail-reservation',
		props: true,
		component: () =>
			import('@/pages/marketLocation/MarketLocationDetailReservation'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
