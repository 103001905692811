<template>
	<v-row no-gutters>
		<v-col cols="12" class="d-flex">
			<v-col md="8" class="pa-0">
				<v-card>
					<v-card-title>
						<router-link
							:to="{ name: 'market-course-list' }"
							class="text-decoration-none"
						>
							강의 리스트
						</router-link>
						<span class="mx-1">&#62;</span>
						{{ form.title }}
					</v-card-title>
					<v-divider class="mb-5" />
					<v-card-text>
						<validation-observer ref="observer" v-slot="{ valid }">
							<market-course-form :form.sync="form" />
							<div class="d-flex justify-end pb-6">
								<v-btn
									color="accent"
									@click="
										isOpenLinkMarketCourseLectureDialog =
											!isOpenLinkMarketCourseLectureDialog
									"
									class="mr-1"
								>
									강의 등록하기
								</v-btn>
								<v-btn color="error" @click="deleteMarketCourse" class="mr-1">
									삭제
								</v-btn>
								<v-btn
									color="primary"
									:disabled="!valid"
									@click="updateMarketCourse"
								>
									수정
								</v-btn>
							</div>
						</validation-observer>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col md="4" class="pt-0 pr-0 pb-0">
				<v-card height="669">
					<v-card-title>연결된 강의 리스트</v-card-title>
					<v-divider />
					<template v-if="marketCourseLectures.length > 0">
						<v-card
							v-for="(item, index) in marketCourseLectures"
							:key="'market-course-lecture' + index"
							class="ma-3 cursor-pointer"
							outlined
							@click="openMarketLectureDialog(item)"
						>
							<v-card-title class="text-wrap pr-16">
								{{ item.title }}
							</v-card-title>
							<v-card-subtitle>
								{{ item.createdAt | dateKOSimpleSiBunChoFormat }}
							</v-card-subtitle>
							<v-btn
								icon
								absolute
								top
								right
								@click.prevent="deleteMarketCourseLecture(item)"
							>
								<v-icon color="error">mdi-trash-can-outline</v-icon>
							</v-btn>
						</v-card>
					</template>
					<template v-else>
						<div
							class="d-flex align-center justify-center"
							style="height: 100%"
						>
							연결된 강의 정보가 없습니다
						</div>
					</template>
				</v-card>
			</v-col>
		</v-col>
		<market-course-link-lecture-dialog
			:is-open-link-market-course-lecture-dialog.sync="
				isOpenLinkMarketCourseLectureDialog
			"
			@fetch-market-course-lectures="isFetchMarketCourseLectures = $event"
			:market-course-id="marketCourseId"
		/>
		<market-course-lecture-detail-dialog
			@fetch-market-course-lectures="isFetchMarketCourseLectures = $event"
			:is-open-market-lecture-dialog.sync="isOpenMarketLectureDialog"
			:market-lecture-id="selectedMarketLectureId"
		/>
	</v-row>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketCourseService from '@/services/MarketCourseService'
import MarketLectureService from '@/services/MarketLectureService'

import MarketCourseForm from './components/MarketCourseForm.vue'
import MarketCourseLinkLectureDialog from './MarketCourseLinkLectureDialog.vue'
import MarketCourseLectureDetailDialog from './MarketCourseLectureDetailDialog.vue'

export default {
	components: {
		MarketCourseForm,
		MarketCourseLinkLectureDialog,
		MarketCourseLectureDetailDialog,
	},
	setup() {
		const form = ref({})
		const { route, router } = useRouter()
		const marketCourseId = ref(route.value.params.marketCourseId)
		const isOpenLinkMarketCourseLectureDialog = ref(false)
		const isFetchMarketCourseLectures = ref(false)
		const isOpenMarketLectureDialog = ref(false)
		const marketCourseLectures = ref([])
		const selectedMarketLectureId = ref(null)

		const getMarketCourseDetail = async () => {
			try {
				form.value = await MarketCourseService.getMarketCourseDetail(
					marketCourseId.value,
				)
			} catch (e) {
				console.error(e)
			}
		}

		const listMarketCourseLectures = async () => {
			try {
				marketCourseLectures.value =
					await MarketCourseService.listMarketCourseLectures(
						marketCourseId.value,
					)
			} catch (e) {
				console.error(e)
			} finally {
				isFetchMarketCourseLectures.value = false
			}
		}

		onMounted(() => {
			getMarketCourseDetail()
			listMarketCourseLectures()
		})

		watch(
			() => isFetchMarketCourseLectures.value,
			currentValue => {
				if (currentValue) {
					listMarketCourseLectures()
				}
			},
		)

		const openMarketLectureDialog = lecture => {
			selectedMarketLectureId.value = lecture.id
			isOpenMarketLectureDialog.value = !isOpenMarketLectureDialog.value
		}

		const updateMarketCourse = async () => {
			const confirm = await confirmSwal('과정을 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketCourseService.updateMarketCourse(form.value)
					successSwal('과정 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '과정을 수정하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const deleteMarketCourse = async () => {
			const confirm = await confirmSwal('과정을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketCourseService.deleteMarketCourse(form.value.id)
					await successSwal('과정 삭제가 완료되었습니다')
					listMarketCourseLectures()
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '과정을 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					router.push({ name: 'market-course-list' })
				}
			}
		}

		const deleteMarketCourseLecture = async event => {
			const confirm = await confirmSwal(
				`${event.title} 강의를 삭제하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketLectureService.deleteMarketLecture(event.id)
					successSwal('강의가 삭제되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '이벤트를 해제하는데 문제가 발생했습니다.',
					)
				} finally {
					listMarketCourseLectures()
				}
			}
		}

		return {
			form,
			marketCourseId,
			marketCourseLectures,
			isFetchMarketCourseLectures,
			isOpenMarketLectureDialog,
			isOpenLinkMarketCourseLectureDialog,
			selectedMarketLectureId,

			updateMarketCourse,
			deleteMarketCourse,
			deleteMarketCourseLecture,
			openMarketLectureDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
