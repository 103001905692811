<template>
	<v-row no-gutters>
		<v-col cols="12" md="12">
			<v-card>
				<v-card-title>
					<router-link
						:to="{ name: 'market-orders-list' }"
						class="text-decoration-none"
					>
						주문 상세 정보
					</router-link>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-card>
						<v-card-title>{{ marketOrder.title }}</v-card-title>
						<v-card-text>
							<v-simple-table class="order-table">
								<template v-slot:default>
									<tbody>
										<tr>
											<td>주문 고객</td>
											<td>
												<span
													class="open-modal-text"
													@click="isOpenUserInfoDialog = !isOpenUserInfoDialog"
												>
													고객 정보 보기
												</span>
											</td>
										</tr>
										<tr>
											<td>주문 금액</td>
											<td>
												<strong>{{ marketOrder.amount | commaFormat }}</strong>
												원
											</td>
										</tr>
										<tr>
											<td>주문 날짜</td>
											<td>
												{{ marketOrder.orderedAt | dateKOSimpleSiBunChoFormat }}
											</td>
										</tr>
										<tr>
											<td>결제 정보</td>
											<td class="px-0">
												<v-simple-table
													class="payment-table"
													v-if="marketOrder.paid"
												>
													<template v-slot:default>
														<tbody>
															<tr>
																<td>결제 상품</td>
																<td>
																	{{ marketOrder.pgResponse.name }}
																</td>
															</tr>
															<tr>
																<td>결제 금액</td>
																<td>
																	<strong>
																		{{
																			marketOrder.pgResponse.paid_amount
																				| commaFormat
																		}}
																	</strong>
																	원
																</td>
															</tr>
															<tr>
																<td>결제 수단</td>
																<td>
																	{{
																		resolvePaymentMethod(
																			marketOrder.pgResponse.pay_method,
																		)
																	}}
																	(
																	{{ marketOrder.pgResponse.card_name }}
																	{{ marketOrder.pgResponse.card_number }}
																	)
																</td>
															</tr>
															<tr>
																<td>구매자 이름</td>
																<td>
																	{{ marketOrder.pgResponse.buyer_name }}
																</td>
															</tr>
															<tr>
																<td>구매자 이메일</td>
																<td>
																	{{ marketOrder.pgResponse.buyer_email }}
																</td>
															</tr>
															<tr>
																<td>구매자 휴대폰번호</td>
																<td>
																	{{ marketOrder.pgResponse.buyer_tel }}
																</td>
															</tr>
															<tr>
																<td>구매자 결제 영수증</td>
																<td>
																	<v-btn
																		@click="
																			openReceiptUrl(
																				marketOrder.pgResponse.receipt_url,
																			)
																		"
																		small
																		color="secondary"
																	>
																		결제 영수증
																	</v-btn>
																</td>
															</tr>
															<tr>
																<td>환불 요청 여부</td>
																<td>
																	<div v-if="marketOrder.refundRequested">
																		<v-btn
																			color="secondary"
																			@click="refundApproval"
																			small
																		>
																			환불 승인하기
																		</v-btn>
																	</div>
																</td>
															</tr>
															<tr>
																<td>환불 요청 날짜</td>
																<td>
																	{{
																		marketOrder.refundRequestedAt
																			| dateKOSimpleSiBunChoFormat
																	}}
																</td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
												<div class="pl-4" v-else>결제 정보가 없습니다</div>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-card-text>
			</v-card>
		</v-col>
		<user-dialog
			:user-id="marketOrder.userId"
			:is-open-user-info-dialog.sync="isOpenUserInfoDialog"
		/>
	</v-row>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { resolvePaymentMethod } from '@core/utils/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketOrderService from '@/services/MarketOrderService'

import UserDialog from '@/components/common/UserDialog.vue'

export default {
	components: {
		UserDialog,
	},
	setup() {
		const { route } = useRouter()
		const marketOrderId = ref(route.value.params.marketOrderId)
		const marketOrder = ref({})
		const isOpenUserInfoDialog = ref(false)

		const getMarketOrder = async () => {
			try {
				const data = await MarketOrderService.getMarketOrder(
					marketOrderId.value,
				)
				data.pgResponse = JSON.parse(data.pgResponse)
				marketOrder.value = data
			} catch (e) {
				console.error(e)
			}
		}

		const openReceiptUrl = url => {
			window.open(url)
		}

		onMounted(() => {
			getMarketOrder()
		})

		const refundApproval = async () => {
			const confirm = await confirmSwal('환불 승인하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					// await MarketItemService.updateMarketItem(form.value)
					successSwal('환불이 승인되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '환불을 승인하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			marketOrder,
			isOpenUserInfoDialog,

			refundApproval,
			openReceiptUrl,
			resolvePaymentMethod,
		}
	},
}
</script>
<style lang="scss" scoped>
.order-table,
.payment-table {
	table {
		tr {
			td:first-child {
				width: 16%;
				color: #8c8c8c;
				font-weight: 700;
				background-color: #fbfbfb;
			}
		}
	}
}

.open-modal-text {
	cursor: pointer;
	color: #2271b1;
	text-decoration: underline;
}
</style>
