export default [
	{
		path: '/setting',
		name: 'setting',
		component: () => import('@/pages/setting/Setting'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
