<template>
	<v-card>
		<v-card-title class="d-flex justify-space-between py-2">
			<div class="d-flex align-center">구매 완료 리스트</div>
			<div>
				<v-select
					item-text="name"
					item-value="value"
					:items="dateRangeGroups"
					label="기간 조회"
					@change="changedDateRange"
					style="max-width: 110px"
					hide-details
					dense
					outlined
				/>
				<div class="d-flex align-center ml-1" v-if="isOpenDateRange">
					<date-picker-dialog
						:label="'시작날짜'"
						:date-value.sync="beginDate"
					/>
					<date-picker-dialog :label="'끝날짜'" :date-value.sync="endDate" />
					<v-btn @click="inquiryDate" color="primary">조회</v-btn>
				</div>
			</div>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="posts"
			:items-per-page="itemsPerPage"
			no-data-text="조회된 리워드 포인트 내역 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text="리워드 포인트 내역을 불러오는 중입니다..."
			hide-default-footer
		>
			<template v-slot:[`item.user`]="{ item }">
				<span @click="openUserInfoDialog(item.writerUser.id)" class="clickable">
					{{ item.writerUser.name }}
				</span>
			</template>
			<template v-slot:[`item.title`]="{ item }">
				<span @click="openPostDetailDialog(item.id)" class="clickable">
					{{ item.title }}
				</span>
			</template>
			<template v-slot:[`item.amount`]="{ item }">
				{{ item.resultJson.payment | commaFormat }} 원
			</template>
			<template v-slot:[`item.createdAt`]="{ item }">
				{{ item.createdAt | convertUTC2KST }}
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn color="primary" small @click="openPostDetailDialog(item.id)">
					포스트 보기
				</v-btn>
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
		<user-dialog
			:user-id="selectedUserId"
			:is-open-user-info-dialog.sync="isOpenUserInfoDialog"
		/>
		<market-post-detail-dialog
			:post-id="selectedPostId"
			:is-open-post-detail-dialog.sync="isOpenPostDetailDialog"
		/>
	</v-card>
</template>

<script>
import { ref, reactive, onMounted } from '@vue/composition-api'

import dayjs from 'dayjs'

import { contentsMarketStatus } from '@/helpers'
import { warningSwal } from '@/plugins/swalMixin'

import MarketPostService from '@/services/MarketPostService'

import UserDialog from '@/components/common/UserDialog.vue'
import DatePickerDialog from '@/components/common/DatePickerDialog.vue'
import MarketPostDetailDialog from '@/pages/marketPost/MarketPostDetailDialog.vue'

export default {
	components: {
		UserDialog,
		DatePickerDialog,
		MarketPostDetailDialog,
	},
	setup() {
		const posts = ref([])
		const isLoading = ref(false)
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const selectedUserId = ref(null)
		const selectedPostId = ref(null)
		const beginDate = ref('')
		const endDate = ref('')
		const isOpenDateRange = ref(false)
		const isOpenPostDetailDialog = ref(false)
		const isOpenUserInfoDialog = ref(false)
		const headers = ref([
			{
				text: 'id',
				align: 'left',
				value: 'id',
				width: '120px',
			},
			{ text: '콘텐츠 작성자', value: 'user' },
			{ text: '타이틀', value: 'title' },
			{ text: '내용', value: 'content' },
			{ text: '구매금액', value: 'amount' },
			{ text: '작성일', value: 'createdAt' },
			{ text: '', value: 'actions' },
		])
		const dateRangeGroups = reactive([
			{
				name: '전체',
				value: '',
			},
			{
				name: '1주일',
				value: 0.25,
			},
			{
				name: '1개월',
				value: 1,
			},
			{
				name: '3개월',
				value: 3,
			},
			{
				name: '직접입력',
				value: 0,
			},
		])

		const init = () => {
			currentPage.value = 0
			posts.value = []
		}

		const getMarketPosts = async () => {
			try {
				isLoading.value = true

				const data = await MarketPostService.getMarketPosts({
					limit: itemsPerPage.value,
					page: currentPage.value - 1,
					result: contentsMarketStatus.APPROVED.value,
					beginDate: beginDate.value,
					endDate: endDate.value,
					type: 'CONTEST2',
					published: true,
				})

				posts.value = data.posts
				totalPages.value = parseInt(data.totalPages)
			} catch (e) {
				console.log(e)
			} finally {
				isLoading.value = false
			}
		}

		const handlePageChange = async value => {
			init()
			currentPage.value = value
			await getMarketPosts()
		}

		const openUserInfoDialog = userId => {
			selectedUserId.value = userId
			isOpenUserInfoDialog.value = !isOpenUserInfoDialog.value
		}

		const openPostDetailDialog = postId => {
			if (!postId) {
				warningSwal('포스팅이 존재하지 않습니다')
				return
			}
			selectedPostId.value = postId
			isOpenPostDetailDialog.value = !isOpenPostDetailDialog.value
		}

		const changedDateRange = async dateRange => {
			if (dateRange === 0) {
				beginDate.value = ''
				endDate.value = ''

				isOpenDateRange.value = !isOpenDateRange.value
				return
			}
			isOpenDateRange.value = false

			init()

			if (dateRange) {
				let type = ''
				const currentDatetime = dayjs().format('YYYY-MM-DD  23:59:59')
				if (dateRange > 0.25) {
					type = 'month'
				} else {
					type = 'week'
					dateRange = 1
				}

				beginDate.value = dayjs(currentDatetime)
					.subtract(dateRange, type)
					.format('YYYY-MM-DD 00:00:01')
				endDate.value = currentDatetime
			}

			await getMarketPosts()
		}

		const inquiryDate = async () => {
			init()

			beginDate.value = dayjs(beginDate.value).format('YYYY-MM-DD 00:00:01')
			endDate.value = dayjs(endDate.value).format('YYYY-MM-DD 23:59:59')

			await getMarketPosts()
		}

		onMounted(() => {
			getMarketPosts()
		})

		return {
			posts,
			headers,
			isLoading,
			currentPage,
			itemsPerPage,
			totalPages,
			selectedUserId,
			selectedPostId,
			isOpenDateRange,
			beginDate,
			endDate,
			dateRangeGroups,
			isOpenUserInfoDialog,
			isOpenPostDetailDialog,

			inquiryDate,
			handlePageChange,
			changedDateRange,
			openUserInfoDialog,
			openPostDetailDialog,

			icons: {},
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
