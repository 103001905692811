<template>
	<v-dialog
		v-model="isOpenLicenseTestRoundWorkbookQuestionBankDialog"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				문제 은행
				<v-spacer />
				<v-btn
					icon
					@click="
						$emit(
							'update:is-open-license-test-round-workbook-question-bank-dialog',
							false,
						)
					"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<v-data-table
					:headers="tableColumnHeaders"
					:items="questions"
					v-model="selected"
					item-key="id"
					no-data-text="문제가 존재하지 않습니다"
					:single-select="singleSelect"
					show-select
					hide-default-footer
				>
					<template v-slot:[`item.question`]="{ item }">
						<td>
							<h3>{{ item.question }}</h3>

							<div
								v-for="(choice, index) in item.choices"
								:key="'choice-' + index"
								class="d-flex align-center my-2"
							>
								<span class="circle-number mr-3">{{ index + 1 }}</span>
								<span class="mr-2">{{ choice }}</span>
							</div>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn color="primary" @click="selectQuestion">선택</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import { useRouter } from '@core/utils'

export default {
	props: {
		isOpenLicenseTestRoundWorkbookQuestionBankDialog: {
			type: Boolean,
			required: true,
		},
		questionNumber: {
			required: true,
		},
	},
	components: {},
	setup(props, { emit }) {
		const { route } = useRouter()
		const singleSelect = ref(true)
		const selected = ref([])
		const licenseId = ref(route.value.params.licenseId)
		const testRoundId = ref(route.value.params.testRoundId)
		const tableColumnHeaders = reactive([
			{
				text: '문제',
				value: 'question',
				sortable: false,
			},
		])
		const questions = ref([
			{
				id: 1,
				question:
					'운영체제 분석을 위해 리눅스에서 버전을 확인하고자 할 때 사용되는 명령어는?',
				choices: ['ls', 'cat', 'pwd', 'uname'],
			},
			{
				id: 2,
				question:
					'통신을 위한 프로그램을 생성하여 포트를 할당하고, 클라이언트의 통신 요청 시 클라이언트와 연결하는 내,외부 송,수신 연계기술은?',
				choices: ['DB링크 기술', '소켓 기술', '스크럼 기술', '프로토타입 기술'],
			},
			{
				id: 3,
				question:
					'객체지향 개념에서 연관된 데이터와 함수를 함께 묶어 외부의 경계를 만들고 필요한 인터페이스만을 밖으로 드러내는 과정은?',
				choices: [
					'메시지(Message)',
					'캡슐화(Encapsulation)',
					'다형성(Polymorphism)',
					'상속(Inheritance)',
				],
			},
			{
				id: 4,
				question:
					'GoF(Gangs of Four) 디자인 패턴의 생성패턴에 속하지 않는 것은?',
				choices: [
					'추상 팩토리(Abstract Factory)',
					'빌더(Builder)',
					'어댑터(Adaptor)',
					'싱글턴(Singleton)',
				],
			},
			{
				id: 6,
				question:
					'기본 유스케이스 수행 시 특별한 조건을 만족할 때 수행하는 유스케이스는?',
				choices: ['연관', '확장', '선택', '특화'],
			},
			{
				id: 7,
				question: '다음 중 요구사항 모델링에 활용되지 않는 것은?',
				choices: [
					'애자일(Agile) 방법',
					'유스케이스 다이어그램(Use Case Diagram)',
					'시퀀스 다이어그램(Sequence Diagram)',
					'단계 다이어그램(Phase Diagram)',
				],
			},
		])

		const selectQuestion = async () => {
			const data = {
				...selected.value[0],
				...{
					questionNumber: props.questionNumber,
				},
			}
			emit('selected-question', data)
			emit(
				'update:is-open-license-test-round-workbook-question-bank-dialog',
				false,
			)
		}

		return {
			licenseId,
			testRoundId,
			selected,
			singleSelect,
			tableColumnHeaders,
			questions,

			selectQuestion,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
.circle-number {
	text-align: center;
	height: 20px;
	width: 20px;
	border: 1px solid;
	border-radius: 50%;
	display: inline-block;
}
</style>
