<template>
	<div>
		<validation-provider v-slot="{ errors }" name="name" rules="required">
			<v-select
				item-text="title"
				item-value="id"
				:items="licenses"
				:error-messages="errors"
				v-model="form.licenseId"
				readonly
				label="자격증을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="type" rules="required">
			<v-text-field
				v-model="form.type"
				type="text"
				:error-messages="errors"
				label="타입을 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="location" rules="required">
			<v-text-field
				v-model="form.location"
				type="text"
				:error-messages="errors"
				label="시험 장소를 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="location" rules="required">
			<v-text-field
				v-model="form.maxParticipants"
				type="text"
				:error-messages="errors"
				label="시험 정원을 입력하세요"
				autocomplete="off"
				suffix="명"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="testAt" rules="required">
			<v-text-field
				v-model="form.testAt"
				type="datetime-local"
				:error-messages="errors"
				label="시험 날짜를 입력하세요"
				required
			/>
		</validation-provider>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import MarketLicenseService from '@/services/MarketLicenseService'

export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const licenses = ref([])

		const listLicenses = async () => {
			licenses.value = await MarketLicenseService.listLicenses()
		}
		listLicenses()

		return {
			licenses,
		}
	},
}
</script>
