const questionType = {
	MULTIPLE_QUESTIONS_CHECKBOX: {
		name: '객관식',
		value: 'multipleQuestionsCheckbox',
	},
	MULTIPLE_QUESTIONS_SUBJECTIVE: {
		name: '주관식',
		value: 'multipleQuestionsSubjective',
	},
	ETC_OPTION: { name: '기타', value: 'etc_option' },
	ADD_SUBJECTIVE: { name: '주관식 답변 추가', value: 'addSubjective' },
	ADD_DETAIL_OPTION: { name: '상세 옵션 추가', value: 'addDetailOption' },
}

export default questionType
