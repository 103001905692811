<template>
	<div>
		<v-dialog v-model="dialog" :fullscreen="isSmAndDown">
			<template v-slot:activator="{ on, attrs }">
				<v-btn small text v-bind="attrs" v-on="on">
					<span class="mr-1" style="letter-spacing: -1px">
						<strong>
							{{ postCommentsCount }}
						</strong>
					</span>
					<v-badge
						color="error"
						:content="unreadPostCommentsCount"
						:value="unreadPostCommentsCount"
					>
						<v-icon small>mdi-message-outline</v-icon>
					</v-badge>
				</v-btn>
			</template>

			<v-card>
				<v-card-title class="d-flex justify-space-between">
					<div>
						<v-icon>mdi-message-outline</v-icon>
						코멘트
					</div>
					<v-btn icon small text @click="dialog = !dialog">
						<v-icon small>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-list>
					<v-list-item
						v-for="(postComment, index) in postComments"
						:key="'comment-' + index"
						style="align-items: start"
					>
						<v-list-item-avatar size="18">
							<v-img
								:src="
									postComment.user.publicPictureUrl ||
									require('@/assets/images/avatars/default.svg')
								"
							/>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-sm text-wrap">
								<div>
									<strong>
										{{
											postComment.user.marketerRole === 'ADMIN'
												? '관리자'
												: postComment.user.uid
										}}
									</strong>
									<span class="text-xs">
										{{ getTimeDiff(postComment.createdAt) }}
									</span>
								</div>
								<div>
									{{ postComment.title }}
								</div>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider />
				<v-card-actions class="d-flex justify-end">
					<v-textarea
						v-model="comment"
						placeholder="답변 달기..."
						outlined
						hide-details=""
						rows="2"
						style="background-color: white"
					/>
					<v-btn
						color="primary"
						:ripple="false"
						:disabled="!comment"
						class="px-0 font-weight-bold ml-2 upload-comment"
						@click="addComment"
						style="height: 58px !important"
					>
						등록
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'

import { getTimeDiff } from '@/filter'
import { getVuetify } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'

import MarketPostService from '@/services/MarketPostService'

export default {
	props: {
		postId: {
			type: Number,
		},
		unreadPostCommentsCount: {
			type: Number,
		},
		postCommentsCount: {
			type: Number,
		},
	},
	setup(props) {
		const $vuetify = getVuetify()
		const postComments = ref([])
		const comment = ref('')
		const dialog = ref(false)

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const getMarketPostDetail = async () => {
			try {
				const data = await MarketPostService.getMarketPostDetail(props.postId)

				postComments.value = data.comments
			} catch (e) {
				console.log(e)
			}
		}

		const adminReadMarketPostComments = async () => {
			const unReadCommentIds = postComments.value
				.filter(e => !e.readAdmin)
				.map(e => e.id)

			if (unReadCommentIds.length > 0) {
				try {
					for (let i = 0; i < unReadCommentIds.length; i++) {
						await MarketPostService.adminReadMarketPostComments(
							unReadCommentIds[i],
						)
					}
				} catch (e) {
					console.log(e)
				}
			}
		}

		const addComment = async () => {
			try {
				await MarketPostService.postMarketPostComment({
					id: props.postId,
					comment: comment.value,
				})

				await getMarketPostDetail()

				comment.value = ''
			} catch (e) {
				warningSwal({
					html:
						e.response.status === 400
							? e.response.data.message
							: '댓글을 업로드하는데 문제가 발생했습니다.',
				})
			}
		}

		watch(
			() => dialog.value,
			async currentValue => {
				if (currentValue) {
					await getMarketPostDetail()
					await adminReadMarketPostComments()
				}
			},
			{
				immediate: true,
			},
		)

		return {
			dialog,
			comment,
			isSmAndDown,
			postComments,

			addComment,
			getTimeDiff,
		}
	},
}
</script>
