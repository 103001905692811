import dayjs from 'dayjs'
import axios from '@/api/axios'

const marketBalanceService = {
	listMarketBalanceWithdrawals(payload) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (payload.beginDate) {
				params['beginDate'] = payload.beginDate
			}
			if (payload.endDate) {
				params['endDate'] = payload.endDate
			}
			if (payload.withdrawn) {
				params['withdrawn'] = payload.withdrawn
			}

			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-balance-withdrawals',
					params,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listMarketBalanceWithdrawalsDetail(payload) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (payload.beginDate) {
				const _beginDate = dayjs(payload.beginDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['begin_date'] = _beginDate
			}
			if (payload.endDate) {
				const _endDate = dayjs(payload.endDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['end_date'] = _endDate
			}
			if (payload.withdrawn) {
				params['withdrawn'] = payload.withdrawn
			}
			if (payload.limit) {
				params['limit'] = payload.limit
			}
			if (payload.page >= 0) {
				params['page'] = payload.page
			}
			if (payload.dateCriteria) {
				params['date_criteria'] = payload.dateCriteria
			}

			try {
				const response = await axios({
					method: 'GET',
					url: '/market-balance-withdrawals/detail',
					params,
				})

				resolve({
					data: response.data,
					totalPages: response.headers['x-total-pages-count'],
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postMarketBalanceCompleteWithdrawal(withdarawlId, payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-balance-withdrawals/${withdarawlId}/actions/complete-withdrawal`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	downloadMarketBalanceWithdrawalExcel(payload) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (payload.beginDate) {
				const _beginDate = dayjs(payload.beginDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['begin_date'] = _beginDate
			}
			if (payload.endDate) {
				const _endDate = dayjs(payload.endDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['end_date'] = _endDate
			}
			if (payload.withdrawn) {
				params['withdrawn'] = payload.withdrawn
			}
			if (payload.limit) {
				params['limit'] = payload.limit
			}
			if (payload.page >= 0) {
				params['page'] = payload.page
			}
			if (payload.dateCriteria) {
				params['date_criteria'] = payload.dateCriteria
			}

			try {
				const response = await axios({
					method: 'GET',
					responseType: 'blob',
					url: '/market-balance-withdrawals/excel',
					params,
				})
				resolve(response)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketBalanceService
