export default [
	{
		path: '/market-posts',
		name: 'market-post-list',
		props: true,
		component: () => import('@/pages/marketPost/MarketPostList'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		// children: [
		// 	{
		// 		path: ':postId',
		// 		name: 'market-post-detail',
		// 		props: true,
		// 		component: () => import('@/pages/marketPost/MarketPostDetailDialog'),
		// 		meta: {
		// 			layout: 'LayoutContent',
		// 			requiresAuth: true,
		// 		},
		// 	},
		// ],
	},
	{
		path: '/market-post-payments',
		name: 'market-post-payment-list',
		component: () => import('@/pages/marketPost/MarketPostPaymentList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-post-subjects',
		name: 'market-post-subject-list',
		component: () => import('@/pages/marketPost/MarketPostSubjectList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-post-subjects/:subjectId',
		name: 'market-post-subject-detail',
		props: true,
		component: () => import('@/pages/marketPost/MarketPostSubjectDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-post-subject-register',
		name: 'market-post-subject-register',
		component: () => import('@/pages/marketPost/MarketPostSubjectDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-post-private-inquiry',
		name: 'market-post-private-inquiry',
		component: () =>
			import('@/pages/marketPostPrivateInquiry/MarketPostPrivateInquiry'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
