export default [
	{
		path: '/users',
		name: 'user-list',
		props: true,
		component: () => import('@/pages/user/UserList.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/users/:userId',
		name: 'user-detail',
		props: true,
		component: () => import('@/pages/user/UserDetail.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/users-id-photos',
		name: 'user-id-photo-list',
		props: true,
		component: () => import('@/pages/user/UserIdPhotoList.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
