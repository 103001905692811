<template>
	<v-card elevation="0">
		<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
			<v-tab v-for="(item, index) in items" :key="'tab-' + index">
				{{ item }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item>
				<event-table-list
					:category="educationLicenseCategory"
					:event-category="eventCategory"
				/>
			</v-tab-item>
			<v-tab-item>
				<event-table-list
					:category="licenseTestCategory"
					:event-category="eventCategory"
				/>
			</v-tab-item>
			<v-tab-item>
				<v-simple-table fixed-header height="560px">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">타이틀</th>
								<th class="text-center">내용</th>
								<th class="text-center">공개여부</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="licenses.length > 0">
								<tr
									v-for="(item, index) in licenses"
									:key="index"
									class="cursor-pointer"
									@click="routeToDetail(item.id)"
								>
									<td class="text-center">
										<strong>{{ item.title }}</strong>
									</td>
									<td class="text-center">{{ item.body }}</td>
									<td class="text-center">
										{{ item.visible ? '공개' : '비공개' }}
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td colspan="6">현재 동록한 자격 시험이 없습니다</td>
								</tr>
							</template>
						</tbody>
					</template>
				</v-simple-table>
				<v-card-actions class="d-flex justify-end mt-5">
					<v-btn
						@click="isOpenLicenseRegisterDialog = !isOpenLicenseRegisterDialog"
						color="primary"
					>
						자격증 등록
					</v-btn>
				</v-card-actions>
			</v-tab-item>
		</v-tabs-items>
		<license-register-dialog
			:is-open-license-register-dialog.sync="isOpenLicenseRegisterDialog"
			@is-fetch-licenses="isFetchLicenses = $event"
		/>
	</v-card>
</template>

<script>
import { ref, reactive, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { eventCategory } from '@/helpers'

import MarketLicenseService from '@/services/MarketLicenseService'

import EventTableList from '@/pages/event/EventTableList.vue'
import LicenseRegisterDialog from './LicenseRegisterDialog.vue'

export default {
	components: {
		LicenseRegisterDialog,
		EventTableList,
	},
	setup() {
		const tab = ref(null)
		const items = reactive(['교육', '시험', '자격증'])
		const { router } = useRouter()
		const educationLicenseCategory = ref(eventCategory.EDUCATION_LICENSE.value)
		const licenseTestCategory = ref(eventCategory.LICENSE_TEST.value)
		const licenses = ref([])
		const isFetchLicenses = ref(false)
		const selectedLicense = ref({})
		const isOpenLicenseRegisterDialog = ref(false)
		const isOpenLicenseEditDialog = ref(false)

		const listLicenses = async () => {
			licenses.value = await MarketLicenseService.listLicenses()
		}
		listLicenses()

		const routeToDetail = licenseId => {
			router.push({ name: 'license-detail', params: { licenseId: licenseId } })
		}

		watch(
			() => isFetchLicenses.value,
			currentValue => {
				if (currentValue) {
					listLicenses()
					isFetchLicenses.value = false
				}
			},
		)

		return {
			tab,
			items,
			eventCategory,
			licenses,
			isFetchLicenses,
			selectedLicense,
			isOpenLicenseRegisterDialog,
			isOpenLicenseEditDialog,
			educationLicenseCategory,
			licenseTestCategory,

			routeToDetail,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
