<template>
	<v-dialog v-model="isOpenNoticeEditDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				공지사항 보기
				<v-spacer />
				<v-btn icon @click="$emit('update:is-open-notice-edit-dialog', false)">
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<notice-form :form.sync="form" />
					<div class="d-flex justify-end pb-4 mt-4">
						<v-btn
							color="primary"
							:disabled="!valid"
							@click.stop="editNotice"
							class="mr-1"
						>
							수정
						</v-btn>
						<v-btn color="error" @click.stop="deleteNotice">삭제</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { watch, ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import NoticeService from '@/services/NoticeService'

import NoticeForm from './components/NoticeForm.vue'

export default {
	props: {
		isOpenNoticeEditDialog: {
			type: Boolean,
			required: true,
		},
		selectedNoticeId: {
			type: [Number, String],
		},
	},
	components: {
		NoticeForm,
	},
	setup(props, { emit }) {
		const form = ref({})

		watch(
			() => props.selectedNoticeId,
			async currentValue => {
				if (currentValue) {
					const data = await NoticeService.getNoticeDetail(currentValue)
					form.value = { ...data }
				}
			},
		)

		const editNotice = async () => {
			const confirm = await confirmSwal('공지사항을 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await NoticeService.updateNotice(form.value)
					emit('is-fetch-notices', true)
					successSwal('공지사항 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '공지사항을 수정하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-notice-edit-dialog', false)
				}
			}
		}

		const deleteNotice = async () => {
			const confirm = await confirmSwal('공지사항을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await NoticeService.deleteNotice(form.value.id)
					emit('is-fetch-notices', true)
					successSwal('공지사항 삭제가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '공지사항을 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-notice-edit-dialog', false)
				}
			}
		}

		return {
			form,

			editNotice,
			deleteNotice,
		}
	},
}
</script>
