<template>
	<v-card
		class="card-form pa-4"
		@click="clickCardForm"
		:id="`card-form-${formIndex}`"
		:ripple="false"
	>
		<v-row class="mb-1">
			<v-col md="9">
				<v-text-field
					placeholder="다음 중 자신에게 해당하는 항목을 모두 체크하세요."
					v-model="form.title"
					hide-details
					filled
				/>
			</v-col>
			<v-col md="3">
				<v-select
					item-text="name"
					item-value="value"
					:items="typeGroups"
					v-model="form.type"
					hide-details
					outlined
				/>

				<v-select
					item-text="name"
					item-value="value"
					:items="scoringQuestionCategoryGroups"
					v-model="form.scoringQuestionCategory"
					style="max-width: 160px"
					label="스코어링 타입"
					hide-details
				/>
			</v-col>
		</v-row>
		<v-row class="d-flex justify-space-between" no-gutters>
			<div>
				<v-select
					item-text="name"
					item-value="value"
					:items="numberOfAnswersGroups"
					v-model="form.numberOfAnswers"
					dense
					style="max-width: 160px"
					label="주관식 답변 수 선택"
					hide-details
					v-if="form.type === questionType.MULTIPLE_QUESTIONS_SUBJECTIVE.value"
				/>
			</div>

			<v-checkbox
				label="항목에 이미지 표시 여부"
				v-model="form.hasFieldImage"
				hide-details
				class="mt-0"
			/>
		</v-row>

		<draggable
			v-model="form.selections"
			@start="drag = true"
			@end="drag = false"
			draggable=".item:not(.exclude-item)"
		>
			<v-row
				class="mb-2 item"
				v-for="(selection, selectionIndex) in form.selections"
				:key="`selection-` + selectionIndex"
				no-gutters
				align="center"
				style="cursor: move"
				:class="
					selection.title == questionType.ETC_OPTION.name ? 'exclude-item' : ''
				"
			>
				<div class="d-flex flex-column flex-grow-1">
					<div class="d-flex flex-grow-1 align-center">
						<v-checkbox disabled hide-details class="shrink mr-2 mt-0" />
						<v-text-field
							v-model="selection.title"
							hide-details
							ref="selectionTitle"
							:disabled="selection.title == questionType.ETC_OPTION.name"
							class="pt-0"
						/>

						<v-tooltip bottom absolute v-if="form.selections.length > 1">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									@click="removeOption(selectionIndex)"
									v-bind="attrs"
									v-on="on"
									icon
								>
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</template>
							<span>삭제</span>
						</v-tooltip>

						<v-menu
							bottom
							right
							v-if="selection.title != questionType.ETC_OPTION.name"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>

							<v-list>
								<v-list-item
									v-for="(item, i) in items"
									:key="i"
									link
									:disabled="
										(item.type !== 'addSubjective' &&
											selection.selections &&
											selection.selections[0] &&
											selection.selections[0].subjective) ||
										(item.type === 'addSubjective' &&
											selection.selections &&
											selection.selections[0] &&
											!selection.selections[0].subjective)
									"
									@click="
										item.type === 'addDetailOption'
											? addDetailOption(selectionIndex)
											: addSubjective(selectionIndex)
									"
								>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
					<div class="mt-1">
						<v-simple-table>
							<thead>
								<tr>
									<th class="text-left">비율</th>
									<th class="text-left">방법</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<v-text-field
											hide-details
											placeholder="비율을 입력하세요"
											v-model="selection.ratio"
										/>
									</td>
									<td>
										<v-select
											item-text="name"
											item-value="value"
											:items="scoringAnswerTypeGroups"
											v-model="selection.method"
											style="max-width: 160px"
											placeholder="방법을 선택하세요"
											hide-details
										/>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</div>
					<div class="pl-6 d-flex flex-column flex-grow-1 align-center mt-2">
						<v-row
							v-for="(selection, selectionDetailIndex) in selection.selections"
							:key="'selection-selection-' + selectionDetailIndex"
							class="mb-2"
							no-gutters
							style="width: 100%"
						>
							<div class="d-flex flex-grow-1 align-center">
								<v-checkbox disabled hide-details class="shrink mr-2 mt-0" />
								<v-text-field
									v-model="selection.title"
									hide-details
									ref="selectionTitle"
									:placeholder="
										selection.subjective ? '주관식 질문을 입력하세요' : ''
									"
								/>

								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											@click="
												removeDetailOption(selectionIndex, selectionDetailIndex)
											"
											v-bind="attrs"
											v-on="on"
											icon
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</template>
									<span>삭제</span>
								</v-tooltip>
							</div>
						</v-row>
						<v-row
							class="mt-3"
							no-gutters
							:key="componentKey"
							v-if="
								selection.selections &&
								selection.selections.length > 0 &&
								!selection.selections[0].subjective
							"
							style="width: 100%"
						>
							<v-checkbox disabled hide-details class="shrink mr-2 mt-0">
								<template v-slot:label>
									<v-btn
										@click="addDetailOption(selectionIndex)"
										class="px-0"
										style="min-width: 60px"
										text
									>
										옵션추가
									</v-btn>
									<span
										class="text-sm"
										v-if="isAddedDetailEtcOption(selectionIndex) === -1"
									>
										또는
									</span>
									<v-btn
										@click="addDetailEtcOption(selectionIndex)"
										class="px-0"
										style="min-width: 60px"
										text
										color="#1a73e8"
										v-if="isAddedDetailEtcOption(selectionIndex) === -1"
									>
										&lsquo;기타&rsquo;추가
									</v-btn>
								</template>
							</v-checkbox>
						</v-row>
					</div>
				</div>
			</v-row>
		</draggable>
		<v-row class="mt-3" no-gutters :key="componentKey">
			<v-checkbox disabled hide-details class="shrink mr-2 mt-0">
				<template v-slot:label>
					<v-btn @click="addOption" class="px-0" style="min-width: 60px" text>
						옵션추가
					</v-btn>
					<span class="text-sm" v-if="isAddedEtcOption === -1">또는</span>
					<v-btn
						@click="addEtcOption"
						class="px-0"
						style="min-width: 60px"
						text
						color="#1a73e8"
						v-if="isAddedEtcOption === -1"
					>
						&lsquo;기타&rsquo;추가
					</v-btn>
				</template>
			</v-checkbox>
		</v-row>
		<v-divider class="mt-5" />
		<v-card-actions class="pt-4 pb-1 d-flex justify-end">
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						large
						icon
						@click="$emit('copy-card-form', formIndex)"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-content-copy</v-icon>
					</v-btn>
				</template>
				<span>복사</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						large
						icon
						@click="$emit('delete-card-form', formIndex)"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-trash-can-outline</v-icon>
					</v-btn>
				</template>
				<span>삭제</span>
			</v-tooltip>
		</v-card-actions>
	</v-card>
</template>
<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api'

import { questionType } from '@/helpers'

import draggable from 'vuedraggable'

const MAX_NUMBER_OF_ANSWERS = 20

export default {
	components: {
		draggable,
	},
	props: {
		form: {
			type: Object,
		},
		formIndex: {
			type: Number,
		},
	},
	setup(props) {
		const drag = ref(false)
		const componentKey = ref(0)
		const selectionTitle = ref(null)
		const numberOfAnswersGroups = ref([])
		const scoringQuestionCategoryGroups = reactive([
			{
				name: '스코어링A',
				value: 'A',
			},
			{
				name: '스코어링B',
				value: 'B',
			},
			{
				name: '스코어링C',
				value: 'C',
			},
			{
				name: '스코어링D',
				value: 'D',
			},
			{
				name: '스코어링E',
				value: 'E',
			},
		])
		const scoringAnswerTypeGroups = reactive([
			{
				name: '스코어링AA',
				value: 'AA',
			},
			{
				name: '스코어링BB',
				value: 'BB',
			},
			{
				name: '스코어링CC',
				value: 'CC',
			},
			{
				name: '스코어링DD',
				value: 'DD',
			},
			{
				name: '스코어링EE',
				value: 'EE',
			},
		])
		const typeGroups = reactive([
			{
				name: questionType.MULTIPLE_QUESTIONS_CHECKBOX.name,
				value: questionType.MULTIPLE_QUESTIONS_CHECKBOX.value,
			},
			{
				name: questionType.MULTIPLE_QUESTIONS_SUBJECTIVE.name,
				value: questionType.MULTIPLE_QUESTIONS_SUBJECTIVE.value,
			},
		])
		const items = ref([
			{
				title: questionType.ADD_SUBJECTIVE.name,
				type: questionType.ADD_SUBJECTIVE.value,
			},
			{
				title: questionType.ADD_DETAIL_OPTION.name,
				type: questionType.ADD_DETAIL_OPTION.value,
			},
		])

		const clickCardForm = () => {
			const elements = document.getElementsByClassName('card-form')
			Object.values(elements).forEach(element => {
				element.classList.remove('active')
			})

			const element = document.getElementById(`card-form-${props.formIndex}`)
			if (element) {
				element.classList.add('active')
			}
		}

		const simpleEndArray = end => {
			const temp = []
			for (let i = 0; i < end; i++) {
				const val = i + 1
				temp.push({
					name: `${val}개`,
					value: val,
				})
			}
			return temp
		}

		const isAddedEtcOption = computed(() => {
			return props.form.selections.findIndex(
				e => e.title === questionType.ETC_OPTION.name,
			)
		})

		const addOption = () => {
			const templateOption = {
				title: `옵션${props.form.selections.length + 1}`,
			}

			if (isAddedEtcOption.value !== -1) {
				props.form.selections.splice(isAddedEtcOption.value, 0, templateOption)
			} else {
				props.form.selections.push(templateOption)
			}
		}

		const addEtcOption = () => {
			if (isAddedEtcOption.value === -1) {
				props.form.selections.push({
					title: questionType.ETC_OPTION.name,
					subjective: true,
				})
			}
		}

		const forceRerender = () => {
			componentKey.value += 1
		}

		const addSubjective = index => {
			props.form.selections[index].selections = [
				{ title: '', subjective: true },
			]
			forceRerender()
		}

		const isAddedDetailEtcOption = index => {
			if (
				props.form.selections[index] &&
				props.form.selections[index].selections
			) {
				return props.form.selections[index].selections?.findIndex(
					e => e.title === questionType.ETC_OPTION.name,
				)
			} else {
				return -1
			}
		}

		const addDetailOption = index => {
			const templateOption = {
				title: `상세옵션${
					(props.form.selections[index]?.selections?.length || 0) + 1
				}`,
			}

			if (isAddedDetailEtcOption(index) !== -1) {
				props.form.selections[index].selections.splice(
					isAddedDetailEtcOption(index),
					0,
					templateOption,
				)
			} else {
				if (
					props.form.selections[index] &&
					props.form.selections[index].selections
				) {
					props.form.selections[index].selections.push(templateOption)
				} else {
					props.form.selections[index].selections = [templateOption]
				}
			}
			forceRerender()
		}

		const addDetailEtcOption = index => {
			if (isAddedDetailEtcOption(index) === -1) {
				props.form.selections[index].selections.push({
					title: questionType.ETC_OPTION.name,
					subjective: true,
				})
				forceRerender()
			}
		}

		const removeOption = index => {
			props.form.selections.splice(index, 1)
		}

		const removeDetailOption = (index, detailIndex) => {
			props.form.selections[index].selections.splice(detailIndex, 1)
			forceRerender()
		}

		onMounted(() => {
			numberOfAnswersGroups.value = [...simpleEndArray(MAX_NUMBER_OF_ANSWERS)]
		})

		return {
			drag,
			items,
			typeGroups,
			componentKey,
			selectionTitle,
			isAddedEtcOption,
			isAddedDetailEtcOption,
			numberOfAnswersGroups,
			scoringAnswerTypeGroups,
			scoringQuestionCategoryGroups,

			questionType,

			addOption,
			addEtcOption,
			removeOption,
			removeDetailOption,
			addDetailOption,
			addDetailEtcOption,
			addSubjective,
			clickCardForm,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	label.v-label {
		pointer-events: all;
	}
}

.card-form {
	&.active {
		border-left: 6px solid #0d6efd;
	}
}
.v-card--link:focus::before {
	opacity: 0;
}

.item {
	box-shadow: 0 2px 10px 0 rgba(51, 51, 51, 0.1);
	margin: 12px auto;
	padding: 12px;
}
</style>
