<template>
	<div>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="visible" rules="required">
			<v-text-field
				v-model="form.description"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="설명을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider name="visible">
			<v-checkbox
				class="mb-2"
				v-model="form.visible"
				autocomplete="off"
				label="공개 여부"
				hide-details
			/>
		</validation-provider>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {}
	},
}
</script>
