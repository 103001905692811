<template>
	<v-dialog v-model="isOpenMediaDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				콘텐츠 자세히 보기
				<v-spacer />
				<v-btn icon @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<div v-if="isImage(fileUrl)" class="text-center">
				<img :src="fileUrl" style="max-width: 100%; max-height: 100%" />
			</div>
			<div class="text-center" v-else>
				<video style="width: 100%">
					<source :src="fileUrl" />
				</video>
			</div>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	components: {},
	props: {
		fileUrl: {
			default: null,
		},
		isOpenMediaDialog: {
			required: true,
		},
	},
	setup(_, { emit }) {
		const isImage = url => {
			return url?.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
		}

		const closeDialog = () => {
			emit('update:is-open-media-dialog', false)
		}

		return {
			isImage,

			closeDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
img {
	width: 100%;
}
</style>
