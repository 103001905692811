export default [
	{
		path: '/',
		redirect: '/dashboard',
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('@/pages/dashboard/Dashboard.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
