<template>
	<v-card flat>
		<v-card-text class="px-0">
			<v-row no-gutters>
				<v-select
					item-text="name"
					item-value="value"
					:items="searchCategoryGroups"
					label="검색 항목 선택"
					@change="$emit('selected-search-category', $event)"
					hide-details
					class="mr-1"
					style="max-width: 200px"
					outlined
				/>
				<v-text-field
					placeholder="검색어 입력"
					outlined
					ref="searchField"
					hide-details
					prepend-inner-icon="mdi-magnify"
					class="d-flex align-center mr-1"
					:style="{ maxWidth: isSmAndDown ? '210px' : '' }"
					@change="$emit('keyword', $event)"
				/>
			</v-row>
			<v-row no-gutters class="mt-4 d-flex justify-space-between">
				<div class="d-flex">
					<v-select
						item-text="name"
						item-value="value"
						:items="itemsPerPageGroups"
						label="페이지당 개수"
						@change="changeItemsPerPage"
						:value="10"
						style="max-width: 100px"
						class="mr-1"
						hide-details
						dense
						outlined
					/>
					<v-select
						item-text="name"
						item-value="value"
						:items="licenseGroups"
						label="자격증 급수"
						:value="selectedLicense"
						@change="changeLicense"
						hide-details
						class="mr-1"
						style="max-width: 100px"
						dense
						outlined
					/>
					<v-select
						item-text="name"
						item-value="value"
						:items="dateCriteriaGroups"
						label="날짜 기준 선택"
						:value="selectedDateCriteria"
						@change="changeDateCriteria"
						hide-details
						class="mr-1"
						style="max-width: 200px"
						dense
						outlined
					/>
					<date-picker-range-dialog
						:label="'시작 - 종료 날짜를 입력하세요'"
						:date-value.sync="dateRange"
						:width="244"
					/>
				</div>
				<div class="d-flex">
					<v-btn large @click="inquiry" color="primary">조회</v-btn>
				</div>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import DatePickerRangeDialog from '@/components/common/DatePickerRangeDialog.vue'

export default {
	components: {
		DatePickerRangeDialog,
	},
	setup(props, { emit }) {
		const $vuetify = getVuetify()
		const isLoading = ref(false)
		const itemsPerPageGroups = reactive([
			{
				name: '10',
				value: 10,
			},
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])
		const searchCategoryGroups = reactive([
			{
				name: '이름',
				value: 'user_name',
			},
			{
				name: '유저명',
				value: 'user_uid',
			},
			{
				name: '휴대폰번호',
				value: 'user_phone_number',
			},
			{
				name: '추천인',
				value: 'recommender_user_name',
			},
			{
				name: '추천인 유저명',
				value: 'recommender_user_uid',
			},
			{
				name: '추천인 휴대폰번호',
				value: 'recommender_user_phone_number',
			},
			{
				name: '상위리더',
				value: 'ancestor_user_name',
			},
			{
				name: '상위리더 유저명',
				value: 'ancestor_user_uid',
			},
			{
				name: '상위리더 휴대폰번호',
				value: 'ancestor_user_phone_number',
			},
		])
		const selectedLicense = ref('')
		const licenseGroups = reactive([
			{
				name: '전체',
				value: '',
			},
			{
				name: '3급',
				value: 3,
			},
			{
				name: '2급',
				value: 2,
			},
			{
				name: '1급',
				value: 1,
			},
		])
		const selectedDateCriteria = ref('')
		const dateCriteriaGroups = reactive([
			{
				name: '선택 안함',
				value: '',
			},
			{
				name: '회원 가입일',
				value: 'signupDate',
			},
			{
				name: '강의 신청일',
				value: 'paymentDate',
			},
		])
		const beginDate = ref('')
		const endDate = ref('')
		const isShowRecommender = ref([])
		const isShowAncestorUser = ref([])

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const dateRange = computed({
			get() {
				return [beginDate.value, endDate.value]
			},
			set(value) {
				const temp = value.sort(function (a, b) {
					return new Date(a) - new Date(b)
				})

				beginDate.value = `${temp[0]} 00:00:00`
				endDate.value = `${temp[1]} 23:59:59`

				emit('begin-date', beginDate.value)
				emit('end-date', endDate.value)
			},
		})

		const changeLicense = value => {
			emit('license-ids', value)
		}

		const changeSearchCategory = () => {}

		const changeItemsPerPage = itemsPerPage => {
			emit('items-per-page', itemsPerPage)
		}

		const changeDateCriteria = criteria => {
			emit('date-criteria', criteria)
		}

		const inquiry = async () => {
			emit('inquiry', true)
		}

		return {
			isLoading,
			dateRange,
			isSmAndDown,
			itemsPerPageGroups,
			searchCategoryGroups,
			licenseGroups,
			selectedLicense,
			selectedDateCriteria,
			dateCriteriaGroups,

			isShowRecommender,
			isShowAncestorUser,

			inquiry,
			changeSearchCategory,
			changeItemsPerPage,
			changeLicense,
			changeDateCriteria,
		}
	},
}
</script>
<style lang="scss" scoped>
.finished,
.pass {
	color: #16b1ff;
}
.unfinished,
.failed {
	color: #ff4c51;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
