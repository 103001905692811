import { render, staticRenderFns } from "./MarketLocationReservationList.vue?vue&type=template&id=5f5fc7f9&scoped=true&"
import script from "./MarketLocationReservationList.vue?vue&type=script&lang=js&"
export * from "./MarketLocationReservationList.vue?vue&type=script&lang=js&"
import style0 from "./MarketLocationReservationList.vue?vue&type=style&index=0&id=5f5fc7f9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5fc7f9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VCard,VCardTitle,VChip,VDataTable,VIcon,VListItem,VListItemContent,VListItemTitle})
