export default [
	{
		path: '/events',
		name: 'events',
		component: () => import('@/pages/event/Event'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/events/:eventId',
		name: 'event-detail',
		props: true,
		component: () => import('@/pages/event/EventDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/events/:eventId/participants',
		name: 'event-participants',
		props: true,
		component: () => import('@/pages/event/EventParticipants'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
