export default [
	{
		path: '/market-items',
		name: 'market-items-list',
		component: () => import('@/pages/marketItem/MarketItemList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-items/:marketItemId',
		name: 'market-items-detail',
		props: true,
		component: () => import('@/pages/marketItem/MarketItemDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-items-register',
		name: 'market-items-register',
		component: () => import('@/pages/marketItem/MarketItemRegister'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
