<template>
	<v-card elevation="0">
		<v-card-title class="d-flex justify-space-between">
			콘텐츠 주제 리스트
			<div class="d-flex" style="gap: 4px">
				<date-picker-dialog :label="'날짜'" :date-value.sync="date" />

				<v-select
					item-text="name"
					item-value="value"
					:items="typeGroups"
					v-model="selectedType"
					:disabled="isLoading"
					:menu-props="{
						closeOnClick: true,
						closeOnContentClick: true,
					}"
					label="콘텐츠 타입"
					style="max-width: 138px"
					hide-details
					dense
					outlined
				/>

				<v-select
					item-text="name"
					item-value="value"
					v-model="selectedLoungeType"
					:items="subjectLoungeTypeGroups"
					:disabled="isLoading"
					:menu-props="{
						closeOnClick: true,
						closeOnContentClick: true,
					}"
					label="라운지 위치"
					style="max-width: 138px"
					hide-details
					dense
					outlined
					v-if="selectedType === postTypeHelper.CONTEST2.value"
				/>

				<v-text-field
					v-model="keyword"
					append-icon="mdi-magnify"
					label="검색어 입력"
					class="search-field ml-1"
					@keyup.enter.prevent="inquiry"
					ref="searchField"
					dense
					outlined
					single-line
					hide-details
					autocomplete="off"
				/>

				<v-btn @click="inquiry" color="primary">조회</v-btn>
			</div>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="subjects"
			:items-per-page="itemsPerPage"
			item-key="email"
			no-data-text="조회된 주제가 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text="주제를 불러오는 중입니다..."
			hide-default-footer
			@click:row="routeToDetail($event.id)"
		>
			<template v-slot:[`item.type`]="{ item }">
				{{ resolvePostType(item.type) }}
			</template>
			<template v-slot:[`item.lounge`]="{ item }">
				{{ resoloveSubjectLoungeType(item.lounge) }}
			</template>
			<template v-slot:[`item.startDate`]="{ item }">
				{{ item.startDate | dateKOSimpleFormat }}
			</template>
			<template v-slot:[`item.endDate`]="{ item }">
				{{ item.endDate | dateKOSimpleFormat }}
			</template>
			<template v-slot:[`item.visible`]="{ item }">
				{{ item.visible ? '공개' : '비공개' }}
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn :to="{ name: 'market-post-subject-register' }" color="primary">
				등록
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import dayjs from 'dayjs'

import { useRouter } from '@core/utils'
import { postType as postTypeHelper, subjectLoungeType, error } from '@/helpers'
import { resolvePostType, resoloveSubjectLoungeType } from '@core/utils/filter'

import MarketPostSubjectService from '@/services/MarketPostSubjectService'

import DatePickerDialog from '@/components/common/DatePickerDialog.vue'

export default {
	components: {
		DatePickerDialog,
	},
	setup() {
		const { route, router } = useRouter()
		const isLoading = ref(false)
		const subjects = ref([])
		const date = ref(
			(route.value.query.date &&
				dayjs(route.value.query.date).format('YYYY-MM-DD')) ??
				'',
		)
		const keyword = ref(route.value.query.query ?? '')
		const currentPage = ref(
			(route.value.query.page && parseInt(route.value.query.page)) ?? 1,
		)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const selectedType = ref(null)
		const selectedLoungeType = ref(null)
		const typeGroups = ref([
			postTypeHelper.CONTEST2,
			postTypeHelper.CONTEST2_DONA,
			{
				name: '전체',
				value: '',
			},
		])
		const subjectLoungeTypeGroups = ref([
			{
				name: '전체',
				value: '',
			},
			subjectLoungeType.MIR_YONGSAN,
			subjectLoungeType.MIR_UIJEONGBU,
			subjectLoungeType.MIR_JEONJU,
		])
		const headers = ref([
			{
				text: '타입',
				align: 'left',
				value: 'type',
			},
			{
				text: '타이틀',
				align: 'left',
				value: 'title',
			},
			{
				text: '위치',
				align: 'left',
				value: 'lounge',
			},
			{ text: '시작날짜', value: 'startDate' },
			{ text: '종료날짜', value: 'endDate' },
			{ text: '공개 여부', value: 'visible' },
		])

		const getAll = async () => {
			isLoading.value = true
			const payload = {
				page: currentPage.value - 1,
				limit: itemsPerPage.value,
				orderby: 'endDate',
				order: 'desc',
			}

			try {
				if (date.value && date.value !== error.INVALID_DATE.value) {
					payload.date = dayjs(date.value)
						.utc()
						.format()
						.toString()
						.replace('T', ' ')
						.replace('Z', '')
				}

				selectedType.value
					? (payload.type = selectedType.value)
					: (payload.types = `${postTypeHelper.CONTEST2.value},${postTypeHelper.CONTEST2_DONA.value}`)

				if (
					selectedLoungeType.value &&
					selectedType.value === postTypeHelper.CONTEST2.value
				) {
					payload.lounge = selectedLoungeType.value
				}

				if (keyword.value) {
					payload.title = keyword.value
				}

				const { data, totalPagesCount } = await MarketPostSubjectService.getAll(
					payload,
				)

				totalPages.value = totalPagesCount
				subjects.value = data
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}

		const routeToDetail = subjectId => {
			router.push({
				name: 'market-post-subject-detail',
				params: { subjectId: subjectId },
				query: { ...route.value.query },
			})
		}

		const routerReplace = () => {
			router
				.replace({
					path: route.path,
					query: {
						page: currentPage.value,
						...(keyword.value && { query: keyword.value }),
						...(date.value !== error.INVALID_DATE.value &&
							date.value && { date: date.value }),
					},
				})
				.catch(() => {})
		}

		const inquiry = async () => {
			date.value = dayjs(date.value).format('YYYY-MM-DD 00:00:01')
			currentPage.value = 1

			routerReplace()

			await getAll()
		}

		const handlePageChange = async value => {
			currentPage.value = value

			routerReplace()

			await getAll()
		}

		onMounted(() => {
			getAll()
		})

		return {
			date,
			headers,
			subjects,
			keyword,
			itemsPerPage,
			currentPage,
			totalPages,
			isLoading,
			selectedType,
			selectedLoungeType,
			postTypeHelper,
			typeGroups,
			subjectLoungeTypeGroups,

			resolvePostType,
			resoloveSubjectLoungeType,

			inquiry,
			routeToDetail,
			handlePageChange,
		}
	},
}
</script>
