<template>
	<v-card elevation="6" class="mt-4">
		<v-card-title class="d-flex justify-space-between py-3 font-weight-bold">
			<div class="d-flex align-center">
				<span class="text-lg">리워드 지급하기</span>
			</div>
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-text-field
				ref="withdrawPointInput"
				v-model="withdrawPoint"
				hide-details
				outlined
				reverse
				label="얼마나 보낼까요?"
				placeholder="얼마나 보낼까요?"
				@keypress="allowOnlyNumber($event)"
				prefix="P"
				autocomplete="off"
			/>
			<v-text-field
				v-model="title"
				class="mt-2"
				label="받는분에게 표시"
				placeholder="받는분에게 표시"
				reverse
				hide-details
				outlined
				autocomplete="off"
			/>
		</v-card-text>
		<v-card-actions>
			<v-btn
				x-large
				color="accent"
				class="font-weight-bold w-full"
				:loading="isLoading"
				@click="payPoint"
			>
				리워드 지급하기
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { ref } from '@vue/composition-api'

import { commaFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketBalanceService from '@/services/MarketBalanceService'

export default {
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const withdrawPoint = ref(0)
		const isLoading = ref(false)
		const title = ref('리워드 포인트 지급')

		const payPoint = async () => {
			if (withdrawPoint.value == 0) {
				warningSwal('지급할 포인트를 입력해주세요')
				return
			}
			const confirm = await confirmSwal(
				`${props.user.name}(${props.user.uid})님에게<br/> <strong>${commaFormat(
					withdrawPoint.value,
				)} 포인트</strong>를 지급하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					isLoading.value = true

					await MarketBalanceService.payPoint({
						userId: props.user.id,
						amount: withdrawPoint.value,
						title: title.value,
					})
					withdrawPoint.value == 0

					successSwal('리워드 지급이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '리워드를 지급하는데 문제가 발생했습니다.',
					)
				} finally {
					isLoading.value = false
				}
			}
		}

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		return {
			title,
			isLoading,
			withdrawPoint,

			payPoint,
			allowOnlyNumber,
		}
	},
}
</script>
<style lang="scss" scoped>
.suffix {
	color: #666666;
}
.from {
	font-size: 32px;
	margin-bottom: 12px;

	.title {
		font-weight: 900;
	}

	.balance {
		color: #666666;
		font-size: 18px;
	}
}

.to {
	font-size: 32px;
	margin-bottom: 8px;

	.title {
		font-weight: 900;
	}
}

::v-deep {
	.v-text-field__slot {
		input {
			font-size: 1.4em;
		}
	}
}
</style>
