<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{ name: 'market-items-list' }"
				class="text-decoration-none"
			>
				상품 리스트
			</router-link>
			<span class="mx-1">&#62;</span>
			상품 등록
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<validation-observer ref="observer" v-slot="{ valid }">
				<market-item-form :form="form" />
				<div class="d-flex justify-end pb-6">
					<v-btn
						color="primary"
						:disabled="!valid"
						@click.stop="createMarketItem"
					>
						등록
					</v-btn>
				</div>
			</validation-observer>
		</v-card-text>
	</v-card>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import MarketItemForm from './components/MarketItemForm.vue'

import MarketItemService from '@/services/MarketItemService'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

export default {
	components: {
		MarketItemForm,
	},
	setup() {
		const { router } = useRouter()
		const form = reactive({
			title: '',
			body: '',
			description: '',
			location: '',
			amount: 0,
			files: [],
			startRegistrationDate: '',
			endRegistrationDate: '',
		})

		onMounted(async () => {
			const data = await MarketItemService.listMarketItems()
			if (
				!data[0].title ||
				!data[0].body ||
				!data[0].description ||
				!data[0].location
			) {
				await warningSwal('현재 작성중인 상품이 있습니다.')
				form.id = data[0].id
			} else {
				const newMarketItem = await MarketItemService.createMarketItem(form)
				form.id = newMarketItem.id
			}
		})

		const createMarketItem = async () => {
			const confirm = await confirmSwal('상품을 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = await MarketItemService.createMarketItem(form)

					if (form.files) {
						for (const file of form.files) {
							const _form = new FormData()
							_form.append('data', file)
							try {
								await MarketItemService.uploadMarketItemFile(data.id, _form)
							} catch (e) {
								console.error(e)
							}
						}
					}

					successSwal('상품 등록이 완료되었습니다')

					router.push({ name: 'market-items-list' })
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '상품을 등록하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			form,
			createMarketItem,
		}
	},
}
</script>
<style lang="scss" scoped></style>
