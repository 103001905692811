<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{ name: 'event-detail', params: { eventId: eventId } }"
				class="text-decoration-none"
			>
				{{ event.title }}
			</router-link>
			<span class="mx-1">&#62;</span>
			참여자 보기
			<v-spacer />
			<v-simple-table fixed-header>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">총 정원</th>
							<th class="text-left">
								참여자 (신청완료 &#47; 강제출석체크 &#47; 신청취소)
							</th>
							<th class="text-left">입실 출석 현황</th>
							<th class="text-left">퇴실 출석 현황</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="font-weight-bold">{{ event.maxParticipants }} 명</td>
							<td class="font-weight-bold">
								{{ eventParticipants.length }} 명({{ appliedCount }} 명 &#47;
								{{ forceCheckInCount }} 명 &#47; {{ canceledCount }} 명)
							</td>
							<td rowspan="2">
								<tr>
									<td>완료</td>
									<td class="pl-4">{{ checkedInCount }} 명</td>
								</tr>
								<tr>
									<td>미완료</td>
									<td class="pl-4">{{ unCheckedInCount }} 명</td>
								</tr>
							</td>
							<td>{{ checkedOutCount }} 명</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
			<v-btn color="accent" class="ml-4 white--text" @click="downloadExcel">
				엑셀다운
				<v-icon right dark>mdi-cloud-download</v-icon>
			</v-btn>
		</v-card-title>

		<v-data-table
			:headers="headers"
			v-model="selectedParticipants"
			:items="eventParticipants"
			:items-per-page="-1"
			no-data-text="조회된 고객이 없습니다."
			class="elevation-1"
			:loading="isLoading"
			show-select
			loading-text="고객을 불러오는 중입니다..."
			hide-default-footer
		>
			<template v-slot:[`item.index`]="{ item }">
				<span @click="openUserInfoDialog(item.user.id)" class="clickable">
					{{ eventParticipants.indexOf(item) + 1 }}
				</span>
			</template>
			<template v-slot:[`item.user.ancestorUser`]="{ item }">
				<span
					v-if="item.user.ancestorUser && isExecutive(item.user.ancestorUser)"
				>
					{{ renderTopLeader(item.user.ancestorUser) }}
				</span>
			</template>
			<template v-slot:[`item.user.recommenderUser`]="{ item }">
				<span v-if="item.user.recommenderUser">
					{{ item.user.recommenderUser.uid }}
				</span>
			</template>
			<template v-slot:[`item.createdAt`]="{ item }">
				{{ item.createdAt | dateKOSimpleSiBunChoFormat }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip
					small
					:color="
						resolveApplicationStatusVariant(
							item.marketEvent.category,
							item.status,
							item.retake,
							item.marketEvent.amount > 0 ? true : false,
						)
					"
				>
					{{
						resolveApplicationStatus(
							item.marketEvent.category,
							item.status,
							item.retake,
							item.marketEvent.amount > 0 ? true : false,
						)
					}}
				</v-chip>
			</template>

			<template v-slot:[`item.resultScore`]="{ item }">
				{{ item.resultScore ? item.resultScore : '-' }}
			</template>

			<template v-slot:[`item.result`]="{ item }">
				<v-chip small :color="resolveTestStatusVariant(item.result)">
					{{ resolveTestStatus(item.result) }}
				</v-chip>
			</template>
			<template v-slot:[`item.hasTakeTest`]="{ item }">
				{{ hasTakenTest(item) }}
			</template>
			<template v-slot:[`item.answerSheet`]="{ item }">
				<v-btn
					small
					rounded
					color="secondary"
					@click.stop="openAnswerSheet(item.user.id)"
				>
					답안지 보기
				</v-btn>
			</template>
		</v-data-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn
				:to="{ name: 'event-detail', params: { eventId: eventId } }"
				color="primary"
			>
				이벤트 상세보기
			</v-btn>
		</v-card-actions>
		<user-dialog
			:user-id="selectedUserId"
			:is-open-user-info-dialog.sync="isOpenUserInfoDialog"
		/>
		<license-test-participants-user-test-dialog
			:workbook-id="testRoundWorkbook.id"
			:problem-count="testRoundWorkbook.problemCount"
			:test-answer="testRoundWorkbook.answer"
			:user-id="selectedUserId"
			:is-open-user-test-dialog.sync="isOpenUserTestDialog"
		/>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
	resolveApplicationStatus,
	resolveApplicationStatusVariant,
	resolveTestStatus,
	resolveTestStatusVariant,
} from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { applicationStatus, executive } from '@/helpers'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'
import MarketLicenseService from '@/services/MarketLicenseService'

import UserDialog from '@/components/common/UserDialog.vue'
import LicenseTestParticipantsUserTestDialog from './LicenseTestParticipantsUserTestDialog.vue'

export default {
	components: {
		UserDialog,
		LicenseTestParticipantsUserTestDialog,
	},
	setup() {
		const { route } = useRouter()
		const event = ref({})
		const selectedUserId = ref(0)
		const selectedParticipants = ref([])
		const eventParticipants = ref([])
		const testRoundWorkbook = ref([])
		const isOpenUserInfoDialog = ref(false)
		const isOpenUserTestDialog = ref(false)
		const appliedCount = ref(0)
		const canceledCount = ref(0)
		const forceCheckInCount = ref(0)
		const checkedInCount = ref(0)
		const unCheckedInCount = ref(0)
		const checkedOutCount = ref(0)
		const eventId = ref(route.value.params.eventId)
		const headers = ref([
			{ text: 'no', value: 'index', width: '20px' },
			{ text: '상위리더', value: 'user.ancestorUser', width: '120px' },
			{ text: '추천인', value: 'user.recommenderUser', width: '120px' },
			{ text: '실명', value: 'user.name', width: '100px' },
			{ text: '닉네임', value: 'user.uid', width: '100px' },
			{ text: '생년월일', value: 'user.birthDate', width: '100px' },
			{ text: '휴대폰 번호', value: 'user.phoneNumber', width: '120px' },
			{ text: '신청날짜', value: 'createdAt', width: '100px' },
			{ text: '신청여부', value: 'status', width: '100px' },
			{ text: '점수', value: 'resultScore', width: '30px' },
			{ text: '합격여부', value: 'result', width: '30px' },
			{ text: '응시여부', value: 'hasTakeTest', width: '30px' },
			{ text: '답안지', value: 'answerSheet', width: '100px' },
		])
		const isLoading = ref(false)

		const listTestRoundWorkbooks = async () => {
			const data = await MarketLicenseService.listTestRoundWorkbooks(
				event.value.licenseTestRoundId,
			)
			testRoundWorkbook.value = data[0]
		}

		const getEvent = async () => {
			event.value = await EventService.getEvent(eventId.value)
			listTestRoundWorkbooks()
		}
		getEvent()

		const isExecutive = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			return index !== -1 ? true : false
		}

		const renderTopLeader = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			if (index !== -1) {
				return ancestorUser.uid
			} else {
				return ancestorUser.name
			}
		}

		const listEventParticipants = async () => {
			try {
				isLoading.value = true
				const data = await EventService.listEventParticipants(eventId.value)

				checkedInCount.value = data
					.filter(e => e.checkIn === true)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				unCheckedInCount.value = data
					.filter(e => e.checkIn === false || e.checkIn === null)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				checkedOutCount.value = data
					.filter(e => e.checkOut === true)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				appliedCount.value = data
					.filter(
						e =>
							e.status === applicationStatus.APPLIED.value ||
							e.status === applicationStatus.PAID.value ||
							e.status === applicationStatus.DONE.value,
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				canceledCount.value = data
					.filter(e => e.status === applicationStatus.CANCELED.value)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				forceCheckInCount.value = data
					.filter(e => e.status === applicationStatus.FORCE_CHECK_IN.value)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)

				eventParticipants.value = data
			} catch (e) {
				warningSwal('사용자를 불러오는데 문제가 발생했습니다.')
			} finally {
				isLoading.value = false
			}
		}
		listEventParticipants()

		const downloadExcel = async () => {
			const excelUrl = `${process.env.VUE_APP_API_URL}/market-events/${eventId.value}/participation/excel?limit=10000`
			window.open(excelUrl, '_blank')
		}

		const checkInEventParticipant = async data => {
			const confirm = await confirmSwal(
				`${data.user.name}님 출석 체크 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.checkInEventParticipant({
						eventId: data.marketEvent.id,
						userId: data.user.id,
					})
					await listEventParticipants()
					successSwal('출석체크가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '출석체크하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const cancelCheckInEventParticipant = async data => {
			const confirm = await confirmSwal(
				`${data.user.name} 출석체크를 취소하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.cancelCheckInEventParticipant({
						eventId: data.marketEvent.id,
						userId: data.user.id,
					})
					await listEventParticipants()
					successSwal('출석체크가 취소되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '출석체크를 취소하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const hasTakenTest = item => {
			return item.checkIn && item.checkOut ? '응시' : '미응시'
		}

		const openUserInfoDialog = userId => {
			selectedUserId.value = userId
			isOpenUserInfoDialog.value = !isOpenUserInfoDialog.value
		}

		const openAnswerSheet = userId => {
			selectedUserId.value = userId
			isOpenUserTestDialog.value = !isOpenUserTestDialog.value
		}

		return {
			headers,
			isLoading,
			eventId,
			event,
			selectedUserId,
			eventParticipants,
			testRoundWorkbook,
			isOpenUserInfoDialog,
			isOpenUserTestDialog,
			checkedInCount,
			unCheckedInCount,
			checkedOutCount,
			appliedCount,
			canceledCount,
			forceCheckInCount,
			selectedParticipants,

			openAnswerSheet,
			hasTakenTest,
			resolveApplicationStatus,
			resolveApplicationStatusVariant,
			resolveTestStatus,
			resolveTestStatusVariant,

			downloadExcel,
			openUserInfoDialog,
			checkInEventParticipant,
			cancelCheckInEventParticipant,
			isExecutive,
			renderTopLeader,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}

.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
