<template>
	<v-dialog
		v-model="isOpenLicenseEditTestRoundDialog"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				자격 시험 보기
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-license-edit-test-round-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<license-test-round-form :form.sync="form" />
					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							class="mr-1"
							:disabled="!valid"
							@click.stop="updateTestRound"
						>
							수정
						</v-btn>
						<v-btn color="error" @click.stop="deleteTestRound">삭제</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { dateKOSimpleSiBunChoMsFormat, dateKSTToUTCFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseTestRoundForm from './components/LicenseTestRoundForm.vue'

export default {
	props: {
		isOpenLicenseEditTestRoundDialog: {
			type: Boolean,
			required: true,
		},
		selectedTestRound: {
			type: Object,
		},
	},
	components: {
		LicenseTestRoundForm,
	},
	setup(props, { emit }) {
		const form = ref({
			licenseId: null,
			type: '',
			location: '',
			maxParticipants: 0,
			testAt: '',
		})

		watch(
			() => props.selectedTestRound,
			currentValue => {
				if (currentValue) {
					form.value = { ...currentValue }
					form.value.testAt = dateKOSimpleSiBunChoMsFormat(form.value.testAt)
				}
			},
			{ immediate: true },
		)

		const updateTestRound = async () => {
			const confirm = await confirmSwal('자격 시험을 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }
					data.testAt = dateKSTToUTCFormat(data.testAt)

					await MarketLicenseService.updateTestRound(data)
					emit('is-fetch-test-rounds', true)
					successSwal('자격 시험 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '자격 시험을 수정하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-license-edit-test-round-dialog', false)
				}
			}
		}

		const deleteTestRound = async () => {
			const confirm = await confirmSwal('자격 시험을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLicenseService.deleteTestRound(form.value.id)
					emit('is-fetch-test-rounds', true)
					successSwal('자격 시험 삭제가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '자격 시험을 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-license-edit-test-round-dialog', false)
				}
			}
		}

		return {
			form,

			updateTestRound,
			deleteTestRound,
		}
	},
}
</script>
