<template>
	<v-dialog
		v-model="isOpenLicenseRegisterTestRoundDialog"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				자격 시험 등록
				<v-spacer />
				<v-btn
					icon
					@click="
						$emit('update:is-open-license-register-test-round-dialog', false)
					"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<license-test-round-form :form.sync="form" />
					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							:disabled="!valid"
							@click.stop="createTestRound"
						>
							등록
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

import { mdiClose } from '@mdi/js'

import { dateKSTToUTCFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseTestRoundForm from './components/LicenseTestRoundForm.vue'

export default {
	props: {
		isOpenLicenseRegisterTestRoundDialog: {
			type: Boolean,
			required: true,
		},
		licenseId: {
			required: true,
		},
	},
	components: {
		LicenseTestRoundForm,
	},
	setup(props, { emit }) {
		const form = ref({
			licenseId: props.licenseId,
			type: '',
			location: '',
			maxParticipants: 0,
			testAt: '',
		})

		const createTestRound = async () => {
			const confirm = await confirmSwal('자격 시험을 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }
					data.testAt = dateKSTToUTCFormat(data.testAt)

					await MarketLicenseService.createTestRound(data)
					emit('is-fetch-test-rounds', true)
					successSwal('자격 시험 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '자격 시험을 등록하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-license-register-test-round-dialog', false)
				}
			}
		}

		return {
			form,
			createTestRound,

			icons: {
				mdiClose,
			},
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
