var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","item-value":"value","items":_vm.categories,"error-messages":errors,"label":"카테고리를 선택하세요","readonly":"","required":""},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})]}}])}),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"타이틀을 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('div',{staticClass:"mb-4"},[_c('toast-ui-editor',{attrs:{"content":_vm.form.body},on:{"update:content":function($event){return _vm.$set(_vm.form, "body", $event)}}})],1),_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"장소를 입력하세요","autocomplete":"off","required":""},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})]}}])}),_c('validation-provider',{attrs:{"name":"maxParticipants","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"정원을 입력하세요","autocomplete":"off","suffix":"명","required":""},model:{value:(_vm.form.maxParticipants),callback:function ($$v) {_vm.$set(_vm.form, "maxParticipants", $$v)},expression:"form.maxParticipants"}})]}}])}),_c('validation-provider',{attrs:{"name":"eventAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"개최 날짜를 입력하세요","required":""},model:{value:(_vm.form.eventAt),callback:function ($$v) {_vm.$set(_vm.form, "eventAt", $$v)},expression:"form.eventAt"}})]}}])}),_c('validation-provider',{attrs:{"name":"startRegistrationDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"등록 시작 날짜를 입력하세요","required":""},model:{value:(_vm.form.startRegistrationDate),callback:function ($$v) {_vm.$set(_vm.form, "startRegistrationDate", $$v)},expression:"form.startRegistrationDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"endRegistrationDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"등록 마감 날짜를 입력하세요","required":""},model:{value:(_vm.form.endRegistrationDate),callback:function ($$v) {_vm.$set(_vm.form, "endRegistrationDate", $$v)},expression:"form.endRegistrationDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"등록 비용을 입력하세요","autocomplete":"off","suffix":"원","required":""},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}])}),_c('validation-provider',{attrs:{"name":"canRetake"}},[_c('v-checkbox',{attrs:{"label":"재수강 여부","hide-details":""},model:{value:(_vm.form.canRetake),callback:function ($$v) {_vm.$set(_vm.form, "canRetake", $$v)},expression:"form.canRetake"}})],1),(_vm.form.canRetake)?[_c('validation-provider',{attrs:{"name":"startRetakeRegistrationDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"재수강 등록 시작 날짜를 입력하세요","required":""},model:{value:(_vm.form.startRetakeRegistrationDate),callback:function ($$v) {_vm.$set(_vm.form, "startRetakeRegistrationDate", $$v)},expression:"form.startRetakeRegistrationDate"}})]}}],null,false,2087486876)}),_c('validation-provider',{attrs:{"name":"endRetakeRegistrationDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"datetime-local","error-messages":errors,"label":"재수강 등록 마감 날짜를 입력하세요","required":""},model:{value:(_vm.form.endRetakeRegistrationDate),callback:function ($$v) {_vm.$set(_vm.form, "endRetakeRegistrationDate", $$v)},expression:"form.endRetakeRegistrationDate"}})]}}],null,false,215153222)})]:_vm._e(),_c('validation-provider',{attrs:{"name":"visible","rules":"required"}},[_c('v-checkbox',{attrs:{"label":"공개 여부","hide-details":""},model:{value:(_vm.form.visible),callback:function ($$v) {_vm.$set(_vm.form, "visible", $$v)},expression:"form.visible"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }