<template>
	<form class="pa-4">
		<v-avatar rounded size="120" class="mb-6">
			<v-img
				:src="
					user.publicPictureUrl
						? user.publicPictureUrl
						: require('@/assets/images/avatars/default.svg')
				"
			/>
		</v-avatar>
		<v-row>
			<v-col md="6">
				<v-text-field
					v-model="user.id"
					type="text"
					label="유저인덱스"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.email"
					type="text"
					label="이메일"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.name"
					type="text"
					label="실명"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.birthDate"
					type="text"
					label="생년월일"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.uid"
					type="text"
					label="유저명"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="userRecommenderUserId"
					type="text"
					label="추천인"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.address"
					type="text"
					label="주소"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.addressDetail"
					type="text"
					label="상세 주소"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-checkbox
					class="mb-2"
					v-model="user.emailVerified"
					readonly
					hide-details=""
					label="이메일 인증 여부"
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.emailVerifiedAt"
					type="text"
					label="이메일 인증시간"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-checkbox
					class="mb-2"
					v-model="user.phoneNumberVerified"
					hide-details=""
					readonly
					label="휴대폰 번호 인증 여부"
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.phoneNumber"
					type="text"
					label="휴대폰 번호"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.phoneNumberVerifiedAt"
					type="text"
					label="휴대폰 번호 인증시간"
					autocomplete="off"
					readonly
				/>
			</v-col>
			<v-col md="6">
				<v-select
					item-text="name"
					item-value="value"
					:items="Object.values(marketerRole)"
					v-model="user.marketerRole"
					label="IT 마케터 권한"
					required
				/>
			</v-col>
			<v-col md="6">
				<v-text-field
					v-model="user.createdAt"
					autocomplete="off"
					type="text"
					label="가입일"
					readonly
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" md="4" sm="4">
				<v-select
					v-model="user.refundBankCode"
					:items="banks"
					item-text="name"
					item-value="value"
					label="은행을 선택하세요"
					dense
					outlined
				/>
			</v-col>
			<v-col cols="8" md="8" sm="8">
				<v-text-field
					v-model="user.refundBankAccount"
					type="test"
					label="계좌번호를 입력하세요"
					@keypress="allowOnlyNumber($event)"
					autocomplete="off"
					dense
					outlined
					required
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="6" md="6" sm="6">
				<v-text-field
					v-model="user.registrationNumberFront"
					type="text"
					label="주민등록번호 앞자리를 입력하세요"
					autocomplete="off"
					dense
					outlined
					required
					max-length="6"
				/>
			</v-col>
			<v-col cols="6" md="6" sm="6">
				<v-text-field
					v-model="user.registrationNumberRear"
					type="password"
					label="주민등록번호 뒷자리를 입력하세요"
					autocomplete="off"
					dense
					outlined
					required
					max-length="7"
				>
					<template v-slot:prepend>
						<span class="dash">-</span>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12">
				<v-textarea label="관리자 메모" v-model="user.adminMemo" outlined />
			</v-col>
		</v-row>
	</form>
</template>
<script>
import { marketerRole } from '@/helpers'
import { ref, computed, onMounted } from '@vue/composition-api'

import UtilityService from '@/services/UtilityService'

export default {
	props: {
		user: {
			required: true,
		},
	},
	setup(props) {
		const banks = ref([])

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const userRecommenderUserId = computed(() => {
			return props.user.recommenderUser
				? `${props.user.recommenderUser.name}(${props.user.recommenderUser.uid})`
				: ''
		})

		const getBanks = async () => {
			const data = await UtilityService.getBanks()
			banks.value = Object.keys(data).reduce((acc, curr) => {
				acc.push({
					name: data[curr],
					value: curr,
				})
				return acc
			}, [])
		}

		onMounted(() => {
			getBanks()
		})

		return {
			banks,
			marketerRole,

			allowOnlyNumber,

			userRecommenderUserId,
		}
	},
}
</script>
<style lang="scss" scoped>
.dash {
	position: relative;
	top: 4px;
	left: 5px;
}
</style>
