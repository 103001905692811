import axios from '@/api/axios'
import store from '@/store'

import UserService from '@/services/UserService'

const userService = {
	_getUser(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/users/${userId}`,
				})

				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getUser(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/users/${userId}/detail`,
					headers: {
						'X-Recommend-Me-Users': true,
						'X-Issued-Licenses': true,
					},
				})

				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateUser(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/users/${payload.id}`,
					data: payload,
				})

				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getUsers(page, limit, keyword, idPhotoStatus = '') {
		return new Promise(async (resolve, reject) => {
			const params = {}
			if (keyword) {
				params['searchword'] = keyword
			}
			if (idPhotoStatus) {
				params['id_photo_status'] = idPhotoStatus
			}

			try {
				const result = await axios({
					method: 'GET',
					url: `/users/detail?page=${page}&limit=${limit}&order=asc`,
					params,
					headers: {
						'X-Recommend-Me-Users': true,
						'X-Issued-Licenses': true,
					},
				})

				resolve(result)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMe() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/users/me',
				})
				store.dispatch('user/setMe', data)
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeDetail() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/users/me/detail',
				})
				store.dispatch('user/setMeDetail', data)
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	putMe(user) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: '/users/me',
					data: user,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	confirmIdPhoto(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/users/${userId}/actions/confirm-id-photo`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	rejectIdPhoto(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/users/${userId}/actions/reject-id-photo`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	downloadUserExcel() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({
					method: 'GET',
					responseType: 'blob',
					url: `/users/excel?order=desc&orderby=id&page=0&limit=10000`,
				})
				resolve(response)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	downloadUserExcelUserIds(userIds) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({
					method: 'GET',
					responseType: 'blob',
					url: `/users/excel?order=desc&orderby=id&page=0&limit=1000&ids=${userIds}`,
				})
				resolve(response)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeQrCodeUrl(userId) {
		return `${process.env.VUE_APP_API_URL}/users/qr/${userId}`
	},
	async storeUserMe() {
		const data = await UserService.getMeDetail()
		store.dispatch('user/setMe', data)
	},
	async init() {
		if (store.getters['auth/isSignin'] === false) {
			return
		}
		await this.storeUserMe()
	},
}

export default userService
