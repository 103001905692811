export default [
	{
		path: '/market-orders',
		name: 'market-orders-list',
		component: () => import('@/pages/marketOrder/MarketOrderList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-orders/:marketOrderId',
		name: 'market-orders-detail',
		props: true,
		component: () => import('@/pages/marketOrder/MarketOrderDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
