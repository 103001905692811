<template>
	<div>
		<validation-provider v-slot="{ errors }" name="category" rules="required">
			<v-select
				item-text="name"
				item-value="value"
				:items="categories"
				:error-messages="errors"
				v-model="form.category"
				label="카테고리를 선택하세요"
				readonly
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<div class="mb-4">
			<toast-ui-editor :content.sync="form.body" />
		</div>
		<validation-provider v-slot="{ errors }" name="location" rules="required">
			<v-text-field
				v-model="form.location"
				type="text"
				:error-messages="errors"
				label="장소를 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider
			v-slot="{ errors }"
			name="maxParticipants"
			rules="required"
		>
			<v-text-field
				v-model="form.maxParticipants"
				type="text"
				:error-messages="errors"
				label="정원을 입력하세요"
				autocomplete="off"
				suffix="명"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="eventAt" rules="required">
			<v-text-field
				v-model="form.eventAt"
				type="datetime-local"
				:error-messages="errors"
				label="개최 날짜를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="startRegistrationDate">
			<v-text-field
				v-model="form.startRegistrationDate"
				type="datetime-local"
				:error-messages="errors"
				label="등록 시작 날짜를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="endRegistrationDate">
			<v-text-field
				v-model="form.endRegistrationDate"
				type="datetime-local"
				:error-messages="errors"
				label="등록 마감 날짜를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="amount">
			<v-text-field
				v-model="form.amount"
				type="text"
				:error-messages="errors"
				label="등록 비용을 입력하세요"
				autocomplete="off"
				suffix="원"
				required
			/>
		</validation-provider>
		<validation-provider name="canRetake">
			<v-checkbox v-model="form.canRetake" label="재수강 여부" hide-details />
		</validation-provider>
		<template v-if="form.canRetake">
			<validation-provider
				v-slot="{ errors }"
				name="startRetakeRegistrationDate"
			>
				<v-text-field
					v-model="form.startRetakeRegistrationDate"
					type="datetime-local"
					:error-messages="errors"
					label="재수강 등록 시작 날짜를 입력하세요"
					required
				/>
			</validation-provider>
			<validation-provider v-slot="{ errors }" name="endRetakeRegistrationDate">
				<v-text-field
					v-model="form.endRetakeRegistrationDate"
					type="datetime-local"
					:error-messages="errors"
					label="재수강 등록 마감 날짜를 입력하세요"
					required
				/>
			</validation-provider>
		</template>
		<validation-provider name="visible" rules="required">
			<v-checkbox v-model="form.visible" label="공개 여부" hide-details />
		</validation-provider>
	</div>
</template>

<script>
import { reactive } from '@vue/composition-api'

import ToastUiEditor from '@/components/ToastUiEditor.vue'

export default {
	props: {
		eventCategory: {
			type: Object,
			required: true,
		},
		form: {
			type: Object,
			required: true,
		},
	},
	components: {
		ToastUiEditor,
	},
	setup(props) {
		const categories = reactive(Object.values(props.eventCategory))

		return {
			categories,
		}
	},
}
</script>
