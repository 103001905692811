<template>
	<v-dialog v-model="isOpenLinkMarketItemEventDialog" persistent>
		<v-card>
			<v-card-title>
				이벤트 연결하기
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-link-market-item-event-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="events"
					v-model="selectedEvent"
					no-data-text="이벤트가 존재하지 않습니다"
					show-select
					single-select
					hide-default-footer
				>
					<template v-slot:[`item.category`]="{ item }">
						{{ item.category | resolveEventCategory }}
					</template>
					<template v-slot:[`item.eventAt`]="{ item }">
						{{ item.eventAt | dateKOSimpleSiBunChoFormat }}
					</template>
					<template v-slot:[`item.visible`]="{ item }">
						{{ item.visible ? '공개' : '비공개' }}
					</template>
				</v-data-table>
				<div class="d-flex justify-end pb-6">
					<v-btn color="primary" @click.stop="linkMarketItemEvent">
						연결하기
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'
import MarketItemService from '@/services/MarketItemService'

export default {
	props: {
		isOpenLinkMarketItemEventDialog: {
			type: Boolean,
			required: true,
		},
		marketItemId: {
			required: true,
		},
	},
	components: {},
	setup(props, { emit }) {
		const events = ref([])
		const selectedEvent = ref([])
		const headers = ref([
			{ text: '구분', value: 'category', width: '50px' },
			{ text: '타이틀', value: 'title', width: '120px' },
			{ text: '이벤트 일정', value: 'eventAt', width: '200px' },
			{ text: '장소', value: 'location', width: '100px' },
			{ text: '모집인원', value: 'maxParticipants', width: '128px' },
			{ text: '공개 여부', value: 'visible', width: '100px' },
		])

		const listEvents = async () => {
			try {
				events.value = await EventService.listEvents('')
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(() => {
			listEvents()
		})

		const linkMarketItemEvent = async () => {
			if (!selectedEvent.value[0]) {
				warningSwal('연결할 이벤트를 선택하세요')
				return
			}

			const confirm = await confirmSwal(
				`${selectedEvent.value[0]?.title}을(를)<br/> 상품에 연결 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await MarketItemService.linkMarketItemEvent({
						marketItemId: props.marketItemId,
						marketEventId: selectedEvent.value[0].id,
					})
					await successSwal('해당 이벤트가 상품에 연결되었습니다')
					emit('fetch-market-item-event', true)
					emit('update:is-open-link-market-item-event-dialog', false)
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '해당 이벤트를 상품에 연결하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			headers,
			events,
			selectedEvent,

			linkMarketItemEvent,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
