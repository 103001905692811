<template>
	<v-dialog v-model="isOpenLicenseRegisterDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				자격증 등록
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-license-register-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<license-form :form.sync="form" />
					<div class="d-flex justify-end pb-6">
						<v-btn
							color="primary"
							:disabled="!valid"
							@click.stop="createLicense"
						>
							등록
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseForm from './components/LicenseForm.vue'

export default {
	props: {
		isOpenLicenseRegisterDialog: {
			type: Boolean,
			required: true,
		},
	},
	components: {
		LicenseForm,
	},
	setup(_, { emit }) {
		const form = ref({
			title: '',
			body: '',
			visible: false,
		})

		const createLicense = async () => {
			const confirm = await confirmSwal('자격증을 등록 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLicenseService.createLicense(form.value)
					emit('is-fetch-licenses', true)
					successSwal('자격증 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '자격증을 등록하는데 문제가 발생했습니다.',
					)
				} finally {
					emit('update:is-open-license-register-dialog', false)
				}
			}
		}

		return {
			form,
			createLicense,
		}
	},
}
</script>
