import axios from '@/api/axios'

const marketLectureService = {
	listMarketLectures() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-lectures',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLecture(marketLectureId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-lectures/${marketLectureId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLectureDetail(marketLectureId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-lectures/${marketLectureId}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createMarketLecture(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-lectures',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateMarketLecture(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-lectures/${payload.id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketLecture(marketLectureId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-lectures/${marketLectureId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketLectureService
