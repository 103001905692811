import axios from '@/api/axios'

export default {
	getAll(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({
					method: 'GET',
					url: '/market-post-subjects',
					params: payload,
					headers: {
						'X-Content-Market-Subject-Statistics': true,
					},
				})

				resolve({
					data: response.data,
					totalCount: parseInt(response.headers['x-total-count']),
					totalPagesCount: parseInt(response.headers['x-total-pages-count']),
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	get(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-post-subjects/${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-post-subjects/${id}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	create(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-post-subjects',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	update(id, payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-post-subjects/${id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	delete(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-post-subjects/${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}
