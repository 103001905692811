<template>
	<v-card>
		<v-card-title>
			<router-link :to="{ name: 'events' }" class="text-decoration-none">
				이벤트
			</router-link>
			<span class="mx-1">></span>
			상세보기
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<validation-observer ref="observer" v-slot="{ valid }">
				<event-form :form.sync="form" :event-category="eventCategory" />
				<div class="d-flex justify-end pb-6">
					<v-btn color="primary" class="mr-1" @click="createNotice">
						공지사항 등록하기
					</v-btn>
					<v-btn
						color="primary"
						class="mr-1"
						:to="{ name: 'event-participants', params: { eventId: eventId } }"
					>
						참여자 보기
					</v-btn>
					<v-btn
						color="primary"
						class="mr-1"
						:disabled="!valid"
						@click="updateEvent"
					>
						수정
					</v-btn>
					<v-btn color="error" @click="deleteEvent">삭제</v-btn>
				</div>
			</validation-observer>
		</v-card-text>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { eventCategory } from '@/helpers'
import { dateKOSimpleSiBunChoMsFormat, dateKSTToUTCFormat } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'
import NoticeService from '@/services/NoticeService'

import EventForm from './components/EventForm.vue'

export default {
	components: {
		EventForm,
	},
	setup(_, { emit }) {
		const form = ref({})
		const { route, router } = useRouter()
		const eventId = ref(route.value.params.eventId)

		const getEvent = async () => {
			form.value = await EventService.getEvent(eventId.value)
			form.value.eventAt = dateKOSimpleSiBunChoMsFormat(form.value.eventAt)
			form.value.startRegistrationDate = dateKOSimpleSiBunChoMsFormat(
				form.value.startRegistrationDate,
			)
			form.value.endRegistrationDate = dateKOSimpleSiBunChoMsFormat(
				form.value.endRegistrationDate,
			)
			form.value.startRetakeRegistrationDate =
				dateKOSimpleSiBunChoMsFormat(form.value.startRetakeRegistrationDate) ||
				null
			form.value.endRetakeRegistrationDate =
				dateKOSimpleSiBunChoMsFormat(form.value.endRetakeRegistrationDate) ||
				null
		}
		getEvent()

		const updateEvent = async () => {
			const confirm = await confirmSwal('이벤트를 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }

					data.eventAt = dateKSTToUTCFormat(data.eventAt)
					data.startRegistrationDate = dateKSTToUTCFormat(
						data.startRegistrationDate,
					)
					data.endRegistrationDate = dateKSTToUTCFormat(
						data.endRegistrationDate,
					)
					data.startRetakeRegistrationDate = data.canRetake
						? dateKSTToUTCFormat(data.startRetakeRegistrationDate)
						: null
					data.endRetakeRegistrationDate = data.canRetake
						? dateKSTToUTCFormat(data.endRetakeRegistrationDate)
						: null

					await EventService.updateEvent(data)
					emit('is-fetch-events', true)
					successSwal('이벤트 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '이벤트를 수정하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const deleteEvent = async () => {
			const confirm = await confirmSwal('이벤트를 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await EventService.deleteEvent(form.value.id)
					emit('is-fetch-events', true)
					await successSwal('이벤트 삭제가 완료되었습니다')
					router.go(-1)
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '이벤트를 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const createNotice = async () => {
			const confirm = await confirmSwal(
				'이벤트를 공지사항으로 등록 하시겠습니까?',
			)
			if (confirm.isConfirmed) {
				try {
					const data = { ...form.value }
					await NoticeService.createNotice({
						title: data?.title,
						content: data?.body,
						forMarketer: true,
						forFactory: false,
						visible: false,
					})
					successSwal('공지사항 등록이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '공지사항으로 등록하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			form,
			eventId,
			eventCategory,

			updateEvent,
			deleteEvent,
			createNotice,
		}
	},
}
</script>
