<template>
	<div>
		<validation-provider v-slot="{ errors }" name="name" rules="required">
			<v-text-field
				v-model="form.name"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="라운지 이름을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="address" rules="required">
			<v-text-field
				v-model="form.address"
				type="text"
				@click="isOpenPostCodeDialog = !isOpenPostCodeDialog"
				:error-messages="errors"
				label="라운지 주소를 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider
			v-slot="{ errors }"
			name="introduction"
			rules="required"
		>
			<v-text-field
				v-model="form.introduction"
				type="text"
				:error-messages="errors"
				label="라운지 소개를 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider
			v-slot="{ errors }"
			name="phoneNumber"
			rules="required"
		>
			<v-text-field
				v-model="form.phoneNumber"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="라운지 전화번호를 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="amount">
			<v-text-field
				v-model="form.baseFareAnHourWon"
				type="text"
				:error-messages="errors"
				label="라운지 시간당 기본비용을 입력하세요"
				autocomplete="off"
				suffix="원"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="maxNumberOfPeople">
			<v-text-field
				v-model="form.maxNumberOfPeople"
				type="text"
				:error-messages="errors"
				label="이용 가능 인원을 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<post-code-dialog
			:is-open-post-code-dialog.sync="isOpenPostCodeDialog"
			@address="onCompleteAddress($event)"
		/>
	</div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import PostCodeDialog from '@/components/PostCodeDialog.vue'

export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	components: {
		PostCodeDialog,
	},
	setup(props) {
		const isOpenPostCodeDialog = ref(false)

		const onCompleteAddress = address => {
			props.form.address = address.roadAddress
			isOpenPostCodeDialog.value = false
		}

		watch(
			() => props.form.phoneNumber,
			currentValue => {
				if (currentValue) {
					props.form.phoneNumber = currentValue
						.replace(/[^0-9]/g, '')
						.replace(
							/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
							'$1-$2-$3',
						)
						.replace('--', '-')
				}
			},
		)

		return {
			isOpenPostCodeDialog,

			onCompleteAddress,
		}
	},
}
</script>
