const unassignedPostIds = [
	230, 231, 232, 233, 234, 235, 236, 237, 238, 240, 241, 242, 243, 244, 245,
	246, 247, 248, 249, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261,
	262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276,
	277, 278, 279, 280, 281, 282, 283, 285, 303, 304, 305, 306, 307, 308, 309,
	310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324,
	325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339,
	340, 341, 342, 343, 344, 345, 346, 347, 348, 349,
]

export default unassignedPostIds
