<template>
	<v-card elevation="0">
		<v-card-title class="d-flex justify-space-between">
			질문지 리스트
			<!-- <div class="d-flex align-center mr-2">
				<date-picker-dialog
					:label="'날짜'"
					:date-value.sync="date"
				></date-picker-dialog>
				<v-btn @click="inquiryDate" color="primary">조회</v-btn>
			</div> -->
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="questionnaires"
			:items-per-page="-1"
			no-data-text="조회된 질문지가 없습니다."
			class="elevation-1 cursor-pointer"
			:loading="isLoading"
			loading-text="질문지를 불러오는 중입니다..."
			hide-default-footer
			@click:row="routeToDetail($event.id)"
		>
			<template v-slot:[`item.beginDate`]="{ item }">
				{{ item.beginDate | dateKOSimpleFormat }}
			</template>
			<template v-slot:[`item.endDate`]="{ item }">
				{{ item.endDate | dateKOSimpleFormat }}
			</template>
			<template v-slot:[`item.visible`]="{ item }">
				{{ item.visible ? '공개' : '비공개' }}
			</template>
		</v-data-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn
				:to="{ name: 'market-survey-questionnaires-register' }"
				color="primary"
			>
				등록
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import dayjs from 'dayjs'
import { useRouter } from '@core/utils'
import { ref, onMounted } from '@vue/composition-api'

import MarketSurveyService from '@/services/MarketSurveyService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const isLoading = ref(false)
		const questionnaires = ref([])
		const date = ref('')
		const headers = ref([
			{
				text: '타이틀',
				align: 'left',
				value: 'title',
			},
			{ text: '시작날짜', value: 'beginDate' },
			{ text: '종료날짜', value: 'endDate' },
			{ text: '공개 여부', value: 'visible' },
		])

		const listMarketQuestionnaires = async () => {
			try {
				isLoading.value = true

				const payload = {
					date: date.value,
				}
				questionnaires.value =
					await MarketSurveyService.listMarketQuestionnaires(payload)
			} catch (e) {
				console.log(e)
			} finally {
				isLoading.value = false
			}
		}

		const routeToDetail = marketQuestionnairesId => {
			router.push({
				name: 'market-survey-questionnaires-detail',
				params: { marketQuestionnairesId: marketQuestionnairesId },
			})
		}

		const inquiryDate = async () => {
			date.value = dayjs(date.value).format('YYYY-MM-DD 00:00:01')

			await listMarketQuestionnaires()
		}

		onMounted(() => {
			listMarketQuestionnaires()
		})

		return {
			isLoading,
			questionnaires,
			headers,
			date,

			inquiryDate,
			routeToDetail,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
