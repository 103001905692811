<template>
	<v-card style="height: 100%" elevation="0" class="px-3">
		<!-- TODO 자격증 등급 필터 추가 -->
		<v-card-title class="pl-0">사용자 관리</v-card-title>
		<div class="d-flex mb-2 mr-1">
			<v-select
				item-text="name"
				item-value="value"
				:items="itemsPerPageGroups"
				label="페이지당 개수"
				@change="changeItemsPerPage"
				:value="itemsPerPage"
				style="max-width: 100px"
				hide-details
				class="ml-1"
				dense
				outlined
			/>
			<v-text-field
				v-model="keyword"
				append-icon="mdi-magnify"
				label="검색어 입력"
				class="search-field ml-1"
				@keyup.enter.prevent="search('input', $event)"
				ref="searchField"
				dense
				outlined
				single-line
				hide-details
				autocomplete="off"
			/>
			<v-btn
				@click.stop.prevent="search('button', $refs.searchField)"
				class="ml-1"
				color="primary"
			>
				검색
			</v-btn>
			<v-btn
				color="primary"
				class="ml-1"
				@click="downloadUserExcel"
				:loading="isExcelDownloading"
			>
				전체 유저 다운로드
			</v-btn>
		</div>
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="users"
			:items-per-page="itemsPerPage"
			item-key="email"
			no-data-text="조회된 고객이 없습니다."
			class="elevation-1"
			:loading="isLoading"
			loading-text="고객을 불러오는 중입니다..."
			show-select
			hide-default-footer
		>
			<template v-slot:[`item.id`]="{ item }">
				<span @click="handleClick(item.id)" class="clickable">
					{{ item.id }}
				</span>
			</template>
			<template v-slot:[`item.ancestorUser`]="{ item }">
				<span v-if="item.ancestorUser">
					{{ renderTopLeader(item.ancestorUser) }}
				</span>
			</template>
			<template v-slot:[`item.recommenderUser`]="{ item }">
				<span v-if="item.recommenderUser">
					{{ `${item.recommenderUser.name}(${item.recommenderUser.uid})` }}
				</span>
			</template>

			<template v-slot:[`item.licenseIssues`]="{ item }">
				<div v-if="item.licenseIssues.length > 0">
					{{ item.licenseIssues[item.licenseIssues.length - 1].license.title }}
					{{ item.licenseIssues[item.licenseIssues.length - 1].classNumber }} 기
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								outlined
								x-small
								color="primary"
								rounded
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-dots-horizontal</v-icon>
							</v-btn>
						</template>
						<v-simple-table fixed-header dense>
							<template v-slot:default>
								<thead>
									<tr style="background: #e6e6e6">
										<td class="text-center">자격증 명</td>
										<td class="text-center">기수</td>
										<td class="text-center">발급번호</td>
										<td class="text-center">발급일</td>
									</tr>
								</thead>
								<tbody>
									<template v-if="item.licenseIssues.length > 0">
										<tr
											v-for="(item, index) in item.licenseIssues"
											:key="index"
										>
											<td class="text-center">{{ item.license.title }}</td>
											<td class="text-center">{{ item.classNumber }}</td>
											<td class="text-center">{{ item.issueCode }}</td>
											<td class="text-center">
												{{ item.issuedAt | dateFormat }}
											</td>
										</tr>
									</template>
								</tbody>
							</template>
						</v-simple-table>
					</v-menu>
				</div>
			</template>

			<template v-slot:[`item.recommendMeUsers`]="{ item }">
				<div v-if="item.recommendMeUsers.length > 0">
					{{ item.recommendMeUsers.length }} 명
					<v-menu offset-y left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								outlined
								x-small
								color="primary"
								rounded
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-dots-horizontal</v-icon>
							</v-btn>
						</template>
						<v-simple-table fixed-header dense>
							<template v-slot:default>
								<thead>
									<tr style="background: #e6e6e6">
										<td class="text-center">실명</td>
										<td class="text-center">유저명</td>
										<td class="text-center">추천일</td>
									</tr>
								</thead>
								<tbody>
									<template v-if="item.recommendMeUsers.length > 0">
										<tr
											v-for="(item, index) in item.recommendMeUsers"
											:key="index"
										>
											<td class="text-center">{{ item.name }}</td>
											<td class="text-center">{{ item.uid }}</td>
											<td class="text-center">
												{{ item.recommenderUpdatedAt | dateFormat }}
											</td>
										</tr>
									</template>
								</tbody>
							</template>
						</v-simple-table>
					</v-menu>
				</div>
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
	</v-card>
</template>

<script>
import { useRouter } from '@core/utils'
import { ref, reactive } from '@vue/composition-api'
import { resolveMarketerRole, resolveFactoryRole } from '@core/utils/filter'
import { executive } from '@/helpers'

import UserService from '@/services/UserService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const isExcelDownloading = ref(false)
		const users = ref([])
		const selected = ref([])
		const selectedUser = ref({})
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const isLoading = ref(false)
		const keyword = ref('')
		const itemsPerPageGroups = reactive([
			{
				name: '10',
				value: 10,
			},
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])

		const headers = ref([
			{
				text: '유저아이디',
				align: 'left',
				value: 'id',
				width: '80px',
			},
			{ text: '이메일', value: 'email', width: '230px' },
			{ text: '실명', value: 'name', width: '100px' },
			{ text: '휴대폰 번호', value: 'phoneNumber', width: '128px' },
			{ text: '생년월일', value: 'birthDate', width: '100px' },
			{ text: '유저명', value: 'uid', width: '100px' },
			{ text: '상위리더', value: 'ancestorUser', width: '100px' },
			{ text: 'IT마케터 권한', value: 'marketerRole', width: '100px' },
			{ text: '추천인', value: 'recommenderUser', width: '128px' },
			{ text: '나의 추천인 정보', value: 'recommendMeUsers', width: '128px' },
			{ text: '가입일', value: 'createdAt', width: '120px' },
		])

		const getUsers = async () => {
			try {
				isLoading.value = true
				const result = await UserService.getUsers(
					currentPage.value - 1,
					itemsPerPage.value,
					keyword.value,
				)

				totalPages.value = parseInt(result.headers['x-total-pages-count'])
				users.value = result.data
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}
		getUsers()

		const renderTopLeader = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			if (index !== -1) {
				return ancestorUser.uid
			} else {
				return ancestorUser.name
			}
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await getUsers()
		}

		const search = async (type, event) => {
			event.preventDefault()

			if (type === 'button') {
				keyword.value = event.value
			} else {
				keyword.value = event.target.value
			}

			currentPage.value = 1

			await getUsers()
		}

		const handleClick = _userId => {
			router.push({ name: 'user-detail', params: { userId: _userId } })
		}

		const changeItemsPerPage = async limit => {
			itemsPerPage.value = limit
			await getUsers()
		}

		const downloadUserExcel = async () => {
			try {
				isExcelDownloading.value = true
				const response = await UserService.downloadUserExcel()
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', '전체유저.xlsx')
				document.body.appendChild(link)
				link.click()
			} catch (e) {
				console.error(e)
			} finally {
				isExcelDownloading.value = false
			}
		}

		return {
			headers,
			users,
			selected,
			selectedUser,
			currentPage,
			itemsPerPage,
			itemsPerPageGroups,
			totalPages,
			isLoading,
			keyword,
			isExcelDownloading,

			handleClick,
			resolveMarketerRole,
			resolveFactoryRole,
			handlePageChange,
			changeItemsPerPage,
			search,
			renderTopLeader,
			downloadUserExcel,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
