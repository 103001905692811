<template>
	<v-card>
		<v-card-title>
			<router-link :to="{ name: 'licenses' }" class="text-decoration-none">
				자격증
			</router-link>
			<span class="mx-1">&#62;</span>
			{{ form.title }}
		</v-card-title>
		<v-divider class="mb-5" />
		<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
			<v-tab v-for="(item, index) in items" :key="'tab-' + index">
				{{ item }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item>
				<v-card class="pt-5" height="100%">
					<v-card-text>
						<validation-observer ref="observer" v-slot="{ valid }">
							<license-form :form.sync="form" />
							<div class="d-flex justify-end pb-6">
								<v-btn color="error" @click="deleteLicense" class="mr-1">
									삭제
								</v-btn>
								<v-btn
									color="primary"
									:disabled="!valid"
									@click="updateLicense"
								>
									수정
								</v-btn>
							</div>
						</validation-observer>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<license-test-round-list :license-id="licenseId" />
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseTestRoundList from './LicenseTestRoundList.vue'

import LicenseForm from './components/LicenseForm.vue'

export default {
	components: {
		LicenseForm,
		LicenseTestRoundList,
	},
	setup(_, { emit }) {
		const { route, router } = useRouter()
		const form = ref({})
		const tab = ref(route.value.query.tab || null)
		const licenseId = ref(route.value.params.licenseId)
		const items = reactive(['자격증 상세보기', '자격시험 리스트'])

		const getLicense = async () => {
			form.value = await MarketLicenseService.getLicense(licenseId.value)
		}
		getLicense()

		const updateLicense = async () => {
			const confirm = await confirmSwal('자격증을 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLicenseService.updateLicense(form.value)
					successSwal('자격증 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '자격증을 수정하는데 문제가 발생했습니다.',
					)
				} finally {
					getLicense()
				}
			}
		}

		const deleteLicense = async () => {
			const confirm = await confirmSwal('자격증을 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLicenseService.deleteLicense(form.value.id)
					emit('is-fetch-licenses', true)
					successSwal('자격증 삭제가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '자격증을 삭제하는데 문제가 발생했습니다.',
					)
				} finally {
					router.push({ name: 'licenses' })
				}
			}
		}

		return {
			tab,
			form,
			items,
			licenseId,

			updateLicense,
			deleteLicense,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}
</style>
