<template>
	<v-data-table
		:headers="headers"
		:items="eventParticipants"
		:items-per-page="-1"
		no-data-text="조회된 고객이 없습니다."
		class="elevation-1"
		:loading="isLoading"
		loading-text="고객을 불러오는 중입니다..."
		hide-default-footer
	>
		<template v-slot:[`item.title`]="{ item }">
			{{ item.marketEvent.title }}
		</template>
		<template v-slot:[`item.status`]="{ item }">
			<v-chip
				small
				:color="
					resolveApplicationStatusVariant(
						item.marketEvent.category,
						item.status,
						item.retake,
						item.marketEvent.amount > 0 ? true : false,
						item.marketEvent.amount > 0 ? true : false,
					)
				"
			>
				{{
					resolveApplicationStatus(
						item.marketEvent.category,
						item.status,
						item.retake,
						item.marketEvent.amount > 0 ? true : false,
					)
				}}
			</v-chip>
		</template>
		<template v-slot:[`item.checkInAt`]="{ item }">
			<span v-if="item.checkIn && item.checkInAt">
				{{ item.checkInAt | dateKOSimpleSiBunChoFormat }}
			</span>
		</template>
		<template v-slot:[`item.checkOutAt`]="{ item }">
			<span v-if="item.checkIn && item.checkInAt">
				{{ item.checkOutAt | dateKOSimpleSiBunChoFormat }}
			</span>
		</template>
		<template v-slot:[`item.checkInCancelAt`]="{ item }">
			<span v-if="item.checkIn && item.checkInCancelAt">
				{{ item.checkInCancelAt | dateKOSimpleSiBunChoFormat }}
			</span>
		</template>
		<template v-slot:[`item.etc`]="{ item }">
			<v-btn
				small
				color="primary"
				class="mr-1"
				@click.stop="checkInEventParticipant(item)"
				v-if="!item.checkIn"
			>
				출석 체크
			</v-btn>
			<v-btn
				small
				color="secondary"
				@click.stop="cancelCheckInEventParticipant(item)"
				v-else
			>
				출석 체크 취소
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { marketerRole, factoryRole } from '@/helpers'
import {
	resolveApplicationStatus,
	resolveApplicationStatusVariant,
} from '@core/utils/filter'

import EventService from '@/services/EventService'

export default {
	components: {},
	props: {
		userId: {
			type: [String, Number],
			required: true,
		},
	},
	setup(props) {
		const isLoading = ref(false)
		const eventParticipants = ref([])
		const headers = ref([
			{ text: 'no', value: 'index', width: '50px' },
			{ text: '타이틀', value: 'title', width: '200px' },
			{ text: '상태', value: 'status', width: '100px' },
			{ text: '입실 출석 체크 날짜', value: 'checkInAt', width: '120px' },
			{ text: '퇴실 출석 체크 날짜', value: 'checkOutAt', width: '120px' },
			{ text: '출석 체크 취소 날짜', value: 'checkInCancelAt', width: '120px' },
		])

		const getUserEventParticipants = async () => {
			try {
				eventParticipants.value = await EventService.getUserEventParticipants(
					props.userId,
				)
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(async () => {
			await getUserEventParticipants()
		})

		return {
			headers,
			isLoading,
			marketerRole,
			factoryRole,
			eventParticipants,

			resolveApplicationStatus,
			resolveApplicationStatusVariant,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}
</style>
