<template>
	<v-card>
		<v-card-title>
			<router-link
				:to="{
					name: 'license-detail',
					params: { licenseId: licenseId },
					query: { tab: 1 },
				}"
				class="text-decoration-none"
			>
				자격시험 리스트
			</router-link>
			<span class="mx-1">&#62;</span>
			시험지 보기
		</v-card-title>
		<v-divider class="mb-5" />
		<v-card-text>
			<v-simple-table fixed-header height="560px">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-center">시험 타이틀</th>
							<th class="text-center">시험 내용</th>
							<th class="text-center">총 문제 수</th>
							<th class="text-center">최소 합격 점수</th>
							<th class="text-center">공개 여부</th>
							<th class="text-center">수정 &#47; 답안지 등록</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="workbooks.length > 0">
							<tr v-for="(item, index) in workbooks" :key="index">
								<td class="text-center">
									<strong>{{ item.title }}</strong>
								</td>
								<td class="text-center">{{ item.body }}</td>
								<td class="text-center">{{ item.problemCount }} 문제</td>
								<td class="text-center">
									<span v-if="item.minPassScore">
										{{ item.minPassScore }} 점
									</span>
									<span v-else>-</span>
								</td>
								<td class="text-center">
									{{ item.visible ? '공개' : '비공개' }}
								</td>
								<td class="text-center">
									<v-btn
										small
										class="mr-1"
										color="primary"
										@click.stop="openLicenseEditTestRoundWorkbookDialog(item)"
									>
										수정
									</v-btn>
									<v-btn
										small
										class="mr-1"
										color="primary"
										:to="{
											name: 'license-test-round-register-answer-sheet',
											params: {
												licenseId: licenseId,
												testRoundId: testRoundId,
												workbookId: item.id,
											},
										}"
									>
										답안지 등록
									</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td colspan="6">등록된 시험지가 없습니다</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
		<v-card-actions class="d-flex justify-end">
			<v-btn
				@click="
					isOpenLicenseRegisterTestRoundWorkbookDialog =
						!isOpenLicenseRegisterTestRoundWorkbookDialog
				"
				color="primary"
			>
				시험지 등록
			</v-btn>
		</v-card-actions>
		<license-register-test-round-workbook-dialog
			:license-id="licenseId"
			:test-round-id="testRoundId"
			@is-fetch-workbooks="isFetchWorkBooks = $event"
			:is-open-license-register-test-round-workbook-dialog.sync="
				isOpenLicenseRegisterTestRoundWorkbookDialog
			"
		/>
		<license-edit-test-round-workbook-dialog
			:selected-workbook="selectedWorkbook"
			@is-fetch-workbooks="isFetchWorkBooks = $event"
			:is-open-license-edit-test-round-workbook-dialog.sync="
				isOpenLicenseEditTestRoundWorkbookDialog
			"
		/>
	</v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import MarketLicenseService from '@/services/MarketLicenseService'

import LicenseRegisterTestRoundWorkbookDialog from './LicenseRegisterTestRoundWorkbookDialog.vue'
import LicenseEditTestRoundWorkbookDialog from './LicenseEditTestRoundWorkbookDialog.vue'

export default {
	components: {
		LicenseRegisterTestRoundWorkbookDialog,
		LicenseEditTestRoundWorkbookDialog,
	},
	setup() {
		const { route } = useRouter()
		const workbooks = ref({})
		const selectedWorkbook = ref({})
		const isFetchWorkBooks = ref(false)
		const licenseId = ref(route.value.params.licenseId)
		const testRoundId = ref(route.value.params.testRoundId)
		const isOpenLicenseRegisterTestRoundWorkbookDialog = ref(false)
		const isOpenLicenseEditTestRoundWorkbookDialog = ref(false)

		const listTestRoundWorkbook = async () => {
			workbooks.value = await MarketLicenseService.listTestRoundWorkbooks(
				testRoundId.value,
			)
		}
		listTestRoundWorkbook()

		const openLicenseEditTestRoundWorkbookDialog = item => {
			selectedWorkbook.value = item
			isOpenLicenseEditTestRoundWorkbookDialog.value =
				!isOpenLicenseEditTestRoundWorkbookDialog.value
		}

		watch(
			() => isFetchWorkBooks.value,
			currentValue => {
				if (currentValue) {
					listTestRoundWorkbook()
					isFetchWorkBooks.value = false
				}
			},
		)

		return {
			workbooks,
			licenseId,
			testRoundId,
			selectedWorkbook,
			isFetchWorkBooks,
			isOpenLicenseRegisterTestRoundWorkbookDialog,
			isOpenLicenseEditTestRoundWorkbookDialog,

			openLicenseEditTestRoundWorkbookDialog,
		}
	},
}
</script>
