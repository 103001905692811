<template>
	<div>
		<v-col cols="12" md="12" sm="12" class="py-0">
			<div class="d-flex align-center justify-space-between">
				<div class="d-flex align-center" style="min-width: 70px">
					<h2 class="mr-1">{{ questionNumber }}</h2>
					번 문제
				</div>
				<label
					v-for="index in totalQuestionCount"
					:key="'question-' + index"
					class="wrapper items-center align-center d-flex"
				>
					<input
						type="radio"
						:name="'question' + questionNumber"
						:value="index"
						:checked="isChecked(questionNumber, index)"
						disabled
					/>
					<span
						class="checkmark"
						:class="markCorrectAnswer(questionNumber, index) ? 'correct' : ''"
					>
						{{ index }}
					</span>
				</label>
			</div>
		</v-col>
	</div>
</template>
<script>
import { ref } from '@vue/composition-api'

const TOTAL_QUESTION_COUNT = 4

export default {
	props: {
		questionNumber: {
			type: Number,
			required: true,
		},
		workbookId: {
			type: Number,
			required: true,
		},
		testAnswer: {
			type: Array,
			required: true,
		},
		answerSheet: {
			type: Array,
			default: () => [],
		},
	},
	components: {},
	setup(props) {
		const totalQuestionCount = ref(TOTAL_QUESTION_COUNT)

		const isChecked = (questionNumber, _index) => {
			const answerSheet = props.answerSheet
			if (answerSheet.length > 0) {
				const index = answerSheet.findIndex(
					obj => parseInt(obj.key) === questionNumber - 1,
				)
				if (index !== -1) {
					return parseInt(answerSheet[index].value) === _index
				}
			} else {
				return false
			}
		}

		const markCorrectAnswer = (questionNumber, _index) => {
			const testAnswer = props.testAnswer
			const answerSheet = props.answerSheet
			if (answerSheet.length > 0) {
				return testAnswer[questionNumber - 1].value === _index
			}
		}

		return {
			totalQuestionCount,

			isChecked,
			markCorrectAnswer,
		}
	},
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: block;
	position: relative;
	cursor: pointer;
	text-align: center;
	font-size: 20px;
	min-height: 55px;

	@media screen and (max-width: 768px) {
		width: 20%;
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* Hide the browser's default radio button */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		/* When the radio button is checked, add a blue background */
		&:checked ~ .checkmark {
			color: white;
			background-color: #333333;
			transition: 0.4s;
		}

		& ~ .checkmark {
			&.correct {
				border: 3px solid #56ca00;
			}
		}
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0 auto;
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background-color: #ffffff;
		border: 1px solid #ccc;
	}
}
</style>
