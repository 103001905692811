import { render, staticRenderFns } from "./UserDetailOrderPaymentList.vue?vue&type=template&id=6fabffdc&scoped=true&"
import script from "./UserDetailOrderPaymentList.vue?vue&type=script&lang=js&"
export * from "./UserDetailOrderPaymentList.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailOrderPaymentList.vue?vue&type=style&index=0&id=6fabffdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fabffdc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VBtn,VCard,VDataTable,VIcon,VPagination})
