<template>
	<div>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="시험지 타이틀을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="body" rules="required">
			<v-text-field
				v-model="form.body"
				type="text"
				:error-messages="errors"
				label="시험지 내용을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider v-slot="{ errors }" name="location" rules="required">
			<v-text-field
				v-model="form.problemCount"
				type="text"
				:error-messages="errors"
				label="시험지 문항 수를 입력하세요"
				autocomplete="off"
				suffix="문제"
				required
			/>
			<v-text-field
				v-model="form.minPassScore"
				type="text"
				:error-messages="errors"
				label="최소 합격 점수를 입력하세요"
				autocomplete="off"
				suffix="점"
				required
			/>
		</validation-provider>
		<validation-provider name="visible" rules="required">
			<v-checkbox v-model="form.visible" label="공개 여부" hide-details />
		</validation-provider>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	setup() {},
}
</script>
