export default [
	{
		path: '/market-balance-withdrawals',
		name: 'market-balance-withdrawal-list',
		component: () =>
			import('@/pages/marketBalance/MarketBalanceWithdrawalList.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/market-balances',
		name: 'market-balance-list',
		component: () => import('@/pages/marketBalance/MarketBalanceList.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
