import Vue from 'vue'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/ko'

dayjs.locale('ko')
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(relativeTime)

const NUMBER_FORMAT_STANDARD = 1000

const KR_COUNTRY_CODE = 82

const formatDate = date => {
	return date
		.toLocaleDateString()
		.replace(/\./g, '')
		.split(' ')
		.map((v, i) => (i > 0 && v.length < 2 ? '0' + v : v))
		.join('-')
}

Vue.filter('dateFormat', function (datetime) {
	if (!datetime) return ''
	return `${datetime.split('T')[0]}`
})

Vue.filter('toCurrency', function (value) {
	if (typeof value !== 'number') {
		return value
	}
	const formatter = new Intl.NumberFormat('ko-KR', {
		minimumFractionDigits: 0,
	})
	return formatter.format(value)
})

Vue.filter('formatRegBuzNo', function (value) {
	const formattedtRegBuzNo =
		value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5, 10)
	return formattedtRegBuzNo
})

export const getTimeDiff = date => {
	return dayjs(date).from(dayjs())
}

const formatTimestamp = datetime => {
	const _datetime = new Date(datetime)
	_datetime.setHours(_datetime.getHours() + 9)
	const date = formatDate(_datetime)
	const time = _datetime.getHours()
	return `${date} ${time}`
}

export function convertUTC2KST(date) {
	if (!date) return ''

	return dayjs(date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
}
Vue.filter('convertUTC2KST', convertUTC2KST)

export function timeFormat(timeStamp) {
	if (!timeStamp) return ''

	return dayjs(timeStamp).format('A hh:mm')
}
Vue.filter('timeFormat', timeFormat)

export function dateKOSimpleFormat(date) {
	if (!date) return ''

	return dayjs(date).format('YYYY년 MM월 D일')
}
Vue.filter('dateKOSimpleFormat', dateKOSimpleFormat)

export function dateKOFormat(date) {
	if (!date) return ''

	return dayjs(date).format('YYYY년 MM월 D일 A h시 m분')
}
Vue.filter('dateKOFormat', dateKOFormat)

export function dateKOSimpleDotFormat(date) {
	if (!date) return ''

	return dayjs(date).format('YYYY.MM.DD H:mm')
}
Vue.filter('dateKOSimpleDotFormat', dateKOSimpleDotFormat)

export function dateKOSimpleSiBunChoFormat(date) {
	if (!date) return ''

	return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}
Vue.filter('dateKOSimpleSiBunChoFormat', dateKOSimpleSiBunChoFormat)

export function dateKOSimpleSiBunChoMsFormat(date) {
	if (!date) return ''

	return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
}
Vue.filter('dateKOSimpleSiBunChoMsFormat', dateKOSimpleSiBunChoMsFormat)

export function dateSiFormat(datetime) {
	if (!datetime) return ''
	return formatTimestamp(datetime)
}
Vue.filter('dateSiFormat', dateSiFormat)

export function dayDiff(date) {
	if (!date) return

	const currentDateFormat = dayjs().format('YYYY-MM-DD')
	const dateFormat = dayjs(date).format('YYYY-MM-DD')

	return dayjs(dateFormat).diff(dayjs(currentDateFormat), 'day')
}
Vue.filter('dayDiff', dayDiff)

export function substractYear(date, year) {
	if (!date) return ''
	return dayjs(date).subtract(year, 'year')
}
Vue.filter('substractYear', substractYear)

export function dateKSTToUTCFormat(datetime) {
	if (!datetime) return ''

	return datetime + '+09:00'
}
Vue.filter('dateKSTToUTCFormat', dateKSTToUTCFormat)

export function commaFormat(value) {
	let formatValue = value
	if (!value) {
		formatValue = '0'
	} else if (value >= NUMBER_FORMAT_STANDARD) {
		const strNumber = value.toString().replace(/[^\d\\-]/g, '')
		const plainNumber = Number(strNumber).toString()
		formatValue = plainNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}
	return formatValue
}
Vue.filter('commaFormat', commaFormat)

export function commaRemove(value) {
	const data =
		value.toString().indexOf(',') > -1 ? value.replace(/[^\d]/g, '') : value

	return parseInt(data)
}

Vue.filter('commaRemove', commaRemove)

export function excludeInternationalPhoneFormat(value) {
	if (!value) return

	return '0' + value.slice(2)
}

Vue.filter('excludeInternationalPhoneFormat', excludeInternationalPhoneFormat)

export function internationalPhoneFormat(value) {
	const data = KR_COUNTRY_CODE + value.slice(1)
	return data
}

Vue.filter('internationalPhoneFormat', internationalPhoneFormat)
