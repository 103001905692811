<template>
	<v-card>
		<v-card-title>
			<div>
				<div>
					<router-link
						:to="{ name: 'event-detail', params: { eventId: eventId } }"
						class="text-decoration-none"
					>
						{{ event.title }}
					</router-link>
					<span class="mx-1">></span>
					참여자 보기
				</div>
				<div class="mt-4">
					<v-simple-table fixed-header>
						<template v-slot:default>
							<thead>
								<tr>
									<th>장소</th>
									<th>일정</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										{{ event.location }}
									</td>
									<td>
										{{ event.eventAt | dateKOSimpleSiBunChoFormat }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</div>

			<v-spacer />
			<v-simple-table fixed-header>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">총 정원</th>
							<th class="text-left">참여자</th>
							<th class="text-left">참여자 현황</th>
							<th class="text-left">입실 출석 현황</th>
							<th class="text-left">퇴실 출석 현황</th>
						</tr>
						<tr></tr>
					</thead>
					<tbody>
						<tr>
							<td class="font-weight-bold">{{ event.maxParticipants }} 명</td>
							<td class="font-weight-bold">{{ totalCount }} 명</td>
							<td>
								<tr style="height: 30px">
									<td>
										<v-chip small color="info">결제완료</v-chip>
									</td>
									<td class="pl-3">{{ paidCount }} 명</td>
								</tr>
								<tr style="height: 30px">
									<td>
										<v-chip small color="primary">신청완료</v-chip>
									</td>
									<td class="pl-3">{{ appliedCount }} 명</td>
								</tr>
								<tr style="height: 30px">
									<td>
										<v-chip small color="primary">재수강 신청완료</v-chip>
									</td>
									<td class="pl-3">{{ reAppliedCount }} 명</td>
								</tr>
								<tr style="height: 30px">
									<td>
										<v-chip small color="warning">결제대기</v-chip>
									</td>
									<td class="pl-3">{{ waitingPaidCount }} 명</td>
								</tr>
								<tr style="height: 30px">
									<td>
										<v-chip small color="error">신청취소</v-chip>
									</td>
									<td class="pl-3">{{ canceledCount }} 명</td>
								</tr>
								<tr style="height: 30px">
									<td>
										<v-chip small>강제출석체크</v-chip>
									</td>
									<td class="pl-3">{{ forceCheckInCount }} 명</td>
								</tr>
							</td>
							<td>
								<tr>
									<td>완료</td>
									<td class="pl-4">{{ checkedInCount }} 명</td>
								</tr>
								<tr>
									<td>미완료</td>
									<td class="pl-4">{{ unCheckedInCount }} 명</td>
								</tr>
							</td>
							<td>{{ checkedOutCount }} 명</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
			<div class="d-flex flex-column">
				<v-btn
					color="secondary"
					class="ml-4 mb-3 white--text"
					@click="isOpenEventSeatCoexDialog = !isOpenEventSeatCoexDialog"
				>
					좌석 현황 보기
				</v-btn>
				<v-btn
					color="accent"
					class="ml-4 white--text"
					@click="downloadExcel"
					:loading="isExcelDownloading"
				>
					엑셀다운
					<v-icon right dark>mdi-cloud-download</v-icon>
				</v-btn>
			</div>
		</v-card-title>

		<div class="d-flex justify-end mb-2 mr-2">
			<input
				type="checkbox"
				v-model="isStatusPaid"
				@change="checkIsStatusPaid($event)"
			/>
			<span class="ml-2">결제 완료자만 보기</span>
		</div>
		<v-data-table
			:headers="headers"
			v-model="selectedParticipants"
			:items="eventParticipants"
			:items-per-page="-1"
			no-data-text="조회된 고객이 없습니다."
			class="elevation-1"
			:loading="isLoading"
			show-select
			loading-text="고객을 불러오는 중입니다..."
			hide-default-footer
		>
			<template v-slot:[`item.index`]="{ item }">
				<span @click="openUserInfoDialog(item.user.id)" class="clickable">
					{{ eventParticipants.indexOf(item) + 1 }}
				</span>
			</template>
			<template v-slot:[`item.user.ancestorUser`]="{ item }">
				<span
					v-if="item.user.ancestorUser && isExecutive(item.user.ancestorUser)"
				>
					{{ renderTopLeader(item.user.ancestorUser) }}
				</span>
			</template>
			<template v-slot:[`item.user.recommenderUser`]="{ item }">
				<span v-if="item.user.recommenderUser">
					{{ item.user.recommenderUser.uid }}
				</span>
			</template>
			<template v-slot:[`item.createdAt`]="{ item }">
				{{ item.createdAt | dateKOSimpleSiBunChoFormat }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip
					small
					:color="
						resolveApplicationStatusVariant(
							item.marketEvent.category,
							item.status,
							item.retake,
							item.marketEvent.amount > 0 ? true : false,
						)
					"
				>
					{{
						resolveApplicationStatus(
							item.marketEvent.category,
							item.status,
							item.retake,
							item.marketEvent.amount > 0 ? true : false,
						)
					}}
				</v-chip>
			</template>
			<template v-slot:[`item.checkInAt`]="{ item }">
				<span v-if="item.checkIn && item.checkInAt">
					{{ item.checkInAt | dateKOSimpleSiBunChoFormat }}
				</span>
			</template>
			<template v-slot:[`item.checkOutAt`]="{ item }">
				<span v-if="item.checkIn && item.checkInAt">
					{{ item.checkOutAt | dateKOSimpleSiBunChoFormat }}
				</span>
			</template>
			<template v-slot:[`item.checkInCancelAt`]="{ item }">
				<span v-if="item.checkIn && item.checkInCancelAt">
					{{ item.checkInCancelAt | dateKOSimpleSiBunChoFormat }}
				</span>
			</template>
			<template v-slot:[`item.etc`]="{ item }">
				<v-btn
					small
					color="primary"
					class="mr-1"
					@click.stop="checkInEventParticipant(item)"
					v-if="!item.checkIn"
				>
					출석 체크
				</v-btn>
				<v-btn
					small
					color="secondary"
					@click.stop="cancelCheckInEventParticipant(item)"
					v-else
				>
					출석 체크 취소
				</v-btn>
			</template>
		</v-data-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn
				:to="{ name: 'event-detail', params: { eventId: eventId } }"
				color="primary"
			>
				이벤트 상세보기
			</v-btn>
		</v-card-actions>
		<user-dialog
			:user-id="selectedUserId"
			:is-open-user-info-dialog.sync="isOpenUserInfoDialog"
		/>
		<event-seat-coex-dialog
			:event-id="eventId"
			:is-open-event-seat-coex-dialog.sync="isOpenEventSeatCoexDialog"
		/>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
	resolveApplicationStatus,
	resolveApplicationStatusVariant,
} from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { applicationStatus, executive } from '@/helpers'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'

import UserDialog from '@/components/common/UserDialog.vue'
import EventSeatCoexDialog from './EventSeatCoexDialog.vue'

export default {
	components: {
		UserDialog,
		EventSeatCoexDialog,
	},
	setup() {
		const { route } = useRouter()
		const event = ref({})
		const selectedUserId = ref('')
		const selectedParticipants = ref([])
		const eventParticipants = ref([])
		const isExcelDownloading = ref(false)
		const isOpenUserInfoDialog = ref(false)
		const isOpenEventSeatCoexDialog = ref(false)
		const totalCount = ref(0)
		const appliedCount = ref(0)
		const reAppliedCount = ref(0)
		const waitingPaidCount = ref(0)
		const paidCount = ref(0)
		const canceledCount = ref(0)
		const forceCheckInCount = ref(0)
		const checkedInCount = ref(0)
		const unCheckedInCount = ref(0)
		const checkedOutCount = ref(0)
		const eventId = ref(route.value.params.eventId)
		const isStatusPaid = ref(false)
		const headers = ref([
			{ text: 'no', value: 'index', width: '50px' },
			{ text: '상위리더', value: 'user.ancestorUser', width: '120px' },
			{ text: '추천인', value: 'user.recommenderUser', width: '120px' },
			{ text: '실명', value: 'user.name', width: '100px' },
			{ text: '닉네임', value: 'user.uid', width: '100px' },
			{ text: '휴대폰 번호', value: 'user.phoneNumber', width: '128px' },
			{ text: '생년월일', value: 'user.birthDate', width: '100px' },
			{ text: '신청날짜', value: 'createdAt', width: '100px' },
			{ text: '신청여부', value: 'status', width: '128px' },
			{ text: '출석 체크', value: 'etc', width: '120px' },
			{ text: '강제 출석체크 상세', value: 'forceReason', width: '128px' },
			{ text: '입실 출석 체크 날짜', value: 'checkInAt', width: '120px' },
			{ text: '퇴실 출석 체크 날짜', value: 'checkOutAt', width: '120px' },
			{ text: '출석 체크 취소 날짜', value: 'checkInCancelAt', width: '120px' },
		])
		const isLoading = ref(false)

		const getEvent = async () => {
			event.value = await EventService.getEvent(eventId.value)
		}
		getEvent()

		const isExecutive = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			return index !== -1 ? true : false
		}

		const renderTopLeader = ancestorUser => {
			const index = executive.findIndex(
				e => e.name === ancestorUser.name && e.uid === ancestorUser.uid,
			)
			return index !== -1 ? ancestorUser.uid : ancestorUser.name
		}

		const listEventParticipants = async () => {
			try {
				isLoading.value = true
				const data = await EventService.listEventParticipants(eventId.value)

				totalCount.value = data.length
				checkedInCount.value = data
					.filter(
						e =>
							e.checkIn === true &&
							e.status !== applicationStatus.CANCELED.value &&
							e.status !== applicationStatus.FORCE_CHECK_IN.value,
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				unCheckedInCount.value = data
					.filter(
						e =>
							(e.checkIn === false || e.checkIn === null) &&
							e.status !== applicationStatus.CANCELED.value &&
							e.status !== applicationStatus.FORCE_CHECK_IN.value,
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				checkedOutCount.value = data
					.filter(
						e =>
							e.checkOut === true &&
							e.status !== applicationStatus.CANCELED.value &&
							e.status !== applicationStatus.FORCE_CHECK_IN.value,
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				appliedCount.value = data
					.filter(
						e =>
							e.status === applicationStatus.APPLIED.value &&
							e.marketEvent.amount === 0 &&
							!e.retake,
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				reAppliedCount.value = data
					.filter(e => e.retake)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				waitingPaidCount.value = data
					.filter(
						e =>
							e.status === applicationStatus.APPLIED.value &&
							e.marketEvent.amount > 0 &&
							!e.retake,
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				paidCount.value = data
					.filter(
						e =>
							!e.retake &&
							(e.status === applicationStatus.PAID.value ||
								e.status === applicationStatus.DONE.value),
					)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				canceledCount.value = data
					.filter(e => e.status === applicationStatus.CANCELED.value)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)
				forceCheckInCount.value = data
					.filter(e => e.status === applicationStatus.FORCE_CHECK_IN.value)
					.reduce((acc, _) => {
						return acc + 1
					}, 0)

				eventParticipants.value = data
			} catch (e) {
				warningSwal('사용자를 불러오는데 문제가 발생했습니다.')
			} finally {
				isLoading.value = false
			}
		}
		listEventParticipants()

		const downloadExcel = async () => {
			try {
				isExcelDownloading.value = true
				const response = await EventService.downloadEventParticipationExcel(
					eventId.value,
				)
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', '교육 수강자.xlsx')
				document.body.appendChild(link)
				link.click()
			} catch (e) {
				console.error(e)
			} finally {
				isExcelDownloading.value = false
			}
		}

		const checkInEventParticipant = async data => {
			const confirm = await confirmSwal(
				`${data.user.name}님 출석 체크 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.checkInEventParticipant({
						eventId: data.marketEvent.id,
						userId: data.user.id,
					})
					listEventParticipants()
					successSwal('출석체크가 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '출석체크하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const cancelCheckInEventParticipant = async data => {
			const confirm = await confirmSwal(
				`${data.user.name} 출석체크를 취소하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.cancelCheckInEventParticipant({
						eventId: data.marketEvent.id,
						userId: data.user.id,
					})
					listEventParticipants()
					successSwal('출석체크가 취소되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '출석체크를 취소하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const openUserInfoDialog = userId => {
			selectedUserId.value = userId
			isOpenUserInfoDialog.value = !isOpenUserInfoDialog.value
		}

		const checkIsStatusPaid = async () => {
			if (isStatusPaid.value) {
				eventParticipants.value = eventParticipants.value.filter(
					e => e.status === applicationStatus.PAID.value,
				)
				return
			}
			listEventParticipants()
		}

		return {
			headers,
			isStatusPaid,
			isLoading,
			isExcelDownloading,
			eventId,
			event,
			selectedUserId,
			eventParticipants,
			isOpenUserInfoDialog,
			isOpenEventSeatCoexDialog,
			totalCount,
			checkedInCount,
			unCheckedInCount,
			checkedOutCount,
			appliedCount,
			reAppliedCount,
			waitingPaidCount,
			paidCount,
			canceledCount,
			forceCheckInCount,
			selectedParticipants,

			resolveApplicationStatus,
			resolveApplicationStatusVariant,

			checkIsStatusPaid,
			downloadExcel,
			openUserInfoDialog,
			checkInEventParticipant,
			cancelCheckInEventParticipant,
			isExecutive,
			renderTopLeader,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}

.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
