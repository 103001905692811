<template>
	<v-dialog v-model="isOpenPostProcessTargetListDialog" persistent fullscreen>
		<v-card class="mb-16">
			<v-card-title>
				콘텐츠 장바구니
				<v-spacer />
				<v-btn
					icon
					@click="
						$emit('update:is-open-post-process-target-list-dialog', false)
					"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="postProcessTargets"
					:items-per-page="-1"
					no-data-text="장바구니에 내역이 없습니다."
				>
					<template v-slot:[`item.postingNumber`]="{ item }">
						{{ item.postingNumber }}
						<v-btn icon @click="openPostDetailDialog(item.postId)">
							<v-icon>mdi-open-in-new</v-icon>
						</v-btn>
					</template>
					<template v-slot:[`item.writerUser`]="{ item }">
						<div class="d-flex align-center">
							<v-avatar :size="isSmAndDown ? 20 : 26">
								<v-img
									:src="
										item.writerUserPublicPictureUrl ||
										require('@/assets/images/avatars/default.svg')
									"
								/>
							</v-avatar>
							<span class="ml-1">{{ item.writerUserUid }}</span>
						</div>
					</template>
					<template v-slot:[`item.contents`]="{ item }">
						<div class="d-flex align-center" style="width: 160px">
							<market-post-swiper
								:post="{ files: item.postFiles }"
								:is-cart="true"
								style="width: 100%; max-width: 300px; height: 200px"
							/>
						</div>
					</template>
					<template v-slot:[`item.payment`]="{ item }">
						<v-text-field
							v-model="item.payment"
							hide-details
							dense
							outlined
							label="거래 가격을 입력하세요."
						/>
					</template>
					<template v-slot:[`item.result`]="{ item }">
						<span :class="`result-${item.postId}`">-</span>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn @click="deletePost(item.postId, item.firebaseKey)" icon>
							<v-icon large color="error">mdi-close-circle-outline</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn color="primary" @click="onSave">바로 구매하기</v-btn>
			</v-card-actions>
		</v-card>

		<market-post-detail-dialog
			:post-id="selectedPostId"
			@fetch-post-process-targets="isFetchPostProcessTargets = $event"
			:is-open-post-detail-dialog.sync="isOpenPostDetailDialog"
		/>
	</v-dialog>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'

import store from '@/store'
import { getVuetify } from '@core/utils'
import { contentsMarketStatus } from '@/helpers'
import { confirmSwal, successSwal } from '@/plugins/swalMixin'

import MarketPostDetailDialog from './MarketPostDetailDialog'
import MarketPostSwiper from './components/MarketPostSwiper.vue'

import MarketPostService from '@/services/MarketPostService'

export default {
	components: {
		MarketPostSwiper,
		MarketPostDetailDialog,
	},
	props: {
		fetchPostProcessTargets: {
			type: Boolean,
		},
		isOpenPostProcessTargetListDialog: {
			required: true,
		},
	},
	setup(props, { emit }) {
		const $vuetify = getVuetify()
		const selectedPostId = ref(null)
		const isOpenPostDetailDialog = ref(false)
		const isFetchPostProcessTargets = ref(false)
		const postProcessTargets = ref([])
		const headers = ref([
			{
				text: '콘텐츠 번호',
				value: 'postingNumber',
				align: 'center',
				width: '120px',
			},
			{
				text: '콘텐츠 판매자',
				value: 'writerUser',
				align: 'center',
				width: '150px',
			},
			{
				text: '콘텐츠',
				value: 'contents',
				align: 'center',
				width: '150px',
			},
			{ text: '구매금액', value: 'payment', align: 'center', width: '150px' },
			{ text: '처리결과', value: 'result', align: 'center', width: '200px' },
			{ text: '삭제', value: 'actions', align: 'center', width: '200px' },
			{ text: '메모', value: 'message', align: 'center', width: '200px' },
		])
		const paymentGroups = [
			{
				name: '반환',
				value: contentsMarketStatus.DISAPPROVED.value,
			},
		]

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const getMarketPostProcessTargets = async () => {
			const tempObj = await store.getters['post/getPostProcessTargets']()
			if (tempObj) {
				postProcessTargets.value = Object.keys(tempObj).map(k => {
					tempObj[k].firebaseKey = k
					return tempObj[k]
				})
			} else {
				postProcessTargets.value = []
			}
		}

		const isImage = url => {
			return url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
		}

		const openPostDetailDialog = postId => {
			selectedPostId.value = postId
			isOpenPostDetailDialog.value = !isOpenPostDetailDialog.value
		}

		const deletePost = async (postId, firebaseKey) => {
			const confirm = await confirmSwal(
				'해당 콘텐츠를 장바구니에서 제거하시겠습니까?',
			)
			if (confirm.isConfirmed) {
				try {
					store.dispatch('post/deletePostProcessTarget', {
						firebaseKey: firebaseKey,
						postId: postId,
					})
				} catch (e) {
					console.error(e)
				} finally {
					getMarketPostProcessTargets()
				}
			}
		}

		const onSave = async () => {
			const saveConfirm = await confirmSwal('구매하시겠습니까?')
			if (saveConfirm.isConfirmed) {
				let successCount = 0
				for (const post of postProcessTargets.value) {
					const resultElement = document.getElementsByClassName(
						`result-${post.postId}`,
					)[0]
					try {
						if (post.payment === contentsMarketStatus.DISAPPROVED.value) {
							await MarketPostService.disapprovalMarketPost({
								postId: post.postId,
								message: post.message,
							})
							store.dispatch('post/deletePostProcessTarget', {
								postId: post.postId,
								firebaseKey: post.firebaseKey,
							})
						} else {
							await MarketPostService.approvalMarketPost({
								postId: post.postId,
								payment: post.payment,
								message: post.message,
							})
						}

						resultElement.classList.add('text-success')
						resultElement.innerHTML = '처리완료'

						store.dispatch('post/deletePostProcessTarget', {
							postId: post.postId,
							firebaseKey: post.firebaseKey,
						})
						successCount++
					} catch (e) {
						resultElement.classList.add('text-fail')
						resultElement.innerHTML = '처리실패'

						if (e.response.status === 400) {
							resultElement.innerHTML = '이미 처리 됨'
							store.dispatch('post/deletePostProcessTarget', {
								postId: post.postId,
								firebaseKey: post.firebaseKey,
							})
						}
					}
				}

				successSwal(`${successCount}건 처리되었습니다`)
				emit('fetch-market-posts', true)
				emit('update:fetch-post-process-targets', true)
			}
		}

		const renderUrl = file => {
			if (file.url.match(/\.(heic)$/i)) {
				return ''
			}

			return file.url_600 ?? file.url + '?t=' + Date.now()
		}

		const changeSelectedPayment = ($event, item) => {
			const payload = {
				postId: item.postId,
				postingNumber: item.postingNumber,
				writerUserPublicPictureUrl: item.writerUserPublicPictureUrl,
				writerUserUid: item.writerUserUid,
				postFiles: item.postFiles,
				payment: $event,
				message: item.message,
			}

			store.dispatch('post/setPostProcessTarget', payload)
		}

		watch(
			() => props.isOpenPostProcessTargetListDialog,
			currentValue => {
				if (currentValue) {
					getMarketPostProcessTargets()
				}
			},
		)

		watch(
			() => isFetchPostProcessTargets.value,
			currentValue => {
				if (currentValue) {
					getMarketPostProcessTargets()
				}
			},
		)

		return {
			headers,
			isSmAndDown,
			paymentGroups,
			selectedPostId,
			postProcessTargets,
			isOpenPostDetailDialog,
			isFetchPostProcessTargets,

			isImage,
			onSave,
			renderUrl,
			deletePost,
			openPostDetailDialog,
			changeSelectedPayment,
		}
	},
}
</script>
<style lang="scss" scoped>
.text-success {
	color: #56ca00;
}
.text-fail {
	color: #ff4c51;
}
</style>
