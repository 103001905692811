<template>
	<v-dialog v-model="isOpenMarketLectureDialog" persistent max-width="800">
		<v-card>
			<v-card-title>
				강의 연결하기
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-market-lecture-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-5" />
			<v-card-text>
				<validation-observer ref="observer" v-slot="{ valid }">
					<market-lecture-form :form.sync="form" />

					<div class="d-flex justify-end pb-6">
						<v-btn color="error" @click="deleteMarketLecture" class="mr-1">
							삭제
						</v-btn>
						<v-btn
							color="primary"
							:disabled="!valid"
							@click="updateMarketLecture"
						>
							수정
						</v-btn>
					</div>
				</validation-observer>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLectureService from '@/services/MarketLectureService'

import MarketLectureForm from './components/MarketLectureForm.vue'

export default {
	components: {
		MarketLectureForm,
	},
	props: {
		isOpenMarketLectureDialog: {
			type: Boolean,
			required: true,
		},
		marketLectureId: {
			type: [String, Number],
		},
	},
	setup(props, { emit }) {
		const form = ref({})

		const getMarketCourseLecture = async () => {
			try {
				form.value = await MarketLectureService.getMarketLecture(
					props.marketLectureId,
				)
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => props.marketLectureId,
			currentValue => {
				if (currentValue) {
					getMarketCourseLecture()
				}
			},
		)

		const updateMarketLecture = async () => {
			const confirm = await confirmSwal('강의를 수정 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLectureService.updateMarketLecture(form.value)
					successSwal('강의 수정이 완료되었습니다')
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '강의를 수정하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const deleteMarketLecture = async () => {
			const confirm = await confirmSwal('강의를 삭제 하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await MarketLectureService.deleteMarketLecture(form.value.id)
					await successSwal('강의 삭제가 완료되었습니다')
					emit('fetch-market-course-lectures', true)
					emit('update:is-open-market-lecture-dialog', false)
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '강의를 삭제하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		return {
			form,

			updateMarketLecture,
			deleteMarketLecture,
		}
	},
}
</script>
