<template>
	<div>
		<validation-provider v-slot="{ errors }" name="title" rules="required">
			<v-text-field
				v-model="form.title"
				type="text"
				:error-messages="errors"
				label="타이틀을 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider
			v-slot="{ errors }"
			name="description"
			rules="required"
		>
			<v-text-field
				v-model="form.description"
				type="text"
				:error-messages="errors"
				label="설명을 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider
			v-slot="{ errors }"
			name="vimeoVideoId"
			rules="required"
		>
			<v-text-field
				v-model="form.vimeoVideoId"
				type="text"
				:error-messages="errors"
				label="비메오 ID를 입력하세요"
				autocomplete="off"
				required
			/>
		</validation-provider>
		<validation-provider
			v-slot="{ errors }"
			name="vimeoVideoLengthInSec"
			rules="required"
		>
			<v-text-field
				v-model="form.vimeoVideoLengthInSec"
				type="text"
				:error-messages="errors"
				autocomplete="off"
				label="비메오 영상 길이를 초단위로 입력하세요"
				required
			/>
		</validation-provider>
		<validation-provider name="visible">
			<v-checkbox
				class="mb-2"
				v-model="form.visible"
				label="공개 여부"
				hide-details
			/>
		</validation-provider>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {}
	},
}
</script>
