const factoryRole = {
	ADMIN: { name: '관리자', value: 'ADMIN' },
	CONSUMER: {
		name: '고객',
		value: 'CONSUMER',
	},
	PARTNER: { name: '전문가', value: 'PARTNER' },
}

export default factoryRole
