<template>
	<v-card>
		<v-simple-table fixed-header>
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-center" style="width: 10%">구분</th>
						<th class="text-center">타이틀</th>
						<th class="text-center">이벤트 일정</th>
						<th class="text-center">장소</th>
						<th class="text-center">모집인원</th>
						<th class="text-center">마감일</th>
						<th class="text-center">공개 여부</th>
						<th class="text-center">참여자 보기</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="events.length > 0">
						<tr
							v-for="(item, index) in events"
							:key="index"
							class="cursor-pointer"
							@click="routeToDetail(item.id)"
						>
							<td class="text-center">
								<strong>{{ resolveEventCategory(item.category) }}</strong>
							</td>
							<td class="text-center">
								{{ item.title }}
							</td>
							<td class="text-center">
								{{ item.eventAt | dateKOSimpleSiBunChoFormat }}
							</td>
							<td class="text-center">{{ item.location }}</td>
							<td class="text-center">{{ item.maxParticipants }} 명</td>
							<td class="text-center">{{ item.eventAt | dateFormat }}</td>
							<td class="text-center">
								{{ item.visible ? '공개' : '비공개' }}
							</td>
							<td class="text-center">
								<v-btn
									small
									color="primary"
									@click.stop="routeToEventParticipants(item.id)"
								>
									참여자 보기
								</v-btn>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr>
							<td colspan="6">현재 등록된 이벤트가 없습니다</td>
						</tr>
					</template>
				</tbody>
			</template>
		</v-simple-table>
		<v-card-actions class="d-flex justify-end mt-5">
			<v-btn
				@click="isOpenEventRegisterDialog = !isOpenEventRegisterDialog"
				color="primary"
			>
				이벤트 등록
			</v-btn>
		</v-card-actions>
		<event-register-dialog
			:category="category"
			:event-category="eventCategory"
			:is-open-event-register-dialog.sync="isOpenEventRegisterDialog"
			@is-fetch-events="isFetchEvents = $event"
		/>
	</v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { resolveEventCategory } from '@core/utils/filter'

import EventService from '@/services/EventService'

import EventRegisterDialog from './EventRegisterDialog.vue'

export default {
	props: {
		isFetchEvents: {
			type: Boolean,
		},
		category: {
			type: String,
			required: true,
		},
		eventCategory: {
			type: Object,
			required: true,
		},
	},
	components: {
		EventRegisterDialog,
	},
	setup(props, { emit }) {
		const events = ref([])
		const { router } = useRouter()
		const selectedEvent = ref({})
		const isOpenEventRegisterDialog = ref(false)
		const isOpenEventEditDialog = ref(false)

		const listEvents = async () => {
			events.value = await EventService.listEvents(props.category)
		}
		listEvents()

		const routeToDetail = eventId => {
			router.push({ name: 'event-detail', params: { eventId: eventId } })
		}

		const routeToEventParticipants = eventId => {
			let routeName
			if (props.category === props.eventCategory?.LICENSE_TEST.value) {
				routeName = 'license-test-participants'
			} else {
				routeName = 'event-participants'
			}

			router.push({
				name: routeName,
				params: { eventId: eventId },
			})
		}

		watch(
			() => props.isFetchEvents,
			currentValue => {
				if (currentValue) {
					listEvents()
					emit('is-fetch-events', false)
				}
			},
		)

		return {
			events,
			selectedEvent,
			isOpenEventRegisterDialog,
			isOpenEventEditDialog,

			resolveEventCategory,
			routeToDetail,
			routeToEventParticipants,
		}
	},
}
</script>
