<template>
	<v-dialog v-model="isOpenPostPrivateInquiryDialog" persistent max-width="700">
		<v-card class="mb-16" v-if="post">
			<v-card-title class="d-flex justify-space-between py-3 font-weight-bold">
				<div>1:1 문의</div>
				<v-btn
					@click="$emit('update:is-open-post-private-inquiry-dialog', false)"
					icon
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card>
				<v-card-title class="py-3 d-flex justify-space-between">
					<div class="d-flex">
						<v-avatar size="26">
							<v-img
								:src="
									post.writerUser.publicPictureUrl ||
									require('@/assets/images/avatars/default.svg')
								"
							/>
						</v-avatar>
						<div class="pl-2">
							<strong class="mr-2 text-sm">
								{{ post.writerUser.uid }}({{ post.writerUser.uid }})
							</strong>
							<span class="text-sm" v-html="post.title"></span>
						</div>
					</div>
					<div>
						<span class="text-sm">{{ post.createdAt | convertUTC2KST }}</span>
					</div>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-textarea
						lable="문의 내용"
						readonly
						outlined
						hide-details=""
						v-model="post.content"
						rows="4"
					/>
				</v-card-text>
			</v-card>
			<post-comment
				:post="post"
				v-if="post"
				@fetch-post="isFetchPost = $event"
			/>
		</v-card>
	</v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketPostService from '@/services/MarketPostService'

import PostComment from './components/PostComment.vue'

export default {
	components: {
		PostComment,
	},
	props: {
		isOpenPostPrivateInquiryDialog: {
			required: true,
			type: Boolean,
		},
		postId: {
			type: [String, Number],
		},
	},
	setup(props) {
		const post = ref(null)
		const message = ref('')
		const isFetchPost = ref(false)

		const onSave = async () => {
			const saveConfirm = await confirmSwal('답변을 저장하시겠습니까?')
			if (saveConfirm.isConfirmed) {
				try {
					await MarketPostService.approvalMarketPost({
						postId: post.value.id,
						message: message.value,
					})
					successSwal('답변이 저장되었습니다')
				} catch (e) {
					warningSwal({
						html:
							e.response.status === 400
								? e.response.data.message
								: '답변을 남기는데 문제가 발생했습니다.',
					})
				}
			}
		}

		const getMarketPostDetail = async () => {
			try {
				post.value = await MarketPostService.getMarketPostDetail(props.postId)
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => props.isOpenPostPrivateInquiryDialog,
			currentValue => {
				if (currentValue) {
					getMarketPostDetail()
				}
			},
		)

		watch(
			() => isFetchPost.value,
			currentValue => {
				if (currentValue) {
					getMarketPostDetail()
				}
			},
			{
				immediate: true,
			},
		)

		return {
			post,
			message,
			isFetchPost,

			onSave,
		}
	},
}
</script>
