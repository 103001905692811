import dayjs from 'dayjs'
import axios from '@/api/axios'
import { postType } from '@/helpers'

import {
	getDatabase,
	ref,
	onValue,
	get,
	remove,
	push,
} from '@firebase/database'

const MARKET_CONTENT_CART_STORAGE = 'market_content_carts'

const db = getDatabase()

const marketPostService = {
	getMyMarketPostsDetail() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-posts/me/detail?orderby=createdAt&order=desc',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketPosts({
		limit,
		page,
		query,
		category,
		orderby,
		order,
		writerUid,
		result,
		beginDate,
		endDate,
		type = '',
		subjectLounge = '',
		subjectIds = null,
		etags = null,
		contentMarketStatistics = false,
		sortCriteria = '',
		hasSubjectId,
		published,
	}) {
		return new Promise(async (resolve, reject) => {
			const params = {}
			const headers = {
				'X-Comments': true,
				'X-likes': true,
			}

			if (type) {
				params['type'] = type
			} else {
				params[
					'types'
				] = `${postType.CONTEST2.value},${postType.CONTEST2_DONA.value}`
			}

			if (typeof published === 'boolean') {
				params['published'] = published
			}

			if (subjectLounge) {
				params['subject_lounge'] = subjectLounge
			}

			if (typeof hasSubjectId === 'boolean') {
				params['has_subject_id'] = hasSubjectId
			}

			if (limit) {
				params['limit'] = limit
			}
			if (page >= 0) {
				params['page'] = page
			}
			if (query) {
				params['query'] = query
			}
			if (category) {
				params['category'] = category
			}
			if (orderby) {
				params['orderby'] = orderby
			}
			if (order) {
				params['order'] = order
			}
			if (result && result !== 'ALL') {
				params['result'] = result
			}
			if (writerUid) {
				params['writerUid'] = writerUid
			}
			if (etags) {
				params['etags'] = etags
			}
			if (subjectIds && subjectIds.length > 0) {
				params['subject_ids'] = subjectIds.join()
				if (subjectIds.length == 1 && subjectIds[0] === '') {
					delete params['subject_ids']
				}
			}
			if (beginDate) {
				const _beginDate = dayjs(beginDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['begin_date'] = _beginDate
			}
			if (endDate) {
				const _endDate = dayjs(endDate)
					.utc()
					.format()
					.toString()
					.replace('T', ' ')
					.replace('Z', '')
				params['end_date'] = _endDate
			}

			if (contentMarketStatistics) {
				headers['X-Content-Market-Statistics'] = contentMarketStatistics
			}

			if (sortCriteria) {
				sortCriteria.forEach((element, index) => {
					const splittedSortCriteria = element.split('-')
					const _order = `order${index > 0 ? index + 1 : ''}`
					const _orderby = `order${index > 0 ? index + 1 : ''}by`

					params[_order] = splittedSortCriteria[0]
					params[_orderby] = splittedSortCriteria[1]
				})
			}

			try {
				const response = await axios({
					method: 'GET',
					url: '/market-posts/detail',
					params,
					headers,
				})

				resolve({
					posts: response.data,
					totalCount: response.headers['x-total-count'],
					totalPages: response.headers['x-total-pages-count'],
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketPost(id, contentMarketStatistics = false) {
		const headers = {}

		if (contentMarketStatistics) {
			headers['X-Content-Market-Statistics'] = contentMarketStatistics
		}

		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-posts/${id}`,
					headers,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketPostDetail(postId, contentMarketStatistics = false) {
		const headers = {
			'X-comments': true,
		}

		if (contentMarketStatistics) {
			headers['X-Content-Market-Statistics'] = contentMarketStatistics
		}

		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-posts/${postId}/detail`,
					headers,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	create(body) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-posts',
					data: body,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	update(id, payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: `/market-posts/${id}`,
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketPost(postId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-posts/${postId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	approvalMarketPost(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-posts/${payload.postId}/actions/contents-market/approval`,
					data: {
						payment: payload.payment,
						message: payload.message,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	disapprovalMarketPost(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-posts/${payload.postId}/actions/contents-market/disapproval`,
					data: {
						message: payload.message,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	disapprovalAllMarketPost(postIds) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/market-posts/actions/contents-market/disapproval',
					data: {
						ids: postIds,
						message: '',
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postMarketPostComment(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-posts/${payload.id}/comments`,
					data: {
						title: payload.comment,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketContentCart(firebaseUid) {
		return new Promise(async (resolve, reject) => {
			try {
				const marketContentCartRef = ref(
					db,
					`${MARKET_CONTENT_CART_STORAGE}/${firebaseUid}`,
				)
				onValue(
					marketContentCartRef,
					snapshot => {
						let data = null
						if (snapshot.exists()) {
							data = snapshot.val()
						}
						resolve(data)
					},
					{
						onlyOnce: true,
					},
				)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	pushMarketContentCart(firebaseUid, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const marketContentCartRef = ref(
					db,
					`${MARKET_CONTENT_CART_STORAGE}/${firebaseUid}`,
				)
				push(marketContentCartRef, data)

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketContentCart(firebaseUid, firebaseKey) {
		return new Promise(async (resolve, reject) => {
			try {
				const marketContentCartRef = ref(
					db,
					`${MARKET_CONTENT_CART_STORAGE}/${firebaseUid}/${firebaseKey}`,
				)

				get(marketContentCartRef).then(snapshot => {
					if (snapshot.exists()) {
						remove(marketContentCartRef)
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteMarketContentCarts(firebaseUid) {
		return new Promise(async (resolve, reject) => {
			try {
				const marketContentCartRef = ref(
					db,
					`${MARKET_CONTENT_CART_STORAGE}/${firebaseUid}`,
				)
				remove(marketContentCartRef)

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	adminReadMarketPostComments(commentId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-post-comments/${commentId}/actions/admin-read`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	uploadFile(id, body) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-posts/${id}/files`,
					data: body,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketPostService
