<template>
	<v-card style="height: 100%" elevation="0" class="px-3">
		<!-- TODO 자격증 등급 필터 추가 -->
		<v-card-title class="pl-0">증명사진 리스트</v-card-title>
		<div class="d-flex mb-2 mr-1">
			<v-select
				item-text="name"
				item-value="value"
				:items="itemsPerPageGroups"
				label="페이지당 개수"
				@change="changeItemsPerPage"
				:value="30"
				style="max-width: 100px"
				hide-details
				class="ml-1"
				dense
				outlined
			/>
			<v-text-field
				v-model="keyword"
				append-icon="mdi-magnify"
				label="검색어 입력"
				class="search-field ml-1"
				@keyup.enter.prevent="search('input', $event)"
				ref="searchField"
				dense
				outlined
				single-line
				hide-details
				autocomplete="off"
			/>
			<v-btn
				@click.stop.prevent="search('button', $refs.searchField)"
				class="ml-1"
				color="primary"
			>
				검색
			</v-btn>
			<v-select
				item-text="name"
				item-value="value"
				:items="idPhotoStatusGroups"
				label="증명사진 승인여부"
				:value="selectedIdPhotoStatus"
				@change="changeIdPhotoStatus"
				style="max-width: 170px"
				hide-details
				class="ml-1"
				dense
				outlined
			/>
		</div>
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="users"
			:items-per-page="itemsPerPage"
			item-key="email"
			no-data-text="조회된 고객이 없습니다."
			class="elevation-1"
			:loading="isLoading"
			loading-text="고객을 불러오는 중입니다..."
			show-select
			hide-default-footer
		>
			<template v-slot:[`item.id`]="{ item }">
				<span @click="handleClick(item.id)" class="clickable">
					{{ item.id }}
				</span>
			</template>
			<template v-slot:[`item.licenseIssues`]="{ item }">
				<div v-if="item.licenseIssues.length > 0">
					{{ item.licenseIssues[item.licenseIssues.length - 1].license.title }}
					{{ item.licenseIssues[item.licenseIssues.length - 1].classNumber }} 기
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								outlined
								x-small
								color="primary"
								rounded
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-dots-horizontal</v-icon>
							</v-btn>
						</template>
						<v-simple-table fixed-header dense>
							<template v-slot:default>
								<thead>
									<tr style="background: #e6e6e6">
										<td class="text-center">자격증 명</td>
										<td class="text-center">기수</td>
										<td class="text-center">발급번호</td>
										<td class="text-center">발급일</td>
									</tr>
								</thead>
								<tbody>
									<template v-if="item.licenseIssues.length > 0">
										<tr
											v-for="(item, index) in item.licenseIssues"
											:key="index"
										>
											<td class="text-center">{{ item.license.title }}</td>
											<td class="text-center">{{ item.classNumber }}</td>
											<td class="text-center">{{ item.issueCode }}</td>
											<td class="text-center">
												{{ item.issuedAt | dateFormat }}
											</td>
										</tr>
									</template>
								</tbody>
							</template>
						</v-simple-table>
					</v-menu>
				</div>
			</template>
			<template v-slot:[`item.idPhotoUrl`]="{ item }">
				<span @click="openUserIdPhotoDialog(item)" class="cursor-pointer">
					<v-img
						:src="item.idPhotoUrl"
						v-if="item && item.idPhotoUrl"
						max-width="100"
						max-height="120"
						class="my-1"
					/>
				</span>
			</template>
			<template v-slot:[`item.idPhotoStatus`]="{ item }">
				<v-chip small :color="resolveIdPhotoStatusVariant(item.idPhotoStatus)">
					{{ resolveIdPhotoStatus(item.idPhotoStatus) }}
				</v-chip>
			</template>
			<template v-slot:[`item.idPhotoActions`]="{ item }">
				<div
					class="d-flex flex-column"
					v-if="
						item.idPhotoUrl &&
						item.idPhotoStatus.includes([idPhotoStatus.UPLOADED.value])
					"
				>
					<v-btn small @click="confirmIdPhoto(item.id)" color="primary">
						승인
					</v-btn>
					<v-btn
						small
						@click="rejectIdPhoto(item.id)"
						class="mt-1"
						color="error"
					>
						거절
					</v-btn>
				</div>
				<div v-else>-</div>
			</template>
		</v-data-table>
		<div class="mx-auto mt-6">
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="7"
			/>
		</div>
		<user-id-photo-dialog
			:is-open-user-id-photo-dialog.sync="isOpenUserIdPhotoDialog"
			:user="selectedUser"
		/>
	</v-card>
</template>

<script>
import { ref, reactive, onMounted } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { idPhotoStatus } from '@/helpers'
import {
	resolveIdPhotoStatus,
	resolveIdPhotoStatusVariant,
} from '@core/utils/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'

import UserIdPhotoDialog from './UserIdPhotoDialog.vue'

export default {
	components: {
		UserIdPhotoDialog,
	},
	setup() {
		const { router } = useRouter()
		const users = ref([])
		const selected = ref([])
		const selectedUser = ref({})
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)
		const selectedIdPhotoStatus = ref(idPhotoStatus.UPLOADED.value)
		const isLoading = ref(false)
		const keyword = ref('')
		const isOpenUserIdPhotoDialog = ref(false)
		const itemsPerPageGroups = reactive([
			{
				name: '30',
				value: 30,
			},
			{
				name: '60',
				value: 60,
			},
			{
				name: '90',
				value: 90,
			},
			{
				name: '전체',
				value: 1000000,
			},
		])
		const idPhotoStatusGroups = reactive([
			...Object.values(idPhotoStatus).filter(
				e => e.value !== idPhotoStatus.READY.value,
			),
		])

		const headers = ref([
			{
				text: '유저아이디',
				align: 'left',
				value: 'id',
				width: '80px',
			},
			{ text: '실명', value: 'name', width: '100px' },
			{ text: '휴대폰 번호', value: 'phoneNumber', width: '128px' },
			{ text: '생년월일', value: 'birthDate', width: '100px' },
			{ text: '유저명', value: 'uid', width: '100px' },
			{ text: '증명사진', value: 'idPhotoUrl', width: '128px' },
			{ text: '처리 상태', value: 'idPhotoStatus', width: '128px' },
			{ text: '승인 처리', value: 'idPhotoActions', width: '128px' },
		])

		const getUsers = async () => {
			try {
				isLoading.value = true
				const result = await UserService.getUsers(
					currentPage.value - 1,
					itemsPerPage.value,
					keyword.value,
					selectedIdPhotoStatus.value,
				)

				totalPages.value = parseInt(result.headers['x-total-pages-count'])
				users.value = result.data
			} catch (e) {
				console.error(e)
			} finally {
				isLoading.value = false
			}
		}

		const handlePageChange = async value => {
			currentPage.value = value
			await getUsers()
		}

		const search = async (type, event) => {
			event.preventDefault()

			if (type === 'button') {
				keyword.value = event.value
			} else {
				keyword.value = event.target.value
			}

			currentPage.value = 1

			await getUsers()
		}

		const handleClick = _userId => {
			router.push({ name: 'user-detail', params: { userId: _userId } })
		}

		const confirmIdPhoto = async userId => {
			const confirm = await confirmSwal('승인 처리하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await UserService.confirmIdPhoto(userId)
					await successSwal('승인 처리 되었습니다')
					await getUsers()
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '승인 처리하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const rejectIdPhoto = async userId => {
			const confirm = await confirmSwal('거절 처리하시겠습니까?')
			if (confirm.isConfirmed) {
				try {
					await UserService.rejectIdPhoto(userId)
					await successSwal('거절 처리 되었습니다')
					await getUsers()
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '거절 처리하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const changeItemsPerPage = async limit => {
			itemsPerPage.value = limit
			await getUsers()
		}

		const changeIdPhotoStatus = async status => {
			selectedIdPhotoStatus.value = status
			await getUsers()
		}

		const openUserIdPhotoDialog = async user => {
			selectedUser.value = user
			isOpenUserIdPhotoDialog.value = !isOpenUserIdPhotoDialog.value
		}

		onMounted(() => {
			getUsers()
		})

		return {
			headers,
			users,
			selected,
			selectedUser,
			currentPage,
			itemsPerPage,
			idPhotoStatus,
			selectedIdPhotoStatus,
			itemsPerPageGroups,
			idPhotoStatusGroups,
			totalPages,
			isLoading,
			keyword,
			isOpenUserIdPhotoDialog,

			handleClick,
			handlePageChange,
			changeItemsPerPage,
			changeIdPhotoStatus,
			search,
			openUserIdPhotoDialog,
			confirmIdPhoto,
			rejectIdPhoto,

			resolveIdPhotoStatus,
			resolveIdPhotoStatusVariant,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table {
	max-height: 560px;
}
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.clickable {
	cursor: pointer;
	color: #060539;
	text-decoration: underline;
	font-weight: 900;
}
</style>
