export default [
	{
		path: '/licenses',
		name: 'licenses',
		component: () => import('@/pages/license/LicenseList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/licenses/:licenseId',
		name: 'license-detail',
		props: true,
		component: () => import('@/pages/license/LicenseDetail'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/licenses/:eventId/test-participants',
		name: 'license-test-participants',
		component: () => import('@/pages/license/LicenseTestParticipants'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/licenses/:licenseId/test-rounds/:testRoundId',
		name: 'license-test-round',
		props: true,
		component: () => import('@/pages/license/LicenseTestRoundWorkbookList'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
	{
		path: '/licenses/:licenseId/test-rounds/:testRoundId/workbooks/:workbookId',
		name: 'license-test-round-register-answer-sheet',
		props: true,
		component: () =>
			import('@/pages/license/LicenseTestRoundWorkbookRegisterAnswerSheet'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
